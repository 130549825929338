import React, { createContext, useCallback, useState } from "react";
import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";
import { GridColDef } from "@mui/x-data-grid-pro";

import {
  TabValue,
  ViolationsGroupBy,
  ViolationsTableConfiguration,
} from "../../../../../../ReliabilityTypes";
import {
  BasicViolation,
  ImpactGroupIdentifier,
} from "../../../../../../../../generated/reliabilityApi";
import { getInitialPaginationModel } from "../violationTableConstants";

import { Dictionary } from "@/shared/types/Dictionary";

export type CustomColumnDefinition = {
  customColumns: GridColDef<BasicViolation>[];
  loadingStateColumns: GridColDef<BasicViolation>[];
};

export type ViolationsTableContextState = {
  uid?: string;
  violationsGroupBy: ViolationsGroupBy;
  impactGroupIdentifier?: ImpactGroupIdentifier | undefined;
  tabValue: TabValue;
  defaultPaginationModel?: GridPaginationModel;
  scope?: ViolationsTableConfiguration;
  setTabValue: (tabValue: TabValue) => void;
  onInternalStateChange: (state: ViolationsTableInternalState) => void;
  customColumnsDefinitions?: CustomColumnDefinition;
};

export type ViolationsTableInternalState = {
  count?: Dictionary<number>;
};

export type ViolationsTableContextProviderProps = {
  children: React.ReactNode;
  uid: string;
  violationsGroupBy: ViolationsGroupBy;
  impactGroupIdentifier?: ImpactGroupIdentifier | undefined;
  onInternalStateChange?: (state: ViolationsTableInternalState) => void;
  scope?: ViolationsTableConfiguration;
  paginationModel?: GridPaginationModel;
  customColumnsDefinitions?: CustomColumnDefinition;
};

const initialState: ViolationsTableContextState = {
  uid: undefined,
  violationsGroupBy: "Impact",
  impactGroupIdentifier: undefined,
  tabValue: "all",
  defaultPaginationModel: getInitialPaginationModel("Impact"),
  setTabValue: () => undefined,
  onInternalStateChange: () => undefined,
};

export const ViolationsTableContext =
  createContext<ViolationsTableContextState>(initialState);

const { tabValue: initialTabValue } = initialState;

export const ViolationsTableContextProvider: React.FC<
  ViolationsTableContextProviderProps
> = ({
  children,
  uid,
  violationsGroupBy,
  impactGroupIdentifier,
  scope,
  paginationModel: defaultPaginationModel,
  onInternalStateChange,
  customColumnsDefinitions,
}) => {
  const [tabValue, setTabValue] = useState<TabValue>(initialTabValue);

  const onInternalStateChangeHandler = useCallback(
    (state: ViolationsTableInternalState) => {
      onInternalStateChange?.(state);
    },
    [onInternalStateChange]
  );

  const contextState: ViolationsTableContextState = {
    uid,
    violationsGroupBy,
    impactGroupIdentifier,
    tabValue,
    scope,
    defaultPaginationModel,
    setTabValue,
    onInternalStateChange: onInternalStateChangeHandler,
    customColumnsDefinitions,
  };

  return (
    <ViolationsTableContext.Provider value={contextState}>
      {children}
    </ViolationsTableContext.Provider>
  );
};
