import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { useMemo } from "react";

import { useDriftComparisonContext } from "../context/useDriftComparisonContext";
import { DiffModal } from "../Services/Attributes/types";

import {
  buildHelmOptionValue,
  buildOptionForHelmRelease,
} from "./selectionOptionsUtils";
import { TableData } from "./types";
import {
  getBaselineTableData,
  getComparedTableData,
  getAttributesTableData,
} from "./utils";

import { HelmChart } from "@/generated/addonsApi";
import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import { HelmComparisonResponse } from "@/generated/workspacesApi";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";

export const useBaselineHelmReleasesOptions = (
  baselineHelmReleaseId: MuiSelectionOption<string> | null,
  helmReleases: HelmChart[] | undefined
): MuiSelectionOption<string>[] => {
  return useMemo(() => {
    return (helmReleases ?? [])
      .sort((a, b) => {
        const aUid = buildHelmOptionValue(a);
        const bUid = buildHelmOptionValue(b);
        if (aUid === baselineHelmReleaseId?.value) {
          return -1;
        }
        if (bUid === baselineHelmReleaseId?.value) {
          return 1;
        }
        return compareHelmReleaseInfo(a, b);
      })
      .map((helmRelease) => {
        return buildOptionForHelmRelease(helmRelease);
      });
  }, [baselineHelmReleaseId?.value, helmReleases]);
};

export const compareHelmReleaseInfo = (a: HelmChart, b: HelmChart): number => {
  return (
    a.clusterName.localeCompare(b.clusterName) ||
    a.namespace.localeCompare(b.namespace) ||
    a.name.localeCompare(b.name)
  );
};

export const useHelmReleasesList = () => {
  const { data, isError, refetch, isFetching } = useFetchLiveStateList({});
  const helmReleases = data?.data.data;
  return {
    helmReleases: helmReleases as HelmChart[] | undefined,
    isFetching,
    isError,
    refetch,
  };
};

export const useTableData = ({
  showOnlyDrift,
  data,
  helmReleases,
  setDiffModal,
}: {
  showOnlyDrift: boolean;
  data: HelmComparisonResponse | undefined;
  helmReleases: HelmChart[] | undefined;
  setDiffModal: (diffModal: DiffModal | null) => void;
}): TableData => {
  const { baselineSelection, comparedSelections } = useDriftComparisonContext();
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return useMemo(() => {
    const baseline = getBaselineTableData(data, baselineSelection);

    return {
      attributes: getAttributesTableData({
        baselineData: baseline,
        data,
        showOnlyDrift,
        helmReleases,
        pushDrawer,
        setDiffModal,
      }),
      compared: getComparedTableData({
        data,
        comparedSelections,
        showOnlyDrift,
      }),
      baseline,
    };
  }, [
    data,
    baselineSelection,
    comparedSelections,
    showOnlyDrift,
    helmReleases,
    pushDrawer,
    setDiffModal,
  ]);
};
