// import React, { useCallback, useState } from "react";
// import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { HelmComparisonSelection } from "./HelmComparisonSelection";
import { getUnsupportedWorkspaceText } from "./constants";
import { HelmComparisonContent } from "./HelmComparisonContent";

import { Addon, Entity } from "@/generated/addonsApi";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { WorkspaceGenericEmptyState } from "@/components/workspaces/WorkspaceGenericEmptyState";
import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import Divider from "@/components/monitorsView/common/Divider";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";

export const HelmDriftView: React.FC = () => {
  const { isWorkspaceKindBackendFiltered, currentWorkspace } = useWorkspaces();
  if (!isWorkspaceKindBackendFiltered && currentWorkspace) {
    return <WorkspaceGenericEmptyState title={getUnsupportedWorkspaceText()} />;
  }
  return (
    <AddonContextProvider
      addonType={Addon.HelmCharts}
      addonEntity={Entity.HelmChart}
      disableServerSidePagination
    >
      <HelmComparisonSelection />
      <Divider />
      <DatadogReportLoadingTimeContextProvider
        viewOptions={{
          name: DatadogViewNamesEnum.helmDriftAnalysisAttributes,
          context: {
            feTeam: "troubleshooting",
            beTeam: "troubleshooting",
          },
        }}
      >
        <ResponsiveLayout>
          <HelmComparisonContent />
        </ResponsiveLayout>
      </DatadogReportLoadingTimeContextProvider>
    </AddonContextProvider>
  );
};
