import React, { useRef, useState } from "react";
import {
  ComparisonTable,
  LabeledSwitch,
  LightTooltip,
} from "@komodorio/design-system/komodor-ui";

import { useDriftComparisonContext } from "../context/useDriftComparisonContext";
import { ErrorState } from "../Services/Attributes/ErrorState";
import { EmptyStateComparedItems } from "../common/EmptyStateComparedItems";
import { MAX_COMPARED_SERVICES } from "../Services/constants";
import { useShowOnlyDrift } from "../common/hooks";
import { DriftType } from "../context/types";
import { EmptyStateBaseline } from "../common/EmptyStateBaseline";
import { TableContainer } from "../common/styles";
import { useDriftDatadogReportTime } from "../Services/useDriftDatadogReportTime";
import { DiffModal, DiffModalParams } from "../common/DiffModal";

import {
  HELM_DRIFT_ATTRIBUTES_COMPONENT_NAME,
  SHOW_ONLY_DRIFT_HELM_KEY,
} from "./constants";
import { useHelmReleasesList, useTableData } from "./hooks";

import { useHelmDriftComparison } from "@/shared/hooks/workspaces-api/drift/useHelmDriftComparison";

export const HelmComparisonContent: React.FC = () => {
  const {
    baselineSelection,
    comparedSelections,
    setIsBaselineSelectorOpen,
    setIsComparedSelectorOpen,
  } = useDriftComparisonContext();

  const baselineKomodorUid = baselineSelection?.value ?? "";
  const { data, isLoading, isFetching, isError, refetch } =
    useHelmDriftComparison(
      {
        baselineKomodorUid,
        targetKomodorUids:
          comparedSelections?.map((service) => service.value) ?? [],
        withResourcesComparison: true,
      },
      {
        enabled: !!baselineKomodorUid,
      }
    );

  const divRef = useRef<HTMLDivElement>(null);
  useDriftDatadogReportTime({
    component: HELM_DRIFT_ATTRIBUTES_COMPONENT_NAME,
    isLoading: isFetching,
    divRef,
  });

  const {
    showOnlyDriftTooltipTitle,
    isDriftDetected,
    showOnlyDrift,
    onShowOnlyDriftChange,
  } = useShowOnlyDrift(SHOW_ONLY_DRIFT_HELM_KEY, data?.targetHelmAttributes);

  const { helmReleases } = useHelmReleasesList();
  const [diffModal, setDiffModal] = useState<DiffModalParams | null>(null);

  const { attributes, baseline, compared } = useTableData({
    showOnlyDrift: showOnlyDrift ?? false,
    data,
    helmReleases,
    setDiffModal,
  });

  return (
    <TableContainer ref={divRef}>
      <ComparisonTable
        title="Helm Attributes"
        titleRightElement={
          <LightTooltip title={showOnlyDriftTooltipTitle}>
            <div>
              <LabeledSwitch
                label="Show only drift"
                disabled={!isDriftDetected}
                sx={{
                  typography: {
                    color: isDriftDetected ? "primary.main" : "text.secondary",
                    fontSize: "12px",
                    fontWeight: 500,
                  },
                }}
                checked={showOnlyDrift ?? false}
                onCheck={onShowOnlyDriftChange}
              />
            </div>
          </LightTooltip>
        }
        limit={MAX_COMPARED_SERVICES}
        isLoading={isLoading && baselineSelection !== null}
        isError={isError}
        attributes={attributes}
        baseline={baseline}
        compared={compared}
        noBaselineElement={
          <EmptyStateBaseline
            type={DriftType.Helm}
            disabled={!helmReleases?.length}
            onButtonClick={() => setIsBaselineSelectorOpen(true)}
          />
        }
        noComparedItemsElement={
          comparedSelections != null && comparedSelections.length > 0 ? null : (
            <EmptyStateComparedItems
              type={DriftType.Helm}
              withButton={baselineSelection !== null}
              onButtonClick={() => setIsComparedSelectorOpen(true)}
            />
          )
        }
        errorStateElement={<ErrorState onRetryClick={refetch} />}
      />
      {diffModal ? (
        <DiffModal
          title={diffModal?.title}
          baselineUid={baseline?.komodorUidLabel ?? ""}
          comparedUid={diffModal.comparedUid}
          oldObj={diffModal?.oldObj}
          newObj={diffModal?.newObj}
          onClose={() => setDiffModal(null)}
        />
      ) : null}
    </TableContainer>
  );
};
