import { lazy, Suspense } from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import { MagicAi24 } from "icons";
import { muiColors, muiPalette } from "themes";
import { WarningAmberOutlined } from "@mui/icons-material";
import { SxProps, Theme } from "@mui/material";

const Markdown = lazy(() => import("./Markdown"));

export interface ChatMessageType {
  id: string | number;
  content: string | React.ReactNode;
  isUser: boolean;
  isFailed?: boolean;
}
export interface ChatMessageProps {
  message: ChatMessageType;
}

const MessageBubble: React.FC<{
  isUser: boolean;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}> = ({ isUser, sx = {}, children }) => (
  <>
    {!isUser && (
      <Box paddingRight="8px">
        <MagicAi24 color={muiPalette.action?.active} />
      </Box>
    )}
    <Paper
      elevation={0}
      sx={{
        padding: isUser ? "9px 16px" : 0,
        borderRadius: isUser ? "8px 8px 0 8px" : "0 8px 8px 8px",
        backgroundColor: isUser ? muiColors.gray[50] : "transparent",
        maxWidth: "90%",
        typography: "body2",
        ...sx,
      }}
    >
      {children}
    </Paper>
  </>
);

const FailedMessageBubble: React.FC<{
  isUser: boolean;
  children: React.ReactNode;
}> = ({ isUser, children }) => (
  <MessageBubble
    isUser={isUser}
    sx={{
      padding: "0 16px",
      backgroundColor: muiColors.pink[50],
      color: muiColors.pink[700],
    }}
  >
    <Box display="flex" alignItems="center" columnGap="10px">
      <WarningAmberOutlined />
      {children}
    </Box>
  </MessageBubble>
);

export const ChatMessage: React.FC<ChatMessageProps> = ({ message }) => {
  const { id, isUser, isFailed, content } = message;

  return (
    <ListItem
      key={id}
      alignItems="flex-start"
      sx={{
        justifyContent: isUser ? "flex-end" : "flex-start",
        paddingInline: "24px",
      }}
    >
      {isFailed ? (
        <FailedMessageBubble isUser={isUser}>{content}</FailedMessageBubble>
      ) : (
        <MessageBubble isUser={isUser}>
          {typeof content === "string" ? (
            <Suspense>
              <Markdown>{content}</Markdown>
            </Suspense>
          ) : (
            content
          )}
        </MessageBubble>
      )}
    </ListItem>
  );
};
