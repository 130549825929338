import { useMemo } from "react";

import { useFetchNodesData } from "../../hooks/useFetchNodesData";
import { useFetchPodsData } from "../../hooks/useFetchPodsData";
import { nodesOverTimeAggregation } from "../constants";
import { usePastWeekEpochs } from "../usePastWeekEpochs";
import { MetricData } from "../metricsType";

import { toDataPoint } from "@/components/Metrics/useMetrics";

type NodeMetricsConfig = {
  clusterNames: string[];
  isDataFetchEnabled: boolean;
};

export const useNodesMetrics = (config: NodeMetricsConfig) => {
  const { clusterNames, isDataFetchEnabled: canFetchData } = config;

  const { fromEpoch, toEpoch } = usePastWeekEpochs();

  const requestParams = {
    aggregationTypes: [nodesOverTimeAggregation],
    binSeconds: 3600 * 4,
    clusterNames,
    fromEpoch,
    toEpoch,
  };

  const {
    data: nodesData,
    isFetching: isFetchingNodesData,
    error: nodesDataError,
  } = useFetchNodesData(requestParams, canFetchData);

  const {
    data: podsData,
    isFetching: isFetchingPodsData,
    error: podsDataError,
  } = useFetchPodsData(requestParams, canFetchData);

  const nodes = nodesData?.data[nodesOverTimeAggregation];
  const pods = podsData?.data[nodesOverTimeAggregation];

  const memoryData = useMemo<MetricData>(() => {
    const { memoryUsageBytes, capacityMemoryBytes } = nodes ?? {};
    return {
      usage: memoryUsageBytes?.map(toDataPoint) ?? [],
      capacity: capacityMemoryBytes?.map(toDataPoint) ?? [],
      allocated: pods?.memoryRequestsBytes?.map(toDataPoint) ?? [],
    };
  }, [nodes, pods?.memoryRequestsBytes]);

  const cpuData = useMemo<MetricData>(() => {
    const { cpuUsageMillicpuCores, capacityMillicpuCores } = nodes ?? {};

    return {
      usage: cpuUsageMillicpuCores?.map(toDataPoint) ?? [],
      capacity: capacityMillicpuCores?.map(toDataPoint) ?? [],
      allocated: pods?.cpuRequestsMillicpu?.map(toDataPoint) ?? [],
    };
  }, [nodes, pods?.cpuRequestsMillicpu]);

  return {
    memoryData,
    cpuData,
    isFetching: isFetchingNodesData || isFetchingPodsData,
    error: nodesDataError || podsDataError,
  };
};
