/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Record, String } from "runtypes";
import { ChannelType } from "komodor-types";
import { Select, Typography } from "@komodorio/design-system/deprecated";

import {
  Container,
  Content,
  FormTextInput,
  lightStyle,
  Spacer,
  WorkflowConfigModalStyle,
} from "../styles";
import { useFetchChannelConfiguration } from "../../../../notification/useFetchChannelConfiguration";
import { blueForUIText } from "../../../../../Colors";
import { useInsertChannelConfiguration } from "../../../../notification/useInsertChannelConfiguration";
import PlusIcon from "../../../assets/plus.svg?react";
import { Grid, ErrorMessage, Divider } from "../../../common/styles";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ModalComponent from "../../../common/ModalComponent";
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";

import { ChannelLabel, WebhookGrid, WebhookInfoButton } from "./common";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { Suggestions } from ".";

import { MonitorConfiguration } from "@/generated/monitorsApi";

/*
const testMessage = {
  type: "message",
  attachments: [
    {
      contentType: "application/vnd.microsoft.card.adaptive",
      contentUrl: null,
      content: {
        type: "AdaptiveCard",
        body: [
          {
            type: "TextBlock",
            text: "Great News! Komodor has connected successfully",
            wrap: true,
          },
        ],
        $schema: "https://adaptivecards.io/schemas/adaptive-card.json",
        version: "1.4",
      },
    },
  ],
};
*/

const webhookInfoUrl =
  "https://prod.support.services.microsoft.com/en-us/office/create-incoming-webhooks-with-workflows-for-microsoft-teams-8ae491c7-0394-4861-ba59-055e33f75498";

const IntegrationConfig = Record({ channel: String, webhookUrl: String });

const Label = styled.div`
  ${lightStyle}
  padding-bottom: 0.5rem;
`;

const LinkButton = styled.a`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  ${lightStyle}
  color: ${blueForUIText};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const TeamsSinkOutput: React.FC<{
  rule: MonitorConfiguration;
  setRule: React.Dispatch<React.SetStateAction<MonitorConfiguration>>;
}> = ({ rule: configData, setRule: setConfigData }) => {
  const channelConfigurations = useFetchChannelConfiguration();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const loading = useMemo(() => {
    return channelConfigurations === undefined;
  }, [channelConfigurations]);

  const channels = useMemo(() => {
    const configurations = channelConfigurations
      ?.filter((c) => c?.type === ChannelType.Teams)
      .map((c) => c?.configuration)
      .filter((c) => IntegrationConfig.guard(c))
      .map((c) => c.channel.substring(1));
    return (
      configurations
        ?.filter((c, i) => configurations.indexOf(c) === i)
        .sort() ?? []
    );
  }, [channelConfigurations]);

  const [suggestions, setSuggestions] = useState<Suggestions>([]);
  useEffect(() => {
    if (channels.length === 0) return;
    setSuggestions(channels?.map((c) => ({ label: c, value: c })));
  }, [channels]);

  const [channel, setChannel] = useState(configData.sinks?.teams?.[0] ?? "");
  useEffect(() => {
    setConfigData({
      ...configData,
      sinks: {
        ...configData.sinks,
        teams: channel === "" ? undefined : [channel],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return loading ? null : (
    <>
      <ChannelLabel>
        <Typography size="medium">Teams Channel</Typography>
        <LinkButton onClick={() => setIsAddModalOpen(true)}>
          <PlusIcon /> Add New Channel
        </LinkButton>
      </ChannelLabel>
      <Select
        size="medium"
        width="100%"
        listMaxHeight="15rem"
        placeholder="select channel"
        searchable={suggestions.length > 5}
        options={suggestions}
        value={
          suggestions.find((o) => o.value === channel) ?? {
            label: channel,
            value: channel,
          }
        }
        onChange={(selected) => {
          setChannel(selected.value);
        }}
        onClean={() => setChannel("")}
      />
      {isAddModalOpen && (
        <AddTeamsChannelModal
          isOpen
          handleClose={() => setIsAddModalOpen(false)}
          setChannel={setChannel}
        />
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default TeamsSinkOutput;

const AddTeamsChannelModal: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
  setChannel: (channel: string) => void;
}> = ({ isOpen, handleClose, setChannel }) => {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [newChannel, setNewChannel] = useState("");
  const [webhookUrlValid, setWebhookUrlValid] = useState(true);
  const [newChannelValid, setNewChannelValid] = useState(true);
  // const [testWebhookSuccess, setTestWebhookSuccess] = useState<boolean>();
  const insertChannelConfiguration = useInsertChannelConfiguration();

  const validateWebhookUrl = () => {
    const valid = webhookUrl.match(/^https:[-a-zA-Z0-9@:%&._+~#=/?]+$/) != null;
    setWebhookUrlValid(valid);
    return valid;
  };

  const validateNewChannel = () => {
    const valid = newChannel.match(/^[a-zA-Z0-9 &_-]{1,50}$/) != null;
    setNewChannelValid(valid);
    return valid;
  };

  const handleAddChannel = () => {
    const webhookUrlValid = validateWebhookUrl();
    const newChannelValid = validateNewChannel();
    if (webhookUrlValid && newChannelValid) {
      (async function () {
        await insertChannelConfiguration({
          type: ChannelType.Teams,
          configuration: {
            channel: newChannel.startsWith("@") ? newChannel : "@" + newChannel,
            webhookUrl: webhookUrl,
          },
        });
        setChannel(
          newChannel.startsWith("@") ? newChannel.substring(1) : newChannel
        );
        setWebhookUrl("");
        setNewChannel("");
        handleClose();
      })();
    }
  };

  {
    // Broken as of CU-86c1ztpar: https://app.clickup.com/t/86c1ztpar
    /*
  const handleTestWebhook = () => {
    setTestWebhookSuccess(undefined);
    if (validateWebhookUrl()) {
      (async function () {
        try {
          await fetch(webhookUrl, {
            mode: "no-cors",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(testMessage),
          });
          setTestWebhookSuccess(true);
        } catch (e) {
          setTestWebhookSuccess(false);
        }
      })();
    }
  };
  */
  }

  return (
    <ModalComponent
      title=""
      isOpen={isOpen}
      handleCloseModal={handleClose}
      customStyle={WorkflowConfigModalStyle}
    >
      <Container>
        <Header closeModalCallback={handleClose} title="Add New Webhook" />
        <Divider />
        <Content>
          <WebhookGrid>
            <Label>Webhook URL</Label>
            <WebhookInfoButton href={webhookInfoUrl} target="_blank">
              How do I create a webhook URL?
            </WebhookInfoButton>
          </WebhookGrid>
          <Grid>
            <FormTextInput
              value={webhookUrl}
              placeholder="Enter url"
              isValid={webhookUrlValid}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWebhookUrl(e.target.value)
              }
            />
          </Grid>
          {!webhookUrlValid && (
            <ErrorMessage>A valid webhook URL is required</ErrorMessage>
          )}
          <Spacer />
          <Label>Channel Name</Label>
          <Grid>
            <FormTextInput
              value={newChannel}
              placeholder="Enter name"
              isValid={newChannelValid}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewChannel(e.target.value)
              }
            />
          </Grid>
          {!newChannelValid && (
            <ErrorMessage>A valid channel name is required</ErrorMessage>
          )}
          <Spacer />

          {/*
          <WebhookTest>
            <Button size="small" onClick={handleTestWebhook}>
              Test Webhook
            </Button>
            {testWebhookSuccess ? (
              <>
                <ResultIcon icon={successIcon} />
                <TestMessage testState={TestState.Success}>
                  Test message sent
                </TestMessage>
              </>
            ) : testWebhookSuccess === false ? (
              <>
                <ResultIcon icon={failureIcon} />
                <TestMessage testState={TestState.Failed}>
                  Sending test message failed
                </TestMessage>
              </>
            ) : (
              <></>
            )}
          </WebhookTest>
            */}
        </Content>
        <Divider />
        <Footer
          backData={{
            label: "Cancel",
            action: handleClose,
          }}
          nextData={{ label: "Continue", action: handleAddChannel }}
        />
      </Container>
    </ModalComponent>
  );
};
