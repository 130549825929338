import { ChatInput } from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface ChatFooterProps {
  input: string;
  setInput: (value: string) => void;
  shouldDisableInput: boolean;
  handleSendMessage: () => void;
}

export const ChatFooter: React.FC<ChatFooterProps> = ({
  input,
  setInput,
  shouldDisableInput,
  handleSendMessage,
}) => {
  return (
    <>
      <Divider />
      <Box
        display="grid"
        gridTemplateColumns="max-content auto"
        gap="32px"
        alignItems="center"
        padding="10px 16px"
      >
        <Typography variant="h5">Chat with Klaudia</Typography>
        <ChatInput
          value={input}
          size="medium"
          onChange={(e) => setInput(e.target.value)}
          onSend={handleSendMessage}
          placeholder="Ask me a question about this issue"
          disabled={shouldDisableInput}
          focusOnMount={false}
        />
      </Box>
    </>
  );
};
