import React, { useMemo } from "react";

import { useFetchClustersNodesOverTime } from "../../hooks/useFetchClustersNodesOverTime";
import { usePastWeekEpochs } from "../usePastWeekEpochs";
import { nodesOverTimeAggregation } from "../constants";
import { MetricsContainer } from "../MetricsContainer";
import { useClusterNames } from "../useClusterNames";

import { useStackedAreaChartProps } from "./useNodesCountHooks";

import { StackedAreaChart } from "@/components/Metrics/graphs/StackedAreaChart";
import { GraphContainerWithStatus } from "@/components/Metrics/GraphContainerWithStatus";
import { TooltipItemProps } from "@/components/Metrics/types";
import { nodeAutoscalersPageAriaLabels } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/autoscalersAriaLabels";
import { unixTimeToMilliseconds } from "@/shared/utils/timeUtils";

type NodeCountGraphProps = {
  clusterNames: string[];
};

const SECONDS_4_HOURS = 14400;

export const NodeCountGraph: React.FC<NodeCountGraphProps> = ({
  clusterNames,
}) => {
  const { hasClusters } = useClusterNames();

  const { fromEpoch, toEpoch } = usePastWeekEpochs();

  const { data, isFetching, error } = useFetchClustersNodesOverTime(
    {
      aggregationTypes: [nodesOverTimeAggregation],
      topResultsAmount: 5,
      enableOther: true,
      clusterNames,
      fromEpoch,
      toEpoch,
      binSeconds: SECONDS_4_HOURS,
    },
    hasClusters
  );

  const { nodesOverTimeData, tooltipItems, areas, legendItems } =
    useStackedAreaChartProps({
      data: data?.data,
      aggregation: nodesOverTimeAggregation,
    });

  const graphContainerProps = {
    uid: undefined,
    hasGraphData: !error && !isFetching && !!data?.data,
    error: error ? "Something went wrong" : null,
    loading: !hasClusters || isFetching,
  };

  const nodesOverTimeMillisecondData = useMemo(() => {
    return nodesOverTimeData.map((dataPoint) => ({
      ...dataPoint,
      time: unixTimeToMilliseconds(dataPoint.time),
    }));
  }, [nodesOverTimeData]);

  return (
    <MetricsContainer
      title="Node Count"
      ariaLabel={nodeAutoscalersPageAriaLabels.nodeOverTimeGraph}
    >
      <GraphContainerWithStatus {...graphContainerProps}>
        <StackedAreaChart
          data={nodesOverTimeMillisecondData}
          areas={areas}
          yAxisLabel="Nodes"
          tooltipItems={tooltipItems as TooltipItemProps[]}
          legendItems={legendItems}
        />
      </GraphContainerWithStatus>
    </MetricsContainer>
  );
};
