/* tslint:disable */
/* eslint-disable */
/**
 * Metrics API
 * This is an API that serve Kubernetes resources
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * The aggregation type to retrieve metrics data for.
 * @export
 * @enum {string}
 */

export const AggregationType = {
    Avg: 'avg',
    Max: 'max',
    P99: 'p99',
    P95: 'p95',
    P90: 'p90'
} as const;

export type AggregationType = typeof AggregationType[keyof typeof AggregationType];


        /**
 * Represents a response that fits to cost allocation graph
 * @export
 * @interface AllocationCostDataPoints
 */
export interface AllocationCostDataPoints {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof AllocationCostDataPoints
     */
    'datapoints': { [key: string]: { [key: string]: number; }; };
}
        /**
 * 
 * @export
 * @interface ApiV1CostAllocationOverTimeGetScopeParameter
 */
export interface ApiV1CostAllocationOverTimeGetScopeParameter {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiV1CostAllocationOverTimeGetScopeParameter
     */
    'clusters'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiV1CostAllocationOverTimeGetScopeParameter
     */
    'namespaces'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiV1CostAllocationOverTimeGetScopeParameter
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiV1CostAllocationOverTimeGetScopeParameter
     */
    'services'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface BucketTimeFrame
 */
export interface BucketTimeFrame {
    /**
     * 
     * @type {string}
     * @memberof BucketTimeFrame
     */
    'bucketTimeFrame': BucketTimeFrameBucketTimeFrameEnum;
}

export const BucketTimeFrameBucketTimeFrameEnum = {
    _1h: '1h',
    _24h: '24h',
    _7d: '7d',
    _30d: '30d'
} as const;

export type BucketTimeFrameBucketTimeFrameEnum = typeof BucketTimeFrameBucketTimeFrameEnum[keyof typeof BucketTimeFrameBucketTimeFrameEnum];

        /**
 * 
 * @export
 * @interface ClusterOverviewResponseInner
 */
export interface ClusterOverviewResponseInner {
    /**
     * 
     * @type {ClusterOverviewResponseSingleAggregation}
     * @memberof ClusterOverviewResponseInner
     */
    'avg'?: ClusterOverviewResponseSingleAggregation;
    /**
     * 
     * @type {ClusterOverviewResponseSingleAggregation}
     * @memberof ClusterOverviewResponseInner
     */
    'max'?: ClusterOverviewResponseSingleAggregation;
    /**
     * 
     * @type {ClusterOverviewResponseSingleAggregation}
     * @memberof ClusterOverviewResponseInner
     */
    'p99'?: ClusterOverviewResponseSingleAggregation;
    /**
     * 
     * @type {ClusterOverviewResponseSingleAggregation}
     * @memberof ClusterOverviewResponseInner
     */
    'p95'?: ClusterOverviewResponseSingleAggregation;
    /**
     * 
     * @type {ClusterOverviewResponseSingleAggregation}
     * @memberof ClusterOverviewResponseInner
     */
    'p90'?: ClusterOverviewResponseSingleAggregation;
}
        /**
 * Represents a response containing clusters overview for single aggregation
 * @export
 * @interface ClusterOverviewResponseSingleAggregation
 */
export interface ClusterOverviewResponseSingleAggregation {
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClusterOverviewResponseSingleAggregation
     */
    'memoryUsageBytes'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClusterOverviewResponseSingleAggregation
     */
    'allocatableMemoryBytes'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClusterOverviewResponseSingleAggregation
     */
    'capacityMemoryBytes'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClusterOverviewResponseSingleAggregation
     */
    'cpuUsageMillicores'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClusterOverviewResponseSingleAggregation
     */
    'allocatableMillicores'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClusterOverviewResponseSingleAggregation
     */
    'capacityMillicores'?: Array<Datapoint>;
}
        /**
 * Represents a response containing cluster summary
 * @export
 * @interface ClusterSummaryResponse
 */
export interface ClusterSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof ClusterSummaryResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryResponse
     */
    'memoryUsageBytes'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryResponse
     */
    'memoryCapacityBytes'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryResponse
     */
    'memoryUtilizationPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryResponse
     */
    'cpuUsageMilliCores'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryResponse
     */
    'cpuCapacityMilliCores'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryResponse
     */
    'cpuUtilizationPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryResponse
     */
    'allocatableMilliCpuCores'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryResponse
     */
    'allocatableMemoryByte'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryResponse
     */
    'allocatedMilliCpuCores'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummaryResponse
     */
    'allocatedMemoryByte'?: number;
}
        /**
 * Represents a response containing cluster level metrics from nodes table
 * @export
 * @interface ClustersNodesDataResponse
 */
export interface ClustersNodesDataResponse {
    /**
     * 
     * @type {ClustersNodesDataResponseSingleAggregation}
     * @memberof ClustersNodesDataResponse
     */
    'avg'?: ClustersNodesDataResponseSingleAggregation;
    /**
     * 
     * @type {ClustersNodesDataResponseSingleAggregation}
     * @memberof ClustersNodesDataResponse
     */
    'max'?: ClustersNodesDataResponseSingleAggregation;
    /**
     * 
     * @type {ClustersNodesDataResponseSingleAggregation}
     * @memberof ClustersNodesDataResponse
     */
    'p99'?: ClustersNodesDataResponseSingleAggregation;
    /**
     * 
     * @type {ClustersNodesDataResponseSingleAggregation}
     * @memberof ClustersNodesDataResponse
     */
    'p95'?: ClustersNodesDataResponseSingleAggregation;
    /**
     * 
     * @type {ClustersNodesDataResponseSingleAggregation}
     * @memberof ClustersNodesDataResponse
     */
    'p90'?: ClustersNodesDataResponseSingleAggregation;
}
        /**
 * Represents a response containing cluster usage for single aggregation
 * @export
 * @interface ClustersNodesDataResponseSingleAggregation
 */
export interface ClustersNodesDataResponseSingleAggregation {
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClustersNodesDataResponseSingleAggregation
     */
    'memoryUsageBytes'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClustersNodesDataResponseSingleAggregation
     */
    'cpuUsageMillicpuCores'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClustersNodesDataResponseSingleAggregation
     */
    'capacityMemoryBytes'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClustersNodesDataResponseSingleAggregation
     */
    'capacityMillicpuCores'?: Array<Datapoint>;
}
        /**
 * Represents a response containing cluster level metrics from pods table
 * @export
 * @interface ClustersPodsDataResponse
 */
export interface ClustersPodsDataResponse {
    /**
     * 
     * @type {ClustersPodsDataResponseSingleAggregation}
     * @memberof ClustersPodsDataResponse
     */
    'avg'?: ClustersPodsDataResponseSingleAggregation;
    /**
     * 
     * @type {ClustersPodsDataResponseSingleAggregation}
     * @memberof ClustersPodsDataResponse
     */
    'max'?: ClustersPodsDataResponseSingleAggregation;
    /**
     * 
     * @type {ClustersPodsDataResponseSingleAggregation}
     * @memberof ClustersPodsDataResponse
     */
    'p99'?: ClustersPodsDataResponseSingleAggregation;
    /**
     * 
     * @type {ClustersPodsDataResponseSingleAggregation}
     * @memberof ClustersPodsDataResponse
     */
    'p95'?: ClustersPodsDataResponseSingleAggregation;
    /**
     * 
     * @type {ClustersPodsDataResponseSingleAggregation}
     * @memberof ClustersPodsDataResponse
     */
    'p90'?: ClustersPodsDataResponseSingleAggregation;
}
        /**
 * Represents a response containing cluster usage for single aggregation
 * @export
 * @interface ClustersPodsDataResponseSingleAggregation
 */
export interface ClustersPodsDataResponseSingleAggregation {
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClustersPodsDataResponseSingleAggregation
     */
    'memoryRequestsBytes'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof ClustersPodsDataResponseSingleAggregation
     */
    'cpuRequestsMillicpu'?: Array<Datapoint>;
}
        /**
 * Represents a response containing clusters summary
 * @export
 * @interface ClustersSummaryResponse
 */
export interface ClustersSummaryResponse {
    /**
     * 
     * @type {Array<ClusterSummaryResponse>}
     * @memberof ClustersSummaryResponse
     */
    'clusters'?: Array<ClusterSummaryResponse>;
}
        /**
 * 
 * @export
 * @interface CostAllocationStatsResponse
 */
export interface CostAllocationStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof CostAllocationStatsResponse
     */
    'totalCost': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationStatsResponse
     */
    'totalOptimizationScore': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationStatsResponse
     */
    'totalPotentialSaving': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationStatsResponse
     */
    'totalUnallocatedCpu'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationStatsResponse
     */
    'totalUnallocatedMemory'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationStatsResponse
     */
    'totalUnallocatedCost'?: number;
}
        /**
 * A representation of a table row in allocation cost. Scope key/value will be determined according to the selected scope.
 * @export
 * @interface CostAllocationSummary
 */
export interface CostAllocationSummary {
    /**
     * 
     * @type {string}
     * @memberof CostAllocationSummary
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CostAllocationSummary
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof CostAllocationSummary
     */
    'komodorServiceName': string;
    /**
     * 
     * @type {string}
     * @memberof CostAllocationSummary
     */
    'komodorServiceKind': string;
    /**
     * 
     * @type {string}
     * @memberof CostAllocationSummary
     */
    'komodorServiceId': string;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummary
     */
    'shareOfCost': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummary
     */
    'cpuRequestCoreHours': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummary
     */
    'memoryRequestGBHours': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummary
     */
    'totalCost': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummary
     */
    'optimizationScore': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummary
     */
    'efficiency': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummary
     */
    'potentialSaving': number;
    /**
     * 
     * @type {string}
     * @memberof CostAllocationSummary
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummary
     */
    'idleResourcesCost'?: number;
}
        /**
 * 
 * @export
 * @interface CostAllocationSummaryResponse
 */
export interface CostAllocationSummaryResponse {
    /**
     * 
     * @type {Array<CostAllocationSummary>}
     * @memberof CostAllocationSummaryResponse
     */
    'rows': Array<CostAllocationSummary>;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummaryResponse
     */
    'totalCost': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummaryResponse
     */
    'totalOptimizationScore': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummaryResponse
     */
    'totalEfficiency': number;
    /**
     * 
     * @type {number}
     * @memberof CostAllocationSummaryResponse
     */
    'totalPotentialSaving': number;
}
        /**
 * 
 * @export
 * @interface CostFilterBy
 */
export interface CostFilterBy {
    /**
     * 
     * @type {string}
     * @memberof CostFilterBy
     */
    'column': CostFilterByColumnEnum;
    /**
     * 
     * @type {string}
     * @memberof CostFilterBy
     */
    'operator': CostFilterByOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof CostFilterBy
     */
    'value': string;
}

export const CostFilterByColumnEnum = {
    ClusterName: 'clusterName',
    Namespace: 'namespace',
    Service: 'service',
    KomodorServiceKind: 'komodorServiceKind'
} as const;

export type CostFilterByColumnEnum = typeof CostFilterByColumnEnum[keyof typeof CostFilterByColumnEnum];
export const CostFilterByOperatorEnum = {
    Equals: 'equals'
} as const;

export type CostFilterByOperatorEnum = typeof CostFilterByOperatorEnum[keyof typeof CostFilterByOperatorEnum];

        /**
 * 
 * @export
 * @interface CostOptimizationInterval
 */
export interface CostOptimizationInterval {
    /**
     * 
     * @type {number}
     * @memberof CostOptimizationInterval
     */
    'fromEpoch': number;
    /**
     * 
     * @type {number}
     * @memberof CostOptimizationInterval
     */
    'toEpoch': number;
    /**
     * 
     * @type {OptimizationStrategy}
     * @memberof CostOptimizationInterval
     */
    'optimizationStrategy'?: OptimizationStrategy;
}


        /**
 * Represents a response for optimization score trend
 * @export
 * @interface CostOptimizationScoreTrendResponse
 */
export interface CostOptimizationScoreTrendResponse {
    /**
     * 
     * @type {Array<CostOptimizationScoreTrendResponseIntervalsInner>}
     * @memberof CostOptimizationScoreTrendResponse
     */
    'intervals': Array<CostOptimizationScoreTrendResponseIntervalsInner>;
}
        /**
 * 
 * @export
 * @interface CostOptimizationScoreTrendResponseIntervalsInner
 */
export interface CostOptimizationScoreTrendResponseIntervalsInner {
    /**
     * 
     * @type {number}
     * @memberof CostOptimizationScoreTrendResponseIntervalsInner
     */
    'optimizationScore': number;
    /**
     * 
     * @type {Array<CostOptimizationScoreTrendResponseIntervalsInnerSumByDayInner>}
     * @memberof CostOptimizationScoreTrendResponseIntervalsInner
     */
    'sumByDay': Array<CostOptimizationScoreTrendResponseIntervalsInnerSumByDayInner>;
    /**
     * 
     * @type {string}
     * @memberof CostOptimizationScoreTrendResponseIntervalsInner
     */
    'bucketTimeFrame': CostOptimizationScoreTrendResponseIntervalsInnerBucketTimeFrameEnum;
}

export const CostOptimizationScoreTrendResponseIntervalsInnerBucketTimeFrameEnum = {
    _1h: '1h',
    _24h: '24h',
    _7d: '7d',
    _30d: '30d'
} as const;

export type CostOptimizationScoreTrendResponseIntervalsInnerBucketTimeFrameEnum = typeof CostOptimizationScoreTrendResponseIntervalsInnerBucketTimeFrameEnum[keyof typeof CostOptimizationScoreTrendResponseIntervalsInnerBucketTimeFrameEnum];

        /**
 * 
 * @export
 * @interface CostOptimizationScoreTrendResponseIntervalsInnerSumByDayInner
 */
export interface CostOptimizationScoreTrendResponseIntervalsInnerSumByDayInner {
    /**
     * The timestamp of the data point in milliseconds.
     * @type {number}
     * @memberof CostOptimizationScoreTrendResponseIntervalsInnerSumByDayInner
     */
    'ts': number;
    /**
     * 
     * @type {number}
     * @memberof CostOptimizationScoreTrendResponseIntervalsInnerSumByDayInner
     */
    'value': number;
}
        /**
 * A representation of a table row in right-sizing cost
 * @export
 * @interface CostRightSizingSummary
 */
export interface CostRightSizingSummary {
    /**
     * 
     * @type {string}
     * @memberof CostRightSizingSummary
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof CostRightSizingSummary
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof CostRightSizingSummary
     */
    'service': string;
    /**
     * 
     * @type {string}
     * @memberof CostRightSizingSummary
     */
    'komodorServiceKind': string;
    /**
     * 
     * @type {string}
     * @memberof CostRightSizingSummary
     */
    'komodorServiceId': string;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'shareOfCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'cpuRequestCores': number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'cpuRecommendation': number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'cpuCost': number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'memoryRequestsMiB': number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'memoryCost': number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'memoryRecommendation': number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'efficiency': number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'optimizationScore': number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'potentialSaving': number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'potentialSavingModerate': number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'potentialSavingConservative': number;
    /**
     * 
     * @type {number}
     * @memberof CostRightSizingSummary
     */
    'potentialSavingAggressive': number;
    /**
     * 
     * @type {string}
     * @memberof CostRightSizingSummary
     */
    'id': string;
}
        /**
 * 
 * @export
 * @interface CpuThrottlingDataPointsPerContainer
 */
export interface CpuThrottlingDataPointsPerContainer {
    /**
     * 
     * @type {string}
     * @memberof CpuThrottlingDataPointsPerContainer
     */
    'containerName': string;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof CpuThrottlingDataPointsPerContainer
     */
    'dataPoints': Array<Datapoint>;
}
        /**
 * Represents a response containing CPU throttling metrics per workload containers
 * @export
 * @interface CpuThrottlingResponse
 */
export interface CpuThrottlingResponse {
    /**
     * 
     * @type {Array<CpuThrottlingDataPointsPerContainer>}
     * @memberof CpuThrottlingResponse
     */
    'containers': Array<CpuThrottlingDataPointsPerContainer>;
}
        /**
 * Represents a data point with a timestamp and a value.
 * @export
 * @interface Datapoint
 */
export interface Datapoint {
    /**
     * The timestamp of the data point in milliseconds.
     * @type {number}
     * @memberof Datapoint
     */
    'timestampMs': number;
    /**
     * The value of the data point.
     * @type {number}
     * @memberof Datapoint
     */
    'value': number;
}
        /**
 * Represents a map of data points from the usage type to it\'s relevant datapoints
 * @export
 * @interface DatapointsMap
 */
export interface DatapointsMap {
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof DatapointsMap
     */
    'avgUtilization'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof DatapointsMap
     */
    'maxUtilization'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof DatapointsMap
     */
    'p90Utilization'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof DatapointsMap
     */
    'p95Utilization'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof DatapointsMap
     */
    'p99Utilization'?: Array<Datapoint>;
}
        /**
 * 
 * @export
 * @interface EarliestRowsTimeRecord
 */
export interface EarliestRowsTimeRecord {
    /**
     * 
     * @type {string}
     * @memberof EarliestRowsTimeRecord
     */
    'earliestRecordTimeInHourlyNodesTable': string;
}
        /**
 * 
 * @export
 * @interface EarliestRowsTimeResponse
 */
export interface EarliestRowsTimeResponse {
    /**
     * 
     * @type {{ [key: string]: EarliestRowsTimeRecord; }}
     * @memberof EarliestRowsTimeResponse
     */
    'clusters': { [key: string]: EarliestRowsTimeRecord; };
}
        /**
 * Represents a response containing the latest metrics data from external dns.
 * @export
 * @interface ExternalDnsMetricsResponse
 */
export interface ExternalDnsMetricsResponse {
    /**
     * 
     * @type {string}
     * @memberof ExternalDnsMetricsResponse
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalDnsMetricsResponse
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalDnsMetricsResponse
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalDnsMetricsResponse
     */
    'komodorServiceName': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalDnsMetricsResponse
     */
    'komodorServiceKind': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalDnsMetricsResponse
     */
    'lastSyncAttempt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExternalDnsMetricsResponse
     */
    'managedRecordsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ExternalDnsMetricsResponse
     */
    'unsyncedRecordsNumber': number;
    /**
     * 
     * @type {string}
     * @memberof ExternalDnsMetricsResponse
     */
    'lastSyncTime'?: string;
}
        /**
 * Represents a response for full container metrics
 * @export
 * @interface FullContainerMetricsDataPoints
 */
export interface FullContainerMetricsDataPoints {
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof FullContainerMetricsDataPoints
     */
    'request': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof FullContainerMetricsDataPoints
     */
    'limit': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof FullContainerMetricsDataPoints
     */
    'avgUtilization': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof FullContainerMetricsDataPoints
     */
    'p90Utilization': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof FullContainerMetricsDataPoints
     */
    'p95Utilization': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof FullContainerMetricsDataPoints
     */
    'p99Utilization': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof FullContainerMetricsDataPoints
     */
    'maxUtilization': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof FullContainerMetricsDataPoints
     */
    'conservativeRecommendation'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof FullContainerMetricsDataPoints
     */
    'moderateRecommendation'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof FullContainerMetricsDataPoints
     */
    'aggressiveRecommendation'?: Array<Datapoint>;
}
        /**
 * Represents a response containing a containers max utilization.
 * @export
 * @interface MaxUtilizationResponse
 */
export interface MaxUtilizationResponse {
    /**
     * 
     * @type {number}
     * @memberof MaxUtilizationResponse
     */
    'maxUtilization': number;
}
        /**
 * Represents a response containing metrics data for a specified container.
 * @export
 * @interface MetricsResponse
 */
export interface MetricsResponse {
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof MetricsResponse
     */
    'datapoints': Array<Datapoint>;
    /**
     * 
     * @type {DatapointsMap}
     * @memberof MetricsResponse
     */
    'datapointsMap'?: DatapointsMap;
    /**
     * The aggregation method used to calculate the metrics.
     * @type {string}
     * @memberof MetricsResponse
     */
    'aggregationMethod': string;
    /**
     * A token used to paginate through large result sets.
     * @type {string}
     * @memberof MetricsResponse
     */
    'paginationToken'?: string;
    /**
     * The amount of processed containers
     * @type {number}
     * @memberof MetricsResponse
     */
    'numOfContainers'?: number;
}
        /**
 * Represents a node max utilization.
 * @export
 * @interface NodeMaxUtilization
 */
export interface NodeMaxUtilization {
    /**
     * 
     * @type {string}
     * @memberof NodeMaxUtilization
     */
    'nodeName': string;
    /**
     * 
     * @type {number}
     * @memberof NodeMaxUtilization
     */
    'maxUtilization': number;
}
        /**
 * Represents a response containing nodes data by cluster
 * @export
 * @interface NodesDataByClusterResponse
 */
export interface NodesDataByClusterResponse {
    /**
     * Represents a response containing nodes data by cluster
     * @type {{ [key: string]: SingleClusterNodesData; }}
     * @memberof NodesDataByClusterResponse
     */
    'avg'?: { [key: string]: SingleClusterNodesData; };
    /**
     * Represents a response containing nodes data by cluster
     * @type {{ [key: string]: SingleClusterNodesData; }}
     * @memberof NodesDataByClusterResponse
     */
    'max'?: { [key: string]: SingleClusterNodesData; };
    /**
     * Represents a response containing nodes data by cluster
     * @type {{ [key: string]: SingleClusterNodesData; }}
     * @memberof NodesDataByClusterResponse
     */
    'p99'?: { [key: string]: SingleClusterNodesData; };
    /**
     * Represents a response containing nodes data by cluster
     * @type {{ [key: string]: SingleClusterNodesData; }}
     * @memberof NodesDataByClusterResponse
     */
    'p95'?: { [key: string]: SingleClusterNodesData; };
    /**
     * Represents a response containing nodes data by cluster
     * @type {{ [key: string]: SingleClusterNodesData; }}
     * @memberof NodesDataByClusterResponse
     */
    'p90'?: { [key: string]: SingleClusterNodesData; };
}
        /**
 * Represents a response containing a nodes max utilization.
 * @export
 * @interface NodesMaxUtilizationResponse
 */
export interface NodesMaxUtilizationResponse {
    /**
     * 
     * @type {Array<NodeMaxUtilization>}
     * @memberof NodesMaxUtilizationResponse
     */
    'nodes': Array<NodeMaxUtilization>;
}
        /**
 * Represents a response containing nodes over time
 * @export
 * @interface NodesOverTimeResponse
 */
export interface NodesOverTimeResponse {
    /**
     * 
     * @type {NodesOverTimeResponseSingleAggregationGrouped}
     * @memberof NodesOverTimeResponse
     */
    'avg'?: NodesOverTimeResponseSingleAggregationGrouped;
    /**
     * 
     * @type {NodesOverTimeResponseSingleAggregationGrouped}
     * @memberof NodesOverTimeResponse
     */
    'max'?: NodesOverTimeResponseSingleAggregationGrouped;
    /**
     * 
     * @type {NodesOverTimeResponseSingleAggregationGrouped}
     * @memberof NodesOverTimeResponse
     */
    'p99'?: NodesOverTimeResponseSingleAggregationGrouped;
    /**
     * 
     * @type {NodesOverTimeResponseSingleAggregationGrouped}
     * @memberof NodesOverTimeResponse
     */
    'p95'?: NodesOverTimeResponseSingleAggregationGrouped;
    /**
     * 
     * @type {NodesOverTimeResponseSingleAggregationGrouped}
     * @memberof NodesOverTimeResponse
     */
    'p90'?: NodesOverTimeResponseSingleAggregationGrouped;
}
        /**
 * Represents a response containing nodes over time for single aggregation grouped by cluster by default
 * @export
 * @interface NodesOverTimeResponseSingleAggregationGrouped
 */
export interface NodesOverTimeResponseSingleAggregationGrouped {
    /**
     * 
     * @type {{ [key: string]: Array<Datapoint>; }}
     * @memberof NodesOverTimeResponseSingleAggregationGrouped
     */
    'topClusters'?: { [key: string]: Array<Datapoint>; };
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof NodesOverTimeResponseSingleAggregationGrouped
     */
    'other'?: Array<Datapoint>;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const OptimizationStrategy = {
    Conservative: 'conservative',
    Moderate: 'moderate',
    Aggressive: 'aggressive'
} as const;

export type OptimizationStrategy = typeof OptimizationStrategy[keyof typeof OptimizationStrategy];


        /**
 * 
 * @export
 * @interface PodMemoryAggregated
 */
export interface PodMemoryAggregated {
    /**
     * 
     * @type {string}
     * @memberof PodMemoryAggregated
     */
    'podName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodMemoryAggregated
     */
    'namespace'?: string;
    /**
     * 
     * @type {number}
     * @memberof PodMemoryAggregated
     */
    'memoryRequest'?: number;
    /**
     * 
     * @type {number}
     * @memberof PodMemoryAggregated
     */
    'memoryLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PodMemoryAggregated
     */
    'memoryUsage'?: number;
    /**
     * 
     * @type {Array<PodMemoryAggregatedContainersInner>}
     * @memberof PodMemoryAggregated
     */
    'containers'?: Array<PodMemoryAggregatedContainersInner>;
}
        /**
 * 
 * @export
 * @interface PodMemoryAggregatedBody
 */
export interface PodMemoryAggregatedBody {
    /**
     * 
     * @type {string}
     * @memberof PodMemoryAggregatedBody
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof PodMemoryAggregatedBody
     */
    'aggregationType': PodMemoryAggregatedBodyAggregationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PodMemoryAggregatedBody
     */
    'fromEpoch': number;
    /**
     * 
     * @type {number}
     * @memberof PodMemoryAggregatedBody
     */
    'toEpoch': number;
    /**
     * 
     * @type {string}
     * @memberof PodMemoryAggregatedBody
     */
    'order'?: PodMemoryAggregatedBodyOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PodMemoryAggregatedBody
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PodMemoryAggregatedBody
     */
    'podsNames': Array<string>;
}

export const PodMemoryAggregatedBodyAggregationTypeEnum = {
    Avg: 'avg',
    Min: 'min',
    Max: 'max'
} as const;

export type PodMemoryAggregatedBodyAggregationTypeEnum = typeof PodMemoryAggregatedBodyAggregationTypeEnum[keyof typeof PodMemoryAggregatedBodyAggregationTypeEnum];
export const PodMemoryAggregatedBodyOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type PodMemoryAggregatedBodyOrderEnum = typeof PodMemoryAggregatedBodyOrderEnum[keyof typeof PodMemoryAggregatedBodyOrderEnum];

        /**
 * 
 * @export
 * @interface PodMemoryAggregatedContainersInner
 */
export interface PodMemoryAggregatedContainersInner {
    /**
     * 
     * @type {string}
     * @memberof PodMemoryAggregatedContainersInner
     */
    'containerName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PodMemoryAggregatedContainersInner
     */
    'memoryRequest'?: number;
    /**
     * 
     * @type {number}
     * @memberof PodMemoryAggregatedContainersInner
     */
    'memoryLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PodMemoryAggregatedContainersInner
     */
    'memoryUsage'?: number;
}
        /**
 * Represents a response containing pods data by cluster
 * @export
 * @interface PodsDataByClusterResponse
 */
export interface PodsDataByClusterResponse {
    /**
     * Represents a response containing pods data by cluster
     * @type {{ [key: string]: SingleClusterPodsData; }}
     * @memberof PodsDataByClusterResponse
     */
    'avg'?: { [key: string]: SingleClusterPodsData; };
    /**
     * Represents a response containing pods data by cluster
     * @type {{ [key: string]: SingleClusterPodsData; }}
     * @memberof PodsDataByClusterResponse
     */
    'max'?: { [key: string]: SingleClusterPodsData; };
    /**
     * Represents a response containing pods data by cluster
     * @type {{ [key: string]: SingleClusterPodsData; }}
     * @memberof PodsDataByClusterResponse
     */
    'p99'?: { [key: string]: SingleClusterPodsData; };
    /**
     * Represents a response containing pods data by cluster
     * @type {{ [key: string]: SingleClusterPodsData; }}
     * @memberof PodsDataByClusterResponse
     */
    'p95'?: { [key: string]: SingleClusterPodsData; };
    /**
     * Represents a response containing pods data by cluster
     * @type {{ [key: string]: SingleClusterPodsData; }}
     * @memberof PodsDataByClusterResponse
     */
    'p90'?: { [key: string]: SingleClusterPodsData; };
}
        /**
 * 
 * @export
 * @interface PodsMemoryAggregatedResponse
 */
export interface PodsMemoryAggregatedResponse {
    /**
     * 
     * @type {Array<PodMemoryAggregated>}
     * @memberof PodsMemoryAggregatedResponse
     */
    'rows': Array<PodMemoryAggregated>;
}
        /**
 * 
 * @export
 * @interface ResourceConfigurations
 */
export interface ResourceConfigurations {
    /**
     * cpu in mili cores
     * @type {number}
     * @memberof ResourceConfigurations
     */
    'cpu': number;
    /**
     * memory in MiB
     * @type {number}
     * @memberof ResourceConfigurations
     */
    'memory': number;
}
        /**
 * Represents a data point with a timestamp and a value.
 * @export
 * @interface RightSizingCostDataPoint
 */
export interface RightSizingCostDataPoint {
    /**
     * The timestamp of the data point in milliseconds.
     * @type {number}
     * @memberof RightSizingCostDataPoint
     */
    'timestampEpoch': number;
    /**
     * Actual usage.
     * @type {number}
     * @memberof RightSizingCostDataPoint
     */
    'usage': number;
    /**
     * Requests.
     * @type {number}
     * @memberof RightSizingCostDataPoint
     */
    'request': number;
    /**
     * Recommendation.
     * @type {number}
     * @memberof RightSizingCostDataPoint
     */
    'recommendation': number;
}
        /**
 * Represents a response that fits to cost allocation graph
 * @export
 * @interface RightSizingCostDataPoints
 */
export interface RightSizingCostDataPoints {
    /**
     * 
     * @type {Array<RightSizingCostDataPoint>}
     * @memberof RightSizingCostDataPoints
     */
    'datapoints': Array<RightSizingCostDataPoint>;
}
        /**
 * 
 * @export
 * @interface RightSizingCostRowsResponse
 */
export interface RightSizingCostRowsResponse {
    /**
     * 
     * @type {Array<CostRightSizingSummary>}
     * @memberof RightSizingCostRowsResponse
     */
    'rows': Array<CostRightSizingSummary>;
    /**
     * 
     * @type {string}
     * @memberof RightSizingCostRowsResponse
     */
    'paginationToken': string;
    /**
     * 
     * @type {boolean}
     * @memberof RightSizingCostRowsResponse
     */
    'hasMorePages': boolean;
}
        /**
 * 
 * @export
 * @interface RightSizingCostStatsResponse
 */
export interface RightSizingCostStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostStatsResponse
     */
    'monthlySaving': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostStatsResponse
     */
    'potentialSavingsModerate': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostStatsResponse
     */
    'potentialSavingsConservative': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostStatsResponse
     */
    'potentialSavingsAggressive': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostStatsResponse
     */
    'currentCostPerMonth': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostStatsResponse
     */
    'yearlySaving': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostStatsResponse
     */
    'unallocatedResourcesPrice': number;
}
        /**
 * 
 * @export
 * @interface RightSizingCostSummaryByService
 */
export interface RightSizingCostSummaryByService {
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryByService
     */
    'cpuRequestMiliCore': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryByService
     */
    'cpuMiliCoreRecommendationModerate': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryByService
     */
    'cpuMiliCoreRecommendationConservative': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryByService
     */
    'cpuMiliCoreRecommendationAggressive': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryByService
     */
    'memoryRequestMiB': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryByService
     */
    'memoryRequestMiBRecommendationModerate': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryByService
     */
    'memoryRequestMiBRecommendationConservative': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryByService
     */
    'memoryRequestMiBRecommendationAggressive': number;
    /**
     * 
     * @type {string}
     * @memberof RightSizingCostSummaryByService
     */
    'containerName': string;
}
        /**
 * 
 * @export
 * @interface RightSizingCostSummaryByServiceResponse
 */
export interface RightSizingCostSummaryByServiceResponse {
    /**
     * 
     * @type {Array<RightSizingCostSummaryByService>}
     * @memberof RightSizingCostSummaryByServiceResponse
     */
    'rows': Array<RightSizingCostSummaryByService>;
}
        /**
 * 
 * @export
 * @interface RightSizingCostSummaryResponse
 */
export interface RightSizingCostSummaryResponse {
    /**
     * 
     * @type {Array<CostRightSizingSummary>}
     * @memberof RightSizingCostSummaryResponse
     */
    'rows': Array<CostRightSizingSummary>;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryResponse
     */
    'monthlySaving': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryResponse
     */
    'potentialSavingsModerate'?: number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryResponse
     */
    'potentialSavingsConservative'?: number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryResponse
     */
    'potentialSavingsAggressive'?: number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryResponse
     */
    'currentCostPerMonth': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryResponse
     */
    'yearlySaving': number;
    /**
     * 
     * @type {number}
     * @memberof RightSizingCostSummaryResponse
     */
    'unallocatedResourcesPrice': number;
}
        /**
 * 
 * @export
 * @interface RightSizingOrderBy
 */
export interface RightSizingOrderBy {
    /**
     * 
     * @type {string}
     * @memberof RightSizingOrderBy
     */
    'column'?: RightSizingOrderByColumnEnum;
    /**
     * 
     * @type {string}
     * @memberof RightSizingOrderBy
     */
    'order'?: RightSizingOrderByOrderEnum;
}

export const RightSizingOrderByColumnEnum = {
    PotentialSaving: 'potentialSaving',
    OptimizationScore: 'optimizationScore',
    ShareOfCost: 'shareOfCost',
    KomodorServiceKind: 'komodorServiceKind',
    Service: 'service',
    Namespace: 'namespace',
    ClusterName: 'clusterName'
} as const;

export type RightSizingOrderByColumnEnum = typeof RightSizingOrderByColumnEnum[keyof typeof RightSizingOrderByColumnEnum];
export const RightSizingOrderByOrderEnum = {
    Desc: 'desc',
    Asc: 'asc'
} as const;

export type RightSizingOrderByOrderEnum = typeof RightSizingOrderByOrderEnum[keyof typeof RightSizingOrderByOrderEnum];

        /**
 * Represents a response containing nodes data by cluster
 * @export
 * @interface SingleClusterNodesData
 */
export interface SingleClusterNodesData {
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof SingleClusterNodesData
     */
    'capacityMillicpuCores'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof SingleClusterNodesData
     */
    'capacityMemoryBytes'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof SingleClusterNodesData
     */
    'cpuUsageNanocores'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof SingleClusterNodesData
     */
    'memoryUsageBytes'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof SingleClusterNodesData
     */
    'numberOfNodes'?: Array<Datapoint>;
}
        /**
 * Represents a response containing cluster level metrics from pods table
 * @export
 * @interface SingleClusterPodsData
 */
export interface SingleClusterPodsData {
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof SingleClusterPodsData
     */
    'resourceRequestsMillicpuUnits'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof SingleClusterPodsData
     */
    'resourceRequestsMemoryBytes'?: Array<Datapoint>;
}
        /**
 * A representation of throttled app containers data
 * @export
 * @interface ThrottledAppContainersResponse
 */
export interface ThrottledAppContainersResponse {
    /**
     * 
     * @type {Array<ThrottledAppContainersResponseAffectedContainersInner>}
     * @memberof ThrottledAppContainersResponse
     */
    'affectedContainers': Array<ThrottledAppContainersResponseAffectedContainersInner>;
}
        /**
 * 
 * @export
 * @interface ThrottledAppContainersResponseAffectedContainersInner
 */
export interface ThrottledAppContainersResponseAffectedContainersInner {
    /**
     * 
     * @type {string}
     * @memberof ThrottledAppContainersResponseAffectedContainersInner
     */
    'containerName': string;
    /**
     * The percentage of time this container has been throttled
     * @type {number}
     * @memberof ThrottledAppContainersResponseAffectedContainersInner
     */
    'aboveThrottleLimitTotal': number;
    /**
     * 
     * @type {ResourceConfigurations}
     * @memberof ThrottledAppContainersResponseAffectedContainersInner
     */
    'limits': ResourceConfigurations;
    /**
     * 
     * @type {ResourceConfigurations}
     * @memberof ThrottledAppContainersResponseAffectedContainersInner
     */
    'requests': ResourceConfigurations;
    /**
     * 
     * @type {string}
     * @memberof ThrottledAppContainersResponseAffectedContainersInner
     */
    'komodorServiceId': string;
    /**
     * 
     * @type {string}
     * @memberof ThrottledAppContainersResponseAffectedContainersInner
     */
    'komodorServiceName': string;
    /**
     * 
     * @type {string}
     * @memberof ThrottledAppContainersResponseAffectedContainersInner
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof ThrottledAppContainersResponseAffectedContainersInner
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof ThrottledAppContainersResponseAffectedContainersInner
     */
    'komodorServiceKind': string;
}
        /**
 * An aggregation of all the pods for this container throttled time
 * @export
 * @interface ThrottledContainerAggregation
 */
export interface ThrottledContainerAggregation {
    /**
     * 
     * @type {number}
     * @memberof ThrottledContainerAggregation
     */
    'percentage': number;
}
        /**
 * Represents a response containing pod names.
 * @export
 * @interface TopPodsResponse
 */
export interface TopPodsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof TopPodsResponse
     */
    'podNames': Array<string>;
}
        /**
 * 
 * @export
 * @interface WorkloadMetricsContainerSummary
 */
export interface WorkloadMetricsContainerSummary {
    /**
     * 
     * @type {string}
     * @memberof WorkloadMetricsContainerSummary
     */
    'containerName': string;
    /**
     * 
     * @type {WorkloadMetricsSummary}
     * @memberof WorkloadMetricsContainerSummary
     */
    'summary': WorkloadMetricsSummary;
}
        /**
 * Represents a response for workload containers metrics
 * @export
 * @interface WorkloadMetricsContainers
 */
export interface WorkloadMetricsContainers {
    /**
     * 
     * @type {Array<WorkloadMetricsContainersDataPoints>}
     * @memberof WorkloadMetricsContainers
     */
    'containers': Array<WorkloadMetricsContainersDataPoints>;
}
        /**
 * 
 * @export
 * @interface WorkloadMetricsContainersDataPoints
 */
export interface WorkloadMetricsContainersDataPoints {
    /**
     * 
     * @type {string}
     * @memberof WorkloadMetricsContainersDataPoints
     */
    'containerName': string;
    /**
     * 
     * @type {WorkloadMetricsDataPoints}
     * @memberof WorkloadMetricsContainersDataPoints
     */
    'dataPoints': WorkloadMetricsDataPoints;
}
        /**
 * Represents a response for workload containers restarts
 * @export
 * @interface WorkloadMetricsContainersRestarts
 */
export interface WorkloadMetricsContainersRestarts {
    /**
     * 
     * @type {Array<WorkloadMetricsContainersRestartsDataPoints>}
     * @memberof WorkloadMetricsContainersRestarts
     */
    'containers': Array<WorkloadMetricsContainersRestartsDataPoints>;
}
        /**
 * 
 * @export
 * @interface WorkloadMetricsContainersRestartsDataPoints
 */
export interface WorkloadMetricsContainersRestartsDataPoints {
    /**
     * 
     * @type {string}
     * @memberof WorkloadMetricsContainersRestartsDataPoints
     */
    'containerName': string;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadMetricsContainersRestartsDataPoints
     */
    'dataPoints': Array<Datapoint>;
}
        /**
 * Represents a response for workload containers metrics summary
 * @export
 * @interface WorkloadMetricsContainersSummary
 */
export interface WorkloadMetricsContainersSummary {
    /**
     * 
     * @type {Array<WorkloadMetricsContainerSummary>}
     * @memberof WorkloadMetricsContainersSummary
     */
    'containers': Array<WorkloadMetricsContainerSummary>;
}
        /**
 * Represents a response for workload metrics
 * @export
 * @interface WorkloadMetricsDataPoints
 */
export interface WorkloadMetricsDataPoints {
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadMetricsDataPoints
     */
    'request': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadMetricsDataPoints
     */
    'limit': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadMetricsDataPoints
     */
    'avgUtilization': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadMetricsDataPoints
     */
    'maxUtilization': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadMetricsDataPoints
     */
    'p90Utilization': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadMetricsDataPoints
     */
    'p95Utilization': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadMetricsDataPoints
     */
    'p99Utilization': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadMetricsDataPoints
     */
    'conservativeRecommendation'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadMetricsDataPoints
     */
    'moderateRecommendation'?: Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadMetricsDataPoints
     */
    'aggressiveRecommendation'?: Array<Datapoint>;
}
        /**
 * Represents a response for workload metrics summary
 * @export
 * @interface WorkloadMetricsSummary
 */
export interface WorkloadMetricsSummary {
    /**
     * The latest requests value for a resource kind, over a time window. -1 if no requests are set.
     * @type {number}
     * @memberof WorkloadMetricsSummary
     */
    'latestRequests': number;
    /**
     * The latest limits value for a resource kind, over a time window. -1 if no limits are set.
     * @type {number}
     * @memberof WorkloadMetricsSummary
     */
    'latestLimits': number;
    /**
     * The avg utilization value for a resource kind, over a time window.
     * @type {number}
     * @memberof WorkloadMetricsSummary
     */
    'avgUtilization': number;
    /**
     * The max utilization value for a resource kind, over a time window.
     * @type {number}
     * @memberof WorkloadMetricsSummary
     */
    'maxUtilization': number;
    /**
     * The 90th percentile utilization value for a resource kind, over a time window.
     * @type {number}
     * @memberof WorkloadMetricsSummary
     */
    'p90Utilization': number;
    /**
     * The 95th percentile utilization value for a resource kind, over a time window.
     * @type {number}
     * @memberof WorkloadMetricsSummary
     */
    'p95Utilization': number;
    /**
     * The 99th percentile utilization value for a resource kind, over a time window.
     * @type {number}
     * @memberof WorkloadMetricsSummary
     */
    'p99Utilization': number;
}
        /**
 * Represents a response containing metrics for the specified workload
 * @export
 * @interface WorkloadReplicasStatusResponse
 */
export interface WorkloadReplicasStatusResponse {
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadReplicasStatusResponse
     */
    'ready': Array<Datapoint>;
    /**
     * 
     * @type {Array<Datapoint>}
     * @memberof WorkloadReplicasStatusResponse
     */
    'desired': Array<Datapoint>;
}
    
        /**
* Get the cluster nodes data by cluster from the raw table
* @summary Get the cluster nodes data by cluster from the raw table
* @param params ApisApiApiV1ClustersNodesDataByClusterGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersNodesDataByClusterGetUrl = (params: ApisApiApiV1ClustersNodesDataByClusterGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1ClustersNodesDataByClusterGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1ClustersNodesDataByClusterGet', 'toEpoch', params.toEpoch)
    const localVarPath = `/api/v1/clusters/nodes-data-by-cluster`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.paginationToken !== undefined) {
            localVarQueryParameter['paginationToken'] = params.paginationToken;
    }
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.aggregationTypes) {
        localVarQueryParameter['aggregationTypes'] = params.aggregationTypes;
    }
    if (params.binSeconds !== undefined) {
            localVarQueryParameter['binSeconds'] = params.binSeconds;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the cluster pods data by cluster from the raw table
* @summary Get the cluster pods data by cluster from the raw table
* @param params ApisApiApiV1ClustersPodsDataByClusterGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersPodsDataByClusterGetUrl = (params: ApisApiApiV1ClustersPodsDataByClusterGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1ClustersPodsDataByClusterGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1ClustersPodsDataByClusterGet', 'toEpoch', params.toEpoch)
    const localVarPath = `/api/v1/clusters/pods-data-by-cluster`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.paginationToken !== undefined) {
            localVarQueryParameter['paginationToken'] = params.paginationToken;
    }
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.aggregationTypes) {
        localVarQueryParameter['aggregationTypes'] = params.aggregationTypes;
    }
    if (params.binSeconds !== undefined) {
            localVarQueryParameter['binSeconds'] = params.binSeconds;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the cluster summary details
* @summary Get the cluster cpu and memory details
* @param params ApisApiApiV1ClustersSummaryGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersSummaryGetUrl = (params: ApisApiApiV1ClustersSummaryGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/clusters/summary`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves node max utilization.
* @summary Get memory max utilization in percentage for a node
* @param params ApisApiApiV1NodeMemoryUtilizationMaxGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NodeMemoryUtilizationMaxGetUrl = (params: ApisApiApiV1NodeMemoryUtilizationMaxGetRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1NodeMemoryUtilizationMaxGet', 'clusterName', params.clusterName)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1NodeMemoryUtilizationMaxGet', 'toEpoch', params.toEpoch)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1NodeMemoryUtilizationMaxGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'nodeNames' is not null or undefined
    assertParamExists('apiV1NodeMemoryUtilizationMaxGet', 'nodeNames', params.nodeNames)
    const localVarPath = `/api/v1/node/memory/utilization/max`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.nodeNames) {
        localVarQueryParameter['nodeNames'] = params.nodeNames;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves containers max utilization.
* @summary Get memory max utilization in percentage for a specified container
* @param params ApisApiApiV1PodContainerNameMemoryUtilizationMaxGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1PodContainerNameMemoryUtilizationMaxGetUrl = (params: ApisApiApiV1PodContainerNameMemoryUtilizationMaxGetRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryUtilizationMaxGet', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryUtilizationMaxGet', 'namespace', params.namespace)
    // verify required parameter 'podName' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryUtilizationMaxGet', 'podName', params.podName)
    // verify required parameter 'containerName' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryUtilizationMaxGet', 'containerName', params.containerName)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryUtilizationMaxGet', 'toEpoch', params.toEpoch)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryUtilizationMaxGet', 'fromEpoch', params.fromEpoch)
    const localVarPath = `/api/v1/pod/:containerName/memory/utilization/max`
        .replace(`:${"containerName"}`, encodeURIComponent(String(params.containerName)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.podName !== undefined) {
            localVarQueryParameter['podName'] = params.podName;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves the top x consuming pods from the pod list sent.
* @summary Get top consuming pod names for a container
* @param params ApisApiApiV1PodTopConsumingContainerNameKindTypeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1PodTopConsumingContainerNameKindTypeGetUrl = (params: ApisApiApiV1PodTopConsumingContainerNameKindTypeGetRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1PodTopConsumingContainerNameKindTypeGet', 'type', params.type)
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1PodTopConsumingContainerNameKindTypeGet', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1PodTopConsumingContainerNameKindTypeGet', 'namespace', params.namespace)
    // verify required parameter 'podNames' is not null or undefined
    assertParamExists('apiV1PodTopConsumingContainerNameKindTypeGet', 'podNames', params.podNames)
    // verify required parameter 'containerName' is not null or undefined
    assertParamExists('apiV1PodTopConsumingContainerNameKindTypeGet', 'containerName', params.containerName)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1PodTopConsumingContainerNameKindTypeGet', 'toEpoch', params.toEpoch)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1PodTopConsumingContainerNameKindTypeGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'kind' is not null or undefined
    assertParamExists('apiV1PodTopConsumingContainerNameKindTypeGet', 'kind', params.kind)
    const localVarPath = `/api/v1/pod/top-consuming/:containerName/:kind/:type`
        .replace(`:${"type"}`, encodeURIComponent(String(params.type)))
        .replace(`:${"containerName"}`, encodeURIComponent(String(params.containerName)))
        .replace(`:${"kind"}`, encodeURIComponent(String(params.kind)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.podNames !== undefined) {
            localVarQueryParameter['podNames'] = params.podNames;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ClustersNodesDataByClusterGet operation in ApisApi.
* @export
* @interface ApisApiApiV1ClustersNodesDataByClusterGetRequest
*/
export interface ApisApiApiV1ClustersNodesDataByClusterGetRequest {
    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof ApisApiApiV1ClustersNodesDataByClusterGet
    */
    readonly fromEpoch: number

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof ApisApiApiV1ClustersNodesDataByClusterGet
    */
    readonly toEpoch: number

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof ApisApiApiV1ClustersNodesDataByClusterGet
    */
    readonly accountId?: string

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof ApisApiApiV1ClustersNodesDataByClusterGet
    */
    readonly pageSize?: number

    /**
    * pagination token for getting the next page
    * @type {string}
    * @memberof ApisApiApiV1ClustersNodesDataByClusterGet
    */
    readonly paginationToken?: string

    /**
    * The names of the clusters.
    * @type {Array<string>}
    * @memberof ApisApiApiV1ClustersNodesDataByClusterGet
    */
    readonly clusterNames?: Array<string>

    /**
    * The aggregation types to retrieve metrics data for. Valid values are \&quot;avg\&quot;, \&quot;max\&quot;, \&quot;p99\&quot;, \&quot;p95\&quot;, \&quot;p90\&quot;.
    * @type {Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>}
    * @memberof ApisApiApiV1ClustersNodesDataByClusterGet
    */
    readonly aggregationTypes?: Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>

    /**
    * Time bin in seconds of each entry in the response
    * @type {number}
    * @memberof ApisApiApiV1ClustersNodesDataByClusterGet
    */
    readonly binSeconds?: number
}

/**
* Request parameters for apiV1ClustersPodsDataByClusterGet operation in ApisApi.
* @export
* @interface ApisApiApiV1ClustersPodsDataByClusterGetRequest
*/
export interface ApisApiApiV1ClustersPodsDataByClusterGetRequest {
    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof ApisApiApiV1ClustersPodsDataByClusterGet
    */
    readonly fromEpoch: number

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof ApisApiApiV1ClustersPodsDataByClusterGet
    */
    readonly toEpoch: number

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof ApisApiApiV1ClustersPodsDataByClusterGet
    */
    readonly accountId?: string

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof ApisApiApiV1ClustersPodsDataByClusterGet
    */
    readonly pageSize?: number

    /**
    * pagination token for getting the next page
    * @type {string}
    * @memberof ApisApiApiV1ClustersPodsDataByClusterGet
    */
    readonly paginationToken?: string

    /**
    * The names of the clusters.
    * @type {Array<string>}
    * @memberof ApisApiApiV1ClustersPodsDataByClusterGet
    */
    readonly clusterNames?: Array<string>

    /**
    * The aggregation types to retrieve metrics data for. Valid values are \&quot;avg\&quot;, \&quot;max\&quot;, \&quot;p99\&quot;, \&quot;p95\&quot;, \&quot;p90\&quot;.
    * @type {Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>}
    * @memberof ApisApiApiV1ClustersPodsDataByClusterGet
    */
    readonly aggregationTypes?: Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>

    /**
    * Time bin in seconds of each entry in the response
    * @type {number}
    * @memberof ApisApiApiV1ClustersPodsDataByClusterGet
    */
    readonly binSeconds?: number
}

/**
* Request parameters for apiV1ClustersSummaryGet operation in ApisApi.
* @export
* @interface ApisApiApiV1ClustersSummaryGetRequest
*/
export interface ApisApiApiV1ClustersSummaryGetRequest {
    /**
    * The names of the clusters.
    * @type {Array<string>}
    * @memberof ApisApiApiV1ClustersSummaryGet
    */
    readonly clusterNames?: Array<string>

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof ApisApiApiV1ClustersSummaryGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1NodeMemoryUtilizationMaxGet operation in ApisApi.
* @export
* @interface ApisApiApiV1NodeMemoryUtilizationMaxGetRequest
*/
export interface ApisApiApiV1NodeMemoryUtilizationMaxGetRequest {
    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof ApisApiApiV1NodeMemoryUtilizationMaxGet
    */
    readonly clusterName: string

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof ApisApiApiV1NodeMemoryUtilizationMaxGet
    */
    readonly toEpoch: number

    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof ApisApiApiV1NodeMemoryUtilizationMaxGet
    */
    readonly fromEpoch: number

    /**
    * The names of the nodes for metrics.
    * @type {Array<string>}
    * @memberof ApisApiApiV1NodeMemoryUtilizationMaxGet
    */
    readonly nodeNames: Array<string>

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof ApisApiApiV1NodeMemoryUtilizationMaxGet
    */
    readonly pageSize?: number
}

/**
* Request parameters for apiV1PodContainerNameMemoryUtilizationMaxGet operation in ApisApi.
* @export
* @interface ApisApiApiV1PodContainerNameMemoryUtilizationMaxGetRequest
*/
export interface ApisApiApiV1PodContainerNameMemoryUtilizationMaxGetRequest {
    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof ApisApiApiV1PodContainerNameMemoryUtilizationMaxGet
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof ApisApiApiV1PodContainerNameMemoryUtilizationMaxGet
    */
    readonly namespace: string

    /**
    * The name of the pod where the container is located.
    * @type {string}
    * @memberof ApisApiApiV1PodContainerNameMemoryUtilizationMaxGet
    */
    readonly podName: string

    /**
    * The name of the container to retrieve metrics for.
    * @type {string}
    * @memberof ApisApiApiV1PodContainerNameMemoryUtilizationMaxGet
    */
    readonly containerName: string

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof ApisApiApiV1PodContainerNameMemoryUtilizationMaxGet
    */
    readonly toEpoch: number

    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof ApisApiApiV1PodContainerNameMemoryUtilizationMaxGet
    */
    readonly fromEpoch: number

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof ApisApiApiV1PodContainerNameMemoryUtilizationMaxGet
    */
    readonly pageSize?: number
}

/**
* Request parameters for apiV1PodTopConsumingContainerNameKindTypeGet operation in ApisApi.
* @export
* @interface ApisApiApiV1PodTopConsumingContainerNameKindTypeGetRequest
*/
export interface ApisApiApiV1PodTopConsumingContainerNameKindTypeGetRequest {
    /**
    * The type of memory metric to retrieve. Valid values are \&quot;usage\&quot;, \&quot;limit\&quot;, and \&quot;request\&quot;.
    * @type {'usage' | 'limit' | 'request' | 'capacity'}
    * @memberof ApisApiApiV1PodTopConsumingContainerNameKindTypeGet
    */
    readonly type: 'usage' | 'limit' | 'request' | 'capacity'

    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof ApisApiApiV1PodTopConsumingContainerNameKindTypeGet
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof ApisApiApiV1PodTopConsumingContainerNameKindTypeGet
    */
    readonly namespace: string

    /**
    * The names of the pods where the container is located.
    * @type {string}
    * @memberof ApisApiApiV1PodTopConsumingContainerNameKindTypeGet
    */
    readonly podNames: string

    /**
    * The name of the container to retrieve metrics for.
    * @type {string}
    * @memberof ApisApiApiV1PodTopConsumingContainerNameKindTypeGet
    */
    readonly containerName: string

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof ApisApiApiV1PodTopConsumingContainerNameKindTypeGet
    */
    readonly toEpoch: number

    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof ApisApiApiV1PodTopConsumingContainerNameKindTypeGet
    */
    readonly fromEpoch: number

    /**
    * The kind of metric to retrieve. Valid values are \&quot;memory\&quot;, \&quot;cpu\&quot;.
    * @type {'memory' | 'cpu'}
    * @memberof ApisApiApiV1PodTopConsumingContainerNameKindTypeGet
    */
    readonly kind: 'memory' | 'cpu'

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof ApisApiApiV1PodTopConsumingContainerNameKindTypeGet
    */
    readonly pageSize?: number
}


    
        /**
* Get containers that reach their CPU limit data
* @summary Get containers that reach their CPU limit data
* @param params AvailabilityApiGetThrottledAppContainersRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getThrottledAppContainersUrl = (params: AvailabilityApiGetThrottledAppContainersRequest, baseUrl: string): string => {
    // verify required parameter 'totalThrottleTimeThreshold' is not null or undefined
    assertParamExists('getThrottledAppContainers', 'totalThrottleTimeThreshold', params.totalThrottleTimeThreshold)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('getThrottledAppContainers', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('getThrottledAppContainers', 'toEpoch', params.toEpoch)
    const localVarPath = `/api/v1/availability/trends/throttled-containers`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.totalThrottleTimeThreshold !== undefined) {
            localVarQueryParameter['totalThrottleTimeThreshold'] = params.totalThrottleTimeThreshold;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Aggergate container throttled precentage across all of his pods
* @summary Aggergate container throttled precentage across all of his pods
* @param params AvailabilityApiGetThrottledContainersPercentageRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getThrottledContainersPercentageUrl = (params: AvailabilityApiGetThrottledContainersPercentageRequest, baseUrl: string): string => {
    // verify required parameter 'komodorUid' is not null or undefined
    assertParamExists('getThrottledContainersPercentage', 'komodorUid', params.komodorUid)
    // verify required parameter 'containerName' is not null or undefined
    assertParamExists('getThrottledContainersPercentage', 'containerName', params.containerName)
    const localVarPath = `/api/v1/availability/trends/throttled-container/aggregation`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.komodorUid !== undefined) {
            localVarQueryParameter['komodorUid'] = params.komodorUid;
    }
    if (params.containerName !== undefined) {
            localVarQueryParameter['containerName'] = params.containerName;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for getThrottledAppContainers operation in AvailabilityApi.
* @export
* @interface AvailabilityApiGetThrottledAppContainersRequest
*/
export interface AvailabilityApiGetThrottledAppContainersRequest {
    /**
    * Indicates the percentage of time which the container was throttled at least
    * @type {number}
    * @memberof AvailabilityApiGetThrottledAppContainers
    */
    readonly totalThrottleTimeThreshold: number

    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof AvailabilityApiGetThrottledAppContainers
    */
    readonly fromEpoch: number

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof AvailabilityApiGetThrottledAppContainers
    */
    readonly toEpoch: number

    /**
    * App view id provided in the request headers
    * @type {string}
    * @memberof AvailabilityApiGetThrottledAppContainers
    */
    readonly xAppViewId?: string
}

/**
* Request parameters for getThrottledContainersPercentage operation in AvailabilityApi.
* @export
* @interface AvailabilityApiGetThrottledContainersPercentageRequest
*/
export interface AvailabilityApiGetThrottledContainersPercentageRequest {
    /**
    * 
    * @type {string}
    * @memberof AvailabilityApiGetThrottledContainersPercentage
    */
    readonly komodorUid: string

    /**
    * The name of the container to retrieve metrics for.
    * @type {string}
    * @memberof AvailabilityApiGetThrottledContainersPercentage
    */
    readonly containerName: string
}


    
        /**
* Get the cluster usage details
* @summary Get the cluster usage details
* @param params ClustersApiApiV1ClustersNodesDataGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersNodesDataGetUrl = (params: ClustersApiApiV1ClustersNodesDataGetRequest, baseUrl: string): string => {
    // verify required parameter 'clusterNames' is not null or undefined
    assertParamExists('apiV1ClustersNodesDataGet', 'clusterNames', params.clusterNames)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1ClustersNodesDataGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1ClustersNodesDataGet', 'toEpoch', params.toEpoch)
    const localVarPath = `/api/v1/clusters/nodes-data`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.paginationToken !== undefined) {
            localVarQueryParameter['paginationToken'] = params.paginationToken;
    }
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.aggregationTypes) {
        localVarQueryParameter['aggregationTypes'] = params.aggregationTypes;
    }
    if (params.binSeconds !== undefined) {
            localVarQueryParameter['binSeconds'] = params.binSeconds;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the nodes count over time grouped by cluster
* @summary Get the node count over time grouped by cluster
* @param params ClustersApiApiV1ClustersNodesOverTimeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersNodesOverTimeGetUrl = (params: ClustersApiApiV1ClustersNodesOverTimeGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1ClustersNodesOverTimeGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1ClustersNodesOverTimeGet', 'toEpoch', params.toEpoch)
    const localVarPath = `/api/v1/clusters/nodes-over-time`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.aggregationTypes) {
        localVarQueryParameter['aggregationTypes'] = params.aggregationTypes;
    }
    if (params.topResultsAmount !== undefined) {
            localVarQueryParameter['topResultsAmount'] = params.topResultsAmount;
    }
    if (params.enableOther !== undefined) {
            localVarQueryParameter['enableOther'] = params.enableOther;
    }
    if (params.binSeconds !== undefined) {
            localVarQueryParameter['binSeconds'] = params.binSeconds;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the cluster overview details
* @summary Get the cluster overview details
* @param params ClustersApiApiV1ClustersOverviewGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersOverviewGetUrl = (params: ClustersApiApiV1ClustersOverviewGetRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1ClustersOverviewGet', 'clusterName', params.clusterName)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1ClustersOverviewGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1ClustersOverviewGet', 'toEpoch', params.toEpoch)
    const localVarPath = `/api/v1/clusters/overview`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.paginationToken !== undefined) {
            localVarQueryParameter['paginationToken'] = params.paginationToken;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the cluster pods details
* @summary Get the cluster pods details
* @param params ClustersApiApiV1ClustersPodsDataGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersPodsDataGetUrl = (params: ClustersApiApiV1ClustersPodsDataGetRequest, baseUrl: string): string => {
    // verify required parameter 'clusterNames' is not null or undefined
    assertParamExists('apiV1ClustersPodsDataGet', 'clusterNames', params.clusterNames)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1ClustersPodsDataGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1ClustersPodsDataGet', 'toEpoch', params.toEpoch)
    const localVarPath = `/api/v1/clusters/pods-data`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.paginationToken !== undefined) {
            localVarQueryParameter['paginationToken'] = params.paginationToken;
    }
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.aggregationTypes) {
        localVarQueryParameter['aggregationTypes'] = params.aggregationTypes;
    }
    if (params.binSeconds !== undefined) {
            localVarQueryParameter['binSeconds'] = params.binSeconds;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ClustersNodesDataGet operation in ClustersApi.
* @export
* @interface ClustersApiApiV1ClustersNodesDataGetRequest
*/
export interface ClustersApiApiV1ClustersNodesDataGetRequest {
    /**
    * The names of the clusters.
    * @type {Array<string>}
    * @memberof ClustersApiApiV1ClustersNodesDataGet
    */
    readonly clusterNames: Array<string>

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof ClustersApiApiV1ClustersNodesDataGet
    */
    readonly fromEpoch: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof ClustersApiApiV1ClustersNodesDataGet
    */
    readonly toEpoch: number

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof ClustersApiApiV1ClustersNodesDataGet
    */
    readonly pageSize?: number

    /**
    * pagination token for getting the next page
    * @type {string}
    * @memberof ClustersApiApiV1ClustersNodesDataGet
    */
    readonly paginationToken?: string

    /**
    * The aggregation types to retrieve metrics data for. Valid values are \&quot;avg\&quot;, \&quot;max\&quot;, \&quot;p99\&quot;, \&quot;p95\&quot;, \&quot;p90\&quot;.
    * @type {Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>}
    * @memberof ClustersApiApiV1ClustersNodesDataGet
    */
    readonly aggregationTypes?: Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>

    /**
    * Time bin in seconds of each entry in the response
    * @type {number}
    * @memberof ClustersApiApiV1ClustersNodesDataGet
    */
    readonly binSeconds?: number
}

/**
* Request parameters for apiV1ClustersNodesOverTimeGet operation in ClustersApi.
* @export
* @interface ClustersApiApiV1ClustersNodesOverTimeGetRequest
*/
export interface ClustersApiApiV1ClustersNodesOverTimeGetRequest {
    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof ClustersApiApiV1ClustersNodesOverTimeGet
    */
    readonly fromEpoch: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof ClustersApiApiV1ClustersNodesOverTimeGet
    */
    readonly toEpoch: number

    /**
    * The names of the clusters.
    * @type {Array<string>}
    * @memberof ClustersApiApiV1ClustersNodesOverTimeGet
    */
    readonly clusterNames?: Array<string>

    /**
    * The aggregation types to retrieve metrics data for. Valid values are \&quot;avg\&quot;, \&quot;max\&quot;, \&quot;p99\&quot;, \&quot;p95\&quot;, \&quot;p90\&quot;.
    * @type {Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>}
    * @memberof ClustersApiApiV1ClustersNodesOverTimeGet
    */
    readonly aggregationTypes?: Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>

    /**
    * The amount of top results to return. 0 by default
    * @type {number}
    * @memberof ClustersApiApiV1ClustersNodesOverTimeGet
    */
    readonly topResultsAmount?: number

    /**
    * Enable the \&quot;other\&quot; aggregation group. true by default
    * @type {boolean}
    * @memberof ClustersApiApiV1ClustersNodesOverTimeGet
    */
    readonly enableOther?: boolean

    /**
    * Time bin in seconds of each entry in the response
    * @type {number}
    * @memberof ClustersApiApiV1ClustersNodesOverTimeGet
    */
    readonly binSeconds?: number
}

/**
* Request parameters for apiV1ClustersOverviewGet operation in ClustersApi.
* @export
* @interface ClustersApiApiV1ClustersOverviewGetRequest
*/
export interface ClustersApiApiV1ClustersOverviewGetRequest {
    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof ClustersApiApiV1ClustersOverviewGet
    */
    readonly clusterName: string

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof ClustersApiApiV1ClustersOverviewGet
    */
    readonly fromEpoch: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof ClustersApiApiV1ClustersOverviewGet
    */
    readonly toEpoch: number

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof ClustersApiApiV1ClustersOverviewGet
    */
    readonly pageSize?: number

    /**
    * pagination token for getting the next page
    * @type {string}
    * @memberof ClustersApiApiV1ClustersOverviewGet
    */
    readonly paginationToken?: string
}

/**
* Request parameters for apiV1ClustersPodsDataGet operation in ClustersApi.
* @export
* @interface ClustersApiApiV1ClustersPodsDataGetRequest
*/
export interface ClustersApiApiV1ClustersPodsDataGetRequest {
    /**
    * The names of the clusters.
    * @type {Array<string>}
    * @memberof ClustersApiApiV1ClustersPodsDataGet
    */
    readonly clusterNames: Array<string>

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof ClustersApiApiV1ClustersPodsDataGet
    */
    readonly fromEpoch: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof ClustersApiApiV1ClustersPodsDataGet
    */
    readonly toEpoch: number

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof ClustersApiApiV1ClustersPodsDataGet
    */
    readonly pageSize?: number

    /**
    * pagination token for getting the next page
    * @type {string}
    * @memberof ClustersApiApiV1ClustersPodsDataGet
    */
    readonly paginationToken?: string

    /**
    * The aggregation types to retrieve metrics data for. Valid values are \&quot;avg\&quot;, \&quot;max\&quot;, \&quot;p99\&quot;, \&quot;p95\&quot;, \&quot;p90\&quot;.
    * @type {Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>}
    * @memberof ClustersApiApiV1ClustersPodsDataGet
    */
    readonly aggregationTypes?: Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>

    /**
    * Time bin in seconds of each entry in the response
    * @type {number}
    * @memberof ClustersApiApiV1ClustersPodsDataGet
    */
    readonly binSeconds?: number
}


    
        /**
* Get the cost allocation details as time series
* @summary Get the cost allocation details as time series
* @param params CostApiApiV1CostAllocationOverTimeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CostAllocationOverTimeGetUrl = (params: CostApiApiV1CostAllocationOverTimeGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1CostAllocationOverTimeGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1CostAllocationOverTimeGet', 'toEpoch', params.toEpoch)
    // verify required parameter 'groupBy' is not null or undefined
    assertParamExists('apiV1CostAllocationOverTimeGet', 'groupBy', params.groupBy)
    const localVarPath = `/api/v1/cost/allocation/over-time`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.scope !== undefined) {
            localVarQueryParameter['scope'] = params.scope;
    }
    if (params.groupBy !== undefined) {
            localVarQueryParameter['groupBy'] = params.groupBy;
    }
    if (params.timeAggregatedBy !== undefined) {
            localVarQueryParameter['timeAggregatedBy'] = params.timeAggregatedBy;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the cost allocation summary stats
* @summary Get the cost allocation summary stats
* @param params CostApiApiV1CostAllocationStatsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CostAllocationStatsGetUrl = (params: CostApiApiV1CostAllocationStatsGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1CostAllocationStatsGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1CostAllocationStatsGet', 'toEpoch', params.toEpoch)
    const localVarPath = `/api/v1/cost/allocation/stats`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.scope !== undefined) {
            localVarQueryParameter['scope'] = params.scope;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the cost allocation summary details
* @summary Get the cost allocation summary details
* @param params CostApiApiV1CostAllocationSummaryGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CostAllocationSummaryGetUrl = (params: CostApiApiV1CostAllocationSummaryGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1CostAllocationSummaryGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1CostAllocationSummaryGet', 'toEpoch', params.toEpoch)
    // verify required parameter 'groupBy' is not null or undefined
    assertParamExists('apiV1CostAllocationSummaryGet', 'groupBy', params.groupBy)
    const localVarPath = `/api/v1/cost/allocation/summary`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.scope !== undefined) {
            localVarQueryParameter['scope'] = params.scope;
    }
    if (params.groupBy !== undefined) {
            localVarQueryParameter['groupBy'] = params.groupBy;
    }
    if (params.timeAggregatedBy !== undefined) {
            localVarQueryParameter['timeAggregatedBy'] = params.timeAggregatedBy;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the earliest time in the DB which the account and the cluster has
* @summary Get the earliest time in the DB which the account and the cluster has
* @param params CostApiApiV1CostEarliestRowsTimeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CostEarliestRowsTimeGetUrl = (params: CostApiApiV1CostEarliestRowsTimeGetRequest, baseUrl: string): string => {
    // verify required parameter 'clusterNames' is not null or undefined
    assertParamExists('apiV1CostEarliestRowsTimeGet', 'clusterNames', params.clusterNames)
    const localVarPath = `/api/v1/cost/earliest-rows-time`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the cost right-sizing details as time series by type
* @summary Get the cost right-sizing details as time series by type
* @param params CostApiApiV1CostRightSizingOverTimeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CostRightSizingOverTimeGetUrl = (params: CostApiApiV1CostRightSizingOverTimeGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1CostRightSizingOverTimeGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1CostRightSizingOverTimeGet', 'toEpoch', params.toEpoch)
    // verify required parameter 'strategy' is not null or undefined
    assertParamExists('apiV1CostRightSizingOverTimeGet', 'strategy', params.strategy)
    // verify required parameter 'metricType' is not null or undefined
    assertParamExists('apiV1CostRightSizingOverTimeGet', 'metricType', params.metricType)
    const localVarPath = `/api/v1/cost/right-sizing/over-time`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.strategy !== undefined) {
            localVarQueryParameter['strategy'] = params.strategy;
    }
    if (params.scope !== undefined) {
            localVarQueryParameter['scope'] = params.scope;
    }
    if (params.metricType !== undefined) {
            localVarQueryParameter['metricType'] = params.metricType;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the cost right sizing rows in paginated mode
* @summary Get the cost right sizing rows in paginated mode
* @param params CostApiApiV1CostRightSizingRowsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CostRightSizingRowsGetUrl = (params: CostApiApiV1CostRightSizingRowsGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1CostRightSizingRowsGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1CostRightSizingRowsGet', 'toEpoch', params.toEpoch)
    // verify required parameter 'strategy' is not null or undefined
    assertParamExists('apiV1CostRightSizingRowsGet', 'strategy', params.strategy)
    const localVarPath = `/api/v1/cost/right-sizing/rows`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.strategy !== undefined) {
            localVarQueryParameter['strategy'] = params.strategy;
    }
    if (params.scope !== undefined) {
            localVarQueryParameter['scope'] = params.scope;
    }
    if (params.orderBy !== undefined) {
            localVarQueryParameter['orderBy'] = params.orderBy;
    }
    if (params.filterBy !== undefined) {
            localVarQueryParameter['filterBy'] = params.filterBy;
    }
    if (params.paginationToken !== undefined) {
            localVarQueryParameter['paginationToken'] = params.paginationToken;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the cost right sizing stats
* @summary Get the cost right sizing stats
* @param params CostApiApiV1CostRightSizingStatsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CostRightSizingStatsGetUrl = (params: CostApiApiV1CostRightSizingStatsGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1CostRightSizingStatsGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1CostRightSizingStatsGet', 'toEpoch', params.toEpoch)
    // verify required parameter 'strategy' is not null or undefined
    assertParamExists('apiV1CostRightSizingStatsGet', 'strategy', params.strategy)
    const localVarPath = `/api/v1/cost/right-sizing/stats`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.strategy !== undefined) {
            localVarQueryParameter['strategy'] = params.strategy;
    }
    if (params.scope !== undefined) {
            localVarQueryParameter['scope'] = params.scope;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the cost right-sizing summary details for a given service
* @summary Get the cost right-sizing summary details for a given service
* @param params CostApiApiV1CostRightSizingSummaryAggregatedGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CostRightSizingSummaryAggregatedGetUrl = (params: CostApiApiV1CostRightSizingSummaryAggregatedGetRequest, baseUrl: string): string => {
    // verify required parameter 'komodorServiceName' is not null or undefined
    assertParamExists('apiV1CostRightSizingSummaryAggregatedGet', 'komodorServiceName', params.komodorServiceName)
    // verify required parameter 'komodorServiceKind' is not null or undefined
    assertParamExists('apiV1CostRightSizingSummaryAggregatedGet', 'komodorServiceKind', params.komodorServiceKind)
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1CostRightSizingSummaryAggregatedGet', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1CostRightSizingSummaryAggregatedGet', 'namespace', params.namespace)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1CostRightSizingSummaryAggregatedGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1CostRightSizingSummaryAggregatedGet', 'toEpoch', params.toEpoch)
    const localVarPath = `/api/v1/cost/right-sizing/summary/aggregated`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.komodorServiceName !== undefined) {
            localVarQueryParameter['komodorServiceName'] = params.komodorServiceName;
    }
    if (params.komodorServiceKind !== undefined) {
            localVarQueryParameter['komodorServiceKind'] = params.komodorServiceKind;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the cost right-sizing summary details
* @summary Get the cost right-sizing summary details
* @param params CostApiApiV1CostRightSizingSummaryGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CostRightSizingSummaryGetUrl = (params: CostApiApiV1CostRightSizingSummaryGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1CostRightSizingSummaryGet', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('apiV1CostRightSizingSummaryGet', 'toEpoch', params.toEpoch)
    // verify required parameter 'strategy' is not null or undefined
    assertParamExists('apiV1CostRightSizingSummaryGet', 'strategy', params.strategy)
    const localVarPath = `/api/v1/cost/right-sizing/summary`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.strategy !== undefined) {
            localVarQueryParameter['strategy'] = params.strategy;
    }
    if (params.scope !== undefined) {
            localVarQueryParameter['scope'] = params.scope;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the trend of the optimization score over the previous two weeks
* @summary Get the trend of the optimization score over the previous two weeks
* @param params CostApiGetCostOptimizationTrendsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getCostOptimizationTrendsUrl = (params: CostApiGetCostOptimizationTrendsRequest, baseUrl: string): string => {
    // verify required parameter 'costOptimizationInterval' is not null or undefined
    assertParamExists('getCostOptimizationTrends', 'costOptimizationInterval', params.costOptimizationInterval)
    // verify required parameter 'bucketTimeFrame' is not null or undefined
    assertParamExists('getCostOptimizationTrends', 'bucketTimeFrame', params.bucketTimeFrame)
    const localVarPath = `/api/v1/cost/trends/optimization-score`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.costOptimizationInterval) {
        localVarQueryParameter['costOptimizationInterval'] = params.costOptimizationInterval;
    }
    if (params.bucketTimeFrame !== undefined) {
            localVarQueryParameter['bucketTimeFrame'] = params.bucketTimeFrame;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1CostAllocationOverTimeGet operation in CostApi.
* @export
* @interface CostApiApiV1CostAllocationOverTimeGetRequest
*/
export interface CostApiApiV1CostAllocationOverTimeGetRequest {
    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostAllocationOverTimeGet
    */
    readonly fromEpoch: number

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostAllocationOverTimeGet
    */
    readonly toEpoch: number

    /**
    * The grouping criteria
    * @type {'clusterName' | 'namespace' | 'label' | 'komodorServiceName'}
    * @memberof CostApiApiV1CostAllocationOverTimeGet
    */
    readonly groupBy: 'clusterName' | 'namespace' | 'label' | 'komodorServiceName'

    /**
    * The scope of the metrics data set
    * @type {ApiV1CostAllocationOverTimeGetScopeParameter}
    * @memberof CostApiApiV1CostAllocationOverTimeGet
    */
    readonly scope?: ApiV1CostAllocationOverTimeGetScopeParameter

    /**
    * The time window for which to retrieve metrics data
    * @type {'1h' | '1w' | '1m'}
    * @memberof CostApiApiV1CostAllocationOverTimeGet
    */
    readonly timeAggregatedBy?: '1h' | '1w' | '1m'
}

/**
* Request parameters for apiV1CostAllocationStatsGet operation in CostApi.
* @export
* @interface CostApiApiV1CostAllocationStatsGetRequest
*/
export interface CostApiApiV1CostAllocationStatsGetRequest {
    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostAllocationStatsGet
    */
    readonly fromEpoch: number

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostAllocationStatsGet
    */
    readonly toEpoch: number

    /**
    * The scope of the metrics data set
    * @type {ApiV1CostAllocationOverTimeGetScopeParameter}
    * @memberof CostApiApiV1CostAllocationStatsGet
    */
    readonly scope?: ApiV1CostAllocationOverTimeGetScopeParameter
}

/**
* Request parameters for apiV1CostAllocationSummaryGet operation in CostApi.
* @export
* @interface CostApiApiV1CostAllocationSummaryGetRequest
*/
export interface CostApiApiV1CostAllocationSummaryGetRequest {
    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostAllocationSummaryGet
    */
    readonly fromEpoch: number

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostAllocationSummaryGet
    */
    readonly toEpoch: number

    /**
    * The grouping criteria
    * @type {'clusterName' | 'namespace' | 'label' | 'komodorServiceName'}
    * @memberof CostApiApiV1CostAllocationSummaryGet
    */
    readonly groupBy: 'clusterName' | 'namespace' | 'label' | 'komodorServiceName'

    /**
    * The scope of the metrics data set
    * @type {ApiV1CostAllocationOverTimeGetScopeParameter}
    * @memberof CostApiApiV1CostAllocationSummaryGet
    */
    readonly scope?: ApiV1CostAllocationOverTimeGetScopeParameter

    /**
    * The time window for which to retrieve metrics data
    * @type {'1h' | '1w' | '1m'}
    * @memberof CostApiApiV1CostAllocationSummaryGet
    */
    readonly timeAggregatedBy?: '1h' | '1w' | '1m'

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof CostApiApiV1CostAllocationSummaryGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1CostEarliestRowsTimeGet operation in CostApi.
* @export
* @interface CostApiApiV1CostEarliestRowsTimeGetRequest
*/
export interface CostApiApiV1CostEarliestRowsTimeGetRequest {
    /**
    * The names of the clusters.
    * @type {Array<string>}
    * @memberof CostApiApiV1CostEarliestRowsTimeGet
    */
    readonly clusterNames: Array<string>
}

/**
* Request parameters for apiV1CostRightSizingOverTimeGet operation in CostApi.
* @export
* @interface CostApiApiV1CostRightSizingOverTimeGetRequest
*/
export interface CostApiApiV1CostRightSizingOverTimeGetRequest {
    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostRightSizingOverTimeGet
    */
    readonly fromEpoch: number

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostRightSizingOverTimeGet
    */
    readonly toEpoch: number

    /**
    * The optimization strategy which will effect the end results.
    * @type {OptimizationStrategy}
    * @memberof CostApiApiV1CostRightSizingOverTimeGet
    */
    readonly strategy: OptimizationStrategy

    /**
    * 
    * @type {'cpu' | 'memory'}
    * @memberof CostApiApiV1CostRightSizingOverTimeGet
    */
    readonly metricType: 'cpu' | 'memory'

    /**
    * The scope of the metrics data set
    * @type {ApiV1CostAllocationOverTimeGetScopeParameter}
    * @memberof CostApiApiV1CostRightSizingOverTimeGet
    */
    readonly scope?: ApiV1CostAllocationOverTimeGetScopeParameter
}

/**
* Request parameters for apiV1CostRightSizingRowsGet operation in CostApi.
* @export
* @interface CostApiApiV1CostRightSizingRowsGetRequest
*/
export interface CostApiApiV1CostRightSizingRowsGetRequest {
    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostRightSizingRowsGet
    */
    readonly fromEpoch: number

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostRightSizingRowsGet
    */
    readonly toEpoch: number

    /**
    * The optimization strategy which will effect the end results.
    * @type {OptimizationStrategy}
    * @memberof CostApiApiV1CostRightSizingRowsGet
    */
    readonly strategy: OptimizationStrategy

    /**
    * The scope of the metrics data set
    * @type {ApiV1CostAllocationOverTimeGetScopeParameter}
    * @memberof CostApiApiV1CostRightSizingRowsGet
    */
    readonly scope?: ApiV1CostAllocationOverTimeGetScopeParameter

    /**
    * The order by criteria
    * @type {RightSizingOrderBy}
    * @memberof CostApiApiV1CostRightSizingRowsGet
    */
    readonly orderBy?: RightSizingOrderBy

    /**
    * right sizing filters
    * @type {CostFilterBy}
    * @memberof CostApiApiV1CostRightSizingRowsGet
    */
    readonly filterBy?: CostFilterBy

    /**
    * pagination token for getting the next page
    * @type {string}
    * @memberof CostApiApiV1CostRightSizingRowsGet
    */
    readonly paginationToken?: string

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof CostApiApiV1CostRightSizingRowsGet
    */
    readonly pageSize?: number
}

/**
* Request parameters for apiV1CostRightSizingStatsGet operation in CostApi.
* @export
* @interface CostApiApiV1CostRightSizingStatsGetRequest
*/
export interface CostApiApiV1CostRightSizingStatsGetRequest {
    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostRightSizingStatsGet
    */
    readonly fromEpoch: number

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostRightSizingStatsGet
    */
    readonly toEpoch: number

    /**
    * The optimization strategy which will effect the end results.
    * @type {OptimizationStrategy}
    * @memberof CostApiApiV1CostRightSizingStatsGet
    */
    readonly strategy: OptimizationStrategy

    /**
    * The scope of the metrics data set
    * @type {ApiV1CostAllocationOverTimeGetScopeParameter}
    * @memberof CostApiApiV1CostRightSizingStatsGet
    */
    readonly scope?: ApiV1CostAllocationOverTimeGetScopeParameter
}

/**
* Request parameters for apiV1CostRightSizingSummaryAggregatedGet operation in CostApi.
* @export
* @interface CostApiApiV1CostRightSizingSummaryAggregatedGetRequest
*/
export interface CostApiApiV1CostRightSizingSummaryAggregatedGetRequest {
    /**
    * 
    * @type {string}
    * @memberof CostApiApiV1CostRightSizingSummaryAggregatedGet
    */
    readonly komodorServiceName: string

    /**
    * 
    * @type {string}
    * @memberof CostApiApiV1CostRightSizingSummaryAggregatedGet
    */
    readonly komodorServiceKind: string

    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof CostApiApiV1CostRightSizingSummaryAggregatedGet
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof CostApiApiV1CostRightSizingSummaryAggregatedGet
    */
    readonly namespace: string

    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostRightSizingSummaryAggregatedGet
    */
    readonly fromEpoch: number

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostRightSizingSummaryAggregatedGet
    */
    readonly toEpoch: number
}

/**
* Request parameters for apiV1CostRightSizingSummaryGet operation in CostApi.
* @export
* @interface CostApiApiV1CostRightSizingSummaryGetRequest
*/
export interface CostApiApiV1CostRightSizingSummaryGetRequest {
    /**
    * The start timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostRightSizingSummaryGet
    */
    readonly fromEpoch: number

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof CostApiApiV1CostRightSizingSummaryGet
    */
    readonly toEpoch: number

    /**
    * The optimization strategy which will effect the end results.
    * @type {OptimizationStrategy}
    * @memberof CostApiApiV1CostRightSizingSummaryGet
    */
    readonly strategy: OptimizationStrategy

    /**
    * App view id provided in the request headers
    * @type {string}
    * @memberof CostApiApiV1CostRightSizingSummaryGet
    */
    readonly xAppViewId?: string

    /**
    * The scope of the metrics data set
    * @type {ApiV1CostAllocationOverTimeGetScopeParameter}
    * @memberof CostApiApiV1CostRightSizingSummaryGet
    */
    readonly scope?: ApiV1CostAllocationOverTimeGetScopeParameter
}

/**
* Request parameters for getCostOptimizationTrends operation in CostApi.
* @export
* @interface CostApiGetCostOptimizationTrendsRequest
*/
export interface CostApiGetCostOptimizationTrendsRequest {
    /**
    * Represents an interval which data is taking from. Including from, to and optional strategy
    * @type {Array<CostOptimizationInterval>}
    * @memberof CostApiGetCostOptimizationTrends
    */
    readonly costOptimizationInterval: Array<CostOptimizationInterval>

    /**
    * The time frame each bucket should represent
    * @type {'1h' | '24h' | '7d' | '30d'}
    * @memberof CostApiGetCostOptimizationTrends
    */
    readonly bucketTimeFrame: '1h' | '24h' | '7d' | '30d'

    /**
    * App view id provided in the request headers
    * @type {string}
    * @memberof CostApiGetCostOptimizationTrends
    */
    readonly xAppViewId?: string
}


    
        /**
* Retrieves the latest metrics for external-dns resources. if there are multiple pods for the same external-dns resource, the one updated last will be returned.
* @summary Get the latest metrics for external-dns resources
* @param params ExternalDnsApiApiV1ExternalDnsLatestMetricsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ExternalDnsLatestMetricsGetUrl = (params: ExternalDnsApiApiV1ExternalDnsLatestMetricsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/external-dns/latest-metrics`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ExternalDnsLatestMetricsGet operation in ExternalDnsApi.
* @export
* @interface ExternalDnsApiApiV1ExternalDnsLatestMetricsGetRequest
*/
export interface ExternalDnsApiApiV1ExternalDnsLatestMetricsGetRequest {
    /**
    * The names of the clusters.
    * @type {Array<string>}
    * @memberof ExternalDnsApiApiV1ExternalDnsLatestMetricsGet
    */
    readonly clusterNames?: Array<string>

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof ExternalDnsApiApiV1ExternalDnsLatestMetricsGet
    */
    readonly accountId?: string

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof ExternalDnsApiApiV1ExternalDnsLatestMetricsGet
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof ExternalDnsApiApiV1ExternalDnsLatestMetricsGet
    */
    readonly toEpoch?: number
}


    
        /**
* Retrieves full CPU metrics data for a container based on the specified cluster, namespace, pod, and container names.
* @summary Get all CPU metrics for a specified container
* @param params MetricsApiApiV1FullMetricsPodContainerNameCpuGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1FullMetricsPodContainerNameCpuGetUrl = (params: MetricsApiApiV1FullMetricsPodContainerNameCpuGetRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1FullMetricsPodContainerNameCpuGet', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1FullMetricsPodContainerNameCpuGet', 'namespace', params.namespace)
    // verify required parameter 'podName' is not null or undefined
    assertParamExists('apiV1FullMetricsPodContainerNameCpuGet', 'podName', params.podName)
    // verify required parameter 'containerName' is not null or undefined
    assertParamExists('apiV1FullMetricsPodContainerNameCpuGet', 'containerName', params.containerName)
    // verify required parameter 'endTime' is not null or undefined
    assertParamExists('apiV1FullMetricsPodContainerNameCpuGet', 'endTime', params.endTime)
    const localVarPath = `/api/v1/fullMetrics/pod/:containerName/cpu`
        .replace(`:${"containerName"}`, encodeURIComponent(String(params.containerName)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.podName !== undefined) {
            localVarQueryParameter['podName'] = params.podName;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.timeWindow !== undefined) {
            localVarQueryParameter['timeWindow'] = params.timeWindow;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.endTime !== undefined) {
            localVarQueryParameter['endTime'] = params.endTime;
    }
    if (params.dataCompletionType !== undefined) {
            localVarQueryParameter['dataCompletionType'] = params.dataCompletionType;
    }
    if (params.aggregationTypes) {
        localVarQueryParameter['aggregationTypes'] = params.aggregationTypes;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves full memory metrics data for a container based on the specified cluster, namespace, pod, and container names.
* @summary Get all memory metrics for a specified container
* @param params MetricsApiApiV1FullMetricsPodContainerNameMemoryGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1FullMetricsPodContainerNameMemoryGetUrl = (params: MetricsApiApiV1FullMetricsPodContainerNameMemoryGetRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1FullMetricsPodContainerNameMemoryGet', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1FullMetricsPodContainerNameMemoryGet', 'namespace', params.namespace)
    // verify required parameter 'podName' is not null or undefined
    assertParamExists('apiV1FullMetricsPodContainerNameMemoryGet', 'podName', params.podName)
    // verify required parameter 'containerName' is not null or undefined
    assertParamExists('apiV1FullMetricsPodContainerNameMemoryGet', 'containerName', params.containerName)
    // verify required parameter 'endTime' is not null or undefined
    assertParamExists('apiV1FullMetricsPodContainerNameMemoryGet', 'endTime', params.endTime)
    const localVarPath = `/api/v1/fullMetrics/pod/:containerName/memory`
        .replace(`:${"containerName"}`, encodeURIComponent(String(params.containerName)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.podName !== undefined) {
            localVarQueryParameter['podName'] = params.podName;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.timeWindow !== undefined) {
            localVarQueryParameter['timeWindow'] = params.timeWindow;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.endTime !== undefined) {
            localVarQueryParameter['endTime'] = params.endTime;
    }
    if (params.dataCompletionType !== undefined) {
            localVarQueryParameter['dataCompletionType'] = params.dataCompletionType;
    }
    if (params.aggregationTypes) {
        localVarQueryParameter['aggregationTypes'] = params.aggregationTypes;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves CPU metrics data for a node on the specified cluster
* @summary Get CPU metrics for a specified node
* @param params MetricsApiApiV1NodeCpuTypeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NodeCpuTypeGetUrl = (params: MetricsApiApiV1NodeCpuTypeGetRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1NodeCpuTypeGet', 'type', params.type)
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1NodeCpuTypeGet', 'clusterName', params.clusterName)
    // verify required parameter 'endTime' is not null or undefined
    assertParamExists('apiV1NodeCpuTypeGet', 'endTime', params.endTime)
    // verify required parameter 'nodeName' is not null or undefined
    assertParamExists('apiV1NodeCpuTypeGet', 'nodeName', params.nodeName)
    const localVarPath = `/api/v1/node/cpu/:type`
        .replace(`:${"type"}`, encodeURIComponent(String(params.type)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.timeWindow !== undefined) {
            localVarQueryParameter['timeWindow'] = params.timeWindow;
    }
    if (params.endTime !== undefined) {
            localVarQueryParameter['endTime'] = params.endTime;
    }
    if (params.nodeName !== undefined) {
            localVarQueryParameter['nodeName'] = params.nodeName;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves memory metrics data for a node on the specified cluster
* @summary Get memory metrics for a specified node
* @param params MetricsApiApiV1NodeMemoryTypeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NodeMemoryTypeGetUrl = (params: MetricsApiApiV1NodeMemoryTypeGetRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1NodeMemoryTypeGet', 'type', params.type)
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1NodeMemoryTypeGet', 'clusterName', params.clusterName)
    // verify required parameter 'endTime' is not null or undefined
    assertParamExists('apiV1NodeMemoryTypeGet', 'endTime', params.endTime)
    // verify required parameter 'nodeName' is not null or undefined
    assertParamExists('apiV1NodeMemoryTypeGet', 'nodeName', params.nodeName)
    const localVarPath = `/api/v1/node/memory/:type`
        .replace(`:${"type"}`, encodeURIComponent(String(params.type)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.timeWindow !== undefined) {
            localVarQueryParameter['timeWindow'] = params.timeWindow;
    }
    if (params.endTime !== undefined) {
            localVarQueryParameter['endTime'] = params.endTime;
    }
    if (params.nodeName !== undefined) {
            localVarQueryParameter['nodeName'] = params.nodeName;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves CPU usage metrics data for a container based on the specified cluster, namespace, pod, and container names.
* @summary Get CPU usage metrics for a specified container
* @param params MetricsApiApiV1PodContainerNameCpuTypeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1PodContainerNameCpuTypeGetUrl = (params: MetricsApiApiV1PodContainerNameCpuTypeGetRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1PodContainerNameCpuTypeGet', 'type', params.type)
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1PodContainerNameCpuTypeGet', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1PodContainerNameCpuTypeGet', 'namespace', params.namespace)
    // verify required parameter 'podName' is not null or undefined
    assertParamExists('apiV1PodContainerNameCpuTypeGet', 'podName', params.podName)
    // verify required parameter 'containerName' is not null or undefined
    assertParamExists('apiV1PodContainerNameCpuTypeGet', 'containerName', params.containerName)
    // verify required parameter 'endTime' is not null or undefined
    assertParamExists('apiV1PodContainerNameCpuTypeGet', 'endTime', params.endTime)
    const localVarPath = `/api/v1/pod/:containerName/cpu/:type`
        .replace(`:${"type"}`, encodeURIComponent(String(params.type)))
        .replace(`:${"containerName"}`, encodeURIComponent(String(params.containerName)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.podName !== undefined) {
            localVarQueryParameter['podName'] = params.podName;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.timeWindow !== undefined) {
            localVarQueryParameter['timeWindow'] = params.timeWindow;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.endTime !== undefined) {
            localVarQueryParameter['endTime'] = params.endTime;
    }
    if (params.dataCompletionType !== undefined) {
            localVarQueryParameter['dataCompletionType'] = params.dataCompletionType;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves memory metrics data for a container based on the specified cluster, namespace, pod, and container names.
* @summary Get memory metrics for a specified container
* @param params MetricsApiApiV1PodContainerNameMemoryTypeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1PodContainerNameMemoryTypeGetUrl = (params: MetricsApiApiV1PodContainerNameMemoryTypeGetRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryTypeGet', 'type', params.type)
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryTypeGet', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryTypeGet', 'namespace', params.namespace)
    // verify required parameter 'podName' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryTypeGet', 'podName', params.podName)
    // verify required parameter 'containerName' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryTypeGet', 'containerName', params.containerName)
    // verify required parameter 'endTime' is not null or undefined
    assertParamExists('apiV1PodContainerNameMemoryTypeGet', 'endTime', params.endTime)
    const localVarPath = `/api/v1/pod/:containerName/memory/:type`
        .replace(`:${"type"}`, encodeURIComponent(String(params.type)))
        .replace(`:${"containerName"}`, encodeURIComponent(String(params.containerName)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.podName !== undefined) {
            localVarQueryParameter['podName'] = params.podName;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.timeWindow !== undefined) {
            localVarQueryParameter['timeWindow'] = params.timeWindow;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.endTime !== undefined) {
            localVarQueryParameter['endTime'] = params.endTime;
    }
    if (params.dataCompletionType !== undefined) {
            localVarQueryParameter['dataCompletionType'] = params.dataCompletionType;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves CPU usage metrics data for a pod based on the specified cluster, namespace, pod name.
* @summary Get CPU usage metrics for a specified pod
* @param params MetricsApiApiV1PodCpuTypeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1PodCpuTypeGetUrl = (params: MetricsApiApiV1PodCpuTypeGetRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1PodCpuTypeGet', 'type', params.type)
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1PodCpuTypeGet', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1PodCpuTypeGet', 'namespace', params.namespace)
    // verify required parameter 'podName' is not null or undefined
    assertParamExists('apiV1PodCpuTypeGet', 'podName', params.podName)
    // verify required parameter 'endTime' is not null or undefined
    assertParamExists('apiV1PodCpuTypeGet', 'endTime', params.endTime)
    const localVarPath = `/api/v1/pod/cpu/:type`
        .replace(`:${"type"}`, encodeURIComponent(String(params.type)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.podName !== undefined) {
            localVarQueryParameter['podName'] = params.podName;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.timeWindow !== undefined) {
            localVarQueryParameter['timeWindow'] = params.timeWindow;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.endTime !== undefined) {
            localVarQueryParameter['endTime'] = params.endTime;
    }
    if (params.dataCompletionType !== undefined) {
            localVarQueryParameter['dataCompletionType'] = params.dataCompletionType;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves memory metrics data for a container based on the specified cluster, namespace, pod name.
* @summary Get memory metrics for a specified pod
* @param params MetricsApiApiV1PodMemoryTypeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1PodMemoryTypeGetUrl = (params: MetricsApiApiV1PodMemoryTypeGetRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1PodMemoryTypeGet', 'type', params.type)
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1PodMemoryTypeGet', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1PodMemoryTypeGet', 'namespace', params.namespace)
    // verify required parameter 'podName' is not null or undefined
    assertParamExists('apiV1PodMemoryTypeGet', 'podName', params.podName)
    // verify required parameter 'endTime' is not null or undefined
    assertParamExists('apiV1PodMemoryTypeGet', 'endTime', params.endTime)
    const localVarPath = `/api/v1/pod/memory/:type`
        .replace(`:${"type"}`, encodeURIComponent(String(params.type)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.podName !== undefined) {
            localVarQueryParameter['podName'] = params.podName;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.timeWindow !== undefined) {
            localVarQueryParameter['timeWindow'] = params.timeWindow;
    }
    if (params.endTime !== undefined) {
            localVarQueryParameter['endTime'] = params.endTime;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.dataCompletionType !== undefined) {
            localVarQueryParameter['dataCompletionType'] = params.dataCompletionType;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the aggregated memory data for multiple pods by aggregation type
* @summary Get the aggregated memory data for multiple pods by aggregation type
* @param params MetricsApiApiV1PodsMemoryAggregatedTypePostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1PodsMemoryAggregatedTypePostUrl = (params: MetricsApiApiV1PodsMemoryAggregatedTypePostRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1PodsMemoryAggregatedTypePost', 'type', params.type)
    const localVarPath = `/api/v1/pods/memory/aggregated/:type`
        .replace(`:${"type"}`, encodeURIComponent(String(params.type)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Retrieves number of pods for a specific node on the specified cluster, for a given time window
* @summary Get pods count for a specific node
* @param params MetricsApiGetNodePodsCountRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getNodePodsCountUrl = (params: MetricsApiGetNodePodsCountRequest, baseUrl: string): string => {
    // verify required parameter 'nodeName' is not null or undefined
    assertParamExists('getNodePodsCount', 'nodeName', params.nodeName)
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('getNodePodsCount', 'clusterName', params.clusterName)
    const localVarPath = `/api/v1/node/pods/count`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.nodeName !== undefined) {
            localVarQueryParameter['nodeName'] = params.nodeName;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the CPU throttling data for a specified service\'s containers
* @summary Get the CPU throttling data for a specified service\'s containers
* @param params MetricsApiGetWorkloadContainersCpuThrottlingRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getWorkloadContainersCpuThrottlingUrl = (params: MetricsApiGetWorkloadContainersCpuThrottlingRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('getWorkloadContainersCpuThrottling', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('getWorkloadContainersCpuThrottling', 'namespace', params.namespace)
    // verify required parameter 'komodorServiceName' is not null or undefined
    assertParamExists('getWorkloadContainersCpuThrottling', 'komodorServiceName', params.komodorServiceName)
    // verify required parameter 'komodorServiceKind' is not null or undefined
    assertParamExists('getWorkloadContainersCpuThrottling', 'komodorServiceKind', params.komodorServiceKind)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('getWorkloadContainersCpuThrottling', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('getWorkloadContainersCpuThrottling', 'toEpoch', params.toEpoch)
    const localVarPath = `/api/v1/workload/containers/throttling`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.komodorServiceName !== undefined) {
            localVarQueryParameter['komodorServiceName'] = params.komodorServiceName;
    }
    if (params.komodorServiceKind !== undefined) {
            localVarQueryParameter['komodorServiceKind'] = params.komodorServiceKind;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves CPU or Memory metrics data for all containers of a workload by workload name, kind, cluster and namespace
* @summary Get CPU or Memory metrics for all containers of a specified workload
* @param params MetricsApiGetWorkloadContainersMetricsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getWorkloadContainersMetricsUrl = (params: MetricsApiGetWorkloadContainersMetricsRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('getWorkloadContainersMetrics', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('getWorkloadContainersMetrics', 'namespace', params.namespace)
    // verify required parameter 'komodorServiceName' is not null or undefined
    assertParamExists('getWorkloadContainersMetrics', 'komodorServiceName', params.komodorServiceName)
    // verify required parameter 'komodorServiceKind' is not null or undefined
    assertParamExists('getWorkloadContainersMetrics', 'komodorServiceKind', params.komodorServiceKind)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('getWorkloadContainersMetrics', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('getWorkloadContainersMetrics', 'toEpoch', params.toEpoch)
    // verify required parameter 'kind' is not null or undefined
    assertParamExists('getWorkloadContainersMetrics', 'kind', params.kind)
    const localVarPath = `/api/v1/workload/containers/:kind`
        .replace(`:${"kind"}`, encodeURIComponent(String(params.kind)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.komodorServiceName !== undefined) {
            localVarQueryParameter['komodorServiceName'] = params.komodorServiceName;
    }
    if (params.komodorServiceKind !== undefined) {
            localVarQueryParameter['komodorServiceKind'] = params.komodorServiceKind;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.binSeconds !== undefined) {
            localVarQueryParameter['binSeconds'] = params.binSeconds;
    }
    if (params.aggregationType !== undefined) {
            localVarQueryParameter['aggregationType'] = params.aggregationType;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves a summary of :Kind metrics data for all containers of a workload by workload name, kind, cluster and namespace over the entire time range
* @summary Get a summary of :Kind metrics for all containers of a specified workload over the entire time range
* @param params MetricsApiGetWorkloadContainersMetricsSummaryRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getWorkloadContainersMetricsSummaryUrl = (params: MetricsApiGetWorkloadContainersMetricsSummaryRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('getWorkloadContainersMetricsSummary', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('getWorkloadContainersMetricsSummary', 'namespace', params.namespace)
    // verify required parameter 'komodorServiceName' is not null or undefined
    assertParamExists('getWorkloadContainersMetricsSummary', 'komodorServiceName', params.komodorServiceName)
    // verify required parameter 'komodorServiceKind' is not null or undefined
    assertParamExists('getWorkloadContainersMetricsSummary', 'komodorServiceKind', params.komodorServiceKind)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('getWorkloadContainersMetricsSummary', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('getWorkloadContainersMetricsSummary', 'toEpoch', params.toEpoch)
    // verify required parameter 'kind' is not null or undefined
    assertParamExists('getWorkloadContainersMetricsSummary', 'kind', params.kind)
    const localVarPath = `/api/v1/workload/containers/:kind/summary`
        .replace(`:${"kind"}`, encodeURIComponent(String(params.kind)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.komodorServiceName !== undefined) {
            localVarQueryParameter['komodorServiceName'] = params.komodorServiceName;
    }
    if (params.komodorServiceKind !== undefined) {
            localVarQueryParameter['komodorServiceKind'] = params.komodorServiceKind;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves amount of restarts for each container of a workload by workload name, kind, cluster and namespace over the entire time range
* @summary Get the ammount of restarts for each container in a given workload
* @param params MetricsApiGetWorkloadContainersRestartsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getWorkloadContainersRestartsUrl = (params: MetricsApiGetWorkloadContainersRestartsRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('getWorkloadContainersRestarts', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('getWorkloadContainersRestarts', 'namespace', params.namespace)
    // verify required parameter 'komodorServiceName' is not null or undefined
    assertParamExists('getWorkloadContainersRestarts', 'komodorServiceName', params.komodorServiceName)
    // verify required parameter 'komodorServiceKind' is not null or undefined
    assertParamExists('getWorkloadContainersRestarts', 'komodorServiceKind', params.komodorServiceKind)
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('getWorkloadContainersRestarts', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('getWorkloadContainersRestarts', 'toEpoch', params.toEpoch)
    const localVarPath = `/api/v1/workload/containers/restarts`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.komodorServiceName !== undefined) {
            localVarQueryParameter['komodorServiceName'] = params.komodorServiceName;
    }
    if (params.komodorServiceKind !== undefined) {
            localVarQueryParameter['komodorServiceKind'] = params.komodorServiceKind;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.containerName !== undefined) {
            localVarQueryParameter['containerName'] = params.containerName;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves CPU metrics data for a workload by workload name, kind, cluster and namespace
* @summary Get CPU metrics for a specified workload
* @param params MetricsApiGetWorkloadCpuMetricsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getWorkloadCpuMetricsUrl = (params: MetricsApiGetWorkloadCpuMetricsRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('getWorkloadCpuMetrics', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('getWorkloadCpuMetrics', 'namespace', params.namespace)
    // verify required parameter 'komodorServiceName' is not null or undefined
    assertParamExists('getWorkloadCpuMetrics', 'komodorServiceName', params.komodorServiceName)
    // verify required parameter 'komodorServiceKind' is not null or undefined
    assertParamExists('getWorkloadCpuMetrics', 'komodorServiceKind', params.komodorServiceKind)
    const localVarPath = `/api/v1/workload/cpu`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.komodorServiceName !== undefined) {
            localVarQueryParameter['komodorServiceName'] = params.komodorServiceName;
    }
    if (params.komodorServiceKind !== undefined) {
            localVarQueryParameter['komodorServiceKind'] = params.komodorServiceKind;
    }
    if (params.nodeName !== undefined) {
            localVarQueryParameter['nodeName'] = params.nodeName;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Retrieves memory metrics data for a workload by workload name, kind, cluster and namespace
* @summary Get memory metrics for a specified workload
* @param params MetricsApiGetWorkloadMemoryMetricsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getWorkloadMemoryMetricsUrl = (params: MetricsApiGetWorkloadMemoryMetricsRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('getWorkloadMemoryMetrics', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('getWorkloadMemoryMetrics', 'namespace', params.namespace)
    // verify required parameter 'komodorServiceName' is not null or undefined
    assertParamExists('getWorkloadMemoryMetrics', 'komodorServiceName', params.komodorServiceName)
    // verify required parameter 'komodorServiceKind' is not null or undefined
    assertParamExists('getWorkloadMemoryMetrics', 'komodorServiceKind', params.komodorServiceKind)
    const localVarPath = `/api/v1/workload/memory`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.komodorServiceName !== undefined) {
            localVarQueryParameter['komodorServiceName'] = params.komodorServiceName;
    }
    if (params.komodorServiceKind !== undefined) {
            localVarQueryParameter['komodorServiceKind'] = params.komodorServiceKind;
    }
    if (params.nodeName !== undefined) {
            localVarQueryParameter['nodeName'] = params.nodeName;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the replicas over time
* @summary Get the replicas over time
* @param params MetricsApiGetWorkloadReplicasStatusRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getWorkloadReplicasStatusUrl = (params: MetricsApiGetWorkloadReplicasStatusRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('getWorkloadReplicasStatus', 'fromEpoch', params.fromEpoch)
    // verify required parameter 'toEpoch' is not null or undefined
    assertParamExists('getWorkloadReplicasStatus', 'toEpoch', params.toEpoch)
    // verify required parameter 'komodorUid' is not null or undefined
    assertParamExists('getWorkloadReplicasStatus', 'komodorUid', params.komodorUid)
    const localVarPath = `/api/v1/workloads/:komodorUid/replicas/status`
        .replace(`:${"komodorUid"}`, encodeURIComponent(String(params.komodorUid)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1FullMetricsPodContainerNameCpuGet operation in MetricsApi.
* @export
* @interface MetricsApiApiV1FullMetricsPodContainerNameCpuGetRequest
*/
export interface MetricsApiApiV1FullMetricsPodContainerNameCpuGetRequest {
    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameCpuGet
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameCpuGet
    */
    readonly namespace: string

    /**
    * The name of the pod where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameCpuGet
    */
    readonly podName: string

    /**
    * The name of the container to retrieve metrics for.
    * @type {string}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameCpuGet
    */
    readonly containerName: string

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameCpuGet
    */
    readonly endTime: number

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameCpuGet
    */
    readonly pageSize?: number

    /**
    * The time window for which to retrieve metrics data. Valid values are \&quot;1d\&quot; and \&quot;7d\&quot;.
    * @type {'1d' | '7d'}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameCpuGet
    */
    readonly timeWindow?: '1d' | '7d'

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameCpuGet
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameCpuGet
    */
    readonly toEpoch?: number

    /**
    * Indicates how to fill the data when dealing with missing values. \&quot;default\&quot;- fills the gaps with -1, \&quot;latest\&quot;- fills the gaps with the latest value collected.
    * @type {'default' | 'latest'}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameCpuGet
    */
    readonly dataCompletionType?: 'default' | 'latest'

    /**
    * The aggregation types to retrieve metrics data for. Valid values are \&quot;avg\&quot;, \&quot;max\&quot;, \&quot;p99\&quot;, \&quot;p95\&quot;, \&quot;p90\&quot;.
    * @type {Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameCpuGet
    */
    readonly aggregationTypes?: Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>
}

/**
* Request parameters for apiV1FullMetricsPodContainerNameMemoryGet operation in MetricsApi.
* @export
* @interface MetricsApiApiV1FullMetricsPodContainerNameMemoryGetRequest
*/
export interface MetricsApiApiV1FullMetricsPodContainerNameMemoryGetRequest {
    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameMemoryGet
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameMemoryGet
    */
    readonly namespace: string

    /**
    * The name of the pod where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameMemoryGet
    */
    readonly podName: string

    /**
    * The name of the container to retrieve metrics for.
    * @type {string}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameMemoryGet
    */
    readonly containerName: string

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameMemoryGet
    */
    readonly endTime: number

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameMemoryGet
    */
    readonly pageSize?: number

    /**
    * The time window for which to retrieve metrics data. Valid values are \&quot;1d\&quot; and \&quot;7d\&quot;.
    * @type {'1d' | '7d'}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameMemoryGet
    */
    readonly timeWindow?: '1d' | '7d'

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameMemoryGet
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameMemoryGet
    */
    readonly toEpoch?: number

    /**
    * Indicates how to fill the data when dealing with missing values. \&quot;default\&quot;- fills the gaps with -1, \&quot;latest\&quot;- fills the gaps with the latest value collected.
    * @type {'default' | 'latest'}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameMemoryGet
    */
    readonly dataCompletionType?: 'default' | 'latest'

    /**
    * The aggregation types to retrieve metrics data for. Valid values are \&quot;avg\&quot;, \&quot;max\&quot;, \&quot;p99\&quot;, \&quot;p95\&quot;, \&quot;p90\&quot;.
    * @type {Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>}
    * @memberof MetricsApiApiV1FullMetricsPodContainerNameMemoryGet
    */
    readonly aggregationTypes?: Array<'avg' | 'max' | 'p99' | 'p95' | 'p90'>
}

/**
* Request parameters for apiV1NodeCpuTypeGet operation in MetricsApi.
* @export
* @interface MetricsApiApiV1NodeCpuTypeGetRequest
*/
export interface MetricsApiApiV1NodeCpuTypeGetRequest {
    /**
    * The type of memory metric to retrieve. Valid values are \&quot;usage\&quot;, \&quot;limit\&quot;, and \&quot;request\&quot;.
    * @type {'usage' | 'limit' | 'request' | 'capacity'}
    * @memberof MetricsApiApiV1NodeCpuTypeGet
    */
    readonly type: 'usage' | 'limit' | 'request' | 'capacity'

    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1NodeCpuTypeGet
    */
    readonly clusterName: string

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1NodeCpuTypeGet
    */
    readonly endTime: number

    /**
    * The name of the node for metrics.
    * @type {string}
    * @memberof MetricsApiApiV1NodeCpuTypeGet
    */
    readonly nodeName: string

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof MetricsApiApiV1NodeCpuTypeGet
    */
    readonly pageSize?: number

    /**
    * The time window for which to retrieve metrics data. Valid values are \&quot;1d\&quot; and \&quot;7d\&quot;.
    * @type {'1d' | '7d'}
    * @memberof MetricsApiApiV1NodeCpuTypeGet
    */
    readonly timeWindow?: '1d' | '7d'

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1NodeCpuTypeGet
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1NodeCpuTypeGet
    */
    readonly toEpoch?: number
}

/**
* Request parameters for apiV1NodeMemoryTypeGet operation in MetricsApi.
* @export
* @interface MetricsApiApiV1NodeMemoryTypeGetRequest
*/
export interface MetricsApiApiV1NodeMemoryTypeGetRequest {
    /**
    * The type of memory metric to retrieve. Valid values are \&quot;usage\&quot;, \&quot;limit\&quot;, and \&quot;request\&quot;.
    * @type {'usage' | 'limit' | 'request' | 'capacity'}
    * @memberof MetricsApiApiV1NodeMemoryTypeGet
    */
    readonly type: 'usage' | 'limit' | 'request' | 'capacity'

    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1NodeMemoryTypeGet
    */
    readonly clusterName: string

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1NodeMemoryTypeGet
    */
    readonly endTime: number

    /**
    * The name of the node for metrics.
    * @type {string}
    * @memberof MetricsApiApiV1NodeMemoryTypeGet
    */
    readonly nodeName: string

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof MetricsApiApiV1NodeMemoryTypeGet
    */
    readonly pageSize?: number

    /**
    * The time window for which to retrieve metrics data. Valid values are \&quot;1d\&quot; and \&quot;7d\&quot;.
    * @type {'1d' | '7d'}
    * @memberof MetricsApiApiV1NodeMemoryTypeGet
    */
    readonly timeWindow?: '1d' | '7d'

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1NodeMemoryTypeGet
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1NodeMemoryTypeGet
    */
    readonly toEpoch?: number
}

/**
* Request parameters for apiV1PodContainerNameCpuTypeGet operation in MetricsApi.
* @export
* @interface MetricsApiApiV1PodContainerNameCpuTypeGetRequest
*/
export interface MetricsApiApiV1PodContainerNameCpuTypeGetRequest {
    /**
    * The type of memory metric to retrieve. Valid values are \&quot;usage\&quot;, \&quot;limit\&quot;, and \&quot;request\&quot;.
    * @type {'usage' | 'limit' | 'request' | 'capacity'}
    * @memberof MetricsApiApiV1PodContainerNameCpuTypeGet
    */
    readonly type: 'usage' | 'limit' | 'request' | 'capacity'

    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodContainerNameCpuTypeGet
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodContainerNameCpuTypeGet
    */
    readonly namespace: string

    /**
    * The name of the pod where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodContainerNameCpuTypeGet
    */
    readonly podName: string

    /**
    * The name of the container to retrieve metrics for.
    * @type {string}
    * @memberof MetricsApiApiV1PodContainerNameCpuTypeGet
    */
    readonly containerName: string

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodContainerNameCpuTypeGet
    */
    readonly endTime: number

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof MetricsApiApiV1PodContainerNameCpuTypeGet
    */
    readonly pageSize?: number

    /**
    * The time window for which to retrieve metrics data. Valid values are \&quot;1d\&quot; and \&quot;7d\&quot;.
    * @type {'1d' | '7d'}
    * @memberof MetricsApiApiV1PodContainerNameCpuTypeGet
    */
    readonly timeWindow?: '1d' | '7d'

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodContainerNameCpuTypeGet
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodContainerNameCpuTypeGet
    */
    readonly toEpoch?: number

    /**
    * Indicates how to fill the data when dealing with missing values. \&quot;default\&quot;- fills the gaps with -1, \&quot;latest\&quot;- fills the gaps with the latest value collected.
    * @type {'default' | 'latest'}
    * @memberof MetricsApiApiV1PodContainerNameCpuTypeGet
    */
    readonly dataCompletionType?: 'default' | 'latest'
}

/**
* Request parameters for apiV1PodContainerNameMemoryTypeGet operation in MetricsApi.
* @export
* @interface MetricsApiApiV1PodContainerNameMemoryTypeGetRequest
*/
export interface MetricsApiApiV1PodContainerNameMemoryTypeGetRequest {
    /**
    * The type of memory metric to retrieve. Valid values are \&quot;usage\&quot;, \&quot;limit\&quot;, and \&quot;request\&quot;.
    * @type {'usage' | 'limit' | 'request' | 'capacity'}
    * @memberof MetricsApiApiV1PodContainerNameMemoryTypeGet
    */
    readonly type: 'usage' | 'limit' | 'request' | 'capacity'

    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodContainerNameMemoryTypeGet
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodContainerNameMemoryTypeGet
    */
    readonly namespace: string

    /**
    * The name of the pod where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodContainerNameMemoryTypeGet
    */
    readonly podName: string

    /**
    * The name of the container to retrieve metrics for.
    * @type {string}
    * @memberof MetricsApiApiV1PodContainerNameMemoryTypeGet
    */
    readonly containerName: string

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodContainerNameMemoryTypeGet
    */
    readonly endTime: number

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof MetricsApiApiV1PodContainerNameMemoryTypeGet
    */
    readonly pageSize?: number

    /**
    * The time window for which to retrieve metrics data. Valid values are \&quot;1d\&quot; and \&quot;7d\&quot;.
    * @type {'1d' | '7d'}
    * @memberof MetricsApiApiV1PodContainerNameMemoryTypeGet
    */
    readonly timeWindow?: '1d' | '7d'

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodContainerNameMemoryTypeGet
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodContainerNameMemoryTypeGet
    */
    readonly toEpoch?: number

    /**
    * Indicates how to fill the data when dealing with missing values. \&quot;default\&quot;- fills the gaps with -1, \&quot;latest\&quot;- fills the gaps with the latest value collected.
    * @type {'default' | 'latest'}
    * @memberof MetricsApiApiV1PodContainerNameMemoryTypeGet
    */
    readonly dataCompletionType?: 'default' | 'latest'
}

/**
* Request parameters for apiV1PodCpuTypeGet operation in MetricsApi.
* @export
* @interface MetricsApiApiV1PodCpuTypeGetRequest
*/
export interface MetricsApiApiV1PodCpuTypeGetRequest {
    /**
    * The type of memory metric to retrieve. Valid values are \&quot;usage\&quot;, \&quot;limit\&quot;, and \&quot;request\&quot;.
    * @type {'usage' | 'limit' | 'request' | 'capacity'}
    * @memberof MetricsApiApiV1PodCpuTypeGet
    */
    readonly type: 'usage' | 'limit' | 'request' | 'capacity'

    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodCpuTypeGet
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodCpuTypeGet
    */
    readonly namespace: string

    /**
    * The name of the pod where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodCpuTypeGet
    */
    readonly podName: string

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodCpuTypeGet
    */
    readonly endTime: number

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof MetricsApiApiV1PodCpuTypeGet
    */
    readonly pageSize?: number

    /**
    * The time window for which to retrieve metrics data. Valid values are \&quot;1d\&quot; and \&quot;7d\&quot;.
    * @type {'1d' | '7d'}
    * @memberof MetricsApiApiV1PodCpuTypeGet
    */
    readonly timeWindow?: '1d' | '7d'

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodCpuTypeGet
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodCpuTypeGet
    */
    readonly toEpoch?: number

    /**
    * Indicates how to fill the data when dealing with missing values. \&quot;default\&quot;- fills the gaps with -1, \&quot;latest\&quot;- fills the gaps with the latest value collected.
    * @type {'default' | 'latest'}
    * @memberof MetricsApiApiV1PodCpuTypeGet
    */
    readonly dataCompletionType?: 'default' | 'latest'
}

/**
* Request parameters for apiV1PodMemoryTypeGet operation in MetricsApi.
* @export
* @interface MetricsApiApiV1PodMemoryTypeGetRequest
*/
export interface MetricsApiApiV1PodMemoryTypeGetRequest {
    /**
    * The type of memory metric to retrieve. Valid values are \&quot;usage\&quot;, \&quot;limit\&quot;, and \&quot;request\&quot;.
    * @type {'usage' | 'limit' | 'request' | 'capacity'}
    * @memberof MetricsApiApiV1PodMemoryTypeGet
    */
    readonly type: 'usage' | 'limit' | 'request' | 'capacity'

    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodMemoryTypeGet
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodMemoryTypeGet
    */
    readonly namespace: string

    /**
    * The name of the pod where the container is located.
    * @type {string}
    * @memberof MetricsApiApiV1PodMemoryTypeGet
    */
    readonly podName: string

    /**
    * The end timestamp of the time window for which to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodMemoryTypeGet
    */
    readonly endTime: number

    /**
    * The maximum number of metrics data points to retrieve per page. If not specified, a default page size will be used.
    * @type {number}
    * @memberof MetricsApiApiV1PodMemoryTypeGet
    */
    readonly pageSize?: number

    /**
    * The time window for which to retrieve metrics data. Valid values are \&quot;1d\&quot; and \&quot;7d\&quot;.
    * @type {'1d' | '7d'}
    * @memberof MetricsApiApiV1PodMemoryTypeGet
    */
    readonly timeWindow?: '1d' | '7d'

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodMemoryTypeGet
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiApiV1PodMemoryTypeGet
    */
    readonly toEpoch?: number

    /**
    * Indicates how to fill the data when dealing with missing values. \&quot;default\&quot;- fills the gaps with -1, \&quot;latest\&quot;- fills the gaps with the latest value collected.
    * @type {'default' | 'latest'}
    * @memberof MetricsApiApiV1PodMemoryTypeGet
    */
    readonly dataCompletionType?: 'default' | 'latest'
}

/**
* Request parameters for apiV1PodsMemoryAggregatedTypePost operation in MetricsApi.
* @export
* @interface MetricsApiApiV1PodsMemoryAggregatedTypePostRequest
*/
export interface MetricsApiApiV1PodsMemoryAggregatedTypePostRequest {
    /**
    * The type of memory metric to retrieve. Valid values are \&quot;usage\&quot;, \&quot;limit\&quot;, and \&quot;request\&quot;.
    * @type {'usage' | 'limit' | 'request' | 'capacity'}
    * @memberof MetricsApiApiV1PodsMemoryAggregatedTypePost
    */
    readonly type: 'usage' | 'limit' | 'request' | 'capacity'

    /**
    * 
    * @type {PodMemoryAggregatedBody}
    * @memberof MetricsApiApiV1PodsMemoryAggregatedTypePost
    */
    readonly podMemoryAggregatedBody?: PodMemoryAggregatedBody
}

/**
* Request parameters for getNodePodsCount operation in MetricsApi.
* @export
* @interface MetricsApiGetNodePodsCountRequest
*/
export interface MetricsApiGetNodePodsCountRequest {
    /**
    * The name of the node for metrics.
    * @type {string}
    * @memberof MetricsApiGetNodePodsCount
    */
    readonly nodeName: string

    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiGetNodePodsCount
    */
    readonly clusterName: string

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetNodePodsCount
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetNodePodsCount
    */
    readonly toEpoch?: number
}

/**
* Request parameters for getWorkloadContainersCpuThrottling operation in MetricsApi.
* @export
* @interface MetricsApiGetWorkloadContainersCpuThrottlingRequest
*/
export interface MetricsApiGetWorkloadContainersCpuThrottlingRequest {
    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersCpuThrottling
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersCpuThrottling
    */
    readonly namespace: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersCpuThrottling
    */
    readonly komodorServiceName: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersCpuThrottling
    */
    readonly komodorServiceKind: string

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadContainersCpuThrottling
    */
    readonly fromEpoch: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadContainersCpuThrottling
    */
    readonly toEpoch: number
}

/**
* Request parameters for getWorkloadContainersMetrics operation in MetricsApi.
* @export
* @interface MetricsApiGetWorkloadContainersMetricsRequest
*/
export interface MetricsApiGetWorkloadContainersMetricsRequest {
    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersMetrics
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersMetrics
    */
    readonly namespace: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersMetrics
    */
    readonly komodorServiceName: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersMetrics
    */
    readonly komodorServiceKind: string

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadContainersMetrics
    */
    readonly fromEpoch: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadContainersMetrics
    */
    readonly toEpoch: number

    /**
    * The kind of metric to retrieve. Valid values are \&quot;memory\&quot;, \&quot;cpu\&quot;.
    * @type {'memory' | 'cpu'}
    * @memberof MetricsApiGetWorkloadContainersMetrics
    */
    readonly kind: 'memory' | 'cpu'

    /**
    * Time bin in seconds of each entry in the response
    * @type {number}
    * @memberof MetricsApiGetWorkloadContainersMetrics
    */
    readonly binSeconds?: number

    /**
    * The aggregation type to retrieve metrics data for. Valid values are \&quot;avg\&quot;, \&quot;max\&quot;, \&quot;p99\&quot;, \&quot;p95\&quot;, \&quot;p90\&quot;.
    * @type {AggregationType}
    * @memberof MetricsApiGetWorkloadContainersMetrics
    */
    readonly aggregationType?: AggregationType
}

/**
* Request parameters for getWorkloadContainersMetricsSummary operation in MetricsApi.
* @export
* @interface MetricsApiGetWorkloadContainersMetricsSummaryRequest
*/
export interface MetricsApiGetWorkloadContainersMetricsSummaryRequest {
    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersMetricsSummary
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersMetricsSummary
    */
    readonly namespace: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersMetricsSummary
    */
    readonly komodorServiceName: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersMetricsSummary
    */
    readonly komodorServiceKind: string

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadContainersMetricsSummary
    */
    readonly fromEpoch: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadContainersMetricsSummary
    */
    readonly toEpoch: number

    /**
    * The kind of metric to retrieve. Valid values are \&quot;memory\&quot;, \&quot;cpu\&quot;.
    * @type {'memory' | 'cpu'}
    * @memberof MetricsApiGetWorkloadContainersMetricsSummary
    */
    readonly kind: 'memory' | 'cpu'
}

/**
* Request parameters for getWorkloadContainersRestarts operation in MetricsApi.
* @export
* @interface MetricsApiGetWorkloadContainersRestartsRequest
*/
export interface MetricsApiGetWorkloadContainersRestartsRequest {
    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersRestarts
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersRestarts
    */
    readonly namespace: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersRestarts
    */
    readonly komodorServiceName: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersRestarts
    */
    readonly komodorServiceKind: string

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadContainersRestarts
    */
    readonly fromEpoch: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadContainersRestarts
    */
    readonly toEpoch: number

    /**
    * The name of the container for metrics.
    * @type {string}
    * @memberof MetricsApiGetWorkloadContainersRestarts
    */
    readonly containerName?: string
}

/**
* Request parameters for getWorkloadCpuMetrics operation in MetricsApi.
* @export
* @interface MetricsApiGetWorkloadCpuMetricsRequest
*/
export interface MetricsApiGetWorkloadCpuMetricsRequest {
    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadCpuMetrics
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadCpuMetrics
    */
    readonly namespace: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadCpuMetrics
    */
    readonly komodorServiceName: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadCpuMetrics
    */
    readonly komodorServiceKind: string

    /**
    * The name of the node for metrics.
    * @type {string}
    * @memberof MetricsApiGetWorkloadCpuMetrics
    */
    readonly nodeName?: string

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadCpuMetrics
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadCpuMetrics
    */
    readonly toEpoch?: number
}

/**
* Request parameters for getWorkloadMemoryMetrics operation in MetricsApi.
* @export
* @interface MetricsApiGetWorkloadMemoryMetricsRequest
*/
export interface MetricsApiGetWorkloadMemoryMetricsRequest {
    /**
    * The name of the cluster where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadMemoryMetrics
    */
    readonly clusterName: string

    /**
    * The name of the namespace where the container is located.
    * @type {string}
    * @memberof MetricsApiGetWorkloadMemoryMetrics
    */
    readonly namespace: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadMemoryMetrics
    */
    readonly komodorServiceName: string

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadMemoryMetrics
    */
    readonly komodorServiceKind: string

    /**
    * The name of the node for metrics.
    * @type {string}
    * @memberof MetricsApiGetWorkloadMemoryMetrics
    */
    readonly nodeName?: string

    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadMemoryMetrics
    */
    readonly fromEpoch?: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadMemoryMetrics
    */
    readonly toEpoch?: number
}

/**
* Request parameters for getWorkloadReplicasStatus operation in MetricsApi.
* @export
* @interface MetricsApiGetWorkloadReplicasStatusRequest
*/
export interface MetricsApiGetWorkloadReplicasStatusRequest {
    /**
    * Start epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadReplicasStatus
    */
    readonly fromEpoch: number

    /**
    * End epoch time to retrieve metrics data.
    * @type {number}
    * @memberof MetricsApiGetWorkloadReplicasStatus
    */
    readonly toEpoch: number

    /**
    * 
    * @type {string}
    * @memberof MetricsApiGetWorkloadReplicasStatus
    */
    readonly komodorUid: string
}


    
