import { muiColors } from "@komodorio/design-system";
import {
  MuiSelectionOption,
  ComparisonTableProps,
} from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { ListRenderer } from "../common/ListRenderer";
import { NameField } from "../common/NameFieldCell";
import { DiffModal } from "../Services/Attributes/types";

import { buildHelmOptionValue } from "./selectionOptionsUtils";
import { ComparisonHelm, CellRenderer } from "./types";

import {
  DrawerStatePush,
  DrawerType,
} from "@/shared/store/drawersStackStore/types";
import { HelmComparisonResponse } from "@/generated/workspacesApi";
import { generateSecretId } from "@/components/Inspection/Helm/hooks/utils";
import { HelmChart } from "@/generated/addonsApi";

const defaultCellRenderer =
  (field: keyof ComparisonHelm): CellRenderer =>
  (rowData) => {
    const { isBaseline, data } = rowData;
    const value = isBaseline
      ? data[field]
      : data.data?.[field]?.value !== undefined
      ? data.data?.[field]?.value
      : undefined;
    return (
      <Typography
        variant="body2"
        color={isBaseline ? muiColors.gray[600] : "text.primary"}
      >
        {value}
      </Typography>
    );
  };

const getDrawerData = (release: HelmChart): DrawerStatePush => {
  return {
    drawerType: DrawerType.HelmReleaseDrawer,
    chartSummary: {
      ...release,
      secretId: generateSecretId(release.name, release.revision.toString()),
      cluster: release.clusterName,
      version: `${release.chart}-${release.appVersion}`,
      lastUpdated: release.updatedAt,
      revision: release.revision.toString(),
    },
  };
};
export const getBaselineTableData = (
  data: HelmComparisonResponse | undefined,
  baselineSelection: MuiSelectionOption<string> | null
) => {
  return data
    ? {
        ...data.baselineHelmAttributes,
        version: data.baselineHelmAttributes.version?.value ?? "",
        inventory:
          data.baselineHelmAttributes.inventory?.value?.map(
            (item) => `${item.count} ${item.kind}`
          ) ?? [],
        komodorUidValue: baselineSelection?.value ?? "",
        komodorUidLabel: baselineSelection?.label ?? "",
      }
    : undefined;
};

export const getComparedTableData = ({
  data,
  comparedSelections,
  showOnlyDrift,
}: {
  data: HelmComparisonResponse | undefined;
  comparedSelections: MuiSelectionOption<string>[] | null;
  showOnlyDrift: boolean;
}) => {
  return (
    comparedSelections
      ?.map((uid, i) => {
        const targetHelmAttributes = data?.targetHelmAttributes[i];
        if (!targetHelmAttributes) {
          return {
            isLoading: true,
            isMissingData: false,
          };
        }
        return {
          isLoading: false,
          isMissingData: false,
          data: {
            komodorUidValue: {
              value: uid.value,
              isDiff: false,
            },
            komodorUidLabel: {
              value: uid.label,
              isDiff: false,
            },
            cluster: {
              value: targetHelmAttributes.cluster,
              isDiff: false,
              isEmpty: false,
            },
            namespace: {
              value: targetHelmAttributes.namespace,
              isDiff: false,
            },
            name: {
              value: targetHelmAttributes.name,
              isDiff: false,
              isEmpty: false,
            },
            chart: {
              value: targetHelmAttributes.chart,
              isDiff: false,
              isEmpty: false,
            },
            version: targetHelmAttributes.version,
            inventory: {
              ...targetHelmAttributes.inventory,
              value: targetHelmAttributes.inventory.value?.map(
                (item) => `${item.count} ${item.kind}`
              ),
            },
          },
        };
      })
      .filter((_, i) => {
        if (!showOnlyDrift) {
          return true;
        }
        return data?.targetHelmAttributes?.[i]?.isDiff === true;
      }) ?? []
  );
};

export const getAttributesTableData = ({
  baselineData,
  data,
  showOnlyDrift,
  helmReleases,
  pushDrawer,
  setDiffModal,
}: {
  baselineData: ComparisonHelm | undefined;
  data: HelmComparisonResponse | undefined;
  showOnlyDrift: boolean;
  helmReleases: HelmChart[] | undefined;
  pushDrawer: (drawerState: DrawerStatePush) => void;
  setDiffModal: (diffModal: DiffModal | null) => void;
}): ComparisonTableProps<ComparisonHelm>["attributes"] => {
  return (
    [
      {
        headerName: "Name",
        field: "name",
        showValueAlways: true,
        cellBodyClassName: "name-cell",
        onClick: ({ isBaseline, data }) => {
          const chart = isBaseline
            ? data.komodorUidValue
            : data.data?.komodorUidValue?.value;
          const selectedRelease = helmReleases?.find(
            (release) => buildHelmOptionValue(release) === chart
          );
          if (selectedRelease) {
            pushDrawer(getDrawerData(selectedRelease));
          }
        },
        renderCell: (data) => (
          <NameField
            rowData={data}
            field="name"
            removeTargetIdentifierField="komodorUidValue"
          />
        ),
      },
      {
        headerName: "Chart",
        field: "chart",
        renderCell: defaultCellRenderer("chart"),
        showValueAlways: true,
      },
      {
        headerName: "Cluster",
        field: "cluster",
        renderCell: defaultCellRenderer("cluster"),
        showValueAlways: true,
      },
      {
        headerName: "Namespace",
        field: "namespace",
        renderCell: defaultCellRenderer("namespace"),
        showValueAlways: true,
      },
      {
        headerName: "Version",
        field: "version",
        renderCell: defaultCellRenderer("version"),
        hideValueIfDifferent: false,
      },
      {
        headerName: "Inventory",
        field: "inventory",
        hideValueIfDifferent: true,
        onClick: ({ data, isBaseline }) => {
          if (isBaseline) {
            return;
          }
          setDiffModal({
            title: "Inventory",
            comparedUid: data.data?.komodorUidLabel?.value ?? "",
            oldObj: baselineData?.inventory,
            newObj: data.data?.inventory?.value,
          });
        },
        renderCell: ({ data, isBaseline }) => {
          const names = isBaseline
            ? data.inventory
            : data.data?.inventory?.value;
          return (
            <ListRenderer
              names={names}
              isBaseline={isBaseline}
              maxItems={4}
              withBullets={false}
            />
          );
        },
      },
    ] as ComparisonTableProps<ComparisonHelm>["attributes"]
  ).filter((attribute) => {
    if (
      !showOnlyDrift ||
      attribute.field === "name" ||
      attribute.field === "cluster" ||
      attribute.field === "namespace" ||
      attribute.field === "chart" ||
      attribute.field === "komodorUidValue" ||
      attribute.field === "komodorUidLabel"
    ) {
      return true;
    }

    return data?.baselineHelmAttributes?.[attribute.field]?.isDiff;
  });
};
