import {
  Label,
  MuiSelectionOption,
  MuiSelectionOptionValue,
  SearchField,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import React from "react";

interface Props {
  controlsDisabled: boolean;
  addWorkspaceButtonDisabled?: boolean;
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
  createdByValues: MuiSelectionOption<MuiSelectionOptionValue>[];
}

export const WorkspaceManagementControls: React.FC<Props> = ({
  controlsDisabled,
  addWorkspaceButtonDisabled = false,
  searchTerm,
  setSearchTerm,
  createdByValues,
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap="16px" marginRight="16px">
        <Stack direction="column" gap="3px">
          <Label value="Search" disabled={controlsDisabled} />
          <SearchField
            value={searchTerm}
            onSearch={setSearchTerm}
            ariaLabel="search term entry"
            disabled={controlsDisabled}
          />
        </Stack>

        <Stack direction="column" gap="3px">
          <Label value="Created By" disabled={controlsDisabled} />
          <SingleSelect
            width="209px"
            options={createdByValues}
            ariaLabel="Create By selection"
            disabled={controlsDisabled}
          />
        </Stack>
      </Stack>

      <Stack
        direction="row"
        sx={{
          alignItems: "end",
        }}
      >
        <Button variant="contained" disabled={addWorkspaceButtonDisabled}>
          Create Workspace
        </Button>
      </Stack>
    </Stack>
  );
};
