import { Breadcrumbs } from "@komodorio/design-system/komodor-ui";
import { breadcrumbsClasses, typographyClasses } from "@mui/material";

// @deprecated Use komodor-ui/Breadcrumbs with compact={true} + "maxWidth" in sx
export const ResourceBreadcrumbs: React.FC<{
  clusterName: string;
  namespace: string;
  name: string;
  maxWidth?: string;
  className?: string;
}> = ({ clusterName, namespace, name, maxWidth = "100%", className }) => {
  return (
    <Breadcrumbs
      className={className}
      breadcrumbs={[clusterName, namespace, name]}
      allowOverflow={false}
      sx={{
        maxWidth: maxWidth,
        [`& .${breadcrumbsClasses.separator}`]: {
          marginLeft: 0,
          marginRight: 0,
          color: "text.disabled",
        },
        [`& .${breadcrumbsClasses.li}:last-of-type .${typographyClasses.root}`]:
          {
            color: "text.primary",
          },
      }}
    />
  );
};
