import { muiTheme } from "@komodorio/design-system";
import { useMemo } from "react";
import Alert from "@mui/material/Alert";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import Resource from "../../../../ResourceView/resources";

import type JobEventGroup from "@/components/common/EventGroup/jobEvent/JobEventGroup";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { MonitorType } from "@/generated/monitorsApi";
import { FlowType } from "@/components/AiInvestigation/types";
import { useWorkflowRunById } from "@/components/common/EventGroup/workflowIssues/useWorkflowRunById";
import { AiInvestigation } from "@/components/AiInvestigation/AiInvestigation";
import { AiInvestigationTitle } from "@/components/AiInvestigation/AiInvestigationTitle";

export const FailedJobInvestigationSection: React.FC<{
  resource: Resource;
  eventGroup: JobEventGroup;
}> = ({ resource, eventGroup }) => {
  const jobResource = useMemo(
    () => ({
      ...resource,
      kind: "Job",
      name: eventGroup.name,
      namespace: resource.namespace,
      cluster: resource.cluster,
    }),
    [eventGroup.name, resource]
  );

  const lastEvent = eventGroup.events[eventGroup.events.length - 1];
  const { data: wfRun } = useWorkflowRunById(lastEvent.id, MonitorType.Job);

  if (wfRun?.workflowConfiguration?.isDeleted) {
    return (
      <Stack padding="16px 24px" gap={1}>
        <AiInvestigationTitle richSession={null} />
        <Alert
          variant="outlined"
          severity="error"
          icon={<ErrorOutline fontSize="inherit" />}
          sx={{
            width: "395px",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color={muiTheme.palette.error.dark}>
            AI investigation cannot run on a deleted job.
          </Typography>
        </Alert>
      </Stack>
    );
  }

  return (
    <>
      <AiInvestigation
        drawerData={{
          drawerType: DrawerType.EventDrawerById,
          eventIds: eventGroup.events.map((event) => event.id),
          eventType: MonitorType.Job,
          serviceId: eventGroup.serviceId,
        }}
        requestId={eventGroup.jobUid}
        resource={jobResource}
        isHealthy={!eventGroup.isFailed}
        flowType={FlowType.FailedJobRun}
        padding="16px"
      />
      <Divider />
    </>
  );
};
