import React, { useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "styled-components";

import { CustomGraphDataPoint, TooltipItemProps } from "../types";
import {
  GraphBackgroundColor,
  GraphChartMargin,
  GraphGridColor,
} from "../styles";
import { Tooltip } from "../Tooltip/Tooltip";
import { defaultFormatTooltipTick } from "../utils";
import { GenericLegend, LegendItem } from "../GenericLegend/GenericLegend";

import { useGraphProps } from "./hooks";
import { timeXAxisProps, yAxisProps } from "./graphProps";

import { SmartPopper } from "@/components/common/EventsChart/SmartPopper";

const MIN_WIDTH_WITH_1_INTERVAL = 580;
const MIN_WIDTH_WITH_2_INTERVAL = 400;

const Container = styled.div`
  height: 100%;
`;

const LegendContent = styled(GenericLegend)`
  flex-wrap: wrap;
  margin-left: 0;
  top: 0;
`;

export type MetricArea = Partial<Area>;

type Props = {
  className?: string;
  data: CustomGraphDataPoint[];
  areas: MetricArea[];
  yAxisLabel?: string;
  tooltipItems: TooltipItemProps[];
  legendItems?: LegendItem[];
};

export const StackedAreaChart: React.FC<Props> = ({
  data,
  areas,
  yAxisLabel,
  tooltipItems,
  className,
  legendItems,
}) => {
  const [chartInterval, setChartInterval] = useState<number>(1);

  const { containerProps, chartProps, xAxisProps, tooltipProps } =
    useGraphProps(data);

  const onResize = (width: number) => {
    if (width <= MIN_WIDTH_WITH_2_INTERVAL) {
      setChartInterval(2);
    } else if (width <= MIN_WIDTH_WITH_1_INTERVAL) {
      setChartInterval(1);
    } else {
      setChartInterval(0);
    }
  };
  return (
    <Container className={className} {...containerProps}>
      <ResponsiveContainer width="100%" height="100%" onResize={onResize}>
        <AutoSizer>
          {({ width, height }) => (
            <AreaChart
              width={width}
              height={height}
              data={data}
              margin={GraphChartMargin}
              {...chartProps}
            >
              <CartesianGrid
                stroke={GraphGridColor}
                strokeWidth="1"
                fill={GraphBackgroundColor}
              />
              <XAxis
                {...timeXAxisProps}
                {...xAxisProps}
                interval={chartInterval}
              />
              <YAxis {...yAxisProps(yAxisLabel)} />
              <RechartTooltip
                isAnimationActive={false}
                content={(props) => (
                  <SmartPopper
                    {...props}
                    tooltipContent={(tooltipProps) => (
                      <Tooltip
                        tooltipProps={tooltipProps}
                        dataTransformFunction={defaultFormatTooltipTick}
                        tooltipItems={tooltipItems}
                      />
                    )}
                    {...tooltipProps}
                  />
                )}
              />
              {legendItems && (
                <Legend
                  align="center"
                  content={<LegendContent items={legendItems} />}
                />
              )}
              {areas}
            </AreaChart>
          )}
        </AutoSizer>
      </ResponsiveContainer>
    </Container>
  );
};
