import React from "react";

import { useClusterNames } from "../useClusterNames";

import { useNodesMetrics } from "./useNodesMetrics";
import { MetricGraph } from "./MetricGraph";

export const AutoscalersMetricsGraph: React.FC = () => {
  const { clusterNames, hasClusters } = useClusterNames();
  const { isFetching, error, memoryData, cpuData } = useNodesMetrics({
    clusterNames,
    isDataFetchEnabled: hasClusters,
  });

  const graphContainerProps = {
    uid: undefined,
    hasGraphData: !error && !isFetching,
    error: error ? "Something went wrong" : null,
    loading: !hasClusters || isFetching,
  };

  return (
    <>
      <MetricGraph
        graphContainerProps={graphContainerProps}
        metric="cpu"
        metricData={cpuData}
        showLabel={false}
      />
      <MetricGraph
        graphContainerProps={graphContainerProps}
        metric="memory"
        metricData={memoryData}
        showLabel={false}
      />
    </>
  );
};
