import { ResourceTableModelRow } from "komodor-types";
import React from "react";

import FetchDataLoading from "../common/loaders/LoadingState";
import { ColumnProperties } from "../common/ResourceListTable/TableProperties";

import {
  KubernetesPodsResource,
  KubernetesResource,
} from "./inspectionConfiguration/SupportedResourcesTypes";
import InspectionUpgradeRequired from "./InspectionUpgradeRequired";
import { withNodesProvider } from "./NodesProvider";
import { NoItemsContainer, Panel } from "./styles";
import ResourceListTable from "./../common/ResourceListTable/ResourceListTable";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceListResponse } from "./InspectionViews/common";
import DeletedPodsSwitch from "./filters/DeletedPodsFilter";

import { useTablePageSizeInLs } from "@/components/Inspection/InspectionViews/localStorageHooks";

interface MainViewContentProps {
  clusterName: string;
  agentUpgradeRequired: boolean;
  resourceList: ResourceListResponse;
  kubernetesResource: KubernetesResource;
  filteredTableResults: ResourceTableModelRow[];
  refresh: () => void;
  shouldUseLiveData?: boolean;
  overridingColumns?: ColumnProperties[];
}

const MainViewContent: React.FC<MainViewContentProps> = ({
  clusterName,
  agentUpgradeRequired,
  resourceList,
  kubernetesResource,
  filteredTableResults,
  refresh,
  shouldUseLiveData = false,
  overridingColumns,
}) => {
  const [pageSize, setPageSize] = useTablePageSizeInLs();

  let content = null;
  if (agentUpgradeRequired) {
    content = (
      <Panel>
        <InspectionUpgradeRequired />
      </Panel>
    );
  } else if (resourceList.fetching) {
    content = (
      <Panel>
        <FetchDataLoading
          dataName={
            kubernetesResource.PresentName ?? kubernetesResource.NameInK8S
          }
        />
      </Panel>
    );
  } else if (filteredTableResults.length === 0) {
    content = (
      <>
        {kubernetesResource.Kind === KubernetesPodsResource.Kind && (
          <>
            <DeletedPodsSwitch clusterName={clusterName} />
            <br />
          </>
        )}
        <Panel>
          <NoItemsContainer>There are no items to show</NoItemsContainer>
        </Panel>
      </>
    );
  } else {
    content = (
      <ResourceListTable
        rows={filteredTableResults}
        cluster={clusterName}
        resourceType={kubernetesResource}
        refreshResults={refresh}
        isPaginating={resourceList.isPaginating}
        showLiveDataButton={shouldUseLiveData}
        overridingColumns={overridingColumns}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    );
    if (kubernetesResource.NameInK8S === "pods") {
      content = withNodesProvider(content, clusterName);
    }
  }
  return content;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default MainViewContent;
