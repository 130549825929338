import React, { useMemo } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@komodorio/design-system/komodor-ui";

import { useDataGridHoverRow } from "@/shared/hooks/dataGrid/useDataGridHoverRow";
import { RbacPolicy } from "@/generated/auth";
import { getColumnsDefinitions } from "@/pages/organization-settings/access-management/PoliciesPage/Grid/gridHelpers";
import {
  OnDelete,
  OnEdit,
} from "@/pages/organization-settings/access-management/PoliciesPage/type";
import { NoRowsOverlay } from "@/pages/organization-settings/account/AuditPage/components/NoRowsOverlay";

interface PoliciesGridProps {
  width?: number;
  filteredPolicies: RbacPolicy[];
  showExtraPolicyTypes: boolean;
  setPolicyToEdit: OnEdit;
  setPolicyToDelete: OnDelete;
  isLoading: boolean;
}

export const PoliciesGrid = ({
  width,
  filteredPolicies,
  showExtraPolicyTypes,
  setPolicyToEdit,
  setPolicyToDelete,
  isLoading,
}: PoliciesGridProps) => {
  const { hoveredRowId, handleRowHovered, handleRowLeaved } =
    useDataGridHoverRow();

  const rows = useMemo(
    () =>
      filteredPolicies.map((policy: RbacPolicy) => ({
        ...policy,
        actions: policy.id,
        hovered: policy.id === hoveredRowId,
      })),
    [filteredPolicies, hoveredRowId]
  );

  const columns = useMemo(
    () =>
      getColumnsDefinitions({
        showExtraActions: showExtraPolicyTypes,
        onEdit: setPolicyToEdit,
        onDelete: setPolicyToDelete,
      }).filter((column) => !column.hide),
    [setPolicyToEdit, setPolicyToDelete, showExtraPolicyTypes]
  );

  return (
    <Paper
      variant="elevation"
      elevation={1}
      sx={{ width: width || "100%", overflow: "auto" }}
    >
      <DataGrid
        autoHeight
        columns={columns}
        rows={rows}
        loading={isLoading}
        disableVirtualization
        slotProps={{
          row: {
            onMouseEnter: handleRowHovered,
            onMouseLeave: handleRowLeaved,
          },
        }}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: NoRowsOverlay,
        }}
        sx={{
          "--DataGrid-overlayHeight": "300px",
        }}
      />
    </Paper>
  );
};
