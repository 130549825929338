import React from "react";

import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { useGridPagination } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithHeaderAndTitle/useGridPagination";
import { VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/violationTableConstants";
import {
  ImpactGroupType,
  ViolationsApiGetViolationsRequest,
  ViolationStatus,
} from "@/generated/reliabilityApi";
import { useGetViolations } from "@/shared/hooks/reliability-api/violations/useGetViolations";
import { nodeAutoscalersPageAriaLabels } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/autoscalersAriaLabels";
import { TabNodeMetrics } from "@/components/k8sAddons/addons/NodeAutoscalers/AutoscalerTab/TabNodeMetrics";
import { ViolationTableWithTitleAndFooter } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithHeaderAndTitle/ViolationTableWithTitleAndFooter";
import { AutoscalerConfigView } from "@/components/k8sAddons/addons/NodeAutoscalers/AutoscalerTab/AutoscalerConfigView";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { Addon, Entity } from "@/generated/addonsApi";

export type AutoscalerConfig = {
  cluster: string;
  komodorUid: string;
  name: string;
};
export type AutoscalerTabProps = {
  resource: AutoscalerConfig;
};

const baseViolationsFetchConfig: Pick<
  ViolationsApiGetViolationsRequest,
  "impactGroupType" | "status"
> = {
  impactGroupType: [ImpactGroupType.Dynamic, ImpactGroupType.Static],
  status: [
    ViolationStatus.Open,
    ViolationStatus.Confirmed,
    ViolationStatus.Dismissed,
    ViolationStatus.Ignored,
  ],
};

export const AutoscalerTab: React.FC<AutoscalerTabProps> = ({ resource }) => {
  const { page, pageSize, addPaginationParams, setPaginationModel } =
    useGridPagination({
      defaultPageSize: VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS[0],
    });
  const violationFetchParams = addPaginationParams({
    ...baseViolationsFetchConfig,
    clusterName: [resource.cluster],
  });
  const violationsRes = useGetViolations(
    violationFetchParams,
    resource.cluster != null
  );
  const violationsCount = violationsRes?.data?.data?.violations.length ?? 0;

  return (
    <AddonContextProvider
      addonEntity={Entity.AutoScaler}
      addonType={Addon.AutoScalers}
    >
      <div aria-label={nodeAutoscalersPageAriaLabels.summaryTab}>
        <AccordionWrapper
          title={`Risks (${violationsCount})`}
          accordionContent={
            <ViolationTableWithTitleAndFooter
              paginationConfig={{ page, pageSize, setPaginationModel }}
              violationTableType={"RiskAssessment"}
              violations={violationsRes}
            />
          }
        ></AccordionWrapper>
        <AccordionWrapper
          title="Node Metrics (last 7 days)"
          accordionContent={<TabNodeMetrics cluster={resource.cluster} />}
        />
        <AccordionWrapper
          title="Configuration"
          accordionContent={
            <AutoscalerConfigView
              clusterName={resource.cluster}
              komodorUid={resource.komodorUid}
              resourceName={resource.name}
            />
          }
        />
      </div>
    </AddonContextProvider>
  );
};
