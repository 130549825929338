import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";

import { ResourceBreadcrumbs } from "../ResourceBreadcrumbs";

import { FullWidthCustomServiceSelection } from "./styles";

import {
  buildKomodorUid,
  BuildKomodorUidParams,
  parseKomodorUid,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";

export const optionToCustomElement = (option: MuiSelectionOption<string>) => {
  const service = parseKomodorUid(option.label);
  if (!service) {
    return null;
  }
  return (
    <ResourceBreadcrumbs
      clusterName={service.cluster}
      namespace={service.namespace}
      name={service.name}
      maxWidth="435px"
    />
  );
};

export const optionToCustomElementMultiSelect = (
  option: MuiSelectionOption<string>,
  isChecked?: boolean
) => {
  const komodorUid = parseKomodorUid(option.label);
  if (!komodorUid) {
    return null;
  }
  return (
    <Box display="flex" gap="16px" padding="3.5px 16px" maxWidth="90%">
      <Checkbox
        checked={isChecked}
        sx={{
          "& .MuiSvgIcon-root": { fontSize: 20 },
          padding: 0,
        }}
      />
      <FullWidthCustomServiceSelection
        clusterName={komodorUid.cluster}
        namespace={komodorUid.namespace}
        name={komodorUid.name}
        maxWidth="100%"
      />
    </Box>
  );
};

export const customSearch = (
  searchTerm: string,
  options: MuiSelectionOption<string>[]
) => {
  const lowercaseSearchTerm = searchTerm.toLowerCase();
  return options
    .filter((option) => {
      const komodorUid = parseKomodorUid(option.label);
      if (!komodorUid) {
        return false;
      }
      const { cluster, namespace, name } = komodorUid;
      return (
        name.toLowerCase().includes(lowercaseSearchTerm) ||
        cluster.toLowerCase().includes(lowercaseSearchTerm) ||
        namespace.toLowerCase().includes(lowercaseSearchTerm)
      );
    })
    .sort((a, b) => {
      const aNameMatches = parseKomodorUid(a.label)
        ?.name.toLowerCase()
        .includes(lowercaseSearchTerm);
      const bNameMatches = parseKomodorUid(b.label)
        ?.name.toLowerCase()
        .includes(lowercaseSearchTerm);

      if (aNameMatches && !bNameMatches) {
        return -1;
      }
      if (!aNameMatches && bNameMatches) {
        return 1;
      }
      return 0;
    });
};

export const isOptionDisabled = ({
  option,
  selectedComparedOptions,
  maxCompared,
}: {
  option: MuiSelectionOption<string>;
  selectedComparedOptions: MuiSelectionOption<string>[] | null;
  maxCompared: number;
}): boolean => {
  return (
    !!selectedComparedOptions &&
    selectedComparedOptions.length >= maxCompared &&
    !selectedComparedOptions.some((o) => o.value === option.value)
  );
};

interface CompareOptionParams {
  clusterName: string;
  namespace: string;
  resourceName: string;
}
const compareOptions = (
  a: CompareOptionParams,
  b: CompareOptionParams
): number => {
  return (
    a.clusterName.localeCompare(b.clusterName) ||
    a.namespace.localeCompare(b.namespace) ||
    a.resourceName.localeCompare(b.resourceName)
  );
};

const compareByBaselineMatching = ({
  aServiceNameMatchesBaseline,
  bServiceNameMatchesBaseline,
  a,
  b,
}: {
  aServiceNameMatchesBaseline: boolean;
  bServiceNameMatchesBaseline: boolean;
  a: CompareOptionParams;
  b: CompareOptionParams;
}) => {
  if (aServiceNameMatchesBaseline && bServiceNameMatchesBaseline) {
    return compareOptions(a, b);
  }
  if (aServiceNameMatchesBaseline) {
    return -1;
  }
  if (bServiceNameMatchesBaseline) {
    return 1;
  }
  return compareOptions(a, b);
};

export const comparedOptionsSorter = ({
  aKomodorUidParams,
  bKomodorUidParams,
  baselineSelection,
  comparedSelections,
}: {
  aKomodorUidParams: BuildKomodorUidParams;
  bKomodorUidParams: BuildKomodorUidParams;
  baselineSelection: MuiSelectionOption<string> | null;
  comparedSelections: MuiSelectionOption<string>[] | null;
}) => {
  const [aUid, bUid] = [aKomodorUidParams, bKomodorUidParams].map(
    buildKomodorUid
  );
  const [isACompared, isBCompared] = [aUid, bUid].map((uid) =>
    comparedSelections?.some((option) => option.label === uid)
  );
  const parsedBaselineKomodorUid = baselineSelection
    ? parseKomodorUid(baselineSelection.label)
    : undefined;
  const [aServiceNameMatchesBaseline, bServiceNameMatchesBaseline] = [
    aKomodorUidParams,
    bKomodorUidParams,
  ].map((params) => parsedBaselineKomodorUid?.name === params.resourceName);

  if (isACompared || isBCompared) {
    return isACompared && isBCompared
      ? compareByBaselineMatching({
          aServiceNameMatchesBaseline,
          bServiceNameMatchesBaseline,
          a: aKomodorUidParams,
          b: bKomodorUidParams,
        })
      : isACompared
      ? -1
      : 1;
  }

  return compareByBaselineMatching({
    aServiceNameMatchesBaseline,
    bServiceNameMatchesBaseline,
    a: aKomodorUidParams,
    b: bKomodorUidParams,
  });
};
