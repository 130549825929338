import React, { createContext, PropsWithChildren, useState } from "react";
import { GridSortItem } from "@mui/x-data-grid/models/gridSortModel";

import { Addon, Entity } from "@/generated/addonsApi";
import {
  DEFAULT_SORT_BY_DIRECTION,
  DEFAULT_SORT_BY_FIELD,
} from "@/components/k8sAddons/constants/table";
import { useAddonsQueryStringInLocalStorage } from "@/components/k8sAddons/hooks/filters/useQueryStringInLocalStorage";

type InternalContextState = {
  initialClustersFilterWasSet: boolean;
  setInitialClustersFilterWasSet: (val: boolean) => void;
};

type AddonContextStateProps = {
  addonType: Addon;
  addonEntity: Entity;
  defaultSortModel?: GridSortItem;
  refetchInterval?: number;
  // disable API calls until initial clusterName is set
  setInitialClustersFilter?: boolean;
  // disable server side pagination, fetch entire results
  disableServerSidePagination?: boolean;
};

export type AddonContextState = AddonContextStateProps & InternalContextState;

const initialState: AddonContextState = {
  addonType: Addon.CertManager,
  addonEntity: Entity.Controller,
  refetchInterval: 0,
  setInitialClustersFilter: false,
  initialClustersFilterWasSet: false,
  setInitialClustersFilterWasSet: () => undefined,
  defaultSortModel: {
    field: DEFAULT_SORT_BY_FIELD,
    sort: DEFAULT_SORT_BY_DIRECTION,
  },
  disableServerSidePagination: false,
};

export const AddonContext = createContext<AddonContextState>(initialState);

type AddonContextProps = PropsWithChildren<AddonContextStateProps>;

export const AddonContextProvider: React.FC<AddonContextProps> = ({
  addonEntity,
  addonType,
  refetchInterval,
  setInitialClustersFilter,
  defaultSortModel,
  children,
  disableServerSidePagination,
}) => {
  const [initialClustersFilterWasSet, setInitialClustersFilterWasSet] =
    useState(initialState.initialClustersFilterWasSet);

  useAddonsQueryStringInLocalStorage(addonType, addonEntity);

  const contextState: AddonContextState = {
    addonType,
    addonEntity,
    setInitialClustersFilter,
    refetchInterval,
    defaultSortModel,
    initialClustersFilterWasSet,
    setInitialClustersFilterWasSet,
    disableServerSidePagination,
  };

  return (
    <AddonContext.Provider value={contextState}>
      {children}
    </AddonContext.Provider>
  );
};
