import { useCallback, useEffect } from "react";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";

import { DriftAnalysisTabType } from "./types";

import { DRIFT_ANALYSIS_TAB_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const DriftAnalysisTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] =
    useStringifiedStateInSearchParams<DriftAnalysisTabType>(
      DRIFT_ANALYSIS_TAB_PARAM_KEY
    );

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(DriftAnalysisTabType.Services, true);
    }
  }, [selectedTab, setSelectedTab]);
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: DriftAnalysisTabType) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );
  const { helmDrift } = useOverridableFlags();

  return selectedTab && !!helmDrift ? (
    <TabContext value={selectedTab}>
      <TabList onChange={handleChange}>
        <Tab label="Services" value={DriftAnalysisTabType.Services} />
        <Tab label="Helm Releases" value={DriftAnalysisTabType.HelmReleases} />
      </TabList>
    </TabContext>
  ) : null;
};
