import { Static } from "runtypes";

import { FetchLastAgentInfoQuery } from "@/generated/graphql";
import { ConfigurationType } from "@/shared/hooks/useAgentInfo/useAgentInfo";
import {
  REQUIRED_AGENT_VERSION_CRD,
  REQUIRED_AGENT_VERSION_POD_EXEC,
  REQUIRED_AGENT_VERSION_RESOURCE_LIST,
  REQUIRED_AGENT_VERSION_SECRET_REDACTION,
} from "@/shared/utils/agent/agent";
import { agentResponse } from "@/shared/hooks/agents-api/utils";
import { mockPredefinedClusterNames } from "@/test/mockData/mockConstants";

export type Configuration = Static<typeof ConfigurationType>;

export type AgentInfo = agentResponse["activeAgents"][number];
export type LastAgentInfo =
  FetchLastAgentInfoQuery["kubernetes_agent_info"][number] & {
    configuration: Configuration;
    installationId: string;
  };

export const mockAgentData: LastAgentInfo = {
  clusterName: "development",
  configuration: {
    appmetadata: {
      version: "999.0.0+development",
    },
  },
  inactive: false,
  lastSeenAlive: "2023-05-30T10:33:11",
  installationId: "installation-id",
};

const mixedAgentVersions = [
  REQUIRED_AGENT_VERSION_SECRET_REDACTION,
  REQUIRED_AGENT_VERSION_CRD,
];

const supportedAgentVersions = [REQUIRED_AGENT_VERSION_SECRET_REDACTION];

const unsupportedAgentVersions = [
  REQUIRED_AGENT_VERSION_CRD,
  REQUIRED_AGENT_VERSION_POD_EXEC,
  REQUIRED_AGENT_VERSION_RESOURCE_LIST,
];

const mixedChartVersions = ["2.0.0", "2.5.2"];

type AgentVersions = "mixed" | "all" | "none";

export const generateMockAgentInfoList = (
  len: number,
  agentVersions: AgentVersions = "mixed"
) => {
  const versionsList =
    agentVersions === "mixed"
      ? mixedAgentVersions
      : agentVersions === "all"
      ? supportedAgentVersions
      : unsupportedAgentVersions;

  const chartVersionsList = mixedChartVersions;

  return Array.from({ length: len }, (_, i) => ({
    ...mockAgentData,
    clusterName: `cluster-name-${i}`,
    configuration: {
      ...mockAgentData.configuration,
      ...(i % 2 === 0
        ? {}
        : { chartversion: mixedChartVersions[i % chartVersionsList.length] }),
      appmetadata: {
        ...mockAgentData.configuration.appmetadata,
        version: versionsList[i % versionsList.length],
      },
      daemon: {
        enabled: true,
      },
    },
    installationId: `installation-id-${i}`,
  }));
};

const createPredefinedMockAgentInfo = (name: string) => {
  return {
    ...mockAgentData,
    clusterName: name,
    configuration: {
      ...mockAgentData.configuration,
      enableagenttaskexecution: true,
      enableagenttaskexecutionv2: true,
      chartversion: "2.5.2",
      appmetadata: {
        ...mockAgentData.configuration.appmetadata,
        version: REQUIRED_AGENT_VERSION_SECRET_REDACTION,
      },
      daemon: {
        enabled: true,
      },
    },
    installationId: `installation-id-${name}`,
  };
};

export const generatePredefinedMockAgentInfoList = () => {
  return mockPredefinedClusterNames.map((name) =>
    createPredefinedMockAgentInfo(name)
  );
};

export const generateMockAgentsInfo = (
  len = 10,
  reqTime = 0
): Promise<LastAgentInfo[]> => {
  const response = generateMockAgentInfoList(len);

  return new Promise((resolve) => {
    setTimeout(() => resolve(response), reqTime);
  });
};
