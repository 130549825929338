import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";

import Resource from "@/components/ResourceView/resources";
import {
  DrawerStatePush,
  DrawerType,
} from "@/shared/store/drawersStackStore/types";
import { useResourceAiInvestigation } from "@/components/AiInvestigation/useResourceAiInvestigation";
import { FlowType } from "@/components/AiInvestigation/types";
import { AiInvestigationTitle } from "@/components/AiInvestigation/AiInvestigationTitle";
import { AiInvestigationContent } from "@/components/AiInvestigation/AiInvestigationContent/AiInvestigationContent";

interface AiInvestigationProps {
  requestId: string;
  flowType: FlowType;
  resource: Resource;
  isHealthy: boolean;
  sessionId?: string;
  drawerData?: DrawerStatePush;
  disableTrigger?: boolean;
  padding?: string;
}

export const AiInvestigation: React.FC<AiInvestigationProps> = ({
  requestId,
  flowType,
  resource,
  isHealthy,
  sessionId,
  drawerData,
  disableTrigger,
  padding,
}) => {
  const defaultResourceDrawerData = useMemo<DrawerStatePush>(
    () => ({
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: resource.cluster,
      namespace: resource.namespace,
      resourceType: resource.kind,
      resourceName: resource.name,
    }),
    [resource]
  );

  const {
    enabled,
    richSession,
    onShareClicked,
    isError,
    isLoading,
    onRetryCallback,
  } = useResourceAiInvestigation({
    drawerData: drawerData || defaultResourceDrawerData,
    requestId,
    resource,
    isHealthy,
    flowType,
    sessionId,
    disableTrigger,
  });

  if (!enabled || (isHealthy && !richSession)) {
    return null;
  }

  return (
    <Stack padding={padding} gap={1}>
      <AiInvestigationTitle
        richSession={richSession}
        onShareClicked={onShareClicked}
      />
      <AiInvestigationContent
        richSession={richSession}
        cluster={resource.cluster}
        isError={isError}
        onRetryCallback={onRetryCallback}
        isLoading={isLoading}
      />
    </Stack>
  );
};
