import React from "react";
import Stack from "@mui/material/Stack";

import { MetricGraph } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/AutoscalersMetricsGraph/MetricGraph";
import { useNodesMetrics } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/AutoscalersMetricsGraph/useNodesMetrics";
import { createGraphContainerProps } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/AutoscalersMetricsGraph/createGraphContainerProps";
import { NodeCountGraph } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeMetrics/NodeCount/NodeCountGraph";

type TabNodeMetricsProps = { cluster: string };

export const TabNodeMetrics: React.FC<TabNodeMetricsProps> = ({ cluster }) => {
  const { cpuData, error, isFetching, memoryData } = useNodesMetrics({
    clusterNames: [cluster],
    isDataFetchEnabled: true,
  });

  const graphContainerProps = createGraphContainerProps({
    hasError: !!error,
    cluster,
    isLoading: isFetching,
  });

  return (
    <>
      <NodeCountGraph clusterNames={[cluster]} />
      <Stack direction="row">
        <MetricGraph
          graphContainerProps={graphContainerProps}
          metric="cpu"
          metricData={cpuData}
          showLabel={false}
        />
        <MetricGraph
          graphContainerProps={graphContainerProps}
          metric="memory"
          metricData={memoryData}
          showLabel={false}
        />
      </Stack>
    </>
  );
};
