import { Send } from "@mui/icons-material";
import { IconButton, TextFieldProps } from "@mui/material";
import { TextInput } from "components/komodor-ui/TextInput/TextInput";
import { useEffect, useRef } from "react";

export type ChatInputProps = TextFieldProps & {
  onSend: () => void;
  focusOnMount?: boolean;
};

export const ChatInput: React.FC<ChatInputProps> = ({
  onSend,
  disabled,
  focusOnMount = true,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusOnMount) {
      inputRef.current?.focus();
    }
  }, []);

  const handleSend = () => {
    onSend();
    inputRef.current?.focus();
  };

  return (
    <TextInput
      inputRef={inputRef}
      variant="outlined"
      size="large"
      fullWidth
      onKeyDown={(e) => {
        if (!disabled && e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          handleSend();
        }
      }}
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleSend} disabled={disabled} color="primary">
            <Send color="inherit" />
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};
