import React from "react";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { PoliciesPageContent } from "@/pages/organization-settings/access-management/PoliciesPage/PoliciesPageContent";

export const PoliciesPage: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.accountSettingsPoliciesPage,
        context: {
          feTeam: "barzelim",
          beTeam: "barzelim",
        },
      }}
    >
      <PoliciesPageContent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
