import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import pluralize from "pluralize";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { uniq } from "lodash";

import { ResourceBreadcrumbs } from "../../../ResourceBreadcrumbs";
import { useParseKomodorUids } from "../hooks/metricsRequestsHooks";

import { AriaLabels } from "@/shared/config/ariaLabels";
import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";
import { useIsMetricsSupportedForMultipleClusters } from "@/components/Metrics/metricsHooks";

type InfoSectionProps = {
  komodorUids: string[];
};

const Container = styled.div`
  display: flex;
  padding: 4px 8px 4px 4px;
  border: 1px solid ${muiColors.blue[300]};
  border-radius: 4px;
  align-items: center;
  background-color: white;
`;

export const MetricsNotSupportedSection: React.FC<InfoSectionProps> = ({
  komodorUids,
}) => {
  const parsedKomodorUids = useParseKomodorUids(komodorUids);
  const enabledClusters = useIsMetricsSupportedForMultipleClusters(
    uniq(parsedKomodorUids.map((k) => k.cluster))
  );

  const notSupportedServices = useMemo(
    () =>
      parsedKomodorUids.filter(
        (komodorUid) => !enabledClusters[komodorUid.cluster]
      ),
    [enabledClusters, parsedKomodorUids]
  );

  if (notSupportedServices.length === 0) {
    return null;
  }
  return (
    <Container aria-label={AriaLabels.Metrics.Label}>
      <InfoOutlined
        sx={{
          padding: "8px",
          color: muiColors.blue[600],
          fontSize: "14px",
          backgroundColor: muiColors.blue[50],
        }}
      />

      <Box
        sx={{
          display: "flex",
          gap: "4px",
          padding: "4px 4px 4px 16px",
        }}
      >
        <Typography variant="body2" color={muiColors.blue[600]}>
          Metrics are unavailable for{" "}
        </Typography>
        <Tooltip
          placement={"top"}
          componentsProps={lightMuiTooltipStyle}
          title={notSupportedServices.map((komodorUid, index) => (
            <ResourceBreadcrumbs
              key={index}
              clusterName={komodorUid.cluster}
              namespace={komodorUid.namespace}
              name={komodorUid.name}
            />
          ))}
        >
          <Typography
            variant="body2"
            color={muiColors.blue[600]}
            sx={{
              display: "flex",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {pluralize("service", notSupportedServices.length, true)}
          </Typography>
        </Tooltip>
      </Box>
    </Container>
  );
};
