import { palette, theme } from "@komodorio/design-system";
import { Tag } from "@komodorio/design-system/deprecated";
import { Eye16, Pencil16 } from "@komodorio/design-system/icons";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { sortBy } from "lodash";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import DataTable, { Column } from "../../common/table/DataTable";
import { ActionsCol, CopyText, Flex, Header } from "../styles";
import useIsFreeTier from "../../Freemium/useIsFreeTier";

import { DeleteRoleModal } from "./DeleteRoleModal";

import { useGetRbacRoles } from "@/shared/hooks/auth-service/client/rbacRoles/useGetRbacRoles";
import { RbacRole } from "@/generated/auth";
import { RBAC_ROLE } from "@/shared/config/urlSearchParamsKeys";
import { DELETE_ICON_STYLE } from "@/components/Settings/constants";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { EditRoleModal } from "@/components/Settings/Roles/EditRoleModal";

// TODO Lior: Move to shared
export const ACCOUNT_ADMIN_RBAC_ROLE = "account-admin";
const isRoleEditable = (role?: RbacRole) =>
  role?.name !== ACCOUNT_ADMIN_RBAC_ROLE;

const getPolicies = (r: RbacRole) => (
  <Flex>
    {r.rbacPolicies?.map((policy) => (
      <Tag key={policy.id} color={theme.background.bgGray}>
        {policy.name}
      </Tag>
    ))}
  </Flex>
);

const DefaultRoleTextStyle = styled.span`
  color: ${palette.gray[600]};
`;

const DefaultRoleText: React.FC<{ roleName: string; isDefault: boolean }> = ({
  roleName,
  isDefault,
}) => {
  return (
    <>
      {roleName}{" "}
      {isDefault && <DefaultRoleTextStyle>(default role)</DefaultRoleTextStyle>}
    </>
  );
};

const RolesView: React.FC = () => {
  const [roleSearch, setRoleSearch] = useStateInSearchParams(RBAC_ROLE);
  const { data: roles, refetch, isFetching } = useGetRbacRoles();

  const [openAddRole, setOpenAddRole] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState<RbacRole>();
  const [roleToDelete, setRoleToDelete] = useState<RbacRole>();
  const isFreeTier = useIsFreeTier();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState("isFetchingRoles", isFetching);
  }, [isFetching, reportLoadingState]);

  const columns: Column<RbacRole>[] = [
    {
      name: "Name",
      selector: (r) => (
        <DefaultRoleText roleName={r.name} isDefault={r.isDefault} />
      ),
    },
    { name: "Policies", selector: getPolicies },
    { name: "ID", selector: (r) => <CopyText text={r.id} /> },
    {
      name: "",
      selector: (r) => (
        <ActionsCol>
          <IconButton onClick={() => setRoleToEdit(r)}>
            {isRoleEditable(r) && !isFreeTier ? <Pencil16 /> : <Eye16 />}
          </IconButton>
          {isRoleEditable(r) && !isFreeTier && (
            <IconButton onClick={() => setRoleToDelete(r)}>
              <DeleteOutlineIcon sx={DELETE_ICON_STYLE} fontSize="small" />
            </IconButton>
          )}
        </ActionsCol>
      ),
    },
  ];

  const filteredRoles = useMemo(() => {
    const lowerCaseFilter = (roleSearch || "").toLowerCase();
    return roles
      ? sortBy(
          roles.filter((r) => {
            return [r.name].some((s) => {
              return s.toLowerCase().includes(lowerCaseFilter);
            });
          }),
          (r) => r.name
        )
      : [];
  }, [roles, roleSearch]);

  return (
    <SettingsViewVerticalLayout title={"Roles"}>
      <Header>
        <SearchField
          size="medium"
          width="15rem"
          placeholder="Search"
          value={roleSearch || ""}
          showCloseIcon={true}
          ariaLabel="Search roles"
          onSearch={setRoleSearch}
        />
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenAddRole(true)}
        >
          Add role
        </Button>
      </Header>
      <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
      <DataTable
        data={filteredRoles}
        columns={columns}
        getRowId={(r) => r.id}
        highlightedRows
      />
      {openAddRole && (
        <EditRoleModal
          isReadOnly={false}
          onClose={() => setOpenAddRole(false)}
          refreshRoles={refetch}
        />
      )}
      {!!roleToEdit && (
        <EditRoleModal
          role={roleToEdit}
          onClose={() => setRoleToEdit(undefined)}
          isReadOnly={!isRoleEditable(roleToEdit) || isFreeTier}
          refreshRoles={refetch}
        />
      )}
      {roleToDelete && !roleToDelete.isDefault && (
        <DeleteRoleModal
          onClose={() => setRoleToDelete(undefined)}
          role={roleToDelete}
          refreshRoles={refetch}
        />
      )}
    </SettingsViewVerticalLayout>
  );
};

export const Roles: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.accountSettingsRolesPage,
        context: {
          feTeam: "barzelim",
          beTeam: "barzelim",
        },
      }}
    >
      <RolesView />
    </DatadogReportLoadingTimeContextProvider>
  );
};
