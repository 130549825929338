import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import {
  drawersStackSelector,
  popDrawerSelector,
} from "../../store/drawersStackStore/drawersStackSelectors";
import {
  syncDrawersStackStore,
  useDrawersStackStore,
} from "../../store/drawersStackStore/drawersStackStore";
import {
  DrawerStateType,
  DrawerType,
} from "../../store/drawersStackStore/types";
import { DRAWER_STACK_STATE_URL_PARAM } from "../../config/urlSearchParamsKeys";

import { DrawerStackIndexProvider } from "./DrawerStackIndexProvider";
import { TransitionHandler } from "./TransitionHandler";

import {
  ResourceDrawerByData,
  ResourceDrawerByServiceId,
} from "@/components/ResourceView/ResourceDrawer";
import {
  ViolationsDrawerByScope,
  ViolationsDrawer,
} from "@/components/reliability";
import { ReliabilityPolicyDrawer } from "@/components/reliability/components/pages/policies/PolicyDrawer/ReliabilityPolicyDrawer";
import { EventDrawerById } from "@/components/common/ProcessList/EventDrawerById";
import { LiveStateDrawer } from "@/components/k8sAddons/LiveStateDrawer/LiveStateDrawer";
import { RealtimeIssuesDrawer } from "@/components/reliability/components/RealtimeIssuesDrawer/RealtimeIssuesDrawer";
import { KlaudiaChatDrawer } from "@/components/KlaudiaChat/KlaudiaChatDrawer";
import ChartReleaseDrawer from "@/components/Inspection/Helm/drawers/ChartReleaseDrawer";

const renderDrawer = (
  drawerState: DrawerStateType,
  open: boolean,
  onClose: () => void
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  switch (drawerState.drawerType) {
    case DrawerType.ResourceDrawerByData: {
      return (
        <ResourceDrawerByData open={open} onClose={onClose} {...drawerState} />
      );
    }
    case DrawerType.ResourceDrawerByServiceId: {
      return (
        <ResourceDrawerByServiceId
          open={open}
          onClose={onClose}
          {...drawerState}
        />
      );
    }
    case DrawerType.ViolationsDrawerByScope: {
      return (
        <ViolationsDrawerByScope
          open={open}
          onClose={onClose}
          {...drawerState}
        />
      );
    }
    case DrawerType.ViolationDrawer: {
      return (
        <ViolationsDrawer open={open} onClose={onClose} {...drawerState} />
      );
    }
    case DrawerType.ReliabilityPolicyDrawer: {
      return (
        <ReliabilityPolicyDrawer
          open={open}
          onClose={onClose}
          {...drawerState}
        />
      );
    }
    case DrawerType.EventDrawerById: {
      return <EventDrawerById open={open} onClose={onClose} {...drawerState} />;
    }
    case DrawerType.K8sAddonLiveStateDrawer: {
      return <LiveStateDrawer open={open} onClose={onClose} {...drawerState} />;
    }
    case DrawerType.RealtimeIssuesDrawer: {
      return (
        <RealtimeIssuesDrawer open={open} onClose={onClose} {...drawerState} />
      );
    }
    case DrawerType.KlaudiaChatDrawer: {
      return (
        <KlaudiaChatDrawer open={open} onClose={onClose} {...drawerState} />
      );
    }
    case DrawerType.HelmReleaseDrawer: {
      return (
        <ChartReleaseDrawer open={open} onClose={onClose} {...drawerState} />
      );
    }
    default:
      return null;
  }
};

export const DrawersStack: React.FC = () => {
  const drawersStack = useDrawersStackStore(drawersStackSelector);
  const popDrawer = useDrawersStackStore(popDrawerSelector);

  const stateInUrl = useSearchParams()[0].get(DRAWER_STACK_STATE_URL_PARAM);

  useEffect(() => {
    syncDrawersStackStore();
  }, [stateInUrl]);

  const drawers = useMemo(
    () =>
      drawersStack.map((drawerState) => {
        return (
          <DrawerStackIndexProvider
            key={drawerState.index}
            drawerIndex={drawerState.index}
          >
            <TransitionHandler open={true} onClose={popDrawer}>
              {(drawerIsOpen, internalOnClose) =>
                renderDrawer(drawerState, drawerIsOpen, internalOnClose)
              }
            </TransitionHandler>
          </DrawerStackIndexProvider>
        );
      }),
    [drawersStack, popDrawer]
  );

  return <>{drawers}</>;
};
