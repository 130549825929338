import React from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useNavigate } from "react-router-dom";

import { TabType } from "./types";
import { ActionFooter } from "./ActionFooter";

import {
  CREATE_WORKSPACE_ROUTE,
  CREATE_SCOPED_WORKSPACE_ROUTE,
} from "@/components/routes/routes";
import { AnalyticEvents } from "@/shared/config/analyticsEvents";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";

interface Props {
  selectedValidTab: TabType;
  setAnchorEl: (el: HTMLDivElement | null) => void;
  setIsInstallationModalOpen: (isOpen: boolean) => void;
  setIsAddEditModalOpen: (isOpen: boolean) => void;
}

export const ActionFooterContainer: React.FC<Props> = ({
  selectedValidTab,
  setAnchorEl,
  setIsAddEditModalOpen,
  setIsInstallationModalOpen,
}) => {
  const { canManageWorkspaces } = useHasPermissions();
  const navigate = useNavigate();

  if (!canManageWorkspaces) {
    return null;
  }

  if (selectedValidTab === "workspaces") {
    return (
      <ActionFooter
        label="Add workspace"
        onClick={() => {
          navigate(CREATE_WORKSPACE_ROUTE);
        }}
      />
    );
  } else if (selectedValidTab === "clusters") {
    return (
      <ActionFooter
        label="Connect new cluster"
        onClick={() => {
          setAnchorEl(null);
          setIsInstallationModalOpen(true);
        }}
      />
    );
  } else if (selectedValidTab === "clusters_group") {
    return (
      <ActionFooter
        label="Add cluster group"
        onClick={() => {
          setAnchorEl(null);
          setIsAddEditModalOpen(true);
          datadogRum.addAction(
            AnalyticEvents.Workspaces.WorkspaceSwitcherAddClusterGroupClicked
          );
        }}
      />
    );
  } else if (selectedValidTab === "scoped_workspaces") {
    return (
      <ActionFooter
        label="Add scoped workspace"
        onClick={() => {
          navigate(CREATE_SCOPED_WORKSPACE_ROUTE);
        }}
      />
    );
  }

  return null;
};
