import { IconButton, Stack, Tooltip } from "@mui/material";
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import React, { useMemo } from "react";
import { lightMuiTooltipStyle } from "styles/styles";

interface HoveredGridRowModel extends GridValidRowModel {
  hovered: boolean;
}

type Action<R extends HoveredGridRowModel> = {
  title?: string;
  enabled: boolean;
  key: string;
  ariaLabel?: string;
  icon: React.ReactElement;
  onAction?: (
    params: GridRenderCellParams<{ row: R }, any, any, GridTreeNodeWithRender>
  ) => void;
};

interface Props<R extends HoveredGridRowModel> {
  actions: Action<R>[];
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>;
}

export const ActionCell = <R extends HoveredGridRowModel>({
  actions,
  params,
}: Props<R>) => {
  const actionButtons = useMemo(() => {
    return actions.map((action) => (
      <Tooltip
        title={action.title}
        key={action.key}
        componentsProps={lightMuiTooltipStyle}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        <span>
          <IconButton
            onClick={() => (action.onAction ? action.onAction(params) : null)}
            disabled={!action.enabled}
          >
            {action.icon}
          </IconButton>
        </span>
      </Tooltip>
    ));
  }, [actions]);

  return (
    <Stack
      direction="row"
      gap="1px"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      {params.row.hovered && actionButtons}
    </Stack>
  );
};
