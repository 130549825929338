import React from "react";
import Typography from "@mui/material/Typography";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { CopyText } from "@/components/Settings/styles";
import {
  ActionsCell,
  ActionsCellProps,
} from "@/pages/organization-settings/access-management/PoliciesPage/Grid/ActionsCell";
import { getTagsCell } from "@/pages/organization-settings/access-management/PoliciesPage/Grid/TagsCell";
import {
  OnDelete,
  OnEdit,
} from "@/pages/organization-settings/access-management/PoliciesPage/type";

const TAGS_CELL_WIDTH = 288;

interface PoliciesColumnsDefinitionsProps {
  showExtraActions: boolean;
  onEdit: OnEdit;
  onDelete: OnDelete;
}

export const getColumnsDefinitions = ({
  showExtraActions,
  onEdit,
  onDelete,
}: PoliciesColumnsDefinitionsProps) => {
  return [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="overline2">
          {params.value.replace("_", " ")}
        </Typography>
      ),
      flex: 1,
      hide: !showExtraActions,
    },
    {
      field: "tags",
      headerName: "Tags",
      width: TAGS_CELL_WIDTH,
      resizable: false,
      renderCell: (params: GridRenderCellParams) =>
        getTagsCell({
          params,
          width: TAGS_CELL_WIDTH,
        }),
      hide: !showExtraActions,
    },
    {
      field: "id",
      headerName: "ID",
      renderCell: (params: GridRenderCellParams) => (
        <CopyText text={params.value} />
      ),
      flex: 1.5,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        getActionsCell({
          params,
          onEdit,
          onDelete,
        }),
    },
  ];
};

const getActionsCell = ({ params, onEdit, onDelete }: ActionsCellProps) => {
  return <ActionsCell params={params} onEdit={onEdit} onDelete={onDelete} />;
};
