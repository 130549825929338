/* tslint:disable */
/* eslint-disable */
/**
 * Workspaces API
 * An API that exposes workspaces functionality for the Komodor platform
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface AdditionalInfo
 */
export interface AdditionalInfo {
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalInfo
     */
    'isMetricsInstalled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalInfo
     */
    'approachingEol': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalInfo
     */
    'reachedEol': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalInfo
     */
    'isPodsEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInfo
     */
    'chartVersion': string;
}


/**
 * Check if a given object implements the AdditionalInfo interface.
 */
export function instanceOfAdditionalInfo(value: object): value is AdditionalInfo {
    if (!('isMetricsInstalled' in value) || value['isMetricsInstalled'] === undefined) return false;
    if (!('approachingEol' in value) || value['approachingEol'] === undefined) return false;
    if (!('reachedEol' in value) || value['reachedEol'] === undefined) return false;
    if (!('isPodsEnabled' in value) || value['isPodsEnabled'] === undefined) return false;
    if (!('chartVersion' in value) || value['chartVersion'] === undefined) return false;
    return true;
}

export function AdditionalInfoFromJSON(json: any): AdditionalInfo {
    return AdditionalInfoFromJSONTyped(json, false);
}

export function AdditionalInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'isMetricsInstalled': json['isMetricsInstalled'],
        'approachingEol': json['approachingEol'],
        'reachedEol': json['reachedEol'],
        'isPodsEnabled': json['isPodsEnabled'],
        'chartVersion': json['chartVersion'],
    };
}

export function AdditionalInfoToJSON(value?: AdditionalInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isMetricsInstalled': value['isMetricsInstalled'],
        'approachingEol': value['approachingEol'],
        'reachedEol': value['reachedEol'],
        'isPodsEnabled': value['isPodsEnabled'],
        'chartVersion': value['chartVersion'],
    };
}

        /**
 * 
 * @export
 * @interface BaselineHelmResource
 */
export interface BaselineHelmResource {
    /**
     * 
     * @type {boolean}
     * @memberof BaselineHelmResource
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaselineHelmResource
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaselineHelmResource
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaselineHelmResource
     */
    'namespace'?: string;
}


/**
 * Check if a given object implements the BaselineHelmResource interface.
 */
export function instanceOfBaselineHelmResource(value: object): value is BaselineHelmResource {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function BaselineHelmResourceFromJSON(json: any): BaselineHelmResource {
    return BaselineHelmResourceFromJSONTyped(json, false);
}

export function BaselineHelmResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaselineHelmResource {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isEmpty': json['isEmpty'],
        'value': json['value'] == null ? undefined : json['value'],
        'namespace': json['namespace'] == null ? undefined : json['namespace'],
    };
}

export function BaselineHelmResourceToJSON(value?: BaselineHelmResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isEmpty': value['isEmpty'],
        'value': value['value'],
        'namespace': value['namespace'],
    };
}

        /**
 * 
 * @export
 * @interface BaselineHelmResourceAllOf
 */
export interface BaselineHelmResourceAllOf {
    /**
     * 
     * @type {string}
     * @memberof BaselineHelmResourceAllOf
     */
    'namespace'?: string;
}


/**
 * Check if a given object implements the BaselineHelmResourceAllOf interface.
 */
export function instanceOfBaselineHelmResourceAllOf(value: object): value is BaselineHelmResourceAllOf {
    return true;
}

export function BaselineHelmResourceAllOfFromJSON(json: any): BaselineHelmResourceAllOf {
    return BaselineHelmResourceAllOfFromJSONTyped(json, false);
}

export function BaselineHelmResourceAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaselineHelmResourceAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'namespace': json['namespace'] == null ? undefined : json['namespace'],
    };
}

export function BaselineHelmResourceAllOfToJSON(value?: BaselineHelmResourceAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'namespace': value['namespace'],
    };
}

        /**
 * 
 * @export
 * @interface BaselineHelmResources
 */
export interface BaselineHelmResources {
    /**
     * 
     * @type {ComparisonAttributeInteger}
     * @memberof BaselineHelmResources
     */
    'count': ComparisonAttributeInteger;
    /**
     * 
     * @type {Array<BaselineHelmResource>}
     * @memberof BaselineHelmResources
     */
    'resources': Array<BaselineHelmResource>;
}


/**
 * Check if a given object implements the BaselineHelmResources interface.
 */
export function instanceOfBaselineHelmResources(value: object): value is BaselineHelmResources {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('resources' in value) || value['resources'] === undefined) return false;
    return true;
}

export function BaselineHelmResourcesFromJSON(json: any): BaselineHelmResources {
    return BaselineHelmResourcesFromJSONTyped(json, false);
}

export function BaselineHelmResourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaselineHelmResources {
    if (json == null) {
        return json;
    }
    return {
        
        'count': ComparisonAttributeIntegerFromJSON(json['count']),
        'resources': ((json['resources'] as Array<any>).map(BaselineHelmResourceFromJSON)),
    };
}

export function BaselineHelmResourcesToJSON(value?: BaselineHelmResources | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': ComparisonAttributeIntegerToJSON(value['count']),
        'resources': ((value['resources'] as Array<any>).map(BaselineHelmResourceToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ClusterFilterData
 */
export interface ClusterFilterData {
    /**
     * 
     * @type {string}
     * @memberof ClusterFilterData
     */
    'name': string;
    /**
     * 
     * @type {Array<ClusterFilterDataValues>}
     * @memberof ClusterFilterData
     */
    'values': Array<ClusterFilterDataValues>;
}


/**
 * Check if a given object implements the ClusterFilterData interface.
 */
export function instanceOfClusterFilterData(value: object): value is ClusterFilterData {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function ClusterFilterDataFromJSON(json: any): ClusterFilterData {
    return ClusterFilterDataFromJSONTyped(json, false);
}

export function ClusterFilterDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterFilterData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'values': ((json['values'] as Array<any>).map(ClusterFilterDataValuesFromJSON)),
    };
}

export function ClusterFilterDataToJSON(value?: ClusterFilterData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'values': ((value['values'] as Array<any>).map(ClusterFilterDataValuesToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ClusterFilterDataValues
 */
export interface ClusterFilterDataValues {
    /**
     * 
     * @type {string}
     * @memberof ClusterFilterDataValues
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ClusterFilterDataValues
     */
    'count': number;
}


/**
 * Check if a given object implements the ClusterFilterDataValues interface.
 */
export function instanceOfClusterFilterDataValues(value: object): value is ClusterFilterDataValues {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function ClusterFilterDataValuesFromJSON(json: any): ClusterFilterDataValues {
    return ClusterFilterDataValuesFromJSONTyped(json, false);
}

export function ClusterFilterDataValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterFilterDataValues {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'count': json['count'],
    };
}

export function ClusterFilterDataValuesToJSON(value?: ClusterFilterDataValues | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'count': value['count'],
    };
}

        
/**
 * 
 * @export
 */
export const ClusterProviderType = {
    Aws: 'AWS',
    Gcp: 'GCP',
    Azure: 'AZURE',
    Kubernetes: 'KUBERNETES',
    Openshift: 'OPENSHIFT',
    Vmware: 'VMWARE',
    Oracle: 'ORACLE',
    Vcluster: 'VCLUSTER',
    K3S: 'K3S',
    Rancher: 'RANCHER'
} as const;
export type ClusterProviderType = typeof ClusterProviderType[keyof typeof ClusterProviderType];


export function instanceOfClusterProviderType(value: any): boolean {
    for (const key in ClusterProviderType) {
        if (Object.prototype.hasOwnProperty.call(ClusterProviderType, key)) {
            if (ClusterProviderType[key as keyof typeof ClusterProviderType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ClusterProviderTypeFromJSON(json: any): ClusterProviderType {
    return ClusterProviderTypeFromJSONTyped(json, false);
}

export function ClusterProviderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterProviderType {
    return json as ClusterProviderType;
}

export function ClusterProviderTypeToJSON(value?: ClusterProviderType | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface ClusterSummary
 */
export interface ClusterSummary {
    /**
     * 
     * @type {string}
     * @memberof ClusterSummary
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterSummary
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterSummary
     */
    'clusterName': string;
    /**
     * 
     * @type {ClusterProviderType}
     * @memberof ClusterSummary
     */
    'provider': ClusterProviderType;
    /**
     * 
     * @type {string}
     * @memberof ClusterSummary
     */
    'k8sVersion': string;
    /**
     * 
     * @type {boolean}
     * @memberof ClusterSummary
     */
    'inactive': boolean;
    /**
     * 
     * @type {string}
     * @memberof ClusterSummary
     */
    'lastSeenAlive': string;
    /**
     * 
     * @type {NodeStatus}
     * @memberof ClusterSummary
     */
    'nodes': NodeStatus;
    /**
     * 
     * @type {PodData}
     * @memberof ClusterSummary
     */
    'pods': PodData;
    /**
     * 
     * @type {ResourceUtilization}
     * @memberof ClusterSummary
     */
    'resources'?: ResourceUtilization;
    /**
     * 
     * @type {ServiceData}
     * @memberof ClusterSummary
     */
    'services': ServiceData;
    /**
     * 
     * @type {JobsData}
     * @memberof ClusterSummary
     */
    'jobs': JobsData;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummary
     */
    'potentialSavings': number;
    /**
     * 
     * @type {ViolationStatus}
     * @memberof ClusterSummary
     */
    'violations': ViolationStatus;
    /**
     * 
     * @type {UnhealthyWorkloadsData}
     * @memberof ClusterSummary
     */
    'unhealthyWorkloads': UnhealthyWorkloadsData;
    /**
     * 
     * @type {AdditionalInfo}
     * @memberof ClusterSummary
     */
    'additionalInfo': AdditionalInfo;
    /**
     * 
     * @type {string}
     * @memberof ClusterSummary
     */
    'region': string;
}




/**
 * Check if a given object implements the ClusterSummary interface.
 */
export function instanceOfClusterSummary(value: object): value is ClusterSummary {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('provider' in value) || value['provider'] === undefined) return false;
    if (!('k8sVersion' in value) || value['k8sVersion'] === undefined) return false;
    if (!('inactive' in value) || value['inactive'] === undefined) return false;
    if (!('lastSeenAlive' in value) || value['lastSeenAlive'] === undefined) return false;
    if (!('nodes' in value) || value['nodes'] === undefined) return false;
    if (!('pods' in value) || value['pods'] === undefined) return false;
    if (!('services' in value) || value['services'] === undefined) return false;
    if (!('jobs' in value) || value['jobs'] === undefined) return false;
    if (!('potentialSavings' in value) || value['potentialSavings'] === undefined) return false;
    if (!('violations' in value) || value['violations'] === undefined) return false;
    if (!('unhealthyWorkloads' in value) || value['unhealthyWorkloads'] === undefined) return false;
    if (!('additionalInfo' in value) || value['additionalInfo'] === undefined) return false;
    if (!('region' in value) || value['region'] === undefined) return false;
    return true;
}

export function ClusterSummaryFromJSON(json: any): ClusterSummary {
    return ClusterSummaryFromJSONTyped(json, false);
}

export function ClusterSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'accountId': json['accountId'],
        'clusterName': json['clusterName'],
        'provider': ClusterProviderTypeFromJSON(json['provider']),
        'k8sVersion': json['k8sVersion'],
        'inactive': json['inactive'],
        'lastSeenAlive': json['lastSeenAlive'],
        'nodes': NodeStatusFromJSON(json['nodes']),
        'pods': PodDataFromJSON(json['pods']),
        'resources': json['resources'] == null ? undefined : ResourceUtilizationFromJSON(json['resources']),
        'services': ServiceDataFromJSON(json['services']),
        'jobs': JobsDataFromJSON(json['jobs']),
        'potentialSavings': json['potentialSavings'],
        'violations': ViolationStatusFromJSON(json['violations']),
        'unhealthyWorkloads': UnhealthyWorkloadsDataFromJSON(json['unhealthyWorkloads']),
        'additionalInfo': AdditionalInfoFromJSON(json['additionalInfo']),
        'region': json['region'],
    };
}

export function ClusterSummaryToJSON(value?: ClusterSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'accountId': value['accountId'],
        'clusterName': value['clusterName'],
        'provider': ClusterProviderTypeToJSON(value['provider']),
        'k8sVersion': value['k8sVersion'],
        'inactive': value['inactive'],
        'lastSeenAlive': value['lastSeenAlive'],
        'nodes': NodeStatusToJSON(value['nodes']),
        'pods': PodDataToJSON(value['pods']),
        'resources': ResourceUtilizationToJSON(value['resources']),
        'services': ServiceDataToJSON(value['services']),
        'jobs': JobsDataToJSON(value['jobs']),
        'potentialSavings': value['potentialSavings'],
        'violations': ViolationStatusToJSON(value['violations']),
        'unhealthyWorkloads': UnhealthyWorkloadsDataToJSON(value['unhealthyWorkloads']),
        'additionalInfo': AdditionalInfoToJSON(value['additionalInfo']),
        'region': value['region'],
    };
}

        /**
 * 
 * @export
 * @interface ClusterSummaryByNameResponse
 */
export interface ClusterSummaryByNameResponse {
    /**
     * 
     * @type {ClusterSummary}
     * @memberof ClusterSummaryByNameResponse
     */
    'data': ClusterSummary;
}


/**
 * Check if a given object implements the ClusterSummaryByNameResponse interface.
 */
export function instanceOfClusterSummaryByNameResponse(value: object): value is ClusterSummaryByNameResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ClusterSummaryByNameResponseFromJSON(json: any): ClusterSummaryByNameResponse {
    return ClusterSummaryByNameResponseFromJSONTyped(json, false);
}

export function ClusterSummaryByNameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterSummaryByNameResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ClusterSummaryFromJSON(json['data']),
    };
}

export function ClusterSummaryByNameResponseToJSON(value?: ClusterSummaryByNameResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ClusterSummaryToJSON(value['data']),
    };
}

        /**
 * 
 * @export
 * @interface ClusterWorkspace
 */
export interface ClusterWorkspace {
    /**
     * 
     * @type {string}
     * @memberof ClusterWorkspace
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterWorkspace
     */
    'kind': ClusterWorkspaceKindEnum;
}


/**
 * @export
 */
export const ClusterWorkspaceKindEnum = {
    Cluster: 'cluster'
} as const;
export type ClusterWorkspaceKindEnum = typeof ClusterWorkspaceKindEnum[keyof typeof ClusterWorkspaceKindEnum];



/**
 * Check if a given object implements the ClusterWorkspace interface.
 */
export function instanceOfClusterWorkspace(value: object): value is ClusterWorkspace {
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('kind' in value) || value['kind'] === undefined) return false;
    return true;
}

export function ClusterWorkspaceFromJSON(json: any): ClusterWorkspace {
    return ClusterWorkspaceFromJSONTyped(json, false);
}

export function ClusterWorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterWorkspace {
    if (json == null) {
        return json;
    }
    return {
        
        'clusterName': json['clusterName'],
        'kind': json['kind'],
    };
}

export function ClusterWorkspaceToJSON(value?: ClusterWorkspace | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusterName': value['clusterName'],
        'kind': value['kind'],
    };
}

        /**
 * 
 * @export
 * @interface ClustersFilters
 */
export interface ClustersFilters {
    /**
     * 
     * @type {Array<ClusterFilterData>}
     * @memberof ClustersFilters
     */
    'values': Array<ClusterFilterData>;
}


/**
 * Check if a given object implements the ClustersFilters interface.
 */
export function instanceOfClustersFilters(value: object): value is ClustersFilters {
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function ClustersFiltersFromJSON(json: any): ClustersFilters {
    return ClustersFiltersFromJSONTyped(json, false);
}

export function ClustersFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClustersFilters {
    if (json == null) {
        return json;
    }
    return {
        
        'values': ((json['values'] as Array<any>).map(ClusterFilterDataFromJSON)),
    };
}

export function ClustersFiltersToJSON(value?: ClustersFilters | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'values': ((value['values'] as Array<any>).map(ClusterFilterDataToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ClustersFiltersBody
 */
export interface ClustersFiltersBody {
    /**
     * 
     * @type {CommonClustersFilters}
     * @memberof ClustersFiltersBody
     */
    'filter'?: CommonClustersFilters;
}


/**
 * Check if a given object implements the ClustersFiltersBody interface.
 */
export function instanceOfClustersFiltersBody(value: object): value is ClustersFiltersBody {
    return true;
}

export function ClustersFiltersBodyFromJSON(json: any): ClustersFiltersBody {
    return ClustersFiltersBodyFromJSONTyped(json, false);
}

export function ClustersFiltersBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClustersFiltersBody {
    if (json == null) {
        return json;
    }
    return {
        
        'filter': json['filter'] == null ? undefined : CommonClustersFiltersFromJSON(json['filter']),
    };
}

export function ClustersFiltersBodyToJSON(value?: ClustersFiltersBody | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filter': CommonClustersFiltersToJSON(value['filter']),
    };
}

        /**
 * 
 * @export
 * @interface ClustersFiltersResponse
 */
export interface ClustersFiltersResponse {
    /**
     * 
     * @type {ClustersFilters}
     * @memberof ClustersFiltersResponse
     */
    'data': ClustersFilters;
}


/**
 * Check if a given object implements the ClustersFiltersResponse interface.
 */
export function instanceOfClustersFiltersResponse(value: object): value is ClustersFiltersResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ClustersFiltersResponseFromJSON(json: any): ClustersFiltersResponse {
    return ClustersFiltersResponseFromJSONTyped(json, false);
}

export function ClustersFiltersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClustersFiltersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ClustersFiltersFromJSON(json['data']),
    };
}

export function ClustersFiltersResponseToJSON(value?: ClustersFiltersResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ClustersFiltersToJSON(value['data']),
    };
}

        /**
 * 
 * @export
 * @interface ClustersGroupWorkspace
 */
export interface ClustersGroupWorkspace {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClustersGroupWorkspace
     */
    'clusters': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClustersGroupWorkspace
     */
    'patterns': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ClustersGroupWorkspace
     */
    'totalClusters': number;
    /**
     * 
     * @type {number}
     * @memberof ClustersGroupWorkspace
     */
    'authorizedClusters': number;
    /**
     * 
     * @type {string}
     * @memberof ClustersGroupWorkspace
     */
    'kind': ClustersGroupWorkspaceKindEnum;
}


/**
 * @export
 */
export const ClustersGroupWorkspaceKindEnum = {
    ClustersGroup: 'clustersGroup'
} as const;
export type ClustersGroupWorkspaceKindEnum = typeof ClustersGroupWorkspaceKindEnum[keyof typeof ClustersGroupWorkspaceKindEnum];



/**
 * Check if a given object implements the ClustersGroupWorkspace interface.
 */
export function instanceOfClustersGroupWorkspace(value: object): value is ClustersGroupWorkspace {
    if (!('clusters' in value) || value['clusters'] === undefined) return false;
    if (!('patterns' in value) || value['patterns'] === undefined) return false;
    if (!('totalClusters' in value) || value['totalClusters'] === undefined) return false;
    if (!('authorizedClusters' in value) || value['authorizedClusters'] === undefined) return false;
    if (!('kind' in value) || value['kind'] === undefined) return false;
    return true;
}

export function ClustersGroupWorkspaceFromJSON(json: any): ClustersGroupWorkspace {
    return ClustersGroupWorkspaceFromJSONTyped(json, false);
}

export function ClustersGroupWorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClustersGroupWorkspace {
    if (json == null) {
        return json;
    }
    return {
        
        'clusters': json['clusters'],
        'patterns': json['patterns'],
        'totalClusters': json['totalClusters'],
        'authorizedClusters': json['authorizedClusters'],
        'kind': json['kind'],
    };
}

export function ClustersGroupWorkspaceToJSON(value?: ClustersGroupWorkspace | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusters': value['clusters'],
        'patterns': value['patterns'],
        'totalClusters': value['totalClusters'],
        'authorizedClusters': value['authorizedClusters'],
        'kind': value['kind'],
    };
}

        /**
 * 
 * @export
 * @interface ClustersSummaryBody
 */
export interface ClustersSummaryBody {
    /**
     * 
     * @type {ClustersSummaryFilters}
     * @memberof ClustersSummaryBody
     */
    'filter'?: ClustersSummaryFilters;
}


/**
 * Check if a given object implements the ClustersSummaryBody interface.
 */
export function instanceOfClustersSummaryBody(value: object): value is ClustersSummaryBody {
    return true;
}

export function ClustersSummaryBodyFromJSON(json: any): ClustersSummaryBody {
    return ClustersSummaryBodyFromJSONTyped(json, false);
}

export function ClustersSummaryBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClustersSummaryBody {
    if (json == null) {
        return json;
    }
    return {
        
        'filter': json['filter'] == null ? undefined : ClustersSummaryFiltersFromJSON(json['filter']),
    };
}

export function ClustersSummaryBodyToJSON(value?: ClustersSummaryBody | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filter': ClustersSummaryFiltersToJSON(value['filter']),
    };
}

        /**
 * 
 * @export
 * @interface ClustersSummaryFilters
 */
export interface ClustersSummaryFilters {
    /**
     * 
     * @type {string}
     * @memberof ClustersSummaryFilters
     */
    'clusterName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClustersSummaryFilters
     */
    'provider'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClustersSummaryFilters
     */
    'k8sVersion'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ClustersSummaryFilters
     */
    'includeDisconnected'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClustersSummaryFilters
     */
    'clusterNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClustersSummaryFilters
     */
    'sort'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClustersSummaryFilters
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClustersSummaryFilters
     */
    'offset'?: number;
}


/**
 * Check if a given object implements the ClustersSummaryFilters interface.
 */
export function instanceOfClustersSummaryFilters(value: object): value is ClustersSummaryFilters {
    return true;
}

export function ClustersSummaryFiltersFromJSON(json: any): ClustersSummaryFilters {
    return ClustersSummaryFiltersFromJSONTyped(json, false);
}

export function ClustersSummaryFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClustersSummaryFilters {
    if (json == null) {
        return json;
    }
    return {
        
        'clusterName': json['clusterName'] == null ? undefined : json['clusterName'],
        'provider': json['provider'] == null ? undefined : json['provider'],
        'k8sVersion': json['k8sVersion'] == null ? undefined : json['k8sVersion'],
        'includeDisconnected': json['includeDisconnected'] == null ? undefined : json['includeDisconnected'],
        'clusterNames': json['clusterNames'] == null ? undefined : json['clusterNames'],
        'sort': json['sort'] == null ? undefined : json['sort'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'offset': json['offset'] == null ? undefined : json['offset'],
    };
}

export function ClustersSummaryFiltersToJSON(value?: ClustersSummaryFilters | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusterName': value['clusterName'],
        'provider': value['provider'],
        'k8sVersion': value['k8sVersion'],
        'includeDisconnected': value['includeDisconnected'],
        'clusterNames': value['clusterNames'],
        'sort': value['sort'],
        'limit': value['limit'],
        'offset': value['offset'],
    };
}

        /**
 * 
 * @export
 * @interface ClustersSummaryFiltersAllOf
 */
export interface ClustersSummaryFiltersAllOf {
    /**
     * 
     * @type {string}
     * @memberof ClustersSummaryFiltersAllOf
     */
    'sort'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClustersSummaryFiltersAllOf
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClustersSummaryFiltersAllOf
     */
    'offset'?: number;
}


/**
 * Check if a given object implements the ClustersSummaryFiltersAllOf interface.
 */
export function instanceOfClustersSummaryFiltersAllOf(value: object): value is ClustersSummaryFiltersAllOf {
    return true;
}

export function ClustersSummaryFiltersAllOfFromJSON(json: any): ClustersSummaryFiltersAllOf {
    return ClustersSummaryFiltersAllOfFromJSONTyped(json, false);
}

export function ClustersSummaryFiltersAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClustersSummaryFiltersAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'sort': json['sort'] == null ? undefined : json['sort'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'offset': json['offset'] == null ? undefined : json['offset'],
    };
}

export function ClustersSummaryFiltersAllOfToJSON(value?: ClustersSummaryFiltersAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sort': value['sort'],
        'limit': value['limit'],
        'offset': value['offset'],
    };
}

        /**
 * 
 * @export
 * @interface ClustersSummaryResponse
 */
export interface ClustersSummaryResponse {
    /**
     * 
     * @type {Array<ClusterSummary>}
     * @memberof ClustersSummaryResponse
     */
    'data': Array<ClusterSummary>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof ClustersSummaryResponse
     */
    'meta'?: ResponseMeta;
}


/**
 * Check if a given object implements the ClustersSummaryResponse interface.
 */
export function instanceOfClustersSummaryResponse(value: object): value is ClustersSummaryResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ClustersSummaryResponseFromJSON(json: any): ClustersSummaryResponse {
    return ClustersSummaryResponseFromJSONTyped(json, false);
}

export function ClustersSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClustersSummaryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ClusterSummaryFromJSON)),
        'meta': json['meta'] == null ? undefined : ResponseMetaFromJSON(json['meta']),
    };
}

export function ClustersSummaryResponseToJSON(value?: ClustersSummaryResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(ClusterSummaryToJSON)),
        'meta': ResponseMetaToJSON(value['meta']),
    };
}

        /**
 * 
 * @export
 * @interface CommonClustersFilters
 */
export interface CommonClustersFilters {
    /**
     * 
     * @type {string}
     * @memberof CommonClustersFilters
     */
    'clusterName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommonClustersFilters
     */
    'provider'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommonClustersFilters
     */
    'k8sVersion'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CommonClustersFilters
     */
    'includeDisconnected'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommonClustersFilters
     */
    'clusterNames'?: Array<string>;
}


/**
 * Check if a given object implements the CommonClustersFilters interface.
 */
export function instanceOfCommonClustersFilters(value: object): value is CommonClustersFilters {
    return true;
}

export function CommonClustersFiltersFromJSON(json: any): CommonClustersFilters {
    return CommonClustersFiltersFromJSONTyped(json, false);
}

export function CommonClustersFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommonClustersFilters {
    if (json == null) {
        return json;
    }
    return {
        
        'clusterName': json['clusterName'] == null ? undefined : json['clusterName'],
        'provider': json['provider'] == null ? undefined : json['provider'],
        'k8sVersion': json['k8sVersion'] == null ? undefined : json['k8sVersion'],
        'includeDisconnected': json['includeDisconnected'] == null ? undefined : json['includeDisconnected'],
        'clusterNames': json['clusterNames'] == null ? undefined : json['clusterNames'],
    };
}

export function CommonClustersFiltersToJSON(value?: CommonClustersFilters | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusterName': value['clusterName'],
        'provider': value['provider'],
        'k8sVersion': value['k8sVersion'],
        'includeDisconnected': value['includeDisconnected'],
        'clusterNames': value['clusterNames'],
    };
}

        
/**
 * 
 * @export
 */
export const ComparisonAttribute = {
    Image: 'image',
    EnvVars: 'envVars',
    LivenessProbe: 'livenessProbe',
    ReadinessProbe: 'readinessProbe',
    StartupProbe: 'startupProbe',
    MemoryRequests: 'memoryRequests',
    MemoryLimits: 'memoryLimits',
    CpuRequests: 'cpuRequests',
    CpuLimits: 'cpuLimits'
} as const;
export type ComparisonAttribute = typeof ComparisonAttribute[keyof typeof ComparisonAttribute];


export function instanceOfComparisonAttribute(value: any): boolean {
    for (const key in ComparisonAttribute) {
        if (Object.prototype.hasOwnProperty.call(ComparisonAttribute, key)) {
            if (ComparisonAttribute[key as keyof typeof ComparisonAttribute] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ComparisonAttributeFromJSON(json: any): ComparisonAttribute {
    return ComparisonAttributeFromJSONTyped(json, false);
}

export function ComparisonAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonAttribute {
    return json as ComparisonAttribute;
}

export function ComparisonAttributeToJSON(value?: ComparisonAttribute | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface ComparisonAttributeArrayObject
 */
export interface ComparisonAttributeArrayObject {
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeArrayObject
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeArrayObject
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof ComparisonAttributeArrayObject
     */
    'value'?: Array<object>;
}


/**
 * Check if a given object implements the ComparisonAttributeArrayObject interface.
 */
export function instanceOfComparisonAttributeArrayObject(value: object): value is ComparisonAttributeArrayObject {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function ComparisonAttributeArrayObjectFromJSON(json: any): ComparisonAttributeArrayObject {
    return ComparisonAttributeArrayObjectFromJSONTyped(json, false);
}

export function ComparisonAttributeArrayObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonAttributeArrayObject {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isEmpty': json['isEmpty'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ComparisonAttributeArrayObjectToJSON(value?: ComparisonAttributeArrayObject | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isEmpty': value['isEmpty'],
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ComparisonAttributeArrayObjectAllOf
 */
export interface ComparisonAttributeArrayObjectAllOf {
    /**
     * 
     * @type {Array<object>}
     * @memberof ComparisonAttributeArrayObjectAllOf
     */
    'value'?: Array<object>;
}


/**
 * Check if a given object implements the ComparisonAttributeArrayObjectAllOf interface.
 */
export function instanceOfComparisonAttributeArrayObjectAllOf(value: object): value is ComparisonAttributeArrayObjectAllOf {
    return true;
}

export function ComparisonAttributeArrayObjectAllOfFromJSON(json: any): ComparisonAttributeArrayObjectAllOf {
    return ComparisonAttributeArrayObjectAllOfFromJSONTyped(json, false);
}

export function ComparisonAttributeArrayObjectAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonAttributeArrayObjectAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ComparisonAttributeArrayObjectAllOfToJSON(value?: ComparisonAttributeArrayObjectAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ComparisonAttributeCommon
 */
export interface ComparisonAttributeCommon {
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeCommon
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeCommon
     */
    'isEmpty': boolean;
}


/**
 * Check if a given object implements the ComparisonAttributeCommon interface.
 */
export function instanceOfComparisonAttributeCommon(value: object): value is ComparisonAttributeCommon {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function ComparisonAttributeCommonFromJSON(json: any): ComparisonAttributeCommon {
    return ComparisonAttributeCommonFromJSONTyped(json, false);
}

export function ComparisonAttributeCommonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonAttributeCommon {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isEmpty': json['isEmpty'],
    };
}

export function ComparisonAttributeCommonToJSON(value?: ComparisonAttributeCommon | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isEmpty': value['isEmpty'],
    };
}

        /**
 * 
 * @export
 * @interface ComparisonAttributeInteger
 */
export interface ComparisonAttributeInteger {
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeInteger
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeInteger
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {number}
     * @memberof ComparisonAttributeInteger
     */
    'value'?: number;
}


/**
 * Check if a given object implements the ComparisonAttributeInteger interface.
 */
export function instanceOfComparisonAttributeInteger(value: object): value is ComparisonAttributeInteger {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function ComparisonAttributeIntegerFromJSON(json: any): ComparisonAttributeInteger {
    return ComparisonAttributeIntegerFromJSONTyped(json, false);
}

export function ComparisonAttributeIntegerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonAttributeInteger {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isEmpty': json['isEmpty'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ComparisonAttributeIntegerToJSON(value?: ComparisonAttributeInteger | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isEmpty': value['isEmpty'],
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ComparisonAttributeObject
 */
export interface ComparisonAttributeObject {
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeObject
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeObject
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {object}
     * @memberof ComparisonAttributeObject
     */
    'value'?: object;
}


/**
 * Check if a given object implements the ComparisonAttributeObject interface.
 */
export function instanceOfComparisonAttributeObject(value: object): value is ComparisonAttributeObject {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function ComparisonAttributeObjectFromJSON(json: any): ComparisonAttributeObject {
    return ComparisonAttributeObjectFromJSONTyped(json, false);
}

export function ComparisonAttributeObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonAttributeObject {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isEmpty': json['isEmpty'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ComparisonAttributeObjectToJSON(value?: ComparisonAttributeObject | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isEmpty': value['isEmpty'],
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ComparisonAttributeObjectAllOf
 */
export interface ComparisonAttributeObjectAllOf {
    /**
     * 
     * @type {object}
     * @memberof ComparisonAttributeObjectAllOf
     */
    'value'?: object;
}


/**
 * Check if a given object implements the ComparisonAttributeObjectAllOf interface.
 */
export function instanceOfComparisonAttributeObjectAllOf(value: object): value is ComparisonAttributeObjectAllOf {
    return true;
}

export function ComparisonAttributeObjectAllOfFromJSON(json: any): ComparisonAttributeObjectAllOf {
    return ComparisonAttributeObjectAllOfFromJSONTyped(json, false);
}

export function ComparisonAttributeObjectAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonAttributeObjectAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ComparisonAttributeObjectAllOfToJSON(value?: ComparisonAttributeObjectAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ComparisonAttributeString
 */
export interface ComparisonAttributeString {
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeString
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeString
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {string}
     * @memberof ComparisonAttributeString
     */
    'value'?: string;
}


/**
 * Check if a given object implements the ComparisonAttributeString interface.
 */
export function instanceOfComparisonAttributeString(value: object): value is ComparisonAttributeString {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function ComparisonAttributeStringFromJSON(json: any): ComparisonAttributeString {
    return ComparisonAttributeStringFromJSONTyped(json, false);
}

export function ComparisonAttributeStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonAttributeString {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isEmpty': json['isEmpty'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ComparisonAttributeStringToJSON(value?: ComparisonAttributeString | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isEmpty': value['isEmpty'],
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ComparisonAttributeStringAllOf
 */
export interface ComparisonAttributeStringAllOf {
    /**
     * 
     * @type {string}
     * @memberof ComparisonAttributeStringAllOf
     */
    'value'?: string;
}


/**
 * Check if a given object implements the ComparisonAttributeStringAllOf interface.
 */
export function instanceOfComparisonAttributeStringAllOf(value: object): value is ComparisonAttributeStringAllOf {
    return true;
}

export function ComparisonAttributeStringAllOfFromJSON(json: any): ComparisonAttributeStringAllOf {
    return ComparisonAttributeStringAllOfFromJSONTyped(json, false);
}

export function ComparisonAttributeStringAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonAttributeStringAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ComparisonAttributeStringAllOfToJSON(value?: ComparisonAttributeStringAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ComparisonAttributeStringArray
 */
export interface ComparisonAttributeStringArray {
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeStringArray
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAttributeStringArray
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComparisonAttributeStringArray
     */
    'value'?: Array<string>;
}


/**
 * Check if a given object implements the ComparisonAttributeStringArray interface.
 */
export function instanceOfComparisonAttributeStringArray(value: object): value is ComparisonAttributeStringArray {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function ComparisonAttributeStringArrayFromJSON(json: any): ComparisonAttributeStringArray {
    return ComparisonAttributeStringArrayFromJSONTyped(json, false);
}

export function ComparisonAttributeStringArrayFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonAttributeStringArray {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isEmpty': json['isEmpty'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ComparisonAttributeStringArrayToJSON(value?: ComparisonAttributeStringArray | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isEmpty': value['isEmpty'],
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ComparisonAttributeStringArrayAllOf
 */
export interface ComparisonAttributeStringArrayAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof ComparisonAttributeStringArrayAllOf
     */
    'value'?: Array<string>;
}


/**
 * Check if a given object implements the ComparisonAttributeStringArrayAllOf interface.
 */
export function instanceOfComparisonAttributeStringArrayAllOf(value: object): value is ComparisonAttributeStringArrayAllOf {
    return true;
}

export function ComparisonAttributeStringArrayAllOfFromJSON(json: any): ComparisonAttributeStringArrayAllOf {
    return ComparisonAttributeStringArrayAllOfFromJSONTyped(json, false);
}

export function ComparisonAttributeStringArrayAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonAttributeStringArrayAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ComparisonAttributeStringArrayAllOfToJSON(value?: ComparisonAttributeStringArrayAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface DeleteWorkspaceResponse
 */
export interface DeleteWorkspaceResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteWorkspaceResponse
     */
    'status': boolean;
}


/**
 * Check if a given object implements the DeleteWorkspaceResponse interface.
 */
export function instanceOfDeleteWorkspaceResponse(value: object): value is DeleteWorkspaceResponse {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function DeleteWorkspaceResponseFromJSON(json: any): DeleteWorkspaceResponse {
    return DeleteWorkspaceResponseFromJSONTyped(json, false);
}

export function DeleteWorkspaceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteWorkspaceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function DeleteWorkspaceResponseToJSON(value?: DeleteWorkspaceResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
    };
}

        /**
 * 
 * @export
 * @interface HelmAttributeInventory
 */
export interface HelmAttributeInventory {
    /**
     * 
     * @type {boolean}
     * @memberof HelmAttributeInventory
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HelmAttributeInventory
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {Array<HelmAttributeInventoryValue>}
     * @memberof HelmAttributeInventory
     */
    'value'?: Array<HelmAttributeInventoryValue>;
}


/**
 * Check if a given object implements the HelmAttributeInventory interface.
 */
export function instanceOfHelmAttributeInventory(value: object): value is HelmAttributeInventory {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function HelmAttributeInventoryFromJSON(json: any): HelmAttributeInventory {
    return HelmAttributeInventoryFromJSONTyped(json, false);
}

export function HelmAttributeInventoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelmAttributeInventory {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isEmpty': json['isEmpty'],
        'value': json['value'] == null ? undefined : ((json['value'] as Array<any>).map(HelmAttributeInventoryValueFromJSON)),
    };
}

export function HelmAttributeInventoryToJSON(value?: HelmAttributeInventory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isEmpty': value['isEmpty'],
        'value': value['value'] == null ? undefined : ((value['value'] as Array<any>).map(HelmAttributeInventoryValueToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface HelmAttributeInventoryAllOf
 */
export interface HelmAttributeInventoryAllOf {
    /**
     * 
     * @type {Array<HelmAttributeInventoryValue>}
     * @memberof HelmAttributeInventoryAllOf
     */
    'value'?: Array<HelmAttributeInventoryValue>;
}


/**
 * Check if a given object implements the HelmAttributeInventoryAllOf interface.
 */
export function instanceOfHelmAttributeInventoryAllOf(value: object): value is HelmAttributeInventoryAllOf {
    return true;
}

export function HelmAttributeInventoryAllOfFromJSON(json: any): HelmAttributeInventoryAllOf {
    return HelmAttributeInventoryAllOfFromJSONTyped(json, false);
}

export function HelmAttributeInventoryAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelmAttributeInventoryAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : ((json['value'] as Array<any>).map(HelmAttributeInventoryValueFromJSON)),
    };
}

export function HelmAttributeInventoryAllOfToJSON(value?: HelmAttributeInventoryAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'] == null ? undefined : ((value['value'] as Array<any>).map(HelmAttributeInventoryValueToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface HelmAttributeInventoryValue
 */
export interface HelmAttributeInventoryValue {
    /**
     * 
     * @type {string}
     * @memberof HelmAttributeInventoryValue
     */
    'kind': string;
    /**
     * 
     * @type {number}
     * @memberof HelmAttributeInventoryValue
     */
    'count': number;
}


/**
 * Check if a given object implements the HelmAttributeInventoryValue interface.
 */
export function instanceOfHelmAttributeInventoryValue(value: object): value is HelmAttributeInventoryValue {
    if (!('kind' in value) || value['kind'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function HelmAttributeInventoryValueFromJSON(json: any): HelmAttributeInventoryValue {
    return HelmAttributeInventoryValueFromJSONTyped(json, false);
}

export function HelmAttributeInventoryValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelmAttributeInventoryValue {
    if (json == null) {
        return json;
    }
    return {
        
        'kind': json['kind'],
        'count': json['count'],
    };
}

export function HelmAttributeInventoryValueToJSON(value?: HelmAttributeInventoryValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'kind': value['kind'],
        'count': value['count'],
    };
}

        /**
 * 
 * @export
 * @interface HelmComparisonRequest
 */
export interface HelmComparisonRequest {
    /**
     * 
     * @type {boolean}
     * @memberof HelmComparisonRequest
     */
    'withResourcesComparison'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HelmComparisonRequest
     */
    'baselineKomodorUid': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelmComparisonRequest
     */
    'targetKomodorUids'?: Array<string>;
}


/**
 * Check if a given object implements the HelmComparisonRequest interface.
 */
export function instanceOfHelmComparisonRequest(value: object): value is HelmComparisonRequest {
    if (!('baselineKomodorUid' in value) || value['baselineKomodorUid'] === undefined) return false;
    return true;
}

export function HelmComparisonRequestFromJSON(json: any): HelmComparisonRequest {
    return HelmComparisonRequestFromJSONTyped(json, false);
}

export function HelmComparisonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelmComparisonRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'withResourcesComparison': json['withResourcesComparison'] == null ? undefined : json['withResourcesComparison'],
        'baselineKomodorUid': json['baselineKomodorUid'],
        'targetKomodorUids': json['targetKomodorUids'] == null ? undefined : json['targetKomodorUids'],
    };
}

export function HelmComparisonRequestToJSON(value?: HelmComparisonRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'withResourcesComparison': value['withResourcesComparison'],
        'baselineKomodorUid': value['baselineKomodorUid'],
        'targetKomodorUids': value['targetKomodorUids'],
    };
}

        /**
 * 
 * @export
 * @interface HelmComparisonResponse
 */
export interface HelmComparisonResponse {
    /**
     * 
     * @type {HelmComparisonResponseBaselineAttributes}
     * @memberof HelmComparisonResponse
     */
    'baselineHelmAttributes': HelmComparisonResponseBaselineAttributes;
    /**
     * 
     * @type {Array<HelmComparisonResponseTargetAttributes>}
     * @memberof HelmComparisonResponse
     */
    'targetHelmAttributes': Array<HelmComparisonResponseTargetAttributes>;
    /**
     * 
     * @type {Array<HelmResourcesComparisonForKind>}
     * @memberof HelmComparisonResponse
     */
    'resourcesByKind': Array<HelmResourcesComparisonForKind>;
}


/**
 * Check if a given object implements the HelmComparisonResponse interface.
 */
export function instanceOfHelmComparisonResponse(value: object): value is HelmComparisonResponse {
    if (!('baselineHelmAttributes' in value) || value['baselineHelmAttributes'] === undefined) return false;
    if (!('targetHelmAttributes' in value) || value['targetHelmAttributes'] === undefined) return false;
    if (!('resourcesByKind' in value) || value['resourcesByKind'] === undefined) return false;
    return true;
}

export function HelmComparisonResponseFromJSON(json: any): HelmComparisonResponse {
    return HelmComparisonResponseFromJSONTyped(json, false);
}

export function HelmComparisonResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelmComparisonResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'baselineHelmAttributes': HelmComparisonResponseBaselineAttributesFromJSON(json['baselineHelmAttributes']),
        'targetHelmAttributes': ((json['targetHelmAttributes'] as Array<any>).map(HelmComparisonResponseTargetAttributesFromJSON)),
        'resourcesByKind': ((json['resourcesByKind'] as Array<any>).map(HelmResourcesComparisonForKindFromJSON)),
    };
}

export function HelmComparisonResponseToJSON(value?: HelmComparisonResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'baselineHelmAttributes': HelmComparisonResponseBaselineAttributesToJSON(value['baselineHelmAttributes']),
        'targetHelmAttributes': ((value['targetHelmAttributes'] as Array<any>).map(HelmComparisonResponseTargetAttributesToJSON)),
        'resourcesByKind': ((value['resourcesByKind'] as Array<any>).map(HelmResourcesComparisonForKindToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface HelmComparisonResponseBaselineAttributes
 */
export interface HelmComparisonResponseBaselineAttributes {
    /**
     * 
     * @type {string}
     * @memberof HelmComparisonResponseBaselineAttributes
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof HelmComparisonResponseBaselineAttributes
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof HelmComparisonResponseBaselineAttributes
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HelmComparisonResponseBaselineAttributes
     */
    'chart': string;
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof HelmComparisonResponseBaselineAttributes
     */
    'version': ComparisonAttributeString;
    /**
     * 
     * @type {HelmAttributeInventory}
     * @memberof HelmComparisonResponseBaselineAttributes
     */
    'inventory': HelmAttributeInventory;
}


/**
 * Check if a given object implements the HelmComparisonResponseBaselineAttributes interface.
 */
export function instanceOfHelmComparisonResponseBaselineAttributes(value: object): value is HelmComparisonResponseBaselineAttributes {
    if (!('cluster' in value) || value['cluster'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('chart' in value) || value['chart'] === undefined) return false;
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('inventory' in value) || value['inventory'] === undefined) return false;
    return true;
}

export function HelmComparisonResponseBaselineAttributesFromJSON(json: any): HelmComparisonResponseBaselineAttributes {
    return HelmComparisonResponseBaselineAttributesFromJSONTyped(json, false);
}

export function HelmComparisonResponseBaselineAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelmComparisonResponseBaselineAttributes {
    if (json == null) {
        return json;
    }
    return {
        
        'cluster': json['cluster'],
        'namespace': json['namespace'],
        'name': json['name'],
        'chart': json['chart'],
        'version': ComparisonAttributeStringFromJSON(json['version']),
        'inventory': HelmAttributeInventoryFromJSON(json['inventory']),
    };
}

export function HelmComparisonResponseBaselineAttributesToJSON(value?: HelmComparisonResponseBaselineAttributes | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cluster': value['cluster'],
        'namespace': value['namespace'],
        'name': value['name'],
        'chart': value['chart'],
        'version': ComparisonAttributeStringToJSON(value['version']),
        'inventory': HelmAttributeInventoryToJSON(value['inventory']),
    };
}

        /**
 * 
 * @export
 * @interface HelmComparisonResponseTargetAttributes
 */
export interface HelmComparisonResponseTargetAttributes {
    /**
     * 
     * @type {string}
     * @memberof HelmComparisonResponseTargetAttributes
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof HelmComparisonResponseTargetAttributes
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof HelmComparisonResponseTargetAttributes
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HelmComparisonResponseTargetAttributes
     */
    'chart': string;
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof HelmComparisonResponseTargetAttributes
     */
    'version': ComparisonAttributeString;
    /**
     * 
     * @type {HelmAttributeInventory}
     * @memberof HelmComparisonResponseTargetAttributes
     */
    'inventory': HelmAttributeInventory;
    /**
     * 
     * @type {boolean}
     * @memberof HelmComparisonResponseTargetAttributes
     */
    'isDiff': boolean;
}


/**
 * Check if a given object implements the HelmComparisonResponseTargetAttributes interface.
 */
export function instanceOfHelmComparisonResponseTargetAttributes(value: object): value is HelmComparisonResponseTargetAttributes {
    if (!('cluster' in value) || value['cluster'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('chart' in value) || value['chart'] === undefined) return false;
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('inventory' in value) || value['inventory'] === undefined) return false;
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    return true;
}

export function HelmComparisonResponseTargetAttributesFromJSON(json: any): HelmComparisonResponseTargetAttributes {
    return HelmComparisonResponseTargetAttributesFromJSONTyped(json, false);
}

export function HelmComparisonResponseTargetAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelmComparisonResponseTargetAttributes {
    if (json == null) {
        return json;
    }
    return {
        
        'cluster': json['cluster'],
        'namespace': json['namespace'],
        'name': json['name'],
        'chart': json['chart'],
        'version': ComparisonAttributeStringFromJSON(json['version']),
        'inventory': HelmAttributeInventoryFromJSON(json['inventory']),
        'isDiff': json['isDiff'],
    };
}

export function HelmComparisonResponseTargetAttributesToJSON(value?: HelmComparisonResponseTargetAttributes | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cluster': value['cluster'],
        'namespace': value['namespace'],
        'name': value['name'],
        'chart': value['chart'],
        'version': ComparisonAttributeStringToJSON(value['version']),
        'inventory': HelmAttributeInventoryToJSON(value['inventory']),
        'isDiff': value['isDiff'],
    };
}

        /**
 * 
 * @export
 * @interface HelmComparisonResponseTargetAttributesAllOf
 */
export interface HelmComparisonResponseTargetAttributesAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof HelmComparisonResponseTargetAttributesAllOf
     */
    'isDiff': boolean;
}


/**
 * Check if a given object implements the HelmComparisonResponseTargetAttributesAllOf interface.
 */
export function instanceOfHelmComparisonResponseTargetAttributesAllOf(value: object): value is HelmComparisonResponseTargetAttributesAllOf {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    return true;
}

export function HelmComparisonResponseTargetAttributesAllOfFromJSON(json: any): HelmComparisonResponseTargetAttributesAllOf {
    return HelmComparisonResponseTargetAttributesAllOfFromJSONTyped(json, false);
}

export function HelmComparisonResponseTargetAttributesAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelmComparisonResponseTargetAttributesAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
    };
}

export function HelmComparisonResponseTargetAttributesAllOfToJSON(value?: HelmComparisonResponseTargetAttributesAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
    };
}

        /**
 * 
 * @export
 * @interface HelmResourcesComparisonForKind
 */
export interface HelmResourcesComparisonForKind {
    /**
     * 
     * @type {string}
     * @memberof HelmResourcesComparisonForKind
     */
    'kind': string;
    /**
     * 
     * @type {boolean}
     * @memberof HelmResourcesComparisonForKind
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HelmResourcesComparisonForKind
     */
    'isSupportedKomodorKind': boolean;
    /**
     * 
     * @type {BaselineHelmResources}
     * @memberof HelmResourcesComparisonForKind
     */
    'baselineHelmResources': BaselineHelmResources;
    /**
     * 
     * @type {Array<TargetHelmResources>}
     * @memberof HelmResourcesComparisonForKind
     */
    'targetHelmsResources': Array<TargetHelmResources>;
}


/**
 * Check if a given object implements the HelmResourcesComparisonForKind interface.
 */
export function instanceOfHelmResourcesComparisonForKind(value: object): value is HelmResourcesComparisonForKind {
    if (!('kind' in value) || value['kind'] === undefined) return false;
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isSupportedKomodorKind' in value) || value['isSupportedKomodorKind'] === undefined) return false;
    if (!('baselineHelmResources' in value) || value['baselineHelmResources'] === undefined) return false;
    if (!('targetHelmsResources' in value) || value['targetHelmsResources'] === undefined) return false;
    return true;
}

export function HelmResourcesComparisonForKindFromJSON(json: any): HelmResourcesComparisonForKind {
    return HelmResourcesComparisonForKindFromJSONTyped(json, false);
}

export function HelmResourcesComparisonForKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelmResourcesComparisonForKind {
    if (json == null) {
        return json;
    }
    return {
        
        'kind': json['kind'],
        'isDiff': json['isDiff'],
        'isSupportedKomodorKind': json['isSupportedKomodorKind'],
        'baselineHelmResources': BaselineHelmResourcesFromJSON(json['baselineHelmResources']),
        'targetHelmsResources': ((json['targetHelmsResources'] as Array<any>).map(TargetHelmResourcesFromJSON)),
    };
}

export function HelmResourcesComparisonForKindToJSON(value?: HelmResourcesComparisonForKind | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'kind': value['kind'],
        'isDiff': value['isDiff'],
        'isSupportedKomodorKind': value['isSupportedKomodorKind'],
        'baselineHelmResources': BaselineHelmResourcesToJSON(value['baselineHelmResources']),
        'targetHelmsResources': ((value['targetHelmsResources'] as Array<any>).map(TargetHelmResourcesToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface JobsData
 */
export interface JobsData {
    /**
     * 
     * @type {number}
     * @memberof JobsData
     */
    'count': number;
    /**
     * 
     * @type {KindComponent}
     * @memberof JobsData
     */
    'green': KindComponent;
    /**
     * 
     * @type {KindComponent}
     * @memberof JobsData
     */
    'gray': KindComponent;
    /**
     * 
     * @type {KindComponent}
     * @memberof JobsData
     */
    'red': KindComponent;
}


/**
 * Check if a given object implements the JobsData interface.
 */
export function instanceOfJobsData(value: object): value is JobsData {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('green' in value) || value['green'] === undefined) return false;
    if (!('gray' in value) || value['gray'] === undefined) return false;
    if (!('red' in value) || value['red'] === undefined) return false;
    return true;
}

export function JobsDataFromJSON(json: any): JobsData {
    return JobsDataFromJSONTyped(json, false);
}

export function JobsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobsData {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'green': KindComponentFromJSON(json['green']),
        'gray': KindComponentFromJSON(json['gray']),
        'red': KindComponentFromJSON(json['red']),
    };
}

export function JobsDataToJSON(value?: JobsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'green': KindComponentToJSON(value['green']),
        'gray': KindComponentToJSON(value['gray']),
        'red': KindComponentToJSON(value['red']),
    };
}

        /**
 * 
 * @export
 * @interface KindComponent
 */
export interface KindComponent {
    /**
     * 
     * @type {Array<SingleKind>}
     * @memberof KindComponent
     */
    'kinds': Array<SingleKind>;
    /**
     * 
     * @type {number}
     * @memberof KindComponent
     */
    'count': number;
}


/**
 * Check if a given object implements the KindComponent interface.
 */
export function instanceOfKindComponent(value: object): value is KindComponent {
    if (!('kinds' in value) || value['kinds'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function KindComponentFromJSON(json: any): KindComponent {
    return KindComponentFromJSONTyped(json, false);
}

export function KindComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): KindComponent {
    if (json == null) {
        return json;
    }
    return {
        
        'kinds': ((json['kinds'] as Array<any>).map(SingleKindFromJSON)),
        'count': json['count'],
    };
}

export function KindComponentToJSON(value?: KindComponent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'kinds': ((value['kinds'] as Array<any>).map(SingleKindToJSON)),
        'count': value['count'],
    };
}

        /**
 * 
 * @export
 * @interface LabelsWorkspace
 */
export interface LabelsWorkspace {
    /**
     * 
     * @type {Array<string>}
     * @memberof LabelsWorkspace
     */
    'labels': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LabelsWorkspace
     */
    'kind': LabelsWorkspaceKindEnum;
}


/**
 * @export
 */
export const LabelsWorkspaceKindEnum = {
    Label: 'label'
} as const;
export type LabelsWorkspaceKindEnum = typeof LabelsWorkspaceKindEnum[keyof typeof LabelsWorkspaceKindEnum];



/**
 * Check if a given object implements the LabelsWorkspace interface.
 */
export function instanceOfLabelsWorkspace(value: object): value is LabelsWorkspace {
    if (!('labels' in value) || value['labels'] === undefined) return false;
    if (!('kind' in value) || value['kind'] === undefined) return false;
    return true;
}

export function LabelsWorkspaceFromJSON(json: any): LabelsWorkspace {
    return LabelsWorkspaceFromJSONTyped(json, false);
}

export function LabelsWorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelsWorkspace {
    if (json == null) {
        return json;
    }
    return {
        
        'labels': json['labels'],
        'kind': json['kind'],
    };
}

export function LabelsWorkspaceToJSON(value?: LabelsWorkspace | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'labels': value['labels'],
        'kind': value['kind'],
    };
}

        /**
 * 
 * @export
 * @interface NamespacesWorkspace
 */
export interface NamespacesWorkspace {
    /**
     * 
     * @type {Array<string>}
     * @memberof NamespacesWorkspace
     */
    'namespaces': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NamespacesWorkspace
     */
    'kind': NamespacesWorkspaceKindEnum;
}


/**
 * @export
 */
export const NamespacesWorkspaceKindEnum = {
    Namespace: 'namespace'
} as const;
export type NamespacesWorkspaceKindEnum = typeof NamespacesWorkspaceKindEnum[keyof typeof NamespacesWorkspaceKindEnum];



/**
 * Check if a given object implements the NamespacesWorkspace interface.
 */
export function instanceOfNamespacesWorkspace(value: object): value is NamespacesWorkspace {
    if (!('namespaces' in value) || value['namespaces'] === undefined) return false;
    if (!('kind' in value) || value['kind'] === undefined) return false;
    return true;
}

export function NamespacesWorkspaceFromJSON(json: any): NamespacesWorkspace {
    return NamespacesWorkspaceFromJSONTyped(json, false);
}

export function NamespacesWorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): NamespacesWorkspace {
    if (json == null) {
        return json;
    }
    return {
        
        'namespaces': json['namespaces'],
        'kind': json['kind'],
    };
}

export function NamespacesWorkspaceToJSON(value?: NamespacesWorkspace | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'namespaces': value['namespaces'],
        'kind': value['kind'],
    };
}

        /**
 * 
 * @export
 * @interface NodeStatus
 */
export interface NodeStatus {
    /**
     * 
     * @type {number}
     * @memberof NodeStatus
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof NodeStatus
     */
    'ready': number;
    /**
     * 
     * @type {number}
     * @memberof NodeStatus
     */
    'notReady': number;
}


/**
 * Check if a given object implements the NodeStatus interface.
 */
export function instanceOfNodeStatus(value: object): value is NodeStatus {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('ready' in value) || value['ready'] === undefined) return false;
    if (!('notReady' in value) || value['notReady'] === undefined) return false;
    return true;
}

export function NodeStatusFromJSON(json: any): NodeStatus {
    return NodeStatusFromJSONTyped(json, false);
}

export function NodeStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'ready': json['ready'],
        'notReady': json['notReady'],
    };
}

export function NodeStatusToJSON(value?: NodeStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'ready': value['ready'],
        'notReady': value['notReady'],
    };
}

        /**
 * 
 * @export
 * @interface Pattern
 */
export interface Pattern {
    /**
     * 
     * @type {string}
     * @memberof Pattern
     */
    'include'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pattern
     */
    'exclude': string;
}


/**
 * Check if a given object implements the Pattern interface.
 */
export function instanceOfPattern(value: object): value is Pattern {
    if (!('exclude' in value) || value['exclude'] === undefined) return false;
    return true;
}

export function PatternFromJSON(json: any): Pattern {
    return PatternFromJSONTyped(json, false);
}

export function PatternFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pattern {
    if (json == null) {
        return json;
    }
    return {
        
        'include': json['include'] == null ? undefined : json['include'],
        'exclude': json['exclude'],
    };
}

export function PatternToJSON(value?: Pattern | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'include': value['include'],
        'exclude': value['exclude'],
    };
}

        /**
 * 
 * @export
 * @interface PodData
 */
export interface PodData {
    /**
     * 
     * @type {ReasonComponent}
     * @memberof PodData
     */
    'green': ReasonComponent;
    /**
     * 
     * @type {ReasonComponent}
     * @memberof PodData
     */
    'yellow': ReasonComponent;
    /**
     * 
     * @type {ReasonComponent}
     * @memberof PodData
     */
    'red': ReasonComponent;
    /**
     * 
     * @type {number}
     * @memberof PodData
     */
    'count': number;
}


/**
 * Check if a given object implements the PodData interface.
 */
export function instanceOfPodData(value: object): value is PodData {
    if (!('green' in value) || value['green'] === undefined) return false;
    if (!('yellow' in value) || value['yellow'] === undefined) return false;
    if (!('red' in value) || value['red'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function PodDataFromJSON(json: any): PodData {
    return PodDataFromJSONTyped(json, false);
}

export function PodDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PodData {
    if (json == null) {
        return json;
    }
    return {
        
        'green': ReasonComponentFromJSON(json['green']),
        'yellow': ReasonComponentFromJSON(json['yellow']),
        'red': ReasonComponentFromJSON(json['red']),
        'count': json['count'],
    };
}

export function PodDataToJSON(value?: PodData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'green': ReasonComponentToJSON(value['green']),
        'yellow': ReasonComponentToJSON(value['yellow']),
        'red': ReasonComponentToJSON(value['red']),
        'count': value['count'],
    };
}

        /**
 * 
 * @export
 * @interface ReasonComponent
 */
export interface ReasonComponent {
    /**
     * 
     * @type {number}
     * @memberof ReasonComponent
     */
    'count': number;
    /**
     * 
     * @type {Array<SingleReason>}
     * @memberof ReasonComponent
     */
    'reasons': Array<SingleReason>;
}


/**
 * Check if a given object implements the ReasonComponent interface.
 */
export function instanceOfReasonComponent(value: object): value is ReasonComponent {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('reasons' in value) || value['reasons'] === undefined) return false;
    return true;
}

export function ReasonComponentFromJSON(json: any): ReasonComponent {
    return ReasonComponentFromJSONTyped(json, false);
}

export function ReasonComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReasonComponent {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'reasons': ((json['reasons'] as Array<any>).map(SingleReasonFromJSON)),
    };
}

export function ReasonComponentToJSON(value?: ReasonComponent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'reasons': ((value['reasons'] as Array<any>).map(SingleReasonToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ResolveClustersRequest
 */
export interface ResolveClustersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResolveClustersRequest
     */
    'patterns': Array<string>;
}


/**
 * Check if a given object implements the ResolveClustersRequest interface.
 */
export function instanceOfResolveClustersRequest(value: object): value is ResolveClustersRequest {
    if (!('patterns' in value) || value['patterns'] === undefined) return false;
    return true;
}

export function ResolveClustersRequestFromJSON(json: any): ResolveClustersRequest {
    return ResolveClustersRequestFromJSONTyped(json, false);
}

export function ResolveClustersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResolveClustersRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'patterns': json['patterns'],
    };
}

export function ResolveClustersRequestToJSON(value?: ResolveClustersRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'patterns': value['patterns'],
    };
}

        /**
 * 
 * @export
 * @interface ResolvedClustersResponse
 */
export interface ResolvedClustersResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResolvedClustersResponse
     */
    'clusters': Array<string>;
}


/**
 * Check if a given object implements the ResolvedClustersResponse interface.
 */
export function instanceOfResolvedClustersResponse(value: object): value is ResolvedClustersResponse {
    if (!('clusters' in value) || value['clusters'] === undefined) return false;
    return true;
}

export function ResolvedClustersResponseFromJSON(json: any): ResolvedClustersResponse {
    return ResolvedClustersResponseFromJSONTyped(json, false);
}

export function ResolvedClustersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResolvedClustersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'clusters': json['clusters'],
    };
}

export function ResolvedClustersResponseToJSON(value?: ResolvedClustersResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusters': value['clusters'],
    };
}

        /**
 * 
 * @export
 * @interface ResourceComponent
 */
export interface ResourceComponent {
    /**
     * 
     * @type {number}
     * @memberof ResourceComponent
     */
    'allocated': number;
    /**
     * 
     * @type {number}
     * @memberof ResourceComponent
     */
    'capacity': number;
    /**
     * 
     * @type {number}
     * @memberof ResourceComponent
     */
    'percentage': number;
}


/**
 * Check if a given object implements the ResourceComponent interface.
 */
export function instanceOfResourceComponent(value: object): value is ResourceComponent {
    if (!('allocated' in value) || value['allocated'] === undefined) return false;
    if (!('capacity' in value) || value['capacity'] === undefined) return false;
    if (!('percentage' in value) || value['percentage'] === undefined) return false;
    return true;
}

export function ResourceComponentFromJSON(json: any): ResourceComponent {
    return ResourceComponentFromJSONTyped(json, false);
}

export function ResourceComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceComponent {
    if (json == null) {
        return json;
    }
    return {
        
        'allocated': json['allocated'],
        'capacity': json['capacity'],
        'percentage': json['percentage'],
    };
}

export function ResourceComponentToJSON(value?: ResourceComponent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allocated': value['allocated'],
        'capacity': value['capacity'],
        'percentage': value['percentage'],
    };
}

        /**
 * 
 * @export
 * @interface ResourceScope
 */
export interface ResourceScope {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceScope
     */
    'clusters': Array<string>;
    /**
     * 
     * @type {Array<Pattern>}
     * @memberof ResourceScope
     */
    'clustersPatterns': Array<Pattern>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceScope
     */
    'namespaces': Array<string>;
    /**
     * 
     * @type {Array<Pattern>}
     * @memberof ResourceScope
     */
    'namespacesPatterns': Array<Pattern>;
    /**
     * 
     * @type {Array<Selector>}
     * @memberof ResourceScope
     */
    'selectors': Array<Selector>;
    /**
     * 
     * @type {Array<SelectorPattern>}
     * @memberof ResourceScope
     */
    'selectorsPatterns': Array<SelectorPattern>;
}


/**
 * Check if a given object implements the ResourceScope interface.
 */
export function instanceOfResourceScope(value: object): value is ResourceScope {
    if (!('clusters' in value) || value['clusters'] === undefined) return false;
    if (!('clustersPatterns' in value) || value['clustersPatterns'] === undefined) return false;
    if (!('namespaces' in value) || value['namespaces'] === undefined) return false;
    if (!('namespacesPatterns' in value) || value['namespacesPatterns'] === undefined) return false;
    if (!('selectors' in value) || value['selectors'] === undefined) return false;
    if (!('selectorsPatterns' in value) || value['selectorsPatterns'] === undefined) return false;
    return true;
}

export function ResourceScopeFromJSON(json: any): ResourceScope {
    return ResourceScopeFromJSONTyped(json, false);
}

export function ResourceScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceScope {
    if (json == null) {
        return json;
    }
    return {
        
        'clusters': json['clusters'],
        'clustersPatterns': ((json['clustersPatterns'] as Array<any>).map(PatternFromJSON)),
        'namespaces': json['namespaces'],
        'namespacesPatterns': ((json['namespacesPatterns'] as Array<any>).map(PatternFromJSON)),
        'selectors': ((json['selectors'] as Array<any>).map(SelectorFromJSON)),
        'selectorsPatterns': ((json['selectorsPatterns'] as Array<any>).map(SelectorPatternFromJSON)),
    };
}

export function ResourceScopeToJSON(value?: ResourceScope | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusters': value['clusters'],
        'clustersPatterns': ((value['clustersPatterns'] as Array<any>).map(PatternToJSON)),
        'namespaces': value['namespaces'],
        'namespacesPatterns': ((value['namespacesPatterns'] as Array<any>).map(PatternToJSON)),
        'selectors': ((value['selectors'] as Array<any>).map(SelectorToJSON)),
        'selectorsPatterns': ((value['selectorsPatterns'] as Array<any>).map(SelectorPatternToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ResourceUtilization
 */
export interface ResourceUtilization {
    /**
     * 
     * @type {ResourceComponent}
     * @memberof ResourceUtilization
     */
    'cpu': ResourceComponent;
    /**
     * 
     * @type {ResourceComponent}
     * @memberof ResourceUtilization
     */
    'memory': ResourceComponent;
}


/**
 * Check if a given object implements the ResourceUtilization interface.
 */
export function instanceOfResourceUtilization(value: object): value is ResourceUtilization {
    if (!('cpu' in value) || value['cpu'] === undefined) return false;
    if (!('memory' in value) || value['memory'] === undefined) return false;
    return true;
}

export function ResourceUtilizationFromJSON(json: any): ResourceUtilization {
    return ResourceUtilizationFromJSONTyped(json, false);
}

export function ResourceUtilizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceUtilization {
    if (json == null) {
        return json;
    }
    return {
        
        'cpu': ResourceComponentFromJSON(json['cpu']),
        'memory': ResourceComponentFromJSON(json['memory']),
    };
}

export function ResourceUtilizationToJSON(value?: ResourceUtilization | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cpu': ResourceComponentToJSON(value['cpu']),
        'memory': ResourceComponentToJSON(value['memory']),
    };
}

        /**
 * 
 * @export
 * @interface ResponseMeta
 */
export interface ResponseMeta {
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    'nextOffset': number;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseMeta
     */
    'hasMore': boolean;
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    'total'?: number;
}


/**
 * Check if a given object implements the ResponseMeta interface.
 */
export function instanceOfResponseMeta(value: object): value is ResponseMeta {
    if (!('nextOffset' in value) || value['nextOffset'] === undefined) return false;
    if (!('hasMore' in value) || value['hasMore'] === undefined) return false;
    return true;
}

export function ResponseMetaFromJSON(json: any): ResponseMeta {
    return ResponseMetaFromJSONTyped(json, false);
}

export function ResponseMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseMeta {
    if (json == null) {
        return json;
    }
    return {
        
        'nextOffset': json['nextOffset'],
        'hasMore': json['hasMore'],
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function ResponseMetaToJSON(value?: ResponseMeta | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'nextOffset': value['nextOffset'],
        'hasMore': value['hasMore'],
        'total': value['total'],
    };
}

        /**
 * 
 * @export
 * @interface ScopedWorkspace
 */
export interface ScopedWorkspace {
    /**
     * 
     * @type {string}
     * @memberof ScopedWorkspace
     */
    'kind': ScopedWorkspaceKindEnum;
    /**
     * 
     * @type {ResourceScope}
     * @memberof ScopedWorkspace
     */
    'resourceScope': ResourceScope;
}


/**
 * @export
 */
export const ScopedWorkspaceKindEnum = {
    Scoped: 'scoped'
} as const;
export type ScopedWorkspaceKindEnum = typeof ScopedWorkspaceKindEnum[keyof typeof ScopedWorkspaceKindEnum];



/**
 * Check if a given object implements the ScopedWorkspace interface.
 */
export function instanceOfScopedWorkspace(value: object): value is ScopedWorkspace {
    if (!('kind' in value) || value['kind'] === undefined) return false;
    if (!('resourceScope' in value) || value['resourceScope'] === undefined) return false;
    return true;
}

export function ScopedWorkspaceFromJSON(json: any): ScopedWorkspace {
    return ScopedWorkspaceFromJSONTyped(json, false);
}

export function ScopedWorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScopedWorkspace {
    if (json == null) {
        return json;
    }
    return {
        
        'kind': json['kind'],
        'resourceScope': ResourceScopeFromJSON(json['resourceScope']),
    };
}

export function ScopedWorkspaceToJSON(value?: ScopedWorkspace | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'kind': value['kind'],
        'resourceScope': ResourceScopeToJSON(value['resourceScope']),
    };
}

        /**
 * 
 * @export
 * @interface Selector
 */
export interface Selector {
    /**
     * 
     * @type {string}
     * @memberof Selector
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Selector
     */
    'value': string;
}


/**
 * Check if a given object implements the Selector interface.
 */
export function instanceOfSelector(value: object): value is Selector {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function SelectorFromJSON(json: any): Selector {
    return SelectorFromJSONTyped(json, false);
}

export function SelectorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Selector {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': json['value'],
    };
}

export function SelectorToJSON(value?: Selector | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface SelectorPattern
 */
export interface SelectorPattern {
    /**
     * 
     * @type {string}
     * @memberof SelectorPattern
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof SelectorPattern
     */
    'pattern': string;
}


/**
 * Check if a given object implements the SelectorPattern interface.
 */
export function instanceOfSelectorPattern(value: object): value is SelectorPattern {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('pattern' in value) || value['pattern'] === undefined) return false;
    return true;
}

export function SelectorPatternFromJSON(json: any): SelectorPattern {
    return SelectorPatternFromJSONTyped(json, false);
}

export function SelectorPatternFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectorPattern {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'pattern': json['pattern'],
    };
}

export function SelectorPatternToJSON(value?: SelectorPattern | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'pattern': value['pattern'],
    };
}

        /**
 * 
 * @export
 * @interface ServiceAttributesComparison
 */
export interface ServiceAttributesComparison {
    /**
     * 
     * @type {string}
     * @memberof ServiceAttributesComparison
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAttributesComparison
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAttributesComparison
     */
    'name': string;
    /**
     * 
     * @type {ServiceComparisonResponseDesiredReplicas}
     * @memberof ServiceAttributesComparison
     */
    'desiredReplicas'?: ServiceComparisonResponseDesiredReplicas;
    /**
     * 
     * @type {ServiceComparisonResponseLabels}
     * @memberof ServiceAttributesComparison
     */
    'labels': ServiceComparisonResponseLabels;
    /**
     * 
     * @type {ComparisonAttributeStringArray}
     * @memberof ServiceAttributesComparison
     */
    'containersNames': ComparisonAttributeStringArray;
}


/**
 * Check if a given object implements the ServiceAttributesComparison interface.
 */
export function instanceOfServiceAttributesComparison(value: object): value is ServiceAttributesComparison {
    if (!('cluster' in value) || value['cluster'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('labels' in value) || value['labels'] === undefined) return false;
    if (!('containersNames' in value) || value['containersNames'] === undefined) return false;
    return true;
}

export function ServiceAttributesComparisonFromJSON(json: any): ServiceAttributesComparison {
    return ServiceAttributesComparisonFromJSONTyped(json, false);
}

export function ServiceAttributesComparisonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceAttributesComparison {
    if (json == null) {
        return json;
    }
    return {
        
        'cluster': json['cluster'],
        'namespace': json['namespace'],
        'name': json['name'],
        'desiredReplicas': json['desiredReplicas'] == null ? undefined : ServiceComparisonResponseDesiredReplicasFromJSON(json['desiredReplicas']),
        'labels': ServiceComparisonResponseLabelsFromJSON(json['labels']),
        'containersNames': ComparisonAttributeStringArrayFromJSON(json['containersNames']),
    };
}

export function ServiceAttributesComparisonToJSON(value?: ServiceAttributesComparison | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cluster': value['cluster'],
        'namespace': value['namespace'],
        'name': value['name'],
        'desiredReplicas': ServiceComparisonResponseDesiredReplicasToJSON(value['desiredReplicas']),
        'labels': ServiceComparisonResponseLabelsToJSON(value['labels']),
        'containersNames': ComparisonAttributeStringArrayToJSON(value['containersNames']),
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonRequest
 */
export interface ServiceComparisonRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonRequest
     */
    'baselineServiceKomodorUid': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceComparisonRequest
     */
    'targetServicesKomodorUid'?: Array<string>;
}


/**
 * Check if a given object implements the ServiceComparisonRequest interface.
 */
export function instanceOfServiceComparisonRequest(value: object): value is ServiceComparisonRequest {
    if (!('baselineServiceKomodorUid' in value) || value['baselineServiceKomodorUid'] === undefined) return false;
    return true;
}

export function ServiceComparisonRequestFromJSON(json: any): ServiceComparisonRequest {
    return ServiceComparisonRequestFromJSONTyped(json, false);
}

export function ServiceComparisonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'baselineServiceKomodorUid': json['baselineServiceKomodorUid'],
        'targetServicesKomodorUid': json['targetServicesKomodorUid'] == null ? undefined : json['targetServicesKomodorUid'],
    };
}

export function ServiceComparisonRequestToJSON(value?: ServiceComparisonRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'baselineServiceKomodorUid': value['baselineServiceKomodorUid'],
        'targetServicesKomodorUid': value['targetServicesKomodorUid'],
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonResponse
 */
export interface ServiceComparisonResponse {
    /**
     * 
     * @type {ServiceAttributesComparison}
     * @memberof ServiceComparisonResponse
     */
    'baselineService': ServiceAttributesComparison;
    /**
     * 
     * @type {Array<ServiceComparisonResponseTargetServices>}
     * @memberof ServiceComparisonResponse
     */
    'targetServices': Array<ServiceComparisonResponseTargetServices>;
    /**
     * 
     * @type {Array<ServiceComparisonResponseContainer>}
     * @memberof ServiceComparisonResponse
     */
    'containers': Array<ServiceComparisonResponseContainer>;
}


/**
 * Check if a given object implements the ServiceComparisonResponse interface.
 */
export function instanceOfServiceComparisonResponse(value: object): value is ServiceComparisonResponse {
    if (!('baselineService' in value) || value['baselineService'] === undefined) return false;
    if (!('targetServices' in value) || value['targetServices'] === undefined) return false;
    if (!('containers' in value) || value['containers'] === undefined) return false;
    return true;
}

export function ServiceComparisonResponseFromJSON(json: any): ServiceComparisonResponse {
    return ServiceComparisonResponseFromJSONTyped(json, false);
}

export function ServiceComparisonResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'baselineService': ServiceAttributesComparisonFromJSON(json['baselineService']),
        'targetServices': ((json['targetServices'] as Array<any>).map(ServiceComparisonResponseTargetServicesFromJSON)),
        'containers': ((json['containers'] as Array<any>).map(ServiceComparisonResponseContainerFromJSON)),
    };
}

export function ServiceComparisonResponseToJSON(value?: ServiceComparisonResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'baselineService': ServiceAttributesComparisonToJSON(value['baselineService']),
        'targetServices': ((value['targetServices'] as Array<any>).map(ServiceComparisonResponseTargetServicesToJSON)),
        'containers': ((value['containers'] as Array<any>).map(ServiceComparisonResponseContainerToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonResponseBaselineServiceContainer
 */
export interface ServiceComparisonResponseBaselineServiceContainer {
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof ServiceComparisonResponseBaselineServiceContainer
     */
    'image': ComparisonAttributeString;
    /**
     * 
     * @type {ComparisonAttributeArrayObject}
     * @memberof ServiceComparisonResponseBaselineServiceContainer
     */
    'envVars': ComparisonAttributeArrayObject;
    /**
     * 
     * @type {ComparisonAttributeObject}
     * @memberof ServiceComparisonResponseBaselineServiceContainer
     */
    'livenessProbe': ComparisonAttributeObject;
    /**
     * 
     * @type {ComparisonAttributeObject}
     * @memberof ServiceComparisonResponseBaselineServiceContainer
     */
    'readinessProbe': ComparisonAttributeObject;
    /**
     * 
     * @type {ComparisonAttributeObject}
     * @memberof ServiceComparisonResponseBaselineServiceContainer
     */
    'startupProbe': ComparisonAttributeObject;
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof ServiceComparisonResponseBaselineServiceContainer
     */
    'memoryRequests': ComparisonAttributeString;
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof ServiceComparisonResponseBaselineServiceContainer
     */
    'memoryLimits': ComparisonAttributeString;
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof ServiceComparisonResponseBaselineServiceContainer
     */
    'cpuRequests': ComparisonAttributeString;
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof ServiceComparisonResponseBaselineServiceContainer
     */
    'cpuLimits': ComparisonAttributeString;
}


/**
 * Check if a given object implements the ServiceComparisonResponseBaselineServiceContainer interface.
 */
export function instanceOfServiceComparisonResponseBaselineServiceContainer(value: object): value is ServiceComparisonResponseBaselineServiceContainer {
    if (!('image' in value) || value['image'] === undefined) return false;
    if (!('envVars' in value) || value['envVars'] === undefined) return false;
    if (!('livenessProbe' in value) || value['livenessProbe'] === undefined) return false;
    if (!('readinessProbe' in value) || value['readinessProbe'] === undefined) return false;
    if (!('startupProbe' in value) || value['startupProbe'] === undefined) return false;
    if (!('memoryRequests' in value) || value['memoryRequests'] === undefined) return false;
    if (!('memoryLimits' in value) || value['memoryLimits'] === undefined) return false;
    if (!('cpuRequests' in value) || value['cpuRequests'] === undefined) return false;
    if (!('cpuLimits' in value) || value['cpuLimits'] === undefined) return false;
    return true;
}

export function ServiceComparisonResponseBaselineServiceContainerFromJSON(json: any): ServiceComparisonResponseBaselineServiceContainer {
    return ServiceComparisonResponseBaselineServiceContainerFromJSONTyped(json, false);
}

export function ServiceComparisonResponseBaselineServiceContainerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonResponseBaselineServiceContainer {
    if (json == null) {
        return json;
    }
    return {
        
        'image': ComparisonAttributeStringFromJSON(json['image']),
        'envVars': ComparisonAttributeArrayObjectFromJSON(json['envVars']),
        'livenessProbe': ComparisonAttributeObjectFromJSON(json['livenessProbe']),
        'readinessProbe': ComparisonAttributeObjectFromJSON(json['readinessProbe']),
        'startupProbe': ComparisonAttributeObjectFromJSON(json['startupProbe']),
        'memoryRequests': ComparisonAttributeStringFromJSON(json['memoryRequests']),
        'memoryLimits': ComparisonAttributeStringFromJSON(json['memoryLimits']),
        'cpuRequests': ComparisonAttributeStringFromJSON(json['cpuRequests']),
        'cpuLimits': ComparisonAttributeStringFromJSON(json['cpuLimits']),
    };
}

export function ServiceComparisonResponseBaselineServiceContainerToJSON(value?: ServiceComparisonResponseBaselineServiceContainer | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'image': ComparisonAttributeStringToJSON(value['image']),
        'envVars': ComparisonAttributeArrayObjectToJSON(value['envVars']),
        'livenessProbe': ComparisonAttributeObjectToJSON(value['livenessProbe']),
        'readinessProbe': ComparisonAttributeObjectToJSON(value['readinessProbe']),
        'startupProbe': ComparisonAttributeObjectToJSON(value['startupProbe']),
        'memoryRequests': ComparisonAttributeStringToJSON(value['memoryRequests']),
        'memoryLimits': ComparisonAttributeStringToJSON(value['memoryLimits']),
        'cpuRequests': ComparisonAttributeStringToJSON(value['cpuRequests']),
        'cpuLimits': ComparisonAttributeStringToJSON(value['cpuLimits']),
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonResponseContainer
 */
export interface ServiceComparisonResponseContainer {
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonResponseContainer
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseContainer
     */
    'isDiff': boolean;
    /**
     * 
     * @type {ServiceComparisonResponseBaselineServiceContainer}
     * @memberof ServiceComparisonResponseContainer
     */
    'baselineServiceContainer': ServiceComparisonResponseBaselineServiceContainer;
    /**
     * 
     * @type {Array<ServiceComparisonResponseTargetServicesContainer>}
     * @memberof ServiceComparisonResponseContainer
     */
    'targetServicesContainer': Array<ServiceComparisonResponseTargetServicesContainer>;
}


/**
 * Check if a given object implements the ServiceComparisonResponseContainer interface.
 */
export function instanceOfServiceComparisonResponseContainer(value: object): value is ServiceComparisonResponseContainer {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('baselineServiceContainer' in value) || value['baselineServiceContainer'] === undefined) return false;
    if (!('targetServicesContainer' in value) || value['targetServicesContainer'] === undefined) return false;
    return true;
}

export function ServiceComparisonResponseContainerFromJSON(json: any): ServiceComparisonResponseContainer {
    return ServiceComparisonResponseContainerFromJSONTyped(json, false);
}

export function ServiceComparisonResponseContainerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonResponseContainer {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'isDiff': json['isDiff'],
        'baselineServiceContainer': ServiceComparisonResponseBaselineServiceContainerFromJSON(json['baselineServiceContainer']),
        'targetServicesContainer': ((json['targetServicesContainer'] as Array<any>).map(ServiceComparisonResponseTargetServicesContainerFromJSON)),
    };
}

export function ServiceComparisonResponseContainerToJSON(value?: ServiceComparisonResponseContainer | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'isDiff': value['isDiff'],
        'baselineServiceContainer': ServiceComparisonResponseBaselineServiceContainerToJSON(value['baselineServiceContainer']),
        'targetServicesContainer': ((value['targetServicesContainer'] as Array<any>).map(ServiceComparisonResponseTargetServicesContainerToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonResponseDesiredReplicas
 */
export interface ServiceComparisonResponseDesiredReplicas {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseDesiredReplicas
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseDesiredReplicas
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceComparisonResponseDesiredReplicas
     */
    'value'?: number;
}


/**
 * Check if a given object implements the ServiceComparisonResponseDesiredReplicas interface.
 */
export function instanceOfServiceComparisonResponseDesiredReplicas(value: object): value is ServiceComparisonResponseDesiredReplicas {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function ServiceComparisonResponseDesiredReplicasFromJSON(json: any): ServiceComparisonResponseDesiredReplicas {
    return ServiceComparisonResponseDesiredReplicasFromJSONTyped(json, false);
}

export function ServiceComparisonResponseDesiredReplicasFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonResponseDesiredReplicas {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isEmpty': json['isEmpty'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ServiceComparisonResponseDesiredReplicasToJSON(value?: ServiceComparisonResponseDesiredReplicas | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isEmpty': value['isEmpty'],
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonResponseDesiredReplicasAllOf
 */
export interface ServiceComparisonResponseDesiredReplicasAllOf {
    /**
     * 
     * @type {number}
     * @memberof ServiceComparisonResponseDesiredReplicasAllOf
     */
    'value'?: number;
}


/**
 * Check if a given object implements the ServiceComparisonResponseDesiredReplicasAllOf interface.
 */
export function instanceOfServiceComparisonResponseDesiredReplicasAllOf(value: object): value is ServiceComparisonResponseDesiredReplicasAllOf {
    return true;
}

export function ServiceComparisonResponseDesiredReplicasAllOfFromJSON(json: any): ServiceComparisonResponseDesiredReplicasAllOf {
    return ServiceComparisonResponseDesiredReplicasAllOfFromJSONTyped(json, false);
}

export function ServiceComparisonResponseDesiredReplicasAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonResponseDesiredReplicasAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ServiceComparisonResponseDesiredReplicasAllOfToJSON(value?: ServiceComparisonResponseDesiredReplicasAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonResponseLabels
 */
export interface ServiceComparisonResponseLabels {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseLabels
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseLabels
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ServiceComparisonResponseLabels
     */
    'value'?: { [key: string]: string; };
}


/**
 * Check if a given object implements the ServiceComparisonResponseLabels interface.
 */
export function instanceOfServiceComparisonResponseLabels(value: object): value is ServiceComparisonResponseLabels {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function ServiceComparisonResponseLabelsFromJSON(json: any): ServiceComparisonResponseLabels {
    return ServiceComparisonResponseLabelsFromJSONTyped(json, false);
}

export function ServiceComparisonResponseLabelsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonResponseLabels {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isEmpty': json['isEmpty'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ServiceComparisonResponseLabelsToJSON(value?: ServiceComparisonResponseLabels | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isEmpty': value['isEmpty'],
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonResponseLabelsAllOf
 */
export interface ServiceComparisonResponseLabelsAllOf {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ServiceComparisonResponseLabelsAllOf
     */
    'value'?: { [key: string]: string; };
}


/**
 * Check if a given object implements the ServiceComparisonResponseLabelsAllOf interface.
 */
export function instanceOfServiceComparisonResponseLabelsAllOf(value: object): value is ServiceComparisonResponseLabelsAllOf {
    return true;
}

export function ServiceComparisonResponseLabelsAllOfFromJSON(json: any): ServiceComparisonResponseLabelsAllOf {
    return ServiceComparisonResponseLabelsAllOfFromJSONTyped(json, false);
}

export function ServiceComparisonResponseLabelsAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonResponseLabelsAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ServiceComparisonResponseLabelsAllOfToJSON(value?: ServiceComparisonResponseLabelsAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonResponseTargetServices
 */
export interface ServiceComparisonResponseTargetServices {
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonResponseTargetServices
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonResponseTargetServices
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonResponseTargetServices
     */
    'name': string;
    /**
     * 
     * @type {ServiceComparisonResponseDesiredReplicas}
     * @memberof ServiceComparisonResponseTargetServices
     */
    'desiredReplicas': ServiceComparisonResponseDesiredReplicas;
    /**
     * 
     * @type {ServiceComparisonResponseLabels}
     * @memberof ServiceComparisonResponseTargetServices
     */
    'labels': ServiceComparisonResponseLabels;
    /**
     * 
     * @type {ComparisonAttributeStringArray}
     * @memberof ServiceComparisonResponseTargetServices
     */
    'containersNames': ComparisonAttributeStringArray;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServices
     */
    'isDiff': boolean;
}


/**
 * Check if a given object implements the ServiceComparisonResponseTargetServices interface.
 */
export function instanceOfServiceComparisonResponseTargetServices(value: object): value is ServiceComparisonResponseTargetServices {
    if (!('cluster' in value) || value['cluster'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('desiredReplicas' in value) || value['desiredReplicas'] === undefined) return false;
    if (!('labels' in value) || value['labels'] === undefined) return false;
    if (!('containersNames' in value) || value['containersNames'] === undefined) return false;
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    return true;
}

export function ServiceComparisonResponseTargetServicesFromJSON(json: any): ServiceComparisonResponseTargetServices {
    return ServiceComparisonResponseTargetServicesFromJSONTyped(json, false);
}

export function ServiceComparisonResponseTargetServicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonResponseTargetServices {
    if (json == null) {
        return json;
    }
    return {
        
        'cluster': json['cluster'],
        'namespace': json['namespace'],
        'name': json['name'],
        'desiredReplicas': ServiceComparisonResponseDesiredReplicasFromJSON(json['desiredReplicas']),
        'labels': ServiceComparisonResponseLabelsFromJSON(json['labels']),
        'containersNames': ComparisonAttributeStringArrayFromJSON(json['containersNames']),
        'isDiff': json['isDiff'],
    };
}

export function ServiceComparisonResponseTargetServicesToJSON(value?: ServiceComparisonResponseTargetServices | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cluster': value['cluster'],
        'namespace': value['namespace'],
        'name': value['name'],
        'desiredReplicas': ServiceComparisonResponseDesiredReplicasToJSON(value['desiredReplicas']),
        'labels': ServiceComparisonResponseLabelsToJSON(value['labels']),
        'containersNames': ComparisonAttributeStringArrayToJSON(value['containersNames']),
        'isDiff': value['isDiff'],
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonResponseTargetServicesAllOf
 */
export interface ServiceComparisonResponseTargetServicesAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesAllOf
     */
    'isDiff': boolean;
}


/**
 * Check if a given object implements the ServiceComparisonResponseTargetServicesAllOf interface.
 */
export function instanceOfServiceComparisonResponseTargetServicesAllOf(value: object): value is ServiceComparisonResponseTargetServicesAllOf {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    return true;
}

export function ServiceComparisonResponseTargetServicesAllOfFromJSON(json: any): ServiceComparisonResponseTargetServicesAllOf {
    return ServiceComparisonResponseTargetServicesAllOfFromJSONTyped(json, false);
}

export function ServiceComparisonResponseTargetServicesAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonResponseTargetServicesAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
    };
}

export function ServiceComparisonResponseTargetServicesAllOfToJSON(value?: ServiceComparisonResponseTargetServicesAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonResponseTargetServicesContainer
 */
export interface ServiceComparisonResponseTargetServicesContainer {
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof ServiceComparisonResponseTargetServicesContainer
     */
    'image': ComparisonAttributeString;
    /**
     * 
     * @type {ComparisonAttributeArrayObject}
     * @memberof ServiceComparisonResponseTargetServicesContainer
     */
    'envVars': ComparisonAttributeArrayObject;
    /**
     * 
     * @type {ComparisonAttributeObject}
     * @memberof ServiceComparisonResponseTargetServicesContainer
     */
    'livenessProbe': ComparisonAttributeObject;
    /**
     * 
     * @type {ComparisonAttributeObject}
     * @memberof ServiceComparisonResponseTargetServicesContainer
     */
    'readinessProbe': ComparisonAttributeObject;
    /**
     * 
     * @type {ComparisonAttributeObject}
     * @memberof ServiceComparisonResponseTargetServicesContainer
     */
    'startupProbe': ComparisonAttributeObject;
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof ServiceComparisonResponseTargetServicesContainer
     */
    'memoryRequests': ComparisonAttributeString;
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof ServiceComparisonResponseTargetServicesContainer
     */
    'memoryLimits': ComparisonAttributeString;
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof ServiceComparisonResponseTargetServicesContainer
     */
    'cpuRequests': ComparisonAttributeString;
    /**
     * 
     * @type {ComparisonAttributeString}
     * @memberof ServiceComparisonResponseTargetServicesContainer
     */
    'cpuLimits': ComparisonAttributeString;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesContainer
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesContainer
     */
    'isContainerExists': boolean;
}


/**
 * Check if a given object implements the ServiceComparisonResponseTargetServicesContainer interface.
 */
export function instanceOfServiceComparisonResponseTargetServicesContainer(value: object): value is ServiceComparisonResponseTargetServicesContainer {
    if (!('image' in value) || value['image'] === undefined) return false;
    if (!('envVars' in value) || value['envVars'] === undefined) return false;
    if (!('livenessProbe' in value) || value['livenessProbe'] === undefined) return false;
    if (!('readinessProbe' in value) || value['readinessProbe'] === undefined) return false;
    if (!('startupProbe' in value) || value['startupProbe'] === undefined) return false;
    if (!('memoryRequests' in value) || value['memoryRequests'] === undefined) return false;
    if (!('memoryLimits' in value) || value['memoryLimits'] === undefined) return false;
    if (!('cpuRequests' in value) || value['cpuRequests'] === undefined) return false;
    if (!('cpuLimits' in value) || value['cpuLimits'] === undefined) return false;
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isContainerExists' in value) || value['isContainerExists'] === undefined) return false;
    return true;
}

export function ServiceComparisonResponseTargetServicesContainerFromJSON(json: any): ServiceComparisonResponseTargetServicesContainer {
    return ServiceComparisonResponseTargetServicesContainerFromJSONTyped(json, false);
}

export function ServiceComparisonResponseTargetServicesContainerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonResponseTargetServicesContainer {
    if (json == null) {
        return json;
    }
    return {
        
        'image': ComparisonAttributeStringFromJSON(json['image']),
        'envVars': ComparisonAttributeArrayObjectFromJSON(json['envVars']),
        'livenessProbe': ComparisonAttributeObjectFromJSON(json['livenessProbe']),
        'readinessProbe': ComparisonAttributeObjectFromJSON(json['readinessProbe']),
        'startupProbe': ComparisonAttributeObjectFromJSON(json['startupProbe']),
        'memoryRequests': ComparisonAttributeStringFromJSON(json['memoryRequests']),
        'memoryLimits': ComparisonAttributeStringFromJSON(json['memoryLimits']),
        'cpuRequests': ComparisonAttributeStringFromJSON(json['cpuRequests']),
        'cpuLimits': ComparisonAttributeStringFromJSON(json['cpuLimits']),
        'isDiff': json['isDiff'],
        'isContainerExists': json['isContainerExists'],
    };
}

export function ServiceComparisonResponseTargetServicesContainerToJSON(value?: ServiceComparisonResponseTargetServicesContainer | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'image': ComparisonAttributeStringToJSON(value['image']),
        'envVars': ComparisonAttributeArrayObjectToJSON(value['envVars']),
        'livenessProbe': ComparisonAttributeObjectToJSON(value['livenessProbe']),
        'readinessProbe': ComparisonAttributeObjectToJSON(value['readinessProbe']),
        'startupProbe': ComparisonAttributeObjectToJSON(value['startupProbe']),
        'memoryRequests': ComparisonAttributeStringToJSON(value['memoryRequests']),
        'memoryLimits': ComparisonAttributeStringToJSON(value['memoryLimits']),
        'cpuRequests': ComparisonAttributeStringToJSON(value['cpuRequests']),
        'cpuLimits': ComparisonAttributeStringToJSON(value['cpuLimits']),
        'isDiff': value['isDiff'],
        'isContainerExists': value['isContainerExists'],
    };
}

        /**
 * 
 * @export
 * @interface ServiceComparisonResponseTargetServicesContainerAllOf
 */
export interface ServiceComparisonResponseTargetServicesContainerAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesContainerAllOf
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesContainerAllOf
     */
    'isContainerExists': boolean;
}


/**
 * Check if a given object implements the ServiceComparisonResponseTargetServicesContainerAllOf interface.
 */
export function instanceOfServiceComparisonResponseTargetServicesContainerAllOf(value: object): value is ServiceComparisonResponseTargetServicesContainerAllOf {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isContainerExists' in value) || value['isContainerExists'] === undefined) return false;
    return true;
}

export function ServiceComparisonResponseTargetServicesContainerAllOfFromJSON(json: any): ServiceComparisonResponseTargetServicesContainerAllOf {
    return ServiceComparisonResponseTargetServicesContainerAllOfFromJSONTyped(json, false);
}

export function ServiceComparisonResponseTargetServicesContainerAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceComparisonResponseTargetServicesContainerAllOf {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isContainerExists': json['isContainerExists'],
    };
}

export function ServiceComparisonResponseTargetServicesContainerAllOfToJSON(value?: ServiceComparisonResponseTargetServicesContainerAllOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isContainerExists': value['isContainerExists'],
    };
}

        /**
 * 
 * @export
 * @interface ServiceData
 */
export interface ServiceData {
    /**
     * 
     * @type {number}
     * @memberof ServiceData
     */
    'count': number;
    /**
     * 
     * @type {KindComponent}
     * @memberof ServiceData
     */
    'unhealthy': KindComponent;
    /**
     * 
     * @type {KindComponent}
     * @memberof ServiceData
     */
    'healthy': KindComponent;
}


/**
 * Check if a given object implements the ServiceData interface.
 */
export function instanceOfServiceData(value: object): value is ServiceData {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('unhealthy' in value) || value['unhealthy'] === undefined) return false;
    if (!('healthy' in value) || value['healthy'] === undefined) return false;
    return true;
}

export function ServiceDataFromJSON(json: any): ServiceData {
    return ServiceDataFromJSONTyped(json, false);
}

export function ServiceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceData {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'unhealthy': KindComponentFromJSON(json['unhealthy']),
        'healthy': KindComponentFromJSON(json['healthy']),
    };
}

export function ServiceDataToJSON(value?: ServiceData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'unhealthy': KindComponentToJSON(value['unhealthy']),
        'healthy': KindComponentToJSON(value['healthy']),
    };
}

        /**
 * 
 * @export
 * @interface ServiceIdWorkspace
 */
export interface ServiceIdWorkspace {
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceIdWorkspace
     */
    'serviceIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceIdWorkspace
     */
    'kind': ServiceIdWorkspaceKindEnum;
}


/**
 * @export
 */
export const ServiceIdWorkspaceKindEnum = {
    ServiceId: 'serviceId'
} as const;
export type ServiceIdWorkspaceKindEnum = typeof ServiceIdWorkspaceKindEnum[keyof typeof ServiceIdWorkspaceKindEnum];



/**
 * Check if a given object implements the ServiceIdWorkspace interface.
 */
export function instanceOfServiceIdWorkspace(value: object): value is ServiceIdWorkspace {
    if (!('serviceIds' in value) || value['serviceIds'] === undefined) return false;
    if (!('kind' in value) || value['kind'] === undefined) return false;
    return true;
}

export function ServiceIdWorkspaceFromJSON(json: any): ServiceIdWorkspace {
    return ServiceIdWorkspaceFromJSONTyped(json, false);
}

export function ServiceIdWorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceIdWorkspace {
    if (json == null) {
        return json;
    }
    return {
        
        'serviceIds': json['serviceIds'],
        'kind': json['kind'],
    };
}

export function ServiceIdWorkspaceToJSON(value?: ServiceIdWorkspace | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'serviceIds': value['serviceIds'],
        'kind': value['kind'],
    };
}

        /**
 * 
 * @export
 * @interface SingleKind
 */
export interface SingleKind {
    /**
     * 
     * @type {string}
     * @memberof SingleKind
     */
    'kind': string;
    /**
     * 
     * @type {number}
     * @memberof SingleKind
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof SingleKind
     */
    'reason': string;
}


/**
 * Check if a given object implements the SingleKind interface.
 */
export function instanceOfSingleKind(value: object): value is SingleKind {
    if (!('kind' in value) || value['kind'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('reason' in value) || value['reason'] === undefined) return false;
    return true;
}

export function SingleKindFromJSON(json: any): SingleKind {
    return SingleKindFromJSONTyped(json, false);
}

export function SingleKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleKind {
    if (json == null) {
        return json;
    }
    return {
        
        'kind': json['kind'],
        'count': json['count'],
        'reason': json['reason'],
    };
}

export function SingleKindToJSON(value?: SingleKind | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'kind': value['kind'],
        'count': value['count'],
        'reason': value['reason'],
    };
}

        /**
 * 
 * @export
 * @interface SingleReason
 */
export interface SingleReason {
    /**
     * 
     * @type {string}
     * @memberof SingleReason
     */
    'reason': string;
    /**
     * 
     * @type {number}
     * @memberof SingleReason
     */
    'count': number;
}


/**
 * Check if a given object implements the SingleReason interface.
 */
export function instanceOfSingleReason(value: object): value is SingleReason {
    if (!('reason' in value) || value['reason'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function SingleReasonFromJSON(json: any): SingleReason {
    return SingleReasonFromJSONTyped(json, false);
}

export function SingleReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleReason {
    if (json == null) {
        return json;
    }
    return {
        
        'reason': json['reason'],
        'count': json['count'],
    };
}

export function SingleReasonToJSON(value?: SingleReason | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reason': value['reason'],
        'count': value['count'],
    };
}

        /**
 * 
 * @export
 * @interface TargetHelmResource
 */
export interface TargetHelmResource {
    /**
     * 
     * @type {boolean}
     * @memberof TargetHelmResource
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TargetHelmResource
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {string}
     * @memberof TargetHelmResource
     */
    'namespace'?: string;
}


/**
 * Check if a given object implements the TargetHelmResource interface.
 */
export function instanceOfTargetHelmResource(value: object): value is TargetHelmResource {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function TargetHelmResourceFromJSON(json: any): TargetHelmResource {
    return TargetHelmResourceFromJSONTyped(json, false);
}

export function TargetHelmResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TargetHelmResource {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'isEmpty': json['isEmpty'],
        'namespace': json['namespace'] == null ? undefined : json['namespace'],
    };
}

export function TargetHelmResourceToJSON(value?: TargetHelmResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'isEmpty': value['isEmpty'],
        'namespace': value['namespace'],
    };
}

        /**
 * 
 * @export
 * @interface TargetHelmResources
 */
export interface TargetHelmResources {
    /**
     * 
     * @type {boolean}
     * @memberof TargetHelmResources
     */
    'isDiff': boolean;
    /**
     * 
     * @type {ComparisonAttributeInteger}
     * @memberof TargetHelmResources
     */
    'count': ComparisonAttributeInteger;
    /**
     * 
     * @type {Array<TargetHelmResource>}
     * @memberof TargetHelmResources
     */
    'resources': Array<TargetHelmResource>;
}


/**
 * Check if a given object implements the TargetHelmResources interface.
 */
export function instanceOfTargetHelmResources(value: object): value is TargetHelmResources {
    if (!('isDiff' in value) || value['isDiff'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('resources' in value) || value['resources'] === undefined) return false;
    return true;
}

export function TargetHelmResourcesFromJSON(json: any): TargetHelmResources {
    return TargetHelmResourcesFromJSONTyped(json, false);
}

export function TargetHelmResourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TargetHelmResources {
    if (json == null) {
        return json;
    }
    return {
        
        'isDiff': json['isDiff'],
        'count': ComparisonAttributeIntegerFromJSON(json['count']),
        'resources': ((json['resources'] as Array<any>).map(TargetHelmResourceFromJSON)),
    };
}

export function TargetHelmResourcesToJSON(value?: TargetHelmResources | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isDiff': value['isDiff'],
        'count': ComparisonAttributeIntegerToJSON(value['count']),
        'resources': ((value['resources'] as Array<any>).map(TargetHelmResourceToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface UnhealthyWorkloadsData
 */
export interface UnhealthyWorkloadsData {
    /**
     * 
     * @type {number}
     * @memberof UnhealthyWorkloadsData
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof UnhealthyWorkloadsData
     */
    'services': number;
    /**
     * 
     * @type {number}
     * @memberof UnhealthyWorkloadsData
     */
    'jobs': number;
    /**
     * 
     * @type {number}
     * @memberof UnhealthyWorkloadsData
     */
    'workflows': number;
}


/**
 * Check if a given object implements the UnhealthyWorkloadsData interface.
 */
export function instanceOfUnhealthyWorkloadsData(value: object): value is UnhealthyWorkloadsData {
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('services' in value) || value['services'] === undefined) return false;
    if (!('jobs' in value) || value['jobs'] === undefined) return false;
    if (!('workflows' in value) || value['workflows'] === undefined) return false;
    return true;
}

export function UnhealthyWorkloadsDataFromJSON(json: any): UnhealthyWorkloadsData {
    return UnhealthyWorkloadsDataFromJSONTyped(json, false);
}

export function UnhealthyWorkloadsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnhealthyWorkloadsData {
    if (json == null) {
        return json;
    }
    return {
        
        'total': json['total'],
        'services': json['services'],
        'jobs': json['jobs'],
        'workflows': json['workflows'],
    };
}

export function UnhealthyWorkloadsDataToJSON(value?: UnhealthyWorkloadsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'total': value['total'],
        'services': value['services'],
        'jobs': value['jobs'],
        'workflows': value['workflows'],
    };
}

        /**
 * 
 * @export
 * @interface ViolationStatus
 */
export interface ViolationStatus {
    /**
     * 
     * @type {number}
     * @memberof ViolationStatus
     */
    'count': number;
    /**
     * 
     * @type {ReasonComponent}
     * @memberof ViolationStatus
     */
    'high': ReasonComponent;
    /**
     * 
     * @type {ReasonComponent}
     * @memberof ViolationStatus
     */
    'medium': ReasonComponent;
    /**
     * 
     * @type {ReasonComponent}
     * @memberof ViolationStatus
     */
    'low': ReasonComponent;
}


/**
 * Check if a given object implements the ViolationStatus interface.
 */
export function instanceOfViolationStatus(value: object): value is ViolationStatus {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('high' in value) || value['high'] === undefined) return false;
    if (!('medium' in value) || value['medium'] === undefined) return false;
    if (!('low' in value) || value['low'] === undefined) return false;
    return true;
}

export function ViolationStatusFromJSON(json: any): ViolationStatus {
    return ViolationStatusFromJSONTyped(json, false);
}

export function ViolationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViolationStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'high': ReasonComponentFromJSON(json['high']),
        'medium': ReasonComponentFromJSON(json['medium']),
        'low': ReasonComponentFromJSON(json['low']),
    };
}

export function ViolationStatusToJSON(value?: ViolationStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'high': ReasonComponentToJSON(value['high']),
        'medium': ReasonComponentToJSON(value['medium']),
        'low': ReasonComponentToJSON(value['low']),
    };
}

        /**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'description'?: string;
    /**
     * 
     * @type {WorkspaceKind}
     * @memberof Workspace
     */
    'kind': WorkspaceKind;
    /**
     * 
     * @type {WorkspaceValue}
     * @memberof Workspace
     */
    'value': WorkspaceValue;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'generationType': WorkspaceGenerationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'AuthorEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'UpdatedByEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'lastUpdated': string;
}


/**
 * @export
 */
export const WorkspaceGenerationTypeEnum = {
    Auto: 'auto',
    Manual: 'manual'
} as const;
export type WorkspaceGenerationTypeEnum = typeof WorkspaceGenerationTypeEnum[keyof typeof WorkspaceGenerationTypeEnum];



/**
 * Check if a given object implements the Workspace interface.
 */
export function instanceOfWorkspace(value: object): value is Workspace {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('kind' in value) || value['kind'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('generationType' in value) || value['generationType'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('lastUpdated' in value) || value['lastUpdated'] === undefined) return false;
    return true;
}

export function WorkspaceFromJSON(json: any): Workspace {
    return WorkspaceFromJSONTyped(json, false);
}

export function WorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Workspace {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'kind': WorkspaceKindFromJSON(json['kind']),
        'value': WorkspaceValueFromJSON(json['value']),
        'generationType': json['generationType'],
        'AuthorEmail': json['AuthorEmail'] == null ? undefined : json['AuthorEmail'],
        'UpdatedByEmail': json['UpdatedByEmail'] == null ? undefined : json['UpdatedByEmail'],
        'createdAt': json['createdAt'],
        'lastUpdated': json['lastUpdated'],
    };
}

export function WorkspaceToJSON(value?: Workspace | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'kind': WorkspaceKindToJSON(value['kind']),
        'value': WorkspaceValueToJSON(value['value']),
        'generationType': value['generationType'],
        'AuthorEmail': value['AuthorEmail'],
        'UpdatedByEmail': value['UpdatedByEmail'],
        'createdAt': value['createdAt'],
        'lastUpdated': value['lastUpdated'],
    };
}

        
/**
 * 
 * @export
 */
export const WorkspaceKind = {
    Namespace: 'namespace',
    Label: 'label',
    ServiceId: 'serviceId',
    Cluster: 'cluster',
    ClustersGroup: 'clustersGroup',
    Scoped: 'scoped'
} as const;
export type WorkspaceKind = typeof WorkspaceKind[keyof typeof WorkspaceKind];


export function instanceOfWorkspaceKind(value: any): boolean {
    for (const key in WorkspaceKind) {
        if (Object.prototype.hasOwnProperty.call(WorkspaceKind, key)) {
            if (WorkspaceKind[key as keyof typeof WorkspaceKind] === value) {
                return true;
            }
        }
    }
    return false;
}

export function WorkspaceKindFromJSON(json: any): WorkspaceKind {
    return WorkspaceKindFromJSONTyped(json, false);
}

export function WorkspaceKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceKind {
    return json as WorkspaceKind;
}

export function WorkspaceKindToJSON(value?: WorkspaceKind | null): any {
    return value as any;
}

        /**
* @type WorkspaceValue
* 
* @export
*/
export type WorkspaceValue = { kind: 'cluster' } & ClusterWorkspace | { kind: 'clustersGroup' } & ClustersGroupWorkspace | { kind: 'label' } & LabelsWorkspace | { kind: 'namespace' } & NamespacesWorkspace | { kind: 'scoped' } & ScopedWorkspace | { kind: 'serviceId' } & ServiceIdWorkspace;


export function WorkspaceValueFromJSON<T extends keyof WorkspaceValueTypeMap>(json: any): WorkspaceValueType<T> {
  const type = json['kind'];
  return WorkspaceValueFromJSONTyped<T>(type, json, false);
}

type WorkspaceValueTypeMap = {
    'ClusterWorkspace': { kind: 'cluster' } & ClusterWorkspace;
    'ClustersGroupWorkspace': { kind: 'clustersGroup' } & ClustersGroupWorkspace;
    'LabelsWorkspace': { kind: 'label' } & LabelsWorkspace;
    'NamespacesWorkspace': { kind: 'namespace' } & NamespacesWorkspace;
    'ScopedWorkspace': { kind: 'scoped' } & ScopedWorkspace;
    'ServiceIdWorkspace': { kind: 'serviceId' } & ServiceIdWorkspace;
};

type WorkspaceValueType<T extends keyof WorkspaceValueTypeMap> = WorkspaceValueTypeMap[T];

export function WorkspaceValueFromJSONTyped<T extends keyof WorkspaceValueTypeMap>(type: T, json: any, ignoreDiscriminator: boolean): WorkspaceValueTypeMap[T] {
  if (json == null) {
    return json;
  }
  switch (json['kind']) {
    case 'cluster':
      return Object.assign({}, ClusterWorkspaceFromJSONTyped(json, true), { kind: 'cluster' } as const) as WorkspaceValueTypeMap[T];
    case 'clustersGroup':
      return Object.assign({}, ClustersGroupWorkspaceFromJSONTyped(json, true), { kind: 'clustersGroup' } as const) as WorkspaceValueTypeMap[T];
    case 'label':
      return Object.assign({}, LabelsWorkspaceFromJSONTyped(json, true), { kind: 'label' } as const) as WorkspaceValueTypeMap[T];
    case 'namespace':
      return Object.assign({}, NamespacesWorkspaceFromJSONTyped(json, true), { kind: 'namespace' } as const) as WorkspaceValueTypeMap[T];
    case 'scoped':
      return Object.assign({}, ScopedWorkspaceFromJSONTyped(json, true), { kind: 'scoped' } as const) as WorkspaceValueTypeMap[T];
    case 'serviceId':
      return Object.assign({}, ServiceIdWorkspaceFromJSONTyped(json, true), { kind: 'serviceId' } as const) as WorkspaceValueTypeMap[T];
    default:
      throw new Error(`No variant of WorkspaceValue exists with 'kind=${json['kind']}'`);
  }
}

export function WorkspaceValueToJSON(value?: WorkspaceValue | null): any {
  if (value == null) {
    return value;
  }
  switch (value['kind']) {
    case 'cluster':
      return ClusterWorkspaceToJSON(value);
    case 'clustersGroup':
      return ClustersGroupWorkspaceToJSON(value);
    case 'label':
      return LabelsWorkspaceToJSON(value);
    case 'namespace':
      return NamespacesWorkspaceToJSON(value);
    case 'scoped':
      return ScopedWorkspaceToJSON(value);
    case 'serviceId':
      return ServiceIdWorkspaceToJSON(value);
    default:
      throw new Error(`No variant of WorkspaceValue exists with 'kind=${value['kind']}'`);
  }

}

        /**
 * 
 * @export
 * @interface WorkspacesResponse
 */
export interface WorkspacesResponse {
    /**
     * 
     * @type {Array<Workspace>}
     * @memberof WorkspacesResponse
     */
    'data': Array<Workspace>;
}


/**
 * Check if a given object implements the WorkspacesResponse interface.
 */
export function instanceOfWorkspacesResponse(value: object): value is WorkspacesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function WorkspacesResponseFromJSON(json: any): WorkspacesResponse {
    return WorkspacesResponseFromJSONTyped(json, false);
}

export function WorkspacesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspacesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(WorkspaceFromJSON)),
    };
}

export function WorkspacesResponseToJSON(value?: WorkspacesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(WorkspaceToJSON)),
    };
}

    
        /**
* Retrieve an array of cluster filters
* @summary Get Cluster Filters
* @param params ClustersApiApiV1ClustersFiltersSearchPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersFiltersSearchPostUrl = (params: ClustersApiApiV1ClustersFiltersSearchPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/clusters-filters/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params ClustersApiApiV1ClustersSummaryNameGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersSummaryNameGetUrl = (params: ClustersApiApiV1ClustersSummaryNameGetRequest, baseUrl: string): string => {
    // verify required parameter 'name' is not null or undefined
    assertParamExists('apiV1ClustersSummaryNameGet', 'name', params.name)
    const localVarPath = `/api/v1/clusters-summary/{name}`
        .replace(`{${"name"}}`, encodeURIComponent(String(params.name)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Retrieve an array of cluster summaries
* @summary Get Cluster Summaries
* @param params ClustersApiApiV1ClustersSummarySearchPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersSummarySearchPostUrl = (params: ClustersApiApiV1ClustersSummarySearchPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/clusters-summary/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ClustersFiltersSearchPost operation in ClustersApi.
* @export
* @interface ClustersApiApiV1ClustersFiltersSearchPostRequest
*/
export interface ClustersApiApiV1ClustersFiltersSearchPostRequest {
    /**
    * 
    * @type {ClustersFiltersBody}
    * @memberof ClustersApiApiV1ClustersFiltersSearchPost
    */
    readonly clustersFiltersBody?: ClustersFiltersBody
}

/**
* Request parameters for apiV1ClustersSummaryNameGet operation in ClustersApi.
* @export
* @interface ClustersApiApiV1ClustersSummaryNameGetRequest
*/
export interface ClustersApiApiV1ClustersSummaryNameGetRequest {
    /**
    * The name of the cluster to retrieve
    * @type {string}
    * @memberof ClustersApiApiV1ClustersSummaryNameGet
    */
    readonly name: string
}

/**
* Request parameters for apiV1ClustersSummarySearchPost operation in ClustersApi.
* @export
* @interface ClustersApiApiV1ClustersSummarySearchPostRequest
*/
export interface ClustersApiApiV1ClustersSummarySearchPostRequest {
    /**
    * 
    * @type {ClustersSummaryBody}
    * @memberof ClustersApiApiV1ClustersSummarySearchPost
    */
    readonly clustersSummaryBody?: ClustersSummaryBody
}


    
        /**
* Compare helm releases and return the drift
* @summary Compare baseline and target helm releases
* @param params DriftApiApiV1DriftComparisonHelmPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1DriftComparisonHelmPostUrl = (params: DriftApiApiV1DriftComparisonHelmPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/drift/comparison/helm`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Compare services and return the drift
* @summary Compare baseline and target services
* @param params DriftApiApiV1DriftComparisonServicePostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1DriftComparisonServicePostUrl = (params: DriftApiApiV1DriftComparisonServicePostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/drift/comparison/service`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1DriftComparisonHelmPost operation in DriftApi.
* @export
* @interface DriftApiApiV1DriftComparisonHelmPostRequest
*/
export interface DriftApiApiV1DriftComparisonHelmPostRequest {
    /**
    * 
    * @type {HelmComparisonRequest}
    * @memberof DriftApiApiV1DriftComparisonHelmPost
    */
    readonly helmComparisonRequest?: HelmComparisonRequest
}

/**
* Request parameters for apiV1DriftComparisonServicePost operation in DriftApi.
* @export
* @interface DriftApiApiV1DriftComparisonServicePostRequest
*/
export interface DriftApiApiV1DriftComparisonServicePostRequest {
    /**
    * 
    * @type {ServiceComparisonRequest}
    * @memberof DriftApiApiV1DriftComparisonServicePost
    */
    readonly serviceComparisonRequest?: ServiceComparisonRequest
}


    
        /**
* Resolve user authorized clusters by patterns
* @summary Resolve Cluster names from patterns
* @param params WorkspacesApiApiV1WorkspacesClustersResolvePostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesClustersResolvePostUrl = (params: WorkspacesApiApiV1WorkspacesClustersResolvePostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workspaces/clusters/resolve`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params WorkspacesApiApiV1WorkspacesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesGetUrl = (params: WorkspacesApiApiV1WorkspacesGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workspaces`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.generationType !== undefined) {
            localVarQueryParameter['generationType'] = params.generationType;
    }
    if (params.kinds) {
        localVarQueryParameter['kinds'] = params.kinds;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params WorkspacesApiApiV1WorkspacesIdClustersGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesIdClustersGetUrl = (params: WorkspacesApiApiV1WorkspacesIdClustersGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WorkspacesIdClustersGet', 'id', params.id)
    const localVarPath = `/api/v1/workspaces/{id}/clusters`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Deletes a workspace by its id
* @summary Delete a Workspace
* @param params WorkspacesApiApiV1WorkspacesIdDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesIdDeleteUrl = (params: WorkspacesApiApiV1WorkspacesIdDeleteRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WorkspacesIdDelete', 'id', params.id)
    const localVarPath = `/api/v1/workspaces/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params WorkspacesApiApiV1WorkspacesIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesIdGetUrl = (params: WorkspacesApiApiV1WorkspacesIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WorkspacesIdGet', 'id', params.id)
    const localVarPath = `/api/v1/workspaces/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Updates an existing Workspace and returns its data
* @summary Updates an existing Workspace
* @param params WorkspacesApiApiV1WorkspacesIdPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesIdPutUrl = (params: WorkspacesApiApiV1WorkspacesIdPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WorkspacesIdPut', 'id', params.id)
    // verify required parameter 'workspace' is not null or undefined
    assertParamExists('apiV1WorkspacesIdPut', 'workspace', params.workspace)
    const localVarPath = `/api/v1/workspaces/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Creates a new Workspace and returns its data
* @summary Create a new Workspace
* @param params WorkspacesApiApiV1WorkspacesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesPostUrl = (params: WorkspacesApiApiV1WorkspacesPostRequest, baseUrl: string): string => {
    // verify required parameter 'workspace' is not null or undefined
    assertParamExists('apiV1WorkspacesPost', 'workspace', params.workspace)
    const localVarPath = `/api/v1/workspaces`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1WorkspacesClustersResolvePost operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesClustersResolvePostRequest
*/
export interface WorkspacesApiApiV1WorkspacesClustersResolvePostRequest {
    /**
    * 
    * @type {ResolveClustersRequest}
    * @memberof WorkspacesApiApiV1WorkspacesClustersResolvePost
    */
    readonly resolveClustersRequest?: ResolveClustersRequest
}

/**
* Request parameters for apiV1WorkspacesGet operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesGetRequest
*/
export interface WorkspacesApiApiV1WorkspacesGetRequest {
    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesGet
    */
    readonly accountId?: string

    /**
    * Generation Type: auto/manual/all
    * @type {'auto' | 'manual' | 'all'}
    * @memberof WorkspacesApiApiV1WorkspacesGet
    */
    readonly generationType?: 'auto' | 'manual' | 'all'

    /**
    * Workspace Types: namespace/label/serviceId/cluster/clustersGroup/scoped
    * @type {Array<WorkspaceKind>}
    * @memberof WorkspacesApiApiV1WorkspacesGet
    */
    readonly kinds?: Array<WorkspaceKind>
}

/**
* Request parameters for apiV1WorkspacesIdClustersGet operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesIdClustersGetRequest
*/
export interface WorkspacesApiApiV1WorkspacesIdClustersGetRequest {
    /**
    * The ID of the workspace to retrieve
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdClustersGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1WorkspacesIdDelete operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesIdDeleteRequest
*/
export interface WorkspacesApiApiV1WorkspacesIdDeleteRequest {
    /**
    * uuid of a workspace
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdDelete
    */
    readonly id: string

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdDelete
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1WorkspacesIdGet operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesIdGetRequest
*/
export interface WorkspacesApiApiV1WorkspacesIdGetRequest {
    /**
    * The ID of the workspace to retrieve
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdGet
    */
    readonly id: string

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1WorkspacesIdPut operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesIdPutRequest
*/
export interface WorkspacesApiApiV1WorkspacesIdPutRequest {
    /**
    * The ID of the workspace to retrieve
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdPut
    */
    readonly id: string

    /**
    * 
    * @type {Workspace}
    * @memberof WorkspacesApiApiV1WorkspacesIdPut
    */
    readonly workspace: Workspace

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdPut
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1WorkspacesPost operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesPostRequest
*/
export interface WorkspacesApiApiV1WorkspacesPostRequest {
    /**
    * 
    * @type {Workspace}
    * @memberof WorkspacesApiApiV1WorkspacesPost
    */
    readonly workspace: Workspace

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesPost
    */
    readonly accountId?: string
}


    
