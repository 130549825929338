import React from "react";

import type Pod from "../../resources/pod";

import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { FlowType } from "@/components/AiInvestigation/types";
import { EnableAiBanner } from "@/components/AiInvestigation/AiBanner/EnableAiBanner";
import { AiInvestigation } from "@/components/AiInvestigation/AiInvestigation";

export type PodAiInvestigationTabProps = {
  resource: Pod;
};

export const PodAiInvestigationTab: React.FC<PodAiInvestigationTabProps> = ({
  resource,
}) => {
  const { data: features } = useAccountFeatures();
  const isHealthy = Boolean(resource.isPodReadyOrCompleted);
  if (!resource.id) {
    return null;
  }

  return (
    <>
      {!isHealthy && !features.klaudiaAi && (
        <EnableAiBanner title="Root Cause Analysis" />
      )}
      <AiInvestigation
        requestId={resource.id}
        resource={resource}
        isHealthy={isHealthy}
        flowType={FlowType.UnhealthyPod}
      />
    </>
  );
};
