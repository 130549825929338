/* eslint-disable max-lines */
import { compact } from "lodash";
import { useMemo } from "react";

import {
  AvailabilityCheckType,
  BaseWorkflowCheck,
  CheckDetails,
  DeployCheckType,
  JobCheckTypeEnum,
  PodCheckType,
  WorkflowConfigType,
} from "../../common/types";

import PVCSpecChangedCheck from "./pvc/PVCSpecChangedCheck";
import ImpactedServicesCheck from "./pvc/ImpactedServicesCheck";
import PVCCreationCheck from "./pvc/PVCCreationCheck";
import NodeReadyCheck from "./node/NodeReadyCheck";
import NodeSchedulableCheck from "./node/NodeSchedulableCheck";
import NodeOverCommittedCheck from "./node/NodeOverCommittedCheck";
import ResourceConsumptionCheck, {
  ResourceConsumptionOutput,
} from "./node/ResourceConsumptionCheck";
import NodeHealthyUserPodsCheck from "./node/NodeHealthyUserPodsCheck";
import NodeNetworkAvailableCheck, {
  NodeNetworkAvailableOutput,
} from "./node/NodeNetworkAvailableCheck";
import NodeHealthySystemPodsCheck from "./node/NodeHealthySystemPodsCheck";
import NodeEvictedPodsCheck from "./node/NodeEvictedPodsCheck";
import NodeUnderPressureCheck from "./node/NodeUnderPressureCheck";
import WorkloadHealthyPodsCheck from "./availability/WorkloadHealthyPodsCheck";
import WorkloadDescribeCheck from "./availability/WorkloadDescribeCheck";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkloadInfoCheck from "./availability/WorkloadInfoCheck";
import {
  WorkloadHealthyPodsOutput,
  WorkloadInfoOutput,
} from "./availability/types";
import NodeOtherConditionFailureCheck from "./node/NodeOtherConditionFailureCheck";
import HealthyPodCheck from "./pod/HealthyPodCheck";

import WorkflowCheck from ".";

const getPVCChecks = (results: BaseWorkflowCheck[]) => {
  return compact(
    results.map((check) => {
      switch (check.type) {
        case "spec-changed":
          return new PVCSpecChangedCheck(check);
        case "impacted-services":
          return new ImpactedServicesCheck(check);
        // case "PVC-provisioner-data":
        //   return new ProvisionerDetectionCheck(check);
        case "configuration-problems":
          return new PVCCreationCheck(check);
        default:
          return null;
      }
    })
  );
};

const getNodeChecks = (results: BaseWorkflowCheck[]) => {
  return compact(
    results.map((check) => {
      switch (check.type) {
        case "node-ready":
          return new NodeReadyCheck(check);
        case "node-schedulable":
          return new NodeSchedulableCheck(check);
        case "node-over-committed":
          return new NodeOverCommittedCheck(check);
        case "node-resource-consumption": {
          const output = check.output as ResourceConsumptionOutput;
          if (
            output.nodeMetrics.failureMessage ||
            output.podsMetrics.failureMessage ||
            (output.nodeMetrics.name === "" &&
              output.podsMetrics.pods.length === 0)
          ) {
            return null;
          }
          return new ResourceConsumptionCheck(check);
        }
        case "node-user-pods-healthy":
          return new NodeHealthyUserPodsCheck(check);
        case "node-network-available": {
          const output = check.output as NodeNetworkAvailableOutput;
          if (output === null) {
            return null;
          }
          return new NodeNetworkAvailableCheck(check);
        }
        case "node-other-condition-failure": {
          const output = check.output as NodeNetworkAvailableOutput;
          if (output === null) {
            return null;
          }
          return new NodeOtherConditionFailureCheck(check);
        }
        case "node-system-pods-healthy":
          return new NodeHealthySystemPodsCheck(check);
        case "node-evicted-pods":
          return new NodeEvictedPodsCheck(check);
        case "node-under-pressure":
          return new NodeUnderPressureCheck(check);
        default:
          return null;
      }
    })
  );
};

const getAvailabilityChecks = (
  results: BaseWorkflowCheck[],
  eventTime?: Date,
  closedAt?: Date | null,
  services?: string[],
  clusterName?: string,
  namespace?: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  const firstIssueStartDate = new Date(
    (
      results.find((c) => c.type === AvailabilityCheckType.WorkloadInfoPrevious)
        ?.output as WorkloadInfoOutput
    )?.infoTime ??
      eventTime ??
      ""
  );
  const lastIssueStartDateDate = new Date(
    (
      results.find((c) => c.type === AvailabilityCheckType.WorkloadInfo)
        ?.output as WorkloadInfoOutput
    )?.infoTime ??
      eventTime ??
      ""
  );

  return compact(
    results.map((check) => {
      switch (check.type) {
        case AvailabilityCheckType.WorkloadPodsHealthy:
          return new WorkloadHealthyPodsCheck(
            check,
            lastIssueStartDateDate,
            services,
            clusterName,
            namespace
          );
        case AvailabilityCheckType.WorkloadDescribe:
          return new WorkloadDescribeCheck(check, lastIssueStartDateDate);
        case AvailabilityCheckType.WorkloadInfo: {
          const output = check.output as WorkloadInfoOutput;
          if (output === null) {
            return null;
          }
          const podsHealthyCheckOutput = results.find(
            (check) => check.type === AvailabilityCheckType.WorkloadPodsHealthy
          )?.output as WorkloadHealthyPodsOutput;
          const firstFailedPodName = podsHealthyCheckOutput?.pods?.find(
            (p) => !p.isHealthy
          )?.name;
          return new WorkloadInfoCheck(
            check,
            lastIssueStartDateDate,
            closedAt,
            firstFailedPodName
          );
        }

        case AvailabilityCheckType.WorkloadPodsHealthyPrevious:
          return new WorkloadHealthyPodsCheck(
            check,
            firstIssueStartDate,
            services,
            clusterName,
            namespace
          );
        case AvailabilityCheckType.WorkloadDescribePrevious:
          return new WorkloadDescribeCheck(check, firstIssueStartDate);
        case AvailabilityCheckType.WorkloadInfoPrevious: {
          const output = check.output as WorkloadInfoOutput;
          if (output === null) {
            return null;
          }
          const podsHealthyCheckOutput = results.find(
            (check) =>
              check.type === AvailabilityCheckType.WorkloadPodsHealthyPrevious
          )?.output as WorkloadHealthyPodsOutput;
          const firstFailedPodName = podsHealthyCheckOutput?.pods?.find(
            (p) => !p.isHealthy
          )?.name;
          return new WorkloadInfoCheck(
            check,
            firstIssueStartDate,
            closedAt,
            firstFailedPodName
          );
        }
        default:
          return null;
      }
    })
  );
};

const getJobChecks = (
  results: BaseWorkflowCheck[],
  eventTime?: Date,
  clusterName?: string,
  namespace?: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  return compact(
    results.map((check) => {
      if (check.type === JobCheckTypeEnum.JobCheckType) {
        const ch = new WorkloadHealthyPodsCheck(
          check,
          eventTime,
          undefined,
          clusterName,
          namespace
        );
        ch.question = "Job failed?";
        ch.shortOutput = check.shortOutput;
        return ch;
      } else {
        return null;
      }
    })
  );
};
const getDeployChecks = (
  results: BaseWorkflowCheck[],
  eventTime?: Date,
  clusterName?: string,
  namespace?: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  return compact(
    results.map((check) => {
      if (check.type === DeployCheckType.DeployPodsHealthy) {
        return new WorkloadHealthyPodsCheck(
          check,
          eventTime,
          undefined,
          clusterName,
          namespace
        );
      } else {
        return null;
      }
    })
  );
};
const getPodChecks = (
  results: BaseWorkflowCheck[],
  eventTime?: Date,
  clusterName?: string,
  namespace?: string
  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
) => {
  return compact(
    results.map((check) => {
      if (check.type === PodCheckType.PodHealthy) {
        return new HealthyPodCheck(check, eventTime, clusterName, namespace);
      } else {
        return null;
      }
    })
  );
};
const useWorkflowsChecks = (checkDetails: CheckDetails): WorkflowCheck[] => {
  return useMemo(() => {
    switch (checkDetails.workflowType) {
      case WorkflowConfigType.PVC:
        return getPVCChecks(checkDetails.results);
      case WorkflowConfigType.NodeIssue:
        return getNodeChecks(checkDetails.results);
      case WorkflowConfigType.Availability:
        return getAvailabilityChecks(
          checkDetails.results,
          checkDetails.eventTime,
          checkDetails.closedAt,
          checkDetails.services,
          checkDetails.clusterName,
          checkDetails.namespace
        );
      case WorkflowConfigType.Job:
        return getJobChecks(
          checkDetails.results,
          checkDetails.eventTime,
          checkDetails.clusterName,
          checkDetails.namespace
        );
      case WorkflowConfigType.CronJob:
        return getJobChecks(
          checkDetails.results,
          checkDetails.eventTime,
          checkDetails.clusterName,
          checkDetails.namespace
        );
      case WorkflowConfigType.Deploy:
        return getDeployChecks(
          checkDetails.results,
          checkDetails.eventTime,
          checkDetails.clusterName,
          checkDetails.namespace
        );
      case WorkflowConfigType.Workflow:
        return getPodChecks(
          checkDetails.results,
          checkDetails.eventTime,
          checkDetails.clusterName,
          checkDetails.namespace
        );
      default:
        return [];
    }
  }, [
    checkDetails?.closedAt,
    checkDetails?.clusterName,
    checkDetails?.eventTime,
    checkDetails?.namespace,
    checkDetails?.results,
    checkDetails?.services,
    checkDetails?.workflowType,
  ]);
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useWorkflowsChecks;
