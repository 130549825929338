import { ComparisonTable } from "@komodorio/design-system/komodor-ui";

import { EmptyStateComparedItems } from "../../common/EmptyStateComparedItems";
import { DriftType } from "../../context/types";
import { MAX_COMPARED_SERVICES } from "../constants";
import { useDriftComparisonContext } from "../../context/useDriftComparisonContext";

import { useContainerTableAttributes } from "./ContainersTableUtils";

import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";

interface ContainerTableNoDataProps {
  isLoading: boolean;
  isError: boolean;
  errorStateElement: React.ReactNode;
}
export const ContainerTableNoData = ({
  isLoading,
  isError,
  errorStateElement,
}: ContainerTableNoDataProps) => {
  const { comparedSelections } = useDriftComparisonContext();
  const attributes = useContainerTableAttributes(undefined);
  return (
    <ComparisonTable
      title="Container"
      isStacked={true}
      limit={MAX_COMPARED_SERVICES}
      isLoading={isLoading}
      attributes={attributes}
      baseline={undefined}
      compared={
        comparedSelections?.map((serviceUid) => {
          const name = parseKomodorUid(serviceUid.value)?.name ?? "";
          return {
            data: {
              name: { value: name, isDiff: false, isEmpty: false },
            },
            isMissingData: false,
            isLoading: true,
          };
        }) ?? []
      }
      noBaselineElement={undefined}
      noComparedItemsElement={
        isLoading ? undefined : (
          <EmptyStateComparedItems type={DriftType.Services} />
        )
      }
      isError={isError}
      errorStateElement={errorStateElement}
    />
  );
};
