import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  ACTIONS_ROUTE,
  AGENTS_SETTINGS_ROUTE,
  AUDIT_ROUTE,
  FEATURES_ROUTE,
  INTEGRATIONS_ROUTE,
  IP_ALLOWLIST_ROUTE,
  POLICIES_ROUTE,
  RELIABILITY_SETTINGS_ROUTE,
  ROLES_ROUTE,
  TRACKED_KEYS_ROUTE,
  USAGE_ROUTE,
  USERS_ROUTE,
  WORKSPACE_MANAGEMENT_ROUTE,
} from "@/components/routes/routes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { Permissions } from "@/shared/constants/permissions";

const SETTINGS_SECTIONS = {
  accessManagement: "ACCESS MANAGEMENT",
  account: "ACCOUNT",
  configurations: "CONFIGURATIONS",
};

interface Page {
  title: string;
  route: string;
  disabled?: boolean;
  permissions?: string[];
  flags?: string[];
  hideIfFlags?: string[];
  accountFeatures?: string[];
  tooltip?: string;
}

const PAGES: {
  [key: string]: Page[];
} = {
  [SETTINGS_SECTIONS.accessManagement]: [
    {
      title: "Users",
      route: USERS_ROUTE,
      permissions: [Permissions.canManageUsers],
    },
    {
      title: "Roles",
      route: ROLES_ROUTE,
      permissions: [Permissions.canManageUsers],
    },
    {
      title: "Policies",
      route: POLICIES_ROUTE,
      permissions: [Permissions.canManageUsers],
    },
    {
      title: "Actions",
      route: ACTIONS_ROUTE,
      permissions: [Permissions.canManageUsers],
      accountFeatures: ["rbacClusterSync"],
      tooltip:
        "You need to enable the RBAC Cluster Sync feature to view this section",
    },
    {
      title: "IP Allowlist",
      route: IP_ALLOWLIST_ROUTE,
      permissions: [Permissions.canManageAccountAccess],
    },
  ],
  [SETTINGS_SECTIONS.account]: [
    {
      title: "Usage",
      route: USAGE_ROUTE,
      permissions: [Permissions.canManageUsage],
    },
    {
      title: "Audit",
      route: AUDIT_ROUTE,
      permissions: [Permissions.canViewAudit],
    },
    {
      title: "Agents",
      route: AGENTS_SETTINGS_ROUTE,
      permissions: [Permissions.canManageAgents],
    },
  ],
  [SETTINGS_SECTIONS.configurations]: [
    {
      title: "Health Policies",
      route: RELIABILITY_SETTINGS_ROUTE,
      permissions: [
        "canManageReliabilityPolicies",
        "canManageReliability",
        "canManageMonitors",
      ],
      tooltip:
        "You need at least one of the following permissions to view this section:\n" +
        "‘manage:reliability’ | ’manage:monitors’ | ‘manage:reliability-policies’",
    },
    {
      title: "Workspaces",
      route: WORKSPACE_MANAGEMENT_ROUTE,
      permissions: [Permissions.canManageWorkspaces],
      flags: ["showWorkspaceManagementPage"],
    },
    {
      title: "Integrations",
      route: INTEGRATIONS_ROUTE,
      permissions: [Permissions.canManageIntegrations],
    },
    {
      title: "Features",
      route: FEATURES_ROUTE,
      permissions: [Permissions.canManageFeatures],
    },
    {
      title: "Tracked Keys",
      route: TRACKED_KEYS_ROUTE,
      permissions: [Permissions.canManageTrackedKeys],
      flags: ["trackedKeys"],
    },
  ],
};

const filterByFlags = (page: Page, flags: Record<string, unknown>) => {
  if (!page.flags) return true;
  return page.flags?.every((flag) => flags[flag]);
};

const shouldDisableByPermissions = (
  page: Page,
  permissions: Record<string, boolean>
) => {
  if (!page.permissions) return false;
  return page.permissions?.every((permission) => !permissions[permission]);
};

const shouldDisableByAccountFeatures = (
  page: Page,
  accountFeatures: Record<string, boolean>
) => {
  if (!page.accountFeatures) return false;
  return page.accountFeatures?.every((feature) => !accountFeatures[feature]);
};

const shouldHideByFlags = (page: Page, flags: Record<string, unknown>) => {
  if (!page.hideIfFlags) return false;
  return page.hideIfFlags?.some((flag) => flags[flag]);
};

export const useSettings = () => {
  const flags = useOverridableFlags();
  const permissions = useHasPermissions();
  const { data: accountFeatures } = useAccountFeatures();
  const location = useLocation();

  const items = useMemo(
    () =>
      Object.entries(PAGES).map(([section, pages]) => ({
        title: section,
        pages: pages
          .filter((page) => filterByFlags(page, flags))
          .filter((page) => !shouldHideByFlags(page, flags))
          .map((page) => ({
            title: page.title,
            route: page.route,
            disabled:
              shouldDisableByAccountFeatures(page, accountFeatures) ||
              shouldDisableByPermissions(page, permissions) ||
              false,
            tooltip: page.tooltip,
          })),
      })),
    [accountFeatures, flags, permissions]
  );

  const linkTabs = React.useMemo(() => {
    return items.flatMap((section) => section.pages);
  }, [items]);

  const getTabByRoute = React.useCallback(
    (route: string) => {
      const tab = linkTabs.find((t) => t.route === route);
      if (tab) {
        return tab;
      }

      return linkTabs.find((t) => route.includes(t.route)) || { route: false };
    },
    [linkTabs]
  );

  const currentTab = React.useMemo(
    () => getTabByRoute(location.pathname).route,
    [getTabByRoute, location.pathname]
  );

  return {
    items,
    currentTab,
  };
};
