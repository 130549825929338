import React, { useMemo } from "react";
import { Legend } from "recharts";
import styled from "styled-components";

import { MetricsContainer } from "../MetricsContainer";
import { MetricData } from "../metricsType";

import { legendItems, metricsLines, tooltipItems } from "./metricsGraphUtils";

import {
  GraphContainerWithStatus,
  GraphContainerWithStatusProps,
} from "@/components/Metrics/GraphContainerWithStatus";
import { MetricsGraphContainer } from "@/components/Metrics/graphs/MetricsGraphContainer";
import {
  formatGraphCPUTick,
  formatGraphMemoryTick,
  formatTooltipCPUTick,
  formatTooltipMemoryTick,
  mergeMetrics,
  transformDataPoints,
} from "@/components/Metrics/utils";
import { Tooltip } from "@/components/Metrics/Tooltip/Tooltip";
import { GenericLegend } from "@/components/Metrics/GenericLegend/GenericLegend";

type MetricType = "cpu" | "memory";

export type MetricGraphProps = {
  graphContainerProps: Omit<GraphContainerWithStatusProps, "children">;
  metric: MetricType;
  metricData: MetricData;
  showLabel?: boolean;
};

const graphMetricMapper = {
  cpu: {
    title: "CPU (Cores)",
    label: "Cores",
    formatFunc: (tick: string) => formatGraphCPUTick(tick, "Cores"),
    formatTick: (tick: string) => formatTooltipCPUTick(tick, "Cores"),
  },
  memory: {
    title: "Memory (GiB)",
    label: "GiB",
    formatFunc: (tick: string) => formatGraphMemoryTick(tick, "GiB"),
    formatTick: (tick: string) => formatTooltipMemoryTick(tick, "GiB"),
  },
};

const LegendContent = styled(GenericLegend)`
  margin-top: 8px;
  margin-left: 0;
  top: 0;
`;

export const MetricGraph: React.FC<MetricGraphProps> = ({
  metric,
  graphContainerProps,
  metricData,
  showLabel = true,
}) => {
  const { title, label, formatFunc, formatTick } = graphMetricMapper[metric];

  const data = useMemo(() => {
    const { usage, capacity, allocated } = metricData;

    const usageData = transformDataPoints(usage, "usage");
    const capacityData = transformDataPoints(capacity, "capacity");
    const allocatedData = transformDataPoints(allocated, "allocated");

    const mergedData = mergeMetrics({
      usage: usageData,
      capacity: capacityData,
      allocated: allocatedData,
    });

    return mergedData;
  }, [metricData]);

  return (
    <MetricsContainer title={title}>
      <GraphContainerWithStatus {...graphContainerProps}>
        <MetricsGraphContainer
          tickFormatter={(tick: string) => formatFunc(tick)}
          ariaLabels={{
            Name: `${metric}Graph`,
            Yaxis: `${metric} y-axis`,
            Xaxis: `${metric} x-axis`,
            Graph: `${metric} Graph`,
          }}
          label={showLabel ? label : undefined}
          data={data}
          timelineTicksNum={4}
          disableZoom={true}
          tooltipContent={(tooltipProps) => (
            <Tooltip
              tooltipProps={tooltipProps}
              dataTransformFunction={(tick: string) => formatTick(tick)}
              tooltipItems={tooltipItems}
            />
          )}
        >
          <>
            {
              <Legend
                align="center"
                content={<LegendContent items={legendItems} />}
              />
            }
            {metricsLines()}
          </>
        </MetricsGraphContainer>
      </GraphContainerWithStatus>
    </MetricsContainer>
  );
};
