import Tab from "@mui/material/Tab";
import { useCallback, useEffect } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import styled from "styled-components";

import { ServicesComparisonTabs } from "./constants";
import { AttributesComparison } from "./Attributes/AttributesComparison";
import { MetricsComparisonTabContent } from "./Metrics/MetricsComparisonTabContent";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import { WorkloadMetricsTabContextProvider } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/WorkloadMetricsTabContext";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { SERVICES_DRIFT_TYPE_TAB_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
const EagerTabPanel = styled.div<{ selected: boolean }>`
  padding: 24px 0;
  display: ${({ selected }) => (selected ? "block" : "none")};
`;
export const ComparisonTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] =
    useStringifiedStateInSearchParams<ServicesComparisonTabs>(
      SERVICES_DRIFT_TYPE_TAB_PARAM_KEY
    );

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(ServicesComparisonTabs.Attributes, true);
    }
  }, [selectedTab, setSelectedTab]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: ServicesComparisonTabs) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  return selectedTab ? (
    <TabContext value={selectedTab}>
      <TabList onChange={handleChange}>
        <Tab
          aria-label={AriaLabels.DriftAnalysis.AttributesTab.Tab}
          key={ServicesComparisonTabs.Attributes}
          label={"Attributes Comparison"}
          value={ServicesComparisonTabs.Attributes}
        />
        <Tab
          aria-label={AriaLabels.DriftAnalysis.MetricsTab.Tab}
          key={ServicesComparisonTabs.Metrics}
          label={"Metrics Comparison"}
          value={ServicesComparisonTabs.Metrics}
        />
      </TabList>
      <EagerTabPanel
        selected={selectedTab === ServicesComparisonTabs.Attributes}
      >
        <DatadogReportLoadingTimeContextProvider
          viewOptions={{
            name: DatadogViewNamesEnum.serviceDriftAnalysisAttributesTab,
            context: {
              feTeam: "troubleshooting",
              beTeam: "troubleshooting",
            },
          }}
        >
          <AttributesComparison />
        </DatadogReportLoadingTimeContextProvider>
      </EagerTabPanel>
      <EagerTabPanel selected={selectedTab === ServicesComparisonTabs.Metrics}>
        <WorkloadMetricsTabContextProvider>
          <DatadogReportLoadingTimeContextProvider
            viewOptions={{
              name: DatadogViewNamesEnum.serviceDriftAnalysisMetricsTab,
              context: {
                feTeam: "troubleshooting",
                beTeam: "troubleshooting",
              },
            }}
          >
            <MetricsComparisonTabContent />
          </DatadogReportLoadingTimeContextProvider>
        </WorkloadMetricsTabContextProvider>
      </EagerTabPanel>
    </TabContext>
  ) : null;
};
