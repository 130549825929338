import React, { memo, useEffect, useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {
  AdvancedMultiSelect,
  IntegrationCard,
  MuiSelectionOption,
  SearchField,
} from "@komodorio/design-system/komodor-ui";

import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useInstallations } from "@/pages/organization-settings/configurations/useInstallations";
import { CenteredLoader } from "@/shared/components/CenteredLoader";
import { Install } from "@/components/integrations/management/available/Install";
import { Integration } from "@/components/integrations/management/definitions";
import { RemoveIntegrationModal } from "@/components/integrations/management/installed/RemoveIntegrationButton";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const INSTALLATION_STATE_OPTIONS = [
  { label: "Installed Integration", value: 1 },
  { label: "Not Installed", value: 0 },
];

const IntegrationsPageComponent: React.FC = memo(() => {
  const { availableIntegrationTypes = { types: [] } } = useOverridableFlags();
  const [installationState, setInstallationState] = React.useState<
    MuiSelectionOption<number>[]
  >([]);
  const [integrationToDelete, setIntegrationToDelete] = useState<string | null>(
    null
  );

  const {
    getInstallationByType,
    fetching,
    filterIntegrations,
    searchTerm,
    availableIntegrations,
    installedIntegrations,
    sortByInstallationStatus,
    installations,
  } = useInstallations();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState("isFetchingIntegrations", fetching);
  }, [fetching, reportLoadingState]);

  const content = useMemo(() => {
    const typesAvailableByFF =
      (availableIntegrationTypes as { types: string[] })?.types || [];
    return availableIntegrations
      .filter(
        ([integrationType]: [
          integrationType: string,
          integration: Integration
        ]) => {
          return typesAvailableByFF.includes(integrationType);
        }
      )
      .filter(
        ([integrationType]: [
          integrationType: string,
          integration: Integration
        ]) => {
          // This select has only 2 options.  having them both checked or unchecked is the same.
          if (
            !installationState?.length ||
            installationState.length === INSTALLATION_STATE_OPTIONS.length
          ) {
            return true;
          }

          if (installationState[0].value) {
            return installedIntegrations.has(integrationType);
          }

          return !installedIntegrations.has(integrationType);
        }
      )
      .sort(sortByInstallationStatus)
      .map(([integrationType, integration]) => {
        const isInstalled = installedIntegrations.has(integrationType);

        const CTA = (
          <Install
            integration={integration}
            isInstalled={isInstalled}
            newTab={true}
          />
        );

        const installation = getInstallationByType(integrationType);

        return (
          <Grid item key={integration.title} sx={{ flexShrink: 0 }}>
            <IntegrationCard
              Logo={integration.logo}
              {...integration}
              logoSize={50}
              isInstalled={isInstalled}
              installationDetails={installation}
              InstallCTA={CTA}
              canUninstall={integration.canDelete}
              onUninstall={() => setIntegrationToDelete(integrationType)}
              documentationLink={integration.docsLink}
            />
            {installation && (
              <RemoveIntegrationModal
                installation={installation}
                show={integrationToDelete === integrationType}
                handleClose={() => setIntegrationToDelete(null)}
              />
            )}
          </Grid>
        );
      });
  }, [
    availableIntegrationTypes,
    sortByInstallationStatus,
    installationState,
    availableIntegrations,
    getInstallationByType,
    installedIntegrations,
    setIntegrationToDelete,
    integrationToDelete,
  ]);

  if (fetching || !installations) {
    return (
      <SettingsViewVerticalLayout title="Integrations">
        <CenteredLoader />
      </SettingsViewVerticalLayout>
    );
  }

  return (
    <SettingsViewVerticalLayout title="Integrations">
      <Stack direction="column" rowGap={2}>
        <Stack direction="row" columnGap={1}>
          <SearchField
            value={searchTerm}
            onSearch={filterIntegrations}
            placeholder={"Search"}
            showCloseIcon={true}
            ariaLabel={"Search integrations"}
            label={"Search Integrations"}
          />
          <AdvancedMultiSelect
            options={INSTALLATION_STATE_OPTIONS}
            value={installationState}
            placeholder="Select installation status"
            width={"250px"}
            onChange={setInstallationState}
            ariaLabel={"Select installation status"}
            enableSearch={false}
            enableSelectAll={false}
            label={"Installation Status"}
          />
        </Stack>
        <Grid container columns={4} columnGap={2} rowGap={2}>
          {content}
        </Grid>
      </Stack>
    </SettingsViewVerticalLayout>
  );
});

export const IntegrationsPage: React.FC = memo(() => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.integrationsPage,
        context: {
          feTeam: "barzelim",
          beTeam: "barzelim",
        },
      }}
    >
      <IntegrationsPageComponent />;
    </DatadogReportLoadingTimeContextProvider>
  );
});
