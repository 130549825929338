import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { ActionButtonProps } from "./types";

import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { DRIFT_ANALYSIS_ROUTE } from "@/components/routes/routes";
import {
  BASELINE_DRIFT_OPTION_KEY,
  COMPARED_DRIFT_OPTION_KEY,
} from "@/components/DriftAnalysis/common/constants";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { initialDriftComparisonState } from "@/components/DriftAnalysis/context/initialState";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { closeAllDrawersSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { AriaLabels } from "@/shared/config/ariaLabels";
import HelmChart, {
  HELM_CHART_KIND,
} from "@/components/Inspection/Helm/helmChart";
import { DriftType } from "@/components/DriftAnalysis/context/types";
import { DriftAnalysisTabType } from "@/components/DriftAnalysis/types";
import { DRIFT_ANALYSIS_TAB_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import Resource from "@/components/ResourceView/resources";
import { useWorkspaces } from "@/components/workspaces/WorkspacesTopBar/hooks";
import { buildOptionForHelmRelease } from "@/components/DriftAnalysis/Helm/selectionOptionsUtils";

export const DetectDriftButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
}) => {
  const { driftDetection, helmDrift } = useOverridableFlags();
  const navigate = useNavigate();
  const closeAllDrawers = useDrawersStackStore(closeAllDrawersSelector);
  const { driftTabParam, baselineOption, driftType } =
    useDriftAnalysisParams(resource);
  const searchParams = new URLSearchParams({
    [DRIFT_ANALYSIS_TAB_PARAM_KEY]: JSON.stringify(driftTabParam),
    [BASELINE_DRIFT_OPTION_KEY + driftType]: JSON.stringify(baselineOption),
    [COMPARED_DRIFT_OPTION_KEY + driftType]: JSON.stringify(
      initialDriftComparisonState.comparedSelections
    ),
  });
  const {
    isWorkspaceKindBackendFiltered: isWorkspaceSupported,
    currentWorkspace,
    isLoading,
  } = useWorkspaces();

  // Show helm drift button if:
  // 1. driftType is Helm
  // 2. helmDrift flag is enabled
  // 3. the workspace is supported or undefined (All Clusters) and not loading
  const showHelmDriftButton = useMemo(
    () =>
      driftType === DriftType.Helm &&
      !!helmDrift &&
      (isWorkspaceSupported || (!currentWorkspace && !isLoading)),
    [driftType, helmDrift, isWorkspaceSupported, currentWorkspace, isLoading]
  );

  const showDriftButton = useMemo(
    () =>
      driftDetection &&
      (driftType === DriftType.Services || showHelmDriftButton),
    [driftDetection, driftType, showHelmDriftButton]
  );

  return showDriftButton ? (
    <Button
      ariaLabel={AriaLabels.DriftAnalysis.DetectDriftActionButton}
      disabled={false}
      onClick={() => {
        closeAllDrawers();
        navigate(
          {
            pathname: DRIFT_ANALYSIS_ROUTE,
            search: searchParams.toString(),
          },
          { replace: true }
        );
      }}
    />
  ) : null;
};

const useDriftAnalysisParams = (resource: Resource) => {
  const driftType =
    resource.kind === HELM_CHART_KIND ? DriftType.Helm : DriftType.Services;
  let driftTabParam: DriftAnalysisTabType;
  let baselineOption: MuiSelectionOption<string>;

  switch (driftType) {
    case DriftType.Helm: {
      const helmRelease = resource as HelmChart;
      driftTabParam = DriftAnalysisTabType.HelmReleases;
      baselineOption = buildOptionForHelmRelease({
        name: helmRelease.name,
        chart: helmRelease.appVersion,
        clusterName: helmRelease.cluster,
        namespace: helmRelease.namespace,
        revision: Number(helmRelease.currentRevision),
      });
      break;
    }
    default: {
      const komodorUid = buildKomodorUid({
        kind: resource.kind,
        namespace: resource.namespace,
        resourceName: resource.name,
        clusterName: resource.cluster,
      });
      driftTabParam = DriftAnalysisTabType.Services;
      baselineOption = {
        label: komodorUid,
        value: komodorUid,
      };
    }
  }

  return useMemo(
    () => ({
      driftTabParam,
      baselineOption,
      driftType,
    }),
    [driftTabParam, baselineOption, driftType]
  );
};
