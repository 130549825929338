import React from "react";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";
import { Addon, Entity } from "@/generated/addonsApi";
import { AccordionContentItemContainer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { LabeledData } from "@/components/common/LabeledData/LabeledData";
import { getAutoscalerConfigLabels } from "@/components/k8sAddons/addons/NodeAutoscalers/AutoscalerTab/getAutoscalerConfigLabels";
import { LabeledContent } from "@/components/common/LabeledData/LabeledContent";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { LabeledDataWithTooltip } from "@/components/common/LabeledData/LabeledDataWithTooltip";
import { nodeAutoscalersPageAriaLabels } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/autoscalersAriaLabels";
import { TooltipType } from "@/components/common/LabeledData/LabeledContent.types";
import { ErrorBanner } from "@/components/common/Error/ErrorBanner";

type AutoscalerConfigProps = {
  komodorUid: string;
  resourceName: string;
  clusterName: string;
};

export const LOADING_ERROR_MESSAGE =
  "We were unable to get the Autoscaler configuration data";

export const AutoscalerConfigView: React.FC<AutoscalerConfigProps> = ({
  komodorUid: komodorId,
  resourceName,
  clusterName,
}) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const pushNodePoolDrawer = (nodePoolName: string) => {
    pushDrawer({
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: clusterName,
      namespace: "",
      resourceType: "nodepools.karpenter.sh",
      resourceName: nodePoolName,
      buildPreloadResource: true,
      additionalData: {
        isCustomResource: true,
      },
    });
  };
  const { data, isFetching, error, refetch } = useGetAddonFullLiveState(
    {
      addon: Addon.AutoScalers,
      entity: Entity.AutoScaler,
      uid: komodorId,
      name: resourceName,
      getEntityRequest: {
        clusterName,
      },
    },
    (json) => json,
    { enabled: clusterName !== "" }
  );

  if (error) {
    return (
      <ErrorBanner onRetryClicked={refetch} text={LOADING_ERROR_MESSAGE} />
    );
  }
  const fetchedData = data?.data.data;
  if (isFetching || fetchedData == null) {
    return <LinesLoader />;
  }

  if (fetchedData.type !== "AutoScalerFull") {
    return null;
  }

  const config = getAutoscalerConfigLabels(fetchedData, {
    onNodePoolClick: pushNodePoolDrawer,
  });
  return config.map((item) => {
    return (
      <AccordionContentItemContainer
        key={item.title}
        aria-label={nodeAutoscalersPageAriaLabels.autoscalerTab.configLine}
      >
        {item.tooltip ? (
          <LabeledDataWithTooltip
            title={item.title}
            tooltip={{ title: item.tooltip, type: TooltipType.Info }}
          />
        ) : (
          <LabeledData title={item.title} />
        )}
        <LabeledContent content={item.data} />
      </AccordionContentItemContainer>
    );
  });
};
