export const ariaLabels = {
  table: {
    actionsCell: {
      container: "Actions",
      view: "View Policy",
      edit: "Edit Policy",
      delete: "Delete Policy",
    },
  },
};
