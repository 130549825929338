import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { useCallback, useEffect, useMemo } from "react";

import { SHOW_ONLY_DRIFT_HELM_KEY } from "../Helm/constants";

import { isOptionDisabled } from "./selectionUtils";
import { BreadcrumbWrapper } from "./BreadcrumbWrapper";
import { SelectionTooltipLabel } from "./SelectionTooltipLabel";

import {
  ServiceComparisonResponseTargetServices,
  HelmComparisonResponseTargetAttributes,
} from "@/generated/workspacesApi";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";

export const useComparedOptionsWithDisabled = ({
  selectedComparedOptions,
  comparedOptions,
  maxCompared,
}: {
  selectedComparedOptions: MuiSelectionOption<string>[] | null;
  comparedOptions: MuiSelectionOption<string>[];
  maxCompared: number;
}) => {
  return useMemo(() => {
    return comparedOptions?.map((option) => ({
      ...option,
      disabled: isOptionDisabled({
        option,
        selectedComparedOptions,
        maxCompared,
      }),
    }));
  }, [comparedOptions, selectedComparedOptions, maxCompared]);
};

export const useSelectedComparedOptionsValues = (
  selectedComparedOptions: MuiSelectionOption<string>[] | null
) => {
  return useMemo(() => {
    return selectedComparedOptions?.map(
      ({ value, label }) =>
        ({
          value: value,
          label: label,
          tooltip: <SelectionTooltipLabel value={label} />,
          render: () => <BreadcrumbWrapper value={label} />,
        } as MuiSelectionOption<string>)
    );
  }, [selectedComparedOptions]);
};

export const useShowOnlyDrift = (
  key: string,
  targetElements:
    | ServiceComparisonResponseTargetServices[]
    | HelmComparisonResponseTargetAttributes[]
    | undefined
) => {
  const [showOnlyDrift, setShowOnlyDrift] =
    useStringifiedStateInSearchParams<boolean>(key);
  const isDriftDetected = useMemo(() => {
    return (
      targetElements?.some((targetComparison) => targetComparison.isDiff) ??
      false
    );
  }, [targetElements]);

  useEffect(() => {
    if (showOnlyDrift && !isDriftDetected) {
      setShowOnlyDrift(false);
    }
  }, [isDriftDetected, setShowOnlyDrift, showOnlyDrift]);

  const showOnlyDriftTooltipTitle = useMemo(() => {
    if (isDriftDetected) return "";
    if (!targetElements?.length) {
      return key === SHOW_ONLY_DRIFT_HELM_KEY
        ? "No helm releases to compare"
        : "No services to compare";
    }
    return "No drift detected";
  }, [isDriftDetected, key, targetElements]);

  const onShowOnlyDriftChange = useCallback(
    (checked: boolean) => {
      setShowOnlyDrift(checked);
      dispatchEvent(AnalyticEvents.DriftAnalysis.ShowOnlyDriftSwitcher, {
        checked,
      });
    },
    [setShowOnlyDrift]
  );
  return {
    showOnlyDriftTooltipTitle,
    isDriftDetected,
    showOnlyDrift,
    onShowOnlyDriftChange,
  };
};
