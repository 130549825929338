import { Stack, Typography } from "@mui/material";
import { KomobotEmptyState } from "assets";

interface Props {
  title?: string | React.ReactNode;
  icon?: React.ReactNode;
}

const EmptyState: React.FC<Props> = ({ icon, title }) => {
  return (
    <Stack direction="column" alignItems="center" rowGap={2}>
      {icon ?? <KomobotEmptyState />}

      {typeof title === "string" ? (
        <Typography variant="h4">{title}</Typography>
      ) : (
        title
      )}
    </Stack>
  );
};

export { EmptyState };
