import React from "react";
import styled from "styled-components";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";

import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import {
  useLoadingColumns,
  useTableColumns,
} from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/hooks/nodeAutoscalerTableHooks";
import { useCommonTableProps } from "@/components/k8sAddons/hooks/table/useCommonTableProps";
import { useCommonLiveStateTableProps } from "@/components/k8sAddons/hooks/table/useCommonLiveStateTableProps";
import { nodeAutoscalersPageAriaLabels } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/autoscalersAriaLabels";
import {
  PageTableContainer,
  StyledLiveStateListDataGrid,
} from "@/components/k8sAddons/styles";
import { useCommonDataGridProps } from "@/components/k8sAddons/hooks/table/useCommonDataGridProps";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { SupportedResourcesKinds } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { K8S_ADD_ONS_AVOID_SHOWING_STATUSES } from "@/shared/config/urlSearchParamsKeys";

const TopSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const NodeAutoscalersTable: React.FC = () => {
  const response = useFetchLiveStateList({});
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const { isFetchingData, rowCount, rowsToUse, columnsToUse, title, error } =
    useCommonTableProps({
      response,
      loadingStateColumns: useLoadingColumns(),
      dataStateColumns: useTableColumns(),
      tableTitle: "Autoscalers",
    });

  const commonLiveStateTableProps =
    useCommonLiveStateTableProps(isFetchingData);

  const ariaLabel = isFetchingData
    ? `${nodeAutoscalersPageAriaLabels.table} loading`
    : `${nodeAutoscalersPageAriaLabels.table} content`;

  return (
    <PageTableContainer>
      <TopSection>
        <Typography variant="h4">{title}</Typography>
      </TopSection>
      <StyledLiveStateListDataGrid
        {...useCommonDataGridProps(error)}
        {...commonLiveStateTableProps}
        aria-label={ariaLabel}
        rows={rowsToUse ?? []}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowCount}
        getRowId={(row) => row.id}
        onRowClick={(row) => {
          const { row: data } = row;
          pushDrawer({
            drawerType: DrawerType.ResourceDrawerByData,
            cluster: data.clusterName,
            resourceName: data.name,
            resourceType: SupportedResourcesKinds.KubernetesDeploymentResource,
            urlStates: {
              [K8S_ADD_ONS_AVOID_SHOWING_STATUSES]: true,
            },
          });
        }}
      />
    </PageTableContainer>
  );
};
