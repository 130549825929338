import { DriftComparisonState } from "./types";

export const initialDriftComparisonState: DriftComparisonState = {
  baselineSelection: null,
  comparedSelections: [],
  isBaselineSelectorOpen: false,
  isComparedSelectorOpen: false,
  setBaselineSelection: () => undefined,
  setComparedSelections: () => undefined,
  setIsBaselineSelectorOpen: () => undefined,
  setIsComparedSelectorOpen: () => undefined,
};
