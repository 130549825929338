import { useMemo, useState } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { useListAgents } from "@/shared/hooks/agents-api/agents/useListAgents";
import { mapObjectsToOptions } from "@/shared/utils/selectInputOptions";
import { useAgentsContext } from "@/shared/hooks/agents/context/useAgentsContext";

type Option = {
  label: string;
  value: string;
};

export const useAgents = () => {
  const [selectedClusters, setSelectedClusters] = useState<
    MuiSelectionOption<string>[]
  >([]);
  const [selectedApiKey, setSelectedApiKey] = useState<string>("");
  const [showInactiveAgents, setShowInactiveAgents] = useState(false);

  const selectedClustersNames = useMemo(
    () =>
      selectedClusters.map(
        (cluster: MuiSelectionOption<string>) => cluster.value
      ),
    [selectedClusters]
  );

  const {
    data: agentsList,
    isFetching,
    error,
    refetch,
  } = useListAgents({
    params: {
      clusterNames: selectedClustersNames,
      inactive: showInactiveAgents ? undefined : false,
    },
  });

  const agentsCTX = useAgentsContext();

  const { allAgents, activeAgents } = useMemo(() => {
    const activeAgents = agentsCTX?.activeAgents?.length
      ? agentsCTX.activeAgents
      : [];
    const inactiveAgents = agentsCTX?.inactiveAgents?.length
      ? agentsCTX.inactiveAgents
      : [];
    const allAgents = [...activeAgents, ...inactiveAgents];
    return { activeAgents, inactiveAgents, allAgents };
  }, [agentsCTX?.activeAgents, agentsCTX?.inactiveAgents]);

  const clusters = useMemo(() => {
    const agentsToUse = showInactiveAgents ? allAgents : activeAgents;
    return mapObjectsToOptions(agentsToUse, "clusterName") as Option[];
  }, [allAgents, activeAgents, showInactiveAgents]);

  const handleChangeApiKeyFilter = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedApiKey(e.target.value);

  const filteredAgents = useMemo(() => {
    if (!selectedApiKey) {
      return agentsList;
    }

    return agentsList?.filter((agent) =>
      agent.installationId.toLowerCase().includes(selectedApiKey.toLowerCase())
    );
  }, [agentsList, selectedApiKey]);

  return {
    isFetching,
    error,
    allAgents,
    clusters,
    refetch,
    filteredAgents,
    selectedClusters,
    handleSelectClusters: setSelectedClusters,
    selectedApiKey,
    handleSelectApiKey: handleChangeApiKeyFilter,
    showInactiveAgents,
    setShowInactiveAgents,
  };
};
