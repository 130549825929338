import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { AxiosResponse } from "axios";
import { UseQueryResult } from "@tanstack/react-query";

import { GetViolationsResponse } from "@/generated/reliabilityApi";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { ViolationsTable } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/ViolationsTable/ViolationsTable";
import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";

export const MIN_NUM_OF_VIOLATIONS_TO_SHOW_FOOTER = 6;

const containerAriaLabel =
  reliabilityArialLabels.violationsTable.violationsTableWithTitleAndFooter;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type PaginationConfig = {
  page: number;
  pageSize: number;
  setPaginationModel: (newPaginationModel: {
    page: number;
    pageSize: number;
  }) => void;
};

export type ViolationTableWithTitleAndFooterProps = {
  violations: UseQueryResult<AxiosResponse<GetViolationsResponse>, unknown>;
  paginationConfig: PaginationConfig;
  title?: string;
  violationTableType: ViolationsTableType;
};

export function ViolationTableWithTitleAndFooter({
  violations,
  title,
  violationTableType,
  paginationConfig,
}: ViolationTableWithTitleAndFooterProps) {
  const violationsCount = violations?.data?.data?.violations.length ?? 0;

  return (
    <Container aria-label={containerAriaLabel}>
      {title && <Typography variant="h4"> {title} </Typography>}
      <ViolationsTable
        violationsRes={violations}
        customPaginationObj={{
          customPaginationModel: paginationConfig,
          setCustomPaginationModel: paginationConfig.setPaginationModel,
        }}
        violationTableType={violationTableType}
        hideFooter={violationsCount < MIN_NUM_OF_VIOLATIONS_TO_SHOW_FOOTER}
      />
    </Container>
  );
}
