import { useEffect, useMemo, useState } from "react";
import { ChatMessageType } from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Replay from "@mui/icons-material/Replay";

import { RichSessionData } from "@/components/AiInvestigation/useEnrichedSession";
import { useGetMessagesByRequestId } from "@/shared/hooks/ai-api/client/chat/useGetMessage";
import { usePostMessage } from "@/shared/hooks/ai-api/client/chat/usePostMessage";
import { MessageType } from "@/generated/aiApi";

export const useAiInvestigationChat = ({
  cluster,
  richSession,
}: {
  cluster: string;
  richSession: RichSessionData;
}) => {
  const [input, setInput] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [userMessagePlaceholder, setUserMessagePlaceholder] =
    useState<ChatMessageType>();

  const { data, isError } = useGetMessagesByRequestId(
    {
      requestId: richSession.id,
      cluster,
    },
    {
      enabled: initialLoad || waitingForResponse,
      refetchInterval: 5000,
      keepPreviousData: true,
    }
  );

  const chatSessionId = data?.chatData?.id;
  const { mutateAsync: postMessage } = usePostMessage({
    chatInput: {
      requestId: richSession.id,
      cluster,
      parentSessionId: richSession.id,
      userMessage: "",
    },
    sessionId: chatSessionId,
  });

  useEffect(() => {
    if (data?.chatData?.isComplete) {
      setWaitingForResponse(false);
      setUserMessagePlaceholder(undefined);
    }
  }, [data?.chatData]);

  useEffect(() => {
    if (initialLoad && (data || isError)) {
      setInitialLoad(false);
    }
  }, [data, initialLoad, isError]);

  const messages = useMemo(() => {
    const messagesFromServer = (
      data?.chatData?.chatMessages ?? []
    ).map<ChatMessageType>((message, i) => ({
      id: i,
      content:
        message.content +
        message.documents
          ?.map((doc) => `\n\n${doc.name}:\n\n${doc.content}`)
          .join(""),
      isUser: message.type === MessageType.Human,
    }));
    if (
      userMessagePlaceholder &&
      messagesFromServer.length <= Number(userMessagePlaceholder.id)
    ) {
      messagesFromServer.push(userMessagePlaceholder);
    }
    return messagesFromServer;
  }, [data?.chatData?.chatMessages, userMessagePlaceholder]);

  const handleSendMessage = async () => {
    if (!input.trim()) return;
    setUserMessagePlaceholder({
      id: messages.length,
      content: input,
      isUser: true,
    });
    setInput("");
    try {
      await postMessage(input);
    } catch (e) {
      setUserMessagePlaceholder({
        id: messages.length,
        content: (
          <Box
            display="inherit"
            alignItems="inherit"
            columnGap="inherit"
            paddingY="4px"
          >
            {input}
            <IconButton size="small" onClick={handleSendMessage}>
              <Replay color="action" fontSize="small" />
            </IconButton>
          </Box>
        ),
        isUser: true,
        isFailed: true,
      });
      return;
    }
    setWaitingForResponse(true);
  };

  const isResponseFailed =
    isError || data?.chatData?.isFailed || data?.chatData?.isStuck;

  const shouldDisableInput =
    isResponseFailed ||
    waitingForResponse ||
    userMessagePlaceholder !== undefined;

  return {
    messages,
    handleSendMessage,
    waitingForResponse,
    isResponseFailed,
    chatSessionId,
    input,
    setInput,
    shouldDisableInput,
    shouldAutoScroll: !initialLoad,
  };
};
