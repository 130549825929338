/* eslint-disable max-lines */
import React, { ComponentProps, useMemo } from "react";
import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {
  StyledNumberTextField,
  StyledSingleSelectContainer,
} from "../PolicyDrawerStyles";
import { InputAdornmentWithTypography } from "../PolicyDrawerConfigurations/InputAdornmentWithTypography";
import { getSuffixByCheckType } from "../utils/getSuffixByCheckType";

import { useOnControlValueChange } from "./useOnControlValueChange";

import { CheckType, Severity } from "@/generated/reliabilityApi";
import { useGetIsFieldStateInvalid } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useGetIsFieldStateInvalid";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { kubernetesEndOfLifeOptions } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/policyDrawerUtils";

const {
  getControlContainer: getControlContainerAriaLabel,
  getSelectControl,
  getInputControl,
} = reliabilityArialLabels.policies.policiesDrawer;

type GetConfigurationControlsProps = {
  values: string[];
  configurationNames: string[];
  checkType: CheckType;
  severity: Severity;
  disabled: boolean;
};

export const useConfigurationControls = ({
  checkType,
  severity,
  configurationNames,
  values,
  disabled,
}: GetConfigurationControlsProps): JSX.Element => {
  const setValue = useOnControlValueChange({ checkType, values, severity });
  const getIsFieldStateInvalid = useGetIsFieldStateInvalid(configurationNames);

  const suffixes = getSuffixByCheckType(checkType);

  return useMemo(() => {
    switch (checkType) {
      case CheckType.ThrottledCpu:
      case CheckType.HpaMax:
        return (
          <div style={{ width: "100px" }} {...addControlAriaLabel(checkType)}>
            <StyledNumberTextField
              {...getCommonInputFieldProps({
                setValue,
                configurationName: configurationNames[0],
                disabled,
              })}
              value={values[0] ?? ""}
              min={0}
              max={100}
              InputProps={{
                endAdornment: (
                  <InputAdornmentWithTypography value={suffixes[0]} />
                ),
                "aria-label": getInputControl(configurationNames[0]),
              }}
              disabled={disabled}
              error={getIsFieldStateInvalid(0)}
            />
          </div>
        );
      case CheckType.RestartingContainers:
      case CheckType.NoisyNeighbor:
        return (
          <div style={{ width: "100px" }} {...addControlAriaLabel(checkType)}>
            <StyledNumberTextField
              {...getCommonInputFieldProps({
                setValue,
                configurationName: configurationNames[0],
                disabled,
              })}
              InputProps={{
                "aria-label": getInputControl(configurationNames[0]),
              }}
              value={values[0] ?? ""}
              error={getIsFieldStateInvalid(0)}
            />
          </div>
        );
      case CheckType.DeprecatedApis:
        return (
          <Box
            display="flex"
            gap={"16px"}
            alignItems={"center"}
            {...addControlAriaLabel(checkType)}
          >
            <div style={{ width: "140px" }}>
              <StyledSingleSelectContainer
                isInvalid={getIsFieldStateInvalid(0)}
                aria-label={getSelectControl(configurationNames[0])}
              >
                <SingleSelect
                  {...getCommonSingleSelectFieldProps({
                    setValue,
                    configurationName: configurationNames[0],
                    disabled,
                  })}
                  value={{ value: values[0], label: values[0] }}
                  options={deprecatedApisStateOptions}
                />
              </StyledSingleSelectContainer>
            </div>
            <Typography variant={"body2"}>{suffixes[0]}</Typography>
            <StyledSingleSelectContainer
              style={{ width: "90px" }}
              isInvalid={getIsFieldStateInvalid(1)}
              aria-label={getSelectControl(configurationNames[1])}
            >
              <SingleSelect
                {...getCommonSingleSelectFieldProps({
                  setValue,
                  configurationName: configurationNames[1],
                  disabled,
                })}
                value={{ value: values[1], label: values[1] }}
                options={deprecatedApisVersionsAheadOptions}
              />
            </StyledSingleSelectContainer>
            <Typography variant={"body2"}>{suffixes[1]}</Typography>
          </Box>
        );
      case CheckType.UnderProvisionedWorkloads:
        return (
          <Box display="flex" gap={"8px"} {...addControlAriaLabel(checkType)}>
            <div style={{ width: "150px" }}>
              <StyledNumberTextField
                {...getCommonInputFieldProps({
                  setValue,
                  configurationName: configurationNames[0],
                  disabled,
                })}
                value={values[0] ?? ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornmentWithTypography value={suffixes[0]} />
                  ),
                  "aria-label": getInputControl(configurationNames[0]),
                }}
                error={getIsFieldStateInvalid(0)}
              />
            </div>
            <Typography variant="body2">{suffixes[1]}</Typography>
            <div style={{ width: "150px" }}>
              <StyledNumberTextField
                {...getCommonInputFieldProps({
                  setValue,
                  configurationName: configurationNames[1],
                  disabled,
                })}
                value={values[1] ?? ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornmentWithTypography value={suffixes[2]} />
                  ),
                  "aria-label": getInputControl(configurationNames[1]),
                }}
                error={getIsFieldStateInvalid(1)}
              />
            </div>
          </Box>
        );
      case CheckType.KubernetesEndOfLife:
        return (
          <StyledSingleSelectContainer
            style={{ width: "140px" }}
            isInvalid={getIsFieldStateInvalid(0)}
            {...addControlAriaLabel(checkType)}
          >
            <SingleSelect
              value={{
                value: values[0],
                label:
                  kubernetesEndOfLifeOptions.find(
                    (option) => option.value === values[0]?.toString()
                  )?.label ?? "",
              }}
              {...getCommonSingleSelectFieldProps({
                setValue,
                configurationName: configurationNames[0],
                disabled,
              })}
              options={kubernetesEndOfLifeOptions}
            />
          </StyledSingleSelectContainer>
        );
      case CheckType.SinglePointOfFailure:
      case CheckType.ExternalDnsNotSynced:
        return (
          <Box display="flex" gap={"8px"} {...addControlAriaLabel(checkType)}>
            <div style={{ width: "100px" }}>
              <StyledNumberTextField
                {...getCommonInputFieldProps({
                  setValue,
                  configurationName: configurationNames[0],
                  disabled,
                })}
                InputProps={{
                  "aria-label": getInputControl(configurationNames[0]),
                }}
                value={values[0] ?? ""}
                error={getIsFieldStateInvalid(0)}
              />
            </div>
            <Typography variant={"body2"}>{suffixes[0]}</Typography>
          </Box>
        );
      case CheckType.CertificateExpiration:
        return (
          <Box display="flex" gap={"8px"} {...addControlAriaLabel(checkType)}>
            <div style={{ width: "100px" }}>
              <StyledNumberTextField
                {...getCommonInputFieldProps({
                  setValue,
                  configurationName: configurationNames[0],
                  disabled,
                })}
                InputProps={{
                  "aria-label": getInputControl(configurationNames[0]),
                }}
                value={values[0] ?? ""}
                error={getIsFieldStateInvalid(0)}
              />
            </div>
            <Typography variant={"body2"}>{suffixes[0]}</Typography>
          </Box>
        );
      default:
        return <div />;
    }
  }, [
    checkType,
    configurationNames,
    disabled,
    getIsFieldStateInvalid,
    setValue,
    suffixes,
    values,
  ]);
};

type GetCommonInputFieldProps = {
  setValue: (name: string, value: string) => void;
  configurationName: string;
  disabled?: boolean;
};

const getCommonInputFieldProps = ({
  configurationName,
  setValue,
  disabled,
}: GetCommonInputFieldProps) => ({
  allowNegative: false,
  allowDecimal: false,
  max: 99999999,
  disabled,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(configurationName, e.target.value),
});

const getCommonSingleSelectFieldProps = ({
  configurationName,
  setValue,
  disabled,
}: GetCommonInputFieldProps) => ({
  disabled,
  size: "small" as ComponentProps<typeof SingleSelect>["size"],
  classNames: { dropDownTriggerField: "dropDownTriggerField" },
  onChange: (
    selectedOption?: MuiSelectionOption<MuiSelectionOptionValue> | undefined
  ) => {
    if (selectedOption) {
      setValue(configurationName, selectedOption.value as string);
    }
  },
});

const deprecatedApisStateOptions = [
  { label: "Removed", value: "Removed" },
  { label: "Deprecated", value: "Deprecated" },
];

const deprecatedApisVersionsAheadOptions = Array.from({ length: 5 }).map(
  (_, i) => ({
    label: `${i + 1}`,
    value: `${i + 1}`,
  })
);

const addControlAriaLabel = (checkType: CheckType) => ({
  ["aria-label"]: getControlContainerAriaLabel(checkType),
});
