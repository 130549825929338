import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import React, { createContext, useState } from "react";

import {
  BASELINE_DRIFT_OPTION_KEY,
  COMPARED_DRIFT_OPTION_KEY,
} from "../common/constants";

import { initialDriftComparisonState } from "./initialState";
import { DriftComparisonState, DriftType } from "./types";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

export const DriftComparisonContext = createContext<DriftComparisonState>(
  initialDriftComparisonState
);

export const DriftComparisonContextProvider: React.FC<{
  driftType: DriftType;
  children: React.ReactNode;
}> = ({ children, driftType }) => {
  const [baselineSelection, setBaselineSelection] =
    useStringifiedStateInSearchParams<MuiSelectionOption<string>>(
      BASELINE_DRIFT_OPTION_KEY + driftType
    );
  const [comparedSelections, setComparedSelections] =
    useStringifiedStateInSearchParams<MuiSelectionOption<string>[]>(
      COMPARED_DRIFT_OPTION_KEY + driftType
    );

  const [isBaselineSelectorOpen, setIsBaselineSelectorOpen] =
    useState<boolean>(false);
  const [isComparedSelectorOpen, setIsComparedSelectorOpen] =
    useState<boolean>(false);

  const contextState: DriftComparisonState = {
    baselineSelection,
    comparedSelections,
    isBaselineSelectorOpen,
    isComparedSelectorOpen,
    setBaselineSelection,
    setComparedSelections,
    setIsBaselineSelectorOpen,
    setIsComparedSelectorOpen,
  };

  return (
    <DriftComparisonContext.Provider value={contextState}>
      {children}
    </DriftComparisonContext.Provider>
  );
};
