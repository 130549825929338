import { muiColors } from "@komodorio/design-system";
import {
  MuiSelectionOption,
  Compared,
  ComparisonTableProps,
  RowData,
} from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { ListRenderer } from "../../common/ListRenderer";
import { NameField } from "../../common/NameFieldCell";
import { CellContainer } from "../../common/styles";
import { MAX_CONTAINER_NAMES } from "../constants";

import { StyledChipList } from "./styles";
import { ComparisonService, CellRenderer, DiffModal } from "./types";

import {
  DrawerStatePush,
  DrawerType,
} from "@/shared/store/drawersStackStore/types";
import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { ServiceComparisonResponse } from "@/generated/workspacesApi";

const getLabels = (rowData: RowData<ComparisonService>) => {
  const { isBaseline, data } = rowData;
  return Object.entries(
    isBaseline
      ? data.labels ?? ({} as Record<string, string>)
      : data.data?.labels?.value ?? ({} as Record<string, string>)
  ).map(([key, value]) => `${key}=${value}`);
};

const cellRenderer =
  (field: keyof ComparisonService): CellRenderer =>
  (rowData) => {
    const { isBaseline, data } = rowData;
    if (field === "labels") {
      return (
        <CellContainer
          sx={{
            overflow: "hidden",
            boxSizing: "border-box",
            maxHeight: "72px",
          }}
        >
          <StyledChipList
            restrictedDimension="height"
            labels={getLabels(rowData)}
          />
        </CellContainer>
      );
    }

    if (field === "containersNames") {
      const names = isBaseline
        ? data.containersNames
        : data.data?.containersNames?.value;
      return (
        <CellContainer sx={{ height: "100%" }}>
          <ListRenderer
            names={names}
            isBaseline={isBaseline}
            maxItems={MAX_CONTAINER_NAMES}
          />
        </CellContainer>
      );
    }

    if (field === "name") {
      return (
        <NameField
          rowData={rowData}
          field={field}
          removeTargetIdentifierField="komodorUid"
        />
      );
    }

    const centerText = field === "desiredReplicas";
    const value = isBaseline
      ? data[field]
      : data.data?.[field]?.value ??
        (field === "desiredReplicas" ? 0 : undefined);
    return (
      <Typography
        variant="body2"
        color={isBaseline ? muiColors.gray[600] : "text.primary"}
        textAlign={centerText ? "center" : "left"}
      >
        {value}
      </Typography>
    );
  };

const getDrawerData = ({
  data,
  isBaseline,
}: RowData<ComparisonService>): DrawerStatePush => {
  return {
    drawerType: DrawerType.ResourceDrawerByData,
    cluster: isBaseline ? data.cluster : data.data?.cluster?.value ?? "",
    namespace: isBaseline ? data.namespace : data.data?.namespace?.value ?? "",
    resourceType: isBaseline ? data.kind : data.data?.kind?.value ?? "",
    resourceName: isBaseline ? data.name : data.data?.name?.value ?? "",
  };
};

export const getBaselineTableData = ({
  baselineSelection,
  data,
  baselineParsedKomdorUid,
}: {
  baselineSelection: MuiSelectionOption<string> | null;
  data: ServiceComparisonResponse | undefined;
  baselineParsedKomdorUid: KomodorUidParams | undefined;
}): ComparisonService | undefined => {
  return data?.baselineService
    ? {
        komodorUid: baselineSelection?.value ?? "",
        name: data.baselineService.name,
        cluster: data.baselineService.cluster,
        namespace: data.baselineService.namespace,
        kind: baselineParsedKomdorUid?.kind ?? "",
        desiredReplicas: data.baselineService.desiredReplicas
          ? data.baselineService.desiredReplicas.value ?? 0
          : undefined,
        labels: data.baselineService.labels.value,
        containersNames: data.baselineService.containersNames.value,
      }
    : undefined;
};

export const getComparedTableData = ({
  data,
  comparedSelections,
  showOnlyDrift,
  baselineParsedKomdorUid,
}: {
  data: ServiceComparisonResponse | undefined;
  comparedSelections: MuiSelectionOption<string>[] | null;
  showOnlyDrift: boolean;
  baselineParsedKomdorUid: KomodorUidParams | undefined;
}): Compared<ComparisonService>[] => {
  return (
    comparedSelections
      ?.map((uid, i) => {
        const service = data?.targetServices?.[i];
        if (!service) {
          return {
            isLoading: true,
            isMissingData: false,
            data: {
              name: { value: uid.value, isDiff: false },
            },
          };
        }
        return {
          isLoading: false,
          isMissingData: false,
          data: {
            komodorUid: {
              value: uid.value,
              isDiff: false,
            },
            name: { value: service.name, isDiff: false },
            kind: {
              value: baselineParsedKomdorUid?.kind ?? "",
              isDiff: false,
            },

            // TODO - get isDiff value from backend for cluster and namespace
            cluster: { value: service.cluster, isDiff: false },
            namespace: { value: service.namespace, isDiff: false },
            desiredReplicas: service.desiredReplicas,
            labels: service.labels,
            containersNames: service.containersNames,
          },
        };
      })
      .filter((_, i) => {
        if (!showOnlyDrift) {
          return true;
        }
        return data?.targetServices?.[i]?.isDiff;
      }) ?? []
  );
};

export const getTableAttributes = ({
  baselineData,
  data,
  showOnlyDrift,
  pushDrawer,
  setDiffModal,
}: {
  baselineData: ComparisonService | undefined;
  data: ServiceComparisonResponse | undefined;
  showOnlyDrift: boolean;
  pushDrawer: (drawerStatePush: DrawerStatePush) => void;
  setDiffModal: (diffModal: DiffModal | null) => void;
}): ComparisonTableProps<ComparisonService>["attributes"] => {
  return (
    [
      {
        headerName: "Service/Attribute",
        field: "name",
        renderCell: cellRenderer("name"),
        showValueAlways: true,
        onClick: (data) => {
          pushDrawer(getDrawerData(data));
        },
        cellBodyClassName: "name-cell",
      },
      {
        headerName: "Cluster",
        field: "cluster",
        renderCell: cellRenderer("cluster"),
        showValueAlways: true,
      },
      {
        headerName: "Namespace",
        field: "namespace",
        renderCell: cellRenderer("namespace"),
        showValueAlways: true,
      },
      {
        headerName: "Desired Replicas",
        field: "desiredReplicas",
        renderCell: cellRenderer("desiredReplicas"),
        optional: true,
      },
      {
        headerName: "Labels",
        field: "labels",
        hideValueIfDifferent: true,
        renderCell: cellRenderer("labels"),
        onClick: ({ data, isBaseline }) => {
          if (isBaseline) {
            return;
          }
          setDiffModal({
            title: "Labels",
            comparedUid: data.data?.komodorUid?.value ?? "",
            oldObj: baselineData?.labels,
            newObj: data.data?.labels?.value,
          });
        },
      },
      {
        headerName: "Containers",
        field: "containersNames",
        renderCell: cellRenderer("containersNames"),
        cellBodyClassName: "containers-cell",
      },
    ] as ComparisonTableProps<ComparisonService>["attributes"]
  ).filter((attribute) => {
    if (
      !showOnlyDrift ||
      attribute.field === "name" ||
      attribute.field === "cluster" ||
      attribute.field === "namespace" ||
      attribute.field === "kind" ||
      attribute.field === "komodorUid"
    ) {
      return true;
    }

    return data?.baselineService?.[attribute.field]?.isDiff;
  });
};
