import { orderBy } from "lodash";
import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";

import { UnhealthyPodOutput } from "./types";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PodHealthDetailsEvent from "./PodHealthDetailsEvent";

import type { BaseWorkflowCheck } from "@/components/monitorsView/common/types";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class HealthyPodCheck extends WorkflowCheck {
  readonly output;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  constructor(
    check: BaseWorkflowCheck,
    eventTime?: Date,
    clusterName?: string,
    namespace?: string
  ) {
    super(check);
    this.title = "Pod health";
    this.output = check.output as UnhealthyPodOutput & {
      eventTime?: Date;
      clusterName?: string;
      namespace?: string;
    };
    this.output.pod = {
      ...this.output.pod,
      containers: orderBy(
        this.output.pod?.containers ?? [],
        ["isHealthy", "restartCount"],
        ["asc", "desc"]
      ),
    };

    let shortOutput = "All pods are healthy";
    if (!check.passed) {
      if (this.output?.failureMessage) {
        shortOutput =
          this.output?.failureMessage +
          ` (ready replicas: 0, desired replicas 1)`;
      } else {
        shortOutput = `Pod is not healthy`;
      }
    }
    this.shortOutput = shortOutput;
    this.output.eventTime = eventTime;
    this.output.clusterName = clusterName;
    this.output.namespace = namespace;
  }

  renderCheckDetails(): JSX.Element {
    return <PodHealthDetailsEvent check={this} aria-label="pod-health" />;
  }
  renderCheckEventDetails(): JSX.Element {
    return <PodHealthDetailsEvent check={this} aria-label="pod-health" />;
  }
}
