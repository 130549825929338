import { formatDistance, parseISO } from "date-fns";
import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";
import { getTimeSinceLatestDeploy } from "../../../../Actions/common/monitorsActions";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkloadInfoDetails from "./detailsComponents/WorkloadInfoDetails";
import { WorkloadInfoOutput } from "./types";

import type { BaseWorkflowCheck } from "@/components/monitorsView/common/types";

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default class WorkloadInfoCheck extends WorkflowCheck {
  readonly output;
  readonly eventTime?: Date;
  readonly closedAt?: Date | null;

  // [CU-86c1gn74n] fix max-params
  // eslint-disable-next-line max-params
  constructor(
    check: BaseWorkflowCheck,
    eventTime?: Date,
    closedAt?: Date | null,
    failedPodName?: string
  ) {
    super(check);
    this.title = "Latest deploy";
    this.output = check.output as WorkloadInfoOutput;
    this.output.failedPod = failedPodName;
    const durationInMinutes = getTimeSinceLatestDeploy(this.output, eventTime);
    this.eventTime = eventTime;
    this.closedAt = closedAt;
    this.icon = durationInMinutes
      ? durationInMinutes < 20
        ? "fail"
        : durationInMinutes < 720
        ? "warn"
        : "info"
      : "info";

    const distance = formatDistance(
      eventTime ? eventTime : new Date(),
      parseISO(this.output?.latestDeploy?.eventTime),
      { addSuffix: true }
    );

    this.shortOutput = (
      <div>
        Deployed <b>{distance} before </b> the issue was triggered
      </div>
    );
    this.description =
      "Deploy changes can sometimes indicate why you reached an unhealthy state. We want to let you know if there are changes.";
    this.action =
      "We check the last deploy that have been preformed on this workload.";
    this.question = `View ${this.output?.latestDeploy?.deploymentName} latest deploy changes`;
    this.isFinding = this.icon === "info";
  }

  renderCheckDetails(): JSX.Element {
    return <WorkloadInfoDetails check={this} />;
  }
}
