import React from "react";
import styled from "styled-components";
import { Skeleton } from "@komodorio/design-system/deprecated";
import { Metadata } from "@komodorio/design-system/komodor-ui";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { Label } from "../../../resources";

import { AriaLabels } from "@/shared/config/ariaLabels";

interface SummaryProps {
  labels: Label[] | undefined;
}

const Container = styled.div`
  display: flex;
  gap: 2rem;
`;

export const Summary: React.FC<SummaryProps> = ({ labels }) => {
  return (
    <Container aria-label={AriaLabels.ResourceView.Header.Summary.Container}>
      {labels ? (
        labels.map(
          (label) =>
            label.value && (
              <Metadata
                title={label.name.toUpperCase()}
                value={label.value}
                tooltipProps={label.tooltipProps}
                key={label.name}
                leftIcon={label.leftIcon}
              />
            )
        )
      ) : (
        <>
          <Skeleton height="1.6rem" width="5rem" />
          <Skeleton height="1.6rem" width="5rem" />
          <Skeleton height="1.6rem" width="5rem" />
          <Skeleton height="1.6rem" width="5rem" />
        </>
      )}
    </Container>
  );
};
