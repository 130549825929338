/* eslint-disable max-lines */
import { Divider, Tabs, TabPanel } from "@komodorio/design-system/deprecated";
import { ResourceTableModelRow } from "komodor-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Stack from "@mui/material/Stack";

import { LinesLoader } from "../../common/loaders/Line";
import ResourceListTable from "../../common/ResourceListTable/ResourceListTable";
import { KubernetesResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesResource";
import { useInspectionParams } from "../InspectionViews/common";
import ResourceListErrorState from "../ResourceListErrorState";
import { useFilteredClusters } from "../filters/useFilteredClusters";
import { Direction } from "../../common/ProcessList/SortTitle";
import { useHasPermissions } from "../../../shared/hooks/useUserMetadata/rbac";
import { useInterval } from "../../common/useInterval";
import { InspectionFilters } from "../filters/InspectionFilters";
import { AgentNotSupported } from "../AgentNotSupported";
import { KubernetesHelmResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesHelmResource";

import { HelmReposTab } from "./tabs/HelmReposTab";
import { useParseHelmChartsResponse } from "./hooks/useHelmCharts";
import { invalidateChartRevisionsCache } from "./utils";
import { useHelmChartsFromAgent } from "./hooks/useHelmChartsFromAgent";
import { useSetInitialNamespace } from "./hooks/useSetInitialNamespace";

import { useNamespacesFilter } from "@/components/Inspection/InspectionViews/inpectionViewsHooks";
import { useIsAgentSupported } from "@/components/Inspection/Helm/hooks/useIsAgentSupported";
import { useActiveAgents } from "@/shared/hooks/useAgents";
import { AgentInfoToDefaultClusterName } from "@/components/Inspection/utils/useDefaultClusterForInspection";
import { useChartsByCluster } from "@/components/Inspection/Helm/hooks/useChartsByCluster";
import { UpdateHelmMessage } from "@/components/Inspection/Helm/UpdateHelmMessage";
import { useIsAwaitingNamespacesOnClusterChange } from "@/components/Inspection/Helm/hooks/useIsAwaitingNamespacesOnClusterChange";
import useNamespacesAtm from "@/shared/hooks/ATM/useNamespacesAtm";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { getViewOptionsByResource } from "@/components/Inspection/InspectionViews/datadogReporting/inspectionViewsReportingUtils";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

const LsHelmKey = KubernetesHelmResource.Kind;

enum HelmTabs {
  Releases = "Releases",
  Repos = "Repositories",
}

enum HelmTabsIndex {
  Releases = 0,
  Repos = 1,
}

const REFRESH_REVISIONS_CACHE_INTERVAL_IN_SECONDS = 600;

export const HelmChartsComponent: React.FC<{
  kubernetesResource: KubernetesResource;
}> = ({ kubernetesResource }) => {
  const { oneNamespaceByDefaultInHelm } = useOverridableFlags();
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const LsHelmKeyIfNeeded = oneNamespaceByDefaultInHelm ? LsHelmKey : LsHelmKey;

  const [currentTab, setCurrentTab] = useState(HelmTabsIndex.Releases);
  const [selectedNamespaces] = useNamespacesFilter(LsHelmKeyIfNeeded);
  const [showUpdateMessage, setUpdateMessage] = useState(false);
  const { cluster, onClusterChange, agentId } =
    useInspectionParams(kubernetesResource);
  const { filteredClusterSuggestions } = useFilteredClusters(cluster ?? "");
  const { result: namespacesData } = useNamespacesAtm({
    agentId: oneNamespaceByDefaultInHelm ? agentId ?? "" : "",
    cluster: oneNamespaceByDefaultInHelm ? cluster ?? "" : "",
    refetchUntilCompletion: true,
  });
  const namespaces = useMemo(() => {
    return namespacesData?.data.map((n) => n.name).sort();
  }, [namespacesData?.data]);

  const lastRequestedNamespace = useRef<string | null | undefined>(null);
  const lastRequestCluster = useRef<string | undefined>(cluster);
  const lastRequestAgentId = useRef<string | undefined>(agentId);
  const activeAgents = useActiveAgents() as AgentInfoToDefaultClusterName[];

  const { canManageHelm } = useHasPermissions();
  const { isAwaitingNamespacesChange, onClusterChangeCb } =
    useIsAwaitingNamespacesOnClusterChange(namespaces);

  const { initialNamespace, resetInitialNamespace } = useSetInitialNamespace({
    namespaces: namespaces ?? [],
    isAwaitingNamespacesChange,
    resourceKeyInLS: LsHelmKeyIfNeeded,
  });

  const onClusterChangeEvent = useCallback(
    (cluster: string) => {
      onClusterChangeCb();
      resetInitialNamespace();
      onClusterChange(cluster);
    },
    [onClusterChange, resetInitialNamespace, onClusterChangeCb]
  );

  const [hasAllResults, setHasAllResults] = useState(false);

  // current support in hasura-actions is for a single namespace
  const namespaceToUse =
    selectedNamespaces.length === 1 ? selectedNamespaces[0] : undefined;

  const { execute, isFetching, data, errorMessage } = useHelmChartsFromAgent({
    cluster: cluster ?? "",
    agentId: agentId ?? "",
    namespace: namespaceToUse,
  });

  const resourceList = useParseHelmChartsResponse(
    errorMessage,
    isFetching,
    data
  );

  useEffect(() => {
    reportLoadingState("resource", isFetching);
  }, [isFetching, reportLoadingState]);

  useEffect(() => {
    const initialNamespaceIfNeeded =
      !initialNamespace && oneNamespaceByDefaultInHelm;

    const shouldWaitForNamespaces = !namespaces && oneNamespaceByDefaultInHelm;

    if (
      shouldWaitForNamespaces ||
      initialNamespaceIfNeeded ||
      isAwaitingNamespacesChange
    ) {
      return;
    }

    // No change in scope
    const hasClusterNameChanged = lastRequestCluster.current !== cluster;
    const hasNamespaceChanged =
      lastRequestedNamespace.current !== namespaceToUse;

    // make sure we fetch the data at least once
    if (!agentId) {
      return;
    }

    if (!hasNamespaceChanged && !hasClusterNameChanged) {
      return;
    }

    const hasNamespaceResults = data?.some(
      (r) => r.namespace === namespaceToUse
    );

    const responseIsPerAgent = agentId === lastRequestAgentId.current;
    if (hasNamespaceResults && responseIsPerAgent) {
      return;
    }

    execute();
    setHasAllResults(namespaceToUse === undefined);
    lastRequestedNamespace.current = namespaceToUse;
    lastRequestCluster.current = cluster;
    lastRequestAgentId.current = agentId;
  }, [
    execute,
    initialNamespace,
    resourceList.emptyResult,
    hasAllResults,
    namespaceToUse,
    data,
    agentId,
    namespaces,
    isAwaitingNamespacesChange,
    oneNamespaceByDefaultInHelm,
    cluster,
  ]);

  const clusters = useMemo(() => {
    return activeAgents.map((agent) => ({
      label: agent.clusterName,
      value: agent.clusterName,
    }));
  }, [activeAgents]);

  useInterval(
    invalidateChartRevisionsCache,
    REFRESH_REVISIONS_CACHE_INTERVAL_IN_SECONDS * 1_000
  );
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const onRowClick = useCallback(
    (row: ResourceTableModelRow) => {
      const chart = data?.find((r) => r.secretId === row.secretId);
      if (chart) {
        pushDrawer({
          drawerType: DrawerType.HelmReleaseDrawer,
          chartSummary: chart,
        });
      }
    },
    [data, pushDrawer]
  );

  const chartsByCluster = useChartsByCluster({
    cluster: cluster ?? "",
    kubernetesResource,
    resourceList,
    resourceKeyInLS: LsHelmKeyIfNeeded,
  });

  const isAgentSupported = useIsAgentSupported(activeAgents, cluster);

  const tabs = useMemo(() => {
    const presentTabs = [
      {
        label: HelmTabs.Releases,
      },
    ];
    if (canManageHelm) {
      presentTabs.push({
        label: HelmTabs.Repos,
      });
    }
    return presentTabs;
  }, [canManageHelm]);

  const isWaitingForInitialNamespace =
    !initialNamespace &&
    !!oneNamespaceByDefaultInHelm &&
    namespaces === undefined;

  const isContentLoading = isWaitingForInitialNamespace || isFetching;

  return (
    <Stack sx={{ margin: "16px 24px 24px" }}>
      <Tabs
        value={currentTab}
        onChange={(index) => {
          setCurrentTab(index);
        }}
        tabs={tabs}
      />
      <Divider />
      <TabPanel index={HelmTabsIndex.Releases} value={currentTab}>
        <br />
        {cluster && (
          <InspectionFilters
            kubernetesResource={kubernetesResource}
            resourceList={resourceList}
            selectedCluster={cluster}
            onClusterChange={onClusterChangeEvent}
            namespaces={namespaces}
            clustersOptions={filteredClusterSuggestions}
            resourceKeyInLS={LsHelmKeyIfNeeded}
          />
        )}
        <Divider />
        <br />
        {resourceList.errorMessage ? (
          <ResourceListErrorState
            resourceName={kubernetesResource.NameInK8S}
            errorMessage={resourceList.errorMessage}
            refreshCallback={execute}
          />
        ) : isAgentSupported ? (
          <ResourceListTable
            rows={chartsByCluster}
            resourceType={kubernetesResource}
            cluster={cluster ?? ""}
            refreshResults={() => null}
            overrideRowClick={onRowClick}
            initialSortOrder={{ column: "lastUpdated", order: Direction.down }}
            loadingProps={{
              isLoading: isContentLoading,
              loaderElement: <LinesLoader marginTop="100px" />,
            }}
          />
        ) : (
          <AgentNotSupported
            onUpgradeClicked={() => setUpdateMessage(true)}
            title="Komodor agent on this cluster does not support Helm."
          />
        )}
        {showUpdateMessage && (
          <UpdateHelmMessage setUpdateMessage={setUpdateMessage} />
        )}
      </TabPanel>
      <TabPanel index={HelmTabsIndex.Repos} value={currentTab}>
        {canManageHelm && (
          <HelmReposTab clusters={clusters.map((el) => el.value)} />
        )}
      </TabPanel>
    </Stack>
  );
};

export const HelmCharts: React.FC<{
  kubernetesResource: KubernetesResource;
}> = (props) => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        ...getViewOptionsByResource(KubernetesHelmResource),
      }}
    >
      <HelmChartsComponent {...props} />
    </DatadogReportLoadingTimeContextProvider>
  );
};
