import React, {
  createContext,
  useState,
  PropsWithChildren,
  useCallback,
  useMemo,
} from "react";
import noop from "lodash/noop";

import { CreateTrackedKeyModal } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/CreateTrackedKeyModal";
import { Refetch } from "@/shared/context/trackedKeys/CreateTrackedKeyModal/types";

export type TrackedKeysContextState = {
  addNewKey: (refetch: Refetch) => void;
};

const initialState: TrackedKeysContextState = {
  addNewKey: noop,
};

export const TrackedKeysContext =
  createContext<TrackedKeysContextState>(initialState);

export const TrackedKeysContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [refetch, setRefetch] = useState<Refetch | null>(null);
  const [openCreateTrackedKeyModal, setOpenCreateTrackedKeyModal] =
    useState<boolean>(false);

  const addNewKey = useCallback(
    async (newRefetch: Refetch) => {
      setRefetch(() => newRefetch);
      setOpenCreateTrackedKeyModal(true);
    },
    [setOpenCreateTrackedKeyModal]
  );

  const state = useMemo(
    () => ({
      addNewKey,
    }),
    [addNewKey]
  );

  const handleClose = useCallback(() => {
    setRefetch(null);
    setOpenCreateTrackedKeyModal(false);
  }, []);

  return (
    <TrackedKeysContext.Provider value={state}>
      {children}
      {openCreateTrackedKeyModal && (
        <CreateTrackedKeyModal onClose={handleClose} onRefetch={refetch} />
      )}
    </TrackedKeysContext.Provider>
  );
};
