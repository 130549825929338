import React from "react";
import Typography from "@mui/material/Typography";

import { gray } from "../../../../../themes/colors";
import { selectAriaLabels } from "../ariaLabels";
import { muiTheme } from "themes";

type LabelProps = {
  value?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

export const Label: React.FC<LabelProps> = ({
  value,
  className,
  disabled = false,
}) => {
  return value && typeof value === "string" ? (
    <Typography
      className={className}
      variant={"body2"}
      color={disabled ? muiTheme.palette.text.disabled : gray[800]}
      sx={{ marginBottom: "3px" }}
      aria-label={selectAriaLabels.label}
    >
      <label>{value}</label>
    </Typography>
  ) : (
    value
  );
};
