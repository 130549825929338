import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

export enum DriftType {
  Services = "services",
  Helm = "helm",
}

export type DriftComparisonState = {
  baselineSelection: MuiSelectionOption<string> | null;
  comparedSelections: MuiSelectionOption<string>[] | null;
  isBaselineSelectorOpen: boolean;
  isComparedSelectorOpen: boolean;
  setBaselineSelection: (val: MuiSelectionOption<string> | null) => void;
  setComparedSelections: (val: MuiSelectionOption<string>[] | null) => void;
  setIsBaselineSelectorOpen: (val: boolean) => void;
  setIsComparedSelectorOpen: (val: boolean) => void;
};
