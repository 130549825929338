import React, { useMemo } from "react";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";
import { EmptyState } from "@komodorio/design-system/komodor-ui";

import { RichSessionData } from "@/components/AiInvestigation/useEnrichedSession";
import { OperationsLoader } from "@/components/AiInvestigation/AiInvestigationContent/OperationsLoader";
import { Error } from "@/components/AiInvestigation/AiInvestigationContent/Error";
import { ShortPanel } from "@/components/AiInvestigation/AiInvestigationContent/styles";
import { AiInvestigationResults } from "@/components/AiInvestigation/AiInvestigationContent/AiInvestigationResults";

interface AiInvestigationContentProps {
  richSession: RichSessionData | null;
  cluster: string;
  isLoading: boolean;
  isError?: boolean;
  onRetryCallback: () => void;
}

const Container = styled.div`
  background-color: ${muiColors.common.white};
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
`;

export const AiInvestigationContent: React.FC<AiInvestigationContentProps> = ({
  richSession,
  cluster,
  isLoading,
  onRetryCallback,
  isError,
}) => {
  const contents = useMemo(() => {
    // Render states are:
    // isError => Error state
    // isLoading => Loading state
    // isComplete + isNoProblemFound => Empty state (see CU-86c1bgnu6: https://app.clickup.com/t/86c1bgnu6)
    // Anything else => Complete and finished session, show results
    if (isError) {
      return (
        <Error
          text={
            richSession?.isStuck
              ? "Investigation Incomplete"
              : "Analysis Failed"
          }
          onRetryCallback={onRetryCallback}
        />
      );
    }

    if (isLoading) {
      return <OperationsLoader session={richSession} />;
    }

    const isNoProblemFound =
      richSession?.isComplete && richSession?.isNoProblemFound;
    if (isNoProblemFound) {
      return (
        <ShortPanel center>
          <EmptyState title="No issues found for this investigation." />
        </ShortPanel>
      );
    }

    return (
      richSession && (
        <AiInvestigationResults richSession={richSession} cluster={cluster} />
      )
    );
  }, [isError, isLoading, richSession, cluster, onRetryCallback]);

  if (richSession?.isComplete && richSession?.isNoProblemFound) {
    return null;
  }

  return <Container>{contents}</Container>;
};
