import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  DataGridPro,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import {
  AdvancedMultiSelect,
  LabeledSwitch,
  LinesLoader,
  ManageColumnsButton,
  TextInput,
} from "@komodorio/design-system/komodor-ui";
import { KomobotEmptyState } from "@komodorio/design-system/assets";

import { useAgents } from "./useAgents";

import {
  API_KEY_ACTIONS_CLASSNAME,
  ariaLabels,
  DEFAULT_PAGINATION_MODEL,
  DEFAULT_SORTING_MODEL,
  INITIAL_COLUMN_VISIBILITY,
  PAGE_SIZE_OPTIONS,
  UNTOUCHABLE_COLUMNS,
} from "@/pages/organization-settings/account/AgentsPage/constants";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import KubernetesInstallationModal from "@/components/integrations/installation/kubernetes/KubernetesInstallationModal";
import { getColumnsDefinitions } from "@/pages/organization-settings/account/AgentsPage/gridHelpers";
import { Kubernetes_Agent_Info } from "@/generated/graphql";
import { AgentDrawer } from "@/pages/organization-settings/account/AgentsPage/components/AgentDrawer";
import {
  getDisplayingItemsTextByLimitAndOffset,
  getPaginationModel,
} from "@/components/ClustersView/utils";
import { usePageWidth } from "@/pages/hooks/usePageWidth";
import { AgentRestartDialog } from "@/pages/organization-settings/account/AgentsPage/components/AgentRestartDialog";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { DatadogViewNamesEnum } from "@/shared/types/datadogReporting";

const MULTI_SELECT_WIDTH = "260px";

const AgentsPageComponent: React.FC = () => {
  const {
    clusters,
    isFetching,
    refetch,
    filteredAgents,
    selectedClusters,
    handleSelectClusters,
    selectedApiKey,
    handleSelectApiKey,
    showInactiveAgents,
    setShowInactiveAgents,
  } = useAgents();

  const [agentToRestart, setAgentToRestart] =
    useState<Kubernetes_Agent_Info | null>(null);
  const [filters, setFilters] = useState<{ limit: number; offset: number }>({
    limit: DEFAULT_PAGINATION_MODEL.pageSize,
    offset: DEFAULT_PAGINATION_MODEL.page,
  });

  const { ref, width } = usePageWidth();

  const [sortModel, setSortModel] = useState<GridSortModel>(
    DEFAULT_SORTING_MODEL
  );
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    reportLoadingState("isFetchingAgents", isFetching);
  }, [isFetching, reportLoadingState]);

  const paginationModel = useMemo<GridPaginationModel>(
    () => getPaginationModel(filters.limit, filters.offset),
    [filters]
  );

  const handlePaginationModelChange = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setFilters({
        ...filters,
        limit: newPaginationModel.pageSize,
        offset: newPaginationModel.page * newPaginationModel.pageSize,
      });
    },
    [filters]
  );

  // Todo Lior: add cluster logic + components are duplicated and should be in a provider
  const [isInstallationModalOpen, setIsInstallationModalOpen] =
    useState<boolean>(false);

  const [drawerStoredAgent, setDrawerStoredAgent] =
    useState<Kubernetes_Agent_Info | null>(null);

  const onAddCluster = useCallback(() => {
    setIsInstallationModalOpen(true);
  }, []);

  const columnDefinitions = useMemo(() => {
    return getColumnsDefinitions({ refetch, setAgentToRestart });
  }, [refetch]);

  const [columnsVisibility, setColumnsVisibility] = useState<
    Record<string, boolean>
  >(INITIAL_COLUMN_VISIBILITY);

  const content = useMemo(() => {
    const shouldShowLoader = !width || isFetching;
    if (shouldShowLoader) {
      return (
        <Stack alignItems="center" minHeight="60vh" justifyContent="center">
          <LinesLoader />
        </Stack>
      );
    }

    if (!filteredAgents?.length) {
      return (
        <Stack direction="column" alignItems="center">
          <KomobotEmptyState />
          <Typography variant="h4" align="center">
            No clusters are connected
          </Typography>
          <Button variant="text" color="primary" onClick={onAddCluster}>
            Add a cluster
          </Button>
        </Stack>
      );
    }

    return (
      <Stack>
        <Stack direction={"row"} spacing={1} justifyContent="space-between">
          <Typography variant="h5" color="text.secondary" alignSelf={"center"}>
            {/* Todo Lior: move this into utils and not use the clusters one  */}
            {getDisplayingItemsTextByLimitAndOffset(
              filters?.limit,
              filters?.offset,
              filteredAgents.length
            )}
          </Typography>
          <ManageColumnsButton
            columns={columnDefinitions}
            columnsVisibility={columnsVisibility}
            setColumnsVisibility={setColumnsVisibility}
            untouchableColumns={UNTOUCHABLE_COLUMNS}
            sx={{ "&&": { marginLeft: "auto" } }}
          />
        </Stack>
        <Paper
          variant="elevation"
          elevation={1}
          sx={{ width, overflow: "auto" }}
        >
          <DataGridPro
            disableVirtualization
            pagination
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            rows={filteredAgents}
            disableColumnMenu
            columns={columnDefinitions}
            columnVisibilityModel={columnsVisibility}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            onRowClick={(params: GridRowParams<Kubernetes_Agent_Info>) => {
              setDrawerStoredAgent(params.row);
            }}
            aria-label={`${ariaLabels.table} content`}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1,
              },
              "& .MuiDataGrid-cell.api-key-cell:hover": {
                [`& .${API_KEY_ACTIONS_CLASSNAME}`]: {
                  visibility: "visible",
                },
              },
            }}
          />
        </Paper>
      </Stack>
    );
  }, [
    width,
    isFetching,
    filteredAgents,
    onAddCluster,
    paginationModel,
    handlePaginationModelChange,
    columnsVisibility,
    setColumnsVisibility,
    setSortModel,
    sortModel,
    columnDefinitions,
    filters.offset,
    filters.limit,
  ]);

  return (
    <SettingsViewVerticalLayout title="Agents">
      <Stack direction="column" rowGap={2} ref={ref}>
        <Stack direction="row" spacing={1}>
          <AdvancedMultiSelect
            options={clusters}
            label="Clusters"
            ariaLabel={ariaLabels.clusters}
            width={MULTI_SELECT_WIDTH}
            value={selectedClusters}
            onChange={handleSelectClusters}
          />
          <TextInput
            sx={{ width: MULTI_SELECT_WIDTH }}
            placeholder="Search"
            label="API Keys"
            aria-label={ariaLabels.apiKeys}
            onChange={handleSelectApiKey}
            value={selectedApiKey}
          />
          <LabeledSwitch
            stackProps={{ alignSelf: "end" }}
            label={"Show Inactive Agents"}
            checked={showInactiveAgents}
            onCheck={setShowInactiveAgents}
            ariaLabel={ariaLabels.showInactiveAgents}
          />
          <Box sx={{ "&&": { marginLeft: "auto" } }} alignSelf="end">
            <Button
              variant="contained"
              onClick={onAddCluster}
              aria-label={ariaLabels.addCluster}
            >
              Add cluster
            </Button>
          </Box>
        </Stack>
        {content}
        <KubernetesInstallationModal
          closeModalCallback={() => setIsInstallationModalOpen(false)}
          isOpen={isInstallationModalOpen}
          showWelcomeStep={false}
        />
        <AgentDrawer
          agent={drawerStoredAgent}
          onClose={() => setDrawerStoredAgent(null)}
          restartAgent={(agent: Kubernetes_Agent_Info) =>
            setAgentToRestart(agent)
          }
        />
        {agentToRestart && (
          <AgentRestartDialog
            selectedAgent={agentToRestart}
            onClose={() => setAgentToRestart(null)}
          />
        )}
      </Stack>
    </SettingsViewVerticalLayout>
  );
};

export const AgentsPage: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        name: DatadogViewNamesEnum.accountSettingsAgentsPage,
        context: {
          feTeam: "barzelim",
          beTeam: "barzelim",
        },
      }}
    >
      <AgentsPageComponent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
