import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import {
  ActionCell,
  ManageColumnsButton,
} from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";

import { useScopedWorkspacesList } from "./hooks/useScopedWorkspacesList";

import { TypedScopeWorkspace } from "@/shared/hooks/workspaces-api/types";
import { getDisplayingItemsText } from "@/shared/utils/tableUtils";

const Space = styled.div`
  height: 21px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

interface Props {
  data: TypedScopeWorkspace[];
  isLoading: boolean;
  searchTerm: string;
}

const columns: GridColDef<TypedScopeWorkspace>[] = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    width: 200,
    flex: 1,
    valueGetter: ({ row }) => row.name,
  },
  {
    field: "description",
    headerName: "Description",
    sortable: false,
    width: 200,
    flex: 1,
    valueGetter: ({ row }) => row.description ?? "",
  },
  {
    field: "createdBy",
    headerName: "Created By",
    sortable: false,
    width: 190,
    valueGetter: ({ row }) => row.AuthorEmail ?? "",
  },
  {
    field: "createdAt",
    headerName: "Created At",
    sortable: true,
    width: 140,
    valueGetter: ({ row }) => row.createdAt ?? "",
  },
  {
    field: "lastUpdated",
    headerName: "Last Updated",
    sortable: true,
    width: 140,
    valueGetter: ({ row }) => row.lastUpdated ?? "",
  },
  {
    field: "updatedByEmail",
    headerName: "Last Updated By",
    sortable: true,
    width: 194,
    valueGetter: ({ row }) => row.UpdatedByEmail ?? "",
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return (
        <ActionCell
          params={params}
          actions={[
            {
              key: "go-to-workspace",
              title: "Go to Workspace",
              enabled: true,
              icon: <OpenInNewOutlined fontSize="small" />,
            },
            {
              key: "edit-workspace",
              title: "Edit",
              enabled: true,
              icon: <EditOutlined fontSize="small" />,
            },
            {
              key: "delete-workspace",
              title: "Delete",
              enabled: true,
              icon: <DeleteOutlined fontSize="small" />,
            },
          ]}
        />
      );
    },
  },
];

export const ScopedWorkspacesList: React.FC<Props> = ({
  data,
  isLoading,
  searchTerm,
}) => {
  const [columnsVisibility, setColumnsVisibility] =
    useState<GridColumnVisibilityModel>({
      createdAt: false,
      updatedByEmail: false,
    });
  const { table, paginationModel } = useScopedWorkspacesList({
    data,
    columns,
    columnsVisibility,
    search: searchTerm,
  });

  return (
    <Container>
      <Stack direction="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          marginBottom="3px"
        >
          {isLoading ? (
            <Space />
          ) : (
            <Typography variant="h5" color="text.secondary">
              {getDisplayingItemsText(
                paginationModel.page,
                paginationModel.pageSize,
                data.length
              )}
            </Typography>
          )}

          <ManageColumnsButton
            columns={columns}
            columnsVisibility={columnsVisibility}
            setColumnsVisibility={setColumnsVisibility}
            sx={{ width: "fit-content", justifySelf: "end" }}
            untouchableColumns={["actions", "name"]}
          />
        </Stack>

        <Paper variant="elevation" elevation={1}>
          {table}
        </Paper>
      </Stack>
    </Container>
  );
};
