/* eslint-disable max-lines */
import { muiColors } from "@komodorio/design-system";
import {
  MuiSelectionOption,
  Compared,
  ComparisonTableProps,
  RowData,
} from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import LaunchOutlined from "@mui/icons-material/LaunchOutlined";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { EnvVar } from "kubernetes-types/core/v1";
import YAML from "yaml";

import { MAX_YAML_LINES, SHOW_ONLY_DRIFT_SERVICES_KEY } from "../constants";
import { NameFieldCell } from "../../common/NameFieldCell";
import { SeeAllModalParams } from "../../common/DiffModal";
import { useDriftComparisonContext } from "../../context/useDriftComparisonContext";

import { ComparisonContainer, DiffModal } from "./types";

import {
  DrawerStatePush,
  DrawerType,
} from "@/shared/store/drawersStackStore/types";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import {
  ServiceComparisonResponseContainer,
  ServiceComparisonResponseTargetServices,
} from "@/generated/workspacesApi";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

type CellRenderer =
  ComparisonTableProps<ComparisonContainer>["attributes"][number]["renderCell"];
const cellRenderer =
  (setSeeAllModal?: (seeAllModal: SeeAllModalParams) => void) =>
  (field: keyof ComparisonContainer, title?: string): CellRenderer =>
  ({ data, isBaseline }) => {
    const isYaml =
      field === "livenessProbe" ||
      field === "readinessProbe" ||
      field === "startupProbe" ||
      field === "envVars";
    if (isYaml) {
      const content = YAML.stringify(
        isBaseline ? data[field] : data.data?.[field]?.value,
        { indent: 2 }
      );
      const lines = content.split("\n");
      return (
        <Box
          display="flex"
          flexDirection="column"
          marginTop="-16px"
          marginBottom="-16px"
        >
          <Typography
            variant="body2"
            color={isBaseline ? muiColors.gray[600] : "text.primary"}
            whiteSpace="pre-wrap"
          >
            {lines.slice(0, MAX_YAML_LINES).join("\n")}
          </Typography>
          {lines.length > MAX_YAML_LINES && (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSeeAllModal?.({ title: title ?? "", content });
              }}
              sx={{
                marginTop: "8px",
                cursor: "pointer",
                textDecoration: "none",
                verticalAlign: "middle",
              }}
            >
              See All{" "}
              <LaunchOutlined
                sx={{
                  fontSize: "16px",
                  verticalAlign: "middle",
                  position: "relative",
                  top: "-2px",
                }}
              />
            </Link>
          )}
        </Box>
      );
    }

    if (field === "name") {
      return (
        <NameFieldCell>
          {isBaseline ? data[field] : data.data?.[field]?.value}
        </NameFieldCell>
      );
    }
    const centerText =
      field === "cpuRequests" ||
      field === "cpuLimits" ||
      field === "memoryRequests" ||
      field === "memoryLimits";
    return (
      <Typography
        variant="body2"
        color={isBaseline ? muiColors.gray[600] : "text.primary"}
        textAlign={centerText ? "center" : "left"}
      >
        {
          (isBaseline
            ? data[field]
            : data.data?.[field]?.value) as React.ReactNode
        }
      </Typography>
    );
  };

const getDrawerData = ({
  data,
  isBaseline,
}: RowData<ComparisonContainer>): DrawerStatePush => {
  return {
    drawerType: DrawerType.ResourceDrawerByData,
    cluster: isBaseline ? data.cluster : data.data?.cluster?.value ?? "",
    namespace: isBaseline ? data.namespace : data.data?.namespace?.value ?? "",
    resourceType: isBaseline
      ? data.resourceKind
      : data.data?.resourceKind?.value ?? "",
    resourceName: isBaseline ? data.name : data.data?.name?.value ?? "",
  };
};

export const getComparedServicesContainers = ({
  targetContainer,
  targetServices,
  comparedSelections,
  isLoading,
  showOnlyDrift,
}: {
  targetContainer: ServiceComparisonResponseContainer;
  targetServices: ServiceComparisonResponseTargetServices[] | undefined;
  comparedSelections: MuiSelectionOption<string>[] | null;
  isLoading: boolean;
  showOnlyDrift: boolean;
}): Compared<ComparisonContainer>[] => {
  return (
    comparedSelections
      ?.map((serviceUid, i): Compared<ComparisonContainer> => {
        const parsedComparedKomodorUid = serviceUid
          ? parseKomodorUid(serviceUid.value)
          : undefined;
        const serviceContainer = targetContainer.targetServicesContainer?.[i];
        const serviceData = {
          komodorUid: {
            value: serviceUid.value,
            isDiff: false,
            isEmpty: false,
          },
          cluster: {
            value: parsedComparedKomodorUid?.cluster,
            isDiff: false,
            isEmpty: false,
          },
          namespace: {
            value: parsedComparedKomodorUid?.namespace,
            isDiff: false,
            isEmpty: false,
          },
          resourceKind: {
            value: parsedComparedKomodorUid?.kind,
            isDiff: false,
            isEmpty: false,
          },
          name: {
            value: parsedComparedKomodorUid?.name,
            isDiff: false,
            isEmpty: false,
          },
        };
        if (!serviceContainer) {
          return {
            isLoading: true,
            isMissingData: true,
            data: serviceData,
          };
        }
        return {
          isLoading,
          isMissingData: serviceContainer.isContainerExists === false,
          data: {
            ...serviceData,
            ...(serviceContainer && serviceContainer.isContainerExists
              ? {
                  ...serviceContainer,
                  envVars: {
                    ...serviceContainer.envVars,
                    value: serviceContainer.envVars.value as
                      | Array<EnvVar>
                      | undefined,
                  },
                }
              : {}),
          },
        };
      })
      .filter((_, i) => {
        if (!showOnlyDrift) {
          return true;
        }
        return targetServices?.[i]?.isDiff === true;
      }) ?? []
  );
};

const getFilteredComparedServicesLength = ({
  comparedSelections,
  showOnlyDrift,
  targetServices,
}: {
  comparedSelections: MuiSelectionOption<string>[] | null;
  showOnlyDrift: boolean;
  targetServices: ServiceComparisonResponseTargetServices[] | undefined;
}): number => {
  return (
    comparedSelections?.filter((_, i) => {
      if (!showOnlyDrift) {
        return true;
      }
      return targetServices?.[i]?.isDiff === true;
    }).length ?? 0
  );
};

export const getBaselineContainer = (
  baselineSelection: MuiSelectionOption<string> | null,
  container: ServiceComparisonResponseContainer
): ComparisonContainer => {
  const baselineParsedKomodorUid = baselineSelection
    ? parseKomodorUid(baselineSelection.value)
    : undefined;

  const b = container.baselineServiceContainer;
  return {
    komodorUid: baselineSelection?.value ?? "",
    cluster: baselineParsedKomodorUid?.cluster ?? "",
    namespace: baselineParsedKomodorUid?.namespace ?? "",
    name: baselineParsedKomodorUid?.name ?? "",
    resourceKind: baselineParsedKomodorUid?.kind ?? "",
    image: b.image.value ?? "",
    envVars: b.envVars.value as Array<EnvVar> | undefined,
    livenessProbe: b.livenessProbe.value,
    readinessProbe: b.readinessProbe.value,
    startupProbe: b.startupProbe.value,
    memoryRequests: b.memoryRequests.value ?? "",
    memoryLimits: b.memoryLimits.value ?? "",
    cpuRequests: b.cpuRequests.value ?? "",
    cpuLimits: b.cpuLimits.value ?? "",
  };
};

export const useContainerTableAttributes = (params?: {
  targetServices?: ServiceComparisonResponseTargetServices[];
  baselineContainer?: ComparisonContainer;
  targetContainer?: ServiceComparisonResponseContainer;
  setDiffModal?: (diffModal: DiffModal | null) => void;
  setSeeAllModal?: (seeAllModal: SeeAllModalParams) => void;
}): ComparisonTableProps<ComparisonContainer>["attributes"] => {
  const {
    targetServices,
    baselineContainer,
    targetContainer,
    setDiffModal,
    setSeeAllModal,
  } = params ?? {};
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const { comparedSelections } = useDriftComparisonContext();

  const [showOnlyDrift] = useStringifiedStateInSearchParams<boolean>(
    SHOW_ONLY_DRIFT_SERVICES_KEY
  );
  const comparedLength =
    targetContainer !== undefined
      ? getFilteredComparedServicesLength({
          comparedSelections,
          showOnlyDrift: showOnlyDrift ?? false,
          targetServices,
        })
      : 0;
  return (
    [
      {
        headerName: "Service/Attribute",
        field: "name",
        renderCell: cellRenderer(setSeeAllModal)("name"),
        showValueAlways: true,
        onClick: (data) => {
          pushDrawer(getDrawerData(data));
        },
        cellBodyClassName: "name-cell",
      },
      {
        headerName: "Image",
        field: "image",
        hideValueIfDifferent: false,
        renderCell: cellRenderer(setSeeAllModal)("image"),
      },
      {
        headerName: "CPU requests",
        field: "cpuRequests",
        renderCell: cellRenderer(setSeeAllModal)("cpuRequests"),
      },
      {
        headerName: "CPU limits",
        field: "cpuLimits",
        renderCell: cellRenderer(setSeeAllModal)("cpuLimits"),
      },
      {
        headerName: "Memory requests",
        field: "memoryRequests",
        renderCell: cellRenderer(setSeeAllModal)("memoryRequests"),
      },
      {
        headerName: "Memory limits",
        field: "memoryLimits",
        renderCell: cellRenderer(setSeeAllModal)("memoryLimits"),
      },
      {
        headerName: "Env variables",
        field: "envVars",
        hideValueIfDifferent: true,
        renderCell: cellRenderer(setSeeAllModal)(
          "envVars",
          "Environment variables"
        ),
        onClick: ({ data, isBaseline }) => {
          if (isBaseline) {
            return;
          }
          setDiffModal?.({
            title: "Env variables",
            comparedUid: data.data?.komodorUid?.value ?? "",
            oldObj: baselineContainer?.envVars,
            newObj: data.data?.envVars?.value,
          });
        },
      },
      {
        headerName: "Liveness probes",
        field: "livenessProbe",
        hideValueIfDifferent: true,
        renderCell: cellRenderer(setSeeAllModal)(
          "livenessProbe",
          "Liveness probes"
        ),
        onClick: ({ data, isBaseline }) => {
          if (isBaseline) {
            return;
          }
          setDiffModal?.({
            title: "Liveness probes",
            comparedUid: data.data?.komodorUid?.value ?? "",
            oldObj: baselineContainer?.livenessProbe,
            newObj: data.data?.livenessProbe?.value,
          });
        },
      },
      {
        headerName: "Readiness probes",
        field: "readinessProbe",
        hideValueIfDifferent: true,
        renderCell: cellRenderer(setSeeAllModal)(
          "readinessProbe",
          "Readiness probes"
        ),
        onClick: ({ data, isBaseline }) => {
          if (isBaseline) {
            return;
          }
          setDiffModal?.({
            title: "Readiness probes",
            comparedUid: data.data?.komodorUid?.value ?? "",
            oldObj: baselineContainer?.readinessProbe,
            newObj: data.data?.readinessProbe?.value,
          });
        },
      },
      {
        headerName: "Startup probes",
        field: "startupProbe",
        hideValueIfDifferent: true,
        renderCell: cellRenderer(setSeeAllModal)(
          "startupProbe",
          "Startup probes"
        ),
        onClick: ({ data, isBaseline }) => {
          if (isBaseline) {
            return;
          }
          setDiffModal?.({
            title: "Startup probes",
            comparedUid: data.data?.komodorUid?.value ?? "",
            oldObj: baselineContainer?.startupProbe,
            newObj: data.data?.startupProbe?.value,
          });
        },
      },
    ] as ComparisonTableProps<ComparisonContainer>["attributes"]
  ).filter((attribute) => {
    if (
      comparedLength === 0 ||
      !showOnlyDrift ||
      attribute.field === "name" ||
      attribute.field === "cluster" ||
      attribute.field === "namespace" ||
      attribute.field === "resourceKind" ||
      attribute.field === "komodorUid"
    ) {
      return true;
    }
    return (
      targetContainer?.baselineServiceContainer?.[attribute.field]?.isDiff ===
      true
    );
  });
};
