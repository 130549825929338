import styled from "styled-components";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { muiColors } from "@komodorio/design-system";
import { CheckCircleOutlined24 } from "@komodorio/design-system/icons";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useRef } from "react";

import { AriaLabels } from "../../../shared/config/ariaLabels";
import { RichSessionData } from "../useEnrichedSession";

import { ShortPanel } from "./styles";

import logo from "@/components/integrations/installation/kubernetes/assets/logo.gif";

const StyledStepIcon = ({ active }: { active: boolean }) => {
  return active ? (
    <Logo height="24px" width="24px" />
  ) : (
    <CheckCircleOutlined24 fill={muiColors.gray[500]} />
  );
};

const StepTypography = styled(Typography)<{
  active: boolean;
}>`
  && {
    vertical-align: middle;
    ${({ active }) =>
      active
        ? `font-weight: 700;`
        : `font-weight: 400; color: ${muiColors.gray[500]};`}
  }
`;

const Logo = styled.img.attrs({ src: logo })`
  object-fit: contain;
`;

const StyledStep = styled(Step)`
  + .MuiStepConnector-root {
    margin-left: 12px;
  }
`;

export const OperationsLoader: React.FC<{
  session: RichSessionData | null | undefined;
  linkToResources?: boolean;
}> = ({ session, linkToResources = false }) => {
  const operations = useMemo(
    () =>
      (linkToResources ? session?.enrichedOperations : session?.operations) ??
      [],
    [linkToResources, session?.enrichedOperations, session?.operations]
  );
  const isOperationsEmpty = operations.length === 0;
  const stepperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Scroll to the bottom when steps are updated
    if (stepperRef.current) {
      stepperRef.current.scrollTop = stepperRef.current.scrollHeight;
    }
  }, [operations]);

  return (
    <ShortPanel center={isOperationsEmpty}>
      {isOperationsEmpty ? (
        <>
          <Logo
            aria-label={AriaLabels.AiInvestigator.LoaderLogo}
            height="60px"
            width="80px"
          />
          <Typography variant="subtitle2">
            Loading AI investigation...
          </Typography>
        </>
      ) : (
        <Stepper
          orientation="vertical"
          nonLinear
          activeStep={operations.length - 1}
          sx={{ overflowY: "auto", position: "relative" }}
          ref={stepperRef}
        >
          {operations.map((step, index) => {
            const active =
              index === operations.length - 1 && !session?.isComplete;
            return (
              <StyledStep key={JSON.stringify(step)}>
                <StepLabel
                  sx={index === 0 ? { paddingTop: 0 } : undefined}
                  StepIconComponent={() => <StyledStepIcon active={active} />}
                >
                  <StepTypography variant="subtitle2" active={active}>
                    {step}
                  </StepTypography>
                </StepLabel>
              </StyledStep>
            );
          })}
        </Stepper>
      )}
    </ShortPanel>
  );
};
