import {
  ChatMessagesList,
  ChatMessageType,
} from "@komodorio/design-system/komodor-ui";
import ChatOutlined from "@mui/icons-material/ChatOutlined";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";

interface ChatBodyProps {
  messages: ChatMessageType[];
  chatSessionId: string | undefined;
  waitingForResponse: boolean;
  isResponseFailed: boolean | undefined;
  shouldAutoScroll: boolean;
}

export const ChatBody: React.FC<ChatBodyProps> = ({
  messages,
  waitingForResponse,
  isResponseFailed,
  chatSessionId,
  shouldAutoScroll,
}) => {
  const { isKomodorAdmin } = useUserMetadata();

  if (messages.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />
      <Typography
        variant="h5"
        display="flex"
        gap="8px"
        padding="8px 16px"
        alignItems="center"
      >
        <ChatOutlined color="action" /> Klaudia Chat
        {isKomodorAdmin && chatSessionId && (
          <Link
            variant="body3"
            component="a"
            href={`/ai-investigator/?sessionId=${chatSessionId}`}
            target="_blank"
          >
            View session (internal)
          </Link>
        )}
      </Typography>
      <Divider />
      <ChatMessagesList
        messages={messages}
        isLoading={waitingForResponse}
        isFailed={isResponseFailed}
        failedMessageContent={<Box paddingY="9px">Something went wrong</Box>}
        autoScroll={shouldAutoScroll}
      />
    </>
  );
};
