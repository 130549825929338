import { Divider, Tabs } from "@komodorio/design-system/deprecated";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import { theme } from "@komodorio/design-system";

import ResponsiveLayout from "../../../common/ResponsiveLayout";
import { WhiteContainer } from "../../../ResourceView/resourceStyles";
import { LinesLoader } from "../../../common/loaders/Line";
import ResourceListErrorState from "../../ResourceListErrorState";
import {
  RESOURCE_PREFIX,
  RESOURCE_TAB_POSTFIX,
  SELECTED_CHART_PARAM_KEY,
} from "../../../../shared/config/urlSearchParamsKeys";
import HelmChart, { LastActionResult } from "../helmChart";
import RevisionHistory from "../RevisionHistory/RevisionHistory";
import { useHelmChartInfo } from "../hooks/useHelmChartInfo";
import { useInterval } from "../../../common/useInterval";
import { useHelmChartRevisions } from "../hooks/useHelmChartRevisions";

import { HelmErrorMessage } from "./HelmErrorMessage";
import { Content, LoaderContainerTab } from "./styles";
import { HelmChartDrawerProps } from "./types";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useActiveAgent } from "@/shared/hooks/useAgents";

const ChartReleaseDrawer: React.FC<HelmChartDrawerProps> = ({
  chartSummary,
  open,
  onClose,
}) => {
  const agentId = useActiveAgent(chartSummary.cluster) ?? "";
  const [selectedChartId, setSelectedChartId] = useStateInSearchParams(
    SELECTED_CHART_PARAM_KEY
  );
  const [lastCommandResult, setLastCommandResult] =
    useState<LastActionResult>();

  const {
    data: revisions,
    isFetching,
    errorMessage,
    execute,
  } = useHelmChartRevisions({
    agentId: agentId,
    cluster: chartSummary.cluster,
    namespace: chartSummary.namespace,
    chartName: chartSummary.name,
  });
  const { data: chartInfo, isFetching: isFetchingChartInfo } = useHelmChartInfo(
    agentId,
    chartSummary.cluster,
    chartSummary.namespace,
    selectedChartId ?? chartSummary.secretId
  );

  useInterval(
    async () => {
      if (revisions || isFetching || errorMessage) return;
      await execute();
    },
    open ? 30000 : 0
  );

  useEffect(() => {
    if (!revisions && !isFetching && !errorMessage) {
      execute();
    }
  }, [errorMessage, execute, isFetching, revisions]);

  const latestRevisionChart: HelmChart = useMemo(() => {
    return new HelmChart({
      id: chartSummary.secretId,
      cluster: chartSummary.cluster,
      name: chartSummary.name,
      namespace: chartSummary.namespace,
      agentId,
      status: chartSummary.status,
      currentRevision:
        revisions?.[revisions?.length - 1]?.revision ?? chartSummary.revision,
      appVersion: chartSummary.version,
      revisions: revisions?.map((r) => ({
        revision: r?.revision,
        id: r?.secretId,
      })),
      lastActionCb: async (value: LastActionResult) => {
        setLastCommandResult(value);
        await execute();
      },
    });
  }, [chartSummary, revisions, agentId, execute]);

  const selectedChart = useMemo(() => {
    const selectedRevision =
      revisions?.find((r) => r.secretId === selectedChartId) ?? chartSummary;
    return new HelmChart({
      id: selectedRevision.secretId,
      cluster: selectedRevision.cluster,
      name: selectedRevision.name,
      namespace: selectedRevision.namespace,
      agentId,
      status: selectedRevision.status,
      currentRevision: selectedRevision.revision,
      appVersion: selectedRevision.version,
      parsedManifest: chartInfo?.parsedManifest,
      manifest: chartInfo?.manifest,
      values: chartInfo?.values,
      notes: chartInfo?.notes,
      revisions: revisions?.map((r) => ({
        revision: r?.revision,
        id: r?.secretId,
      })),
      isLastRevision: selectedRevision.secretId === chartSummary.secretId,
    });
  }, [chartSummary, revisions, selectedChartId, chartInfo, agentId]);

  useEffect(() => {
    if (!chartSummary.secretId && revisions && revisions.length > 0) {
      setSelectedChartId(revisions[revisions.length - 1]?.secretId);
    } else {
      setSelectedChartId(chartSummary.secretId);
    }
  }, [chartSummary.secretId, revisions, setSelectedChartId]);
  const [selectedTab, setSelectedTab] = useStateInSearchParams(
    `${RESOURCE_PREFIX}${latestRevisionChart.kind}${RESOURCE_TAB_POSTFIX}`
  );

  const selectedTabIndex = useMemo(() => {
    const index =
      selectedChart.drawerTabs.findIndex((tab) => tab.label === selectedTab) ||
      0;
    return index < 0 ? 0 : index;
  }, [selectedChart.drawerTabs, selectedTab]);

  const [searchParams, setSearchParams] = useSearchParams();
  const closeDrawer = useCallback(() => {
    const cleanSearchParams = [];
    for (const [key, value] of searchParams.entries()) {
      if (!key.startsWith(`${RESOURCE_PREFIX}${latestRevisionChart.kind}`)) {
        cleanSearchParams.push([key, value]);
      }
    }
    setSearchParams(new URLSearchParams(cleanSearchParams));
    onClose();
  }, [setSearchParams, onClose, searchParams, latestRevisionChart.kind]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeDrawer}
      elevation={5}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          width: "60%",
          backgroundColor: theme.background.bgGrayLight,
        },
      }}
    >
      <WhiteContainer>
        <ResponsiveLayout>
          {latestRevisionChart.renderDrawerHeader(
            <IconButton onClick={closeDrawer} sx={{ padding: 0 }}>
              <Close />
            </IconButton>
          )}
          <br />
          {errorMessage && (
            <ResourceListErrorState
              errorMessage={errorMessage}
              resourceName={latestRevisionChart.name}
              refreshCallback={execute}
            />
          )}
          {isFetching && !revisions ? (
            <LinesLoader />
          ) : (
            <RevisionHistory revisions={revisions ?? []} />
          )}
          {lastCommandResult && lastCommandResult?.success === false && (
            <HelmErrorMessage result={lastCommandResult} />
          )}
          <br />
          <Tabs
            tabs={selectedChart.drawerTabs}
            value={selectedTabIndex}
            onChange={(selectedTabIndex) => {
              setSelectedTab(selectedChart.drawerTabs[selectedTabIndex].label);
            }}
          />
        </ResponsiveLayout>
      </WhiteContainer>
      <Divider />
      <Content>
        <ResponsiveLayout>
          {isFetchingChartInfo ? (
            <LoaderContainerTab>
              <LinesLoader />
            </LoaderContainerTab>
          ) : (
            selectedChart.renderDrawerContent(selectedTabIndex)
          )}
        </ResponsiveLayout>
        <br />
      </Content>
    </Drawer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ChartReleaseDrawer;
