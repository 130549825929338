/* eslint-disable max-lines */
import React, { useMemo, useState } from "react";
import { StackedComparisonTable } from "@komodorio/design-system/komodor-ui";

import { useDriftComparisonContext } from "../../context/useDriftComparisonContext";
import {
  DiffModal,
  DiffModalParams,
  SeeAllModalParams,
} from "../../common/DiffModal";

import { YamlViewer } from "./YamlViewer";
import { ContainerComparisonTable } from "./ContainerComparisonTable";
import { ContainerTableNoData } from "./ContainerTableNoData";

import { ServiceComparisonResponse } from "@/generated/workspacesApi";

export type ContainersAttributesComparisonProps = {
  data: ServiceComparisonResponse | undefined;
  showOnlyDrift: boolean;
  isLoading: boolean;
  isError: boolean;
  errorStateElement: React.ReactNode;
};

export const ContainersAttributesComparison: React.FC<
  ContainersAttributesComparisonProps
> = ({ data, showOnlyDrift, isLoading, isError, errorStateElement }) => {
  const { baselineSelection, comparedSelections } = useDriftComparisonContext();
  const [seeAllModal, setSeeAllModal] = useState<SeeAllModalParams | null>(
    null
  );
  const [diffModal, setDiffModal] = useState<DiffModalParams | null>(null);
  const isContainersDriftDetected = useMemo(() => {
    return data?.containers?.some((container) => container.isDiff) ?? false;
  }, [data?.containers]);

  return (
    <>
      {(!showOnlyDrift || isContainersDriftDetected) && (
        <StackedComparisonTable title="Container Attributes">
          {!data?.containers ? (
            <ContainerTableNoData
              isLoading={isLoading}
              isError={isError}
              errorStateElement={errorStateElement}
            />
          ) : (
            data.containers.map((container) => {
              return (
                <ContainerComparisonTable
                  key={container.name}
                  targetContainer={container}
                  targetServices={data.targetServices}
                  isSingleContainer={data.containers.length === 1}
                  isComparedEmpty={!comparedSelections?.length}
                  isLoading={isLoading}
                  setDiffModal={setDiffModal}
                  setSeeAllModal={setSeeAllModal}
                />
              );
            })
          )}
        </StackedComparisonTable>
      )}
      {baselineSelection !== null && seeAllModal !== null ? (
        <YamlViewer
          baselineUid={baselineSelection.value}
          title={seeAllModal.title}
          content={seeAllModal.content}
          onClose={() => setSeeAllModal(null)}
        />
      ) : null}
      {baselineSelection !== null && diffModal !== null ? (
        <DiffModal
          title={diffModal?.title}
          baselineUid={baselineSelection.value}
          comparedUid={diffModal.comparedUid}
          oldObj={diffModal?.oldObj}
          newObj={diffModal?.newObj}
          onClose={() => setDiffModal(null)}
          isYaml={true}
        />
      ) : null}
    </>
  );
};
