import { VIEW_UNAVAILABLE_TEXT } from "@/components/k8sAddons/components/utils";
import { ALL_CLUSTERS } from "@/components/workspaces/WorkspacesTopBar/constants";

export const getUnsupportedWorkspaceText = () => {
  return (
    <>
      The helm drift {VIEW_UNAVAILABLE_TEXT}
      <br />
      Please choose a different workspace or navigate to {ALL_CLUSTERS}.
    </>
  );
};
export const MAX_COMPARED_RELEASES = 10;

export const SHOW_ONLY_DRIFT_HELM_KEY = "showOnlyDriftHelm";

export const HELM_DRIFT_ATTRIBUTES_COMPONENT_NAME =
  "HelmDriftAnalysisAttributes";
