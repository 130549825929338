import React, { useCallback } from "react";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { RbacRole } from "../../../generated/auth";
import { useDeleteRbacRole } from "../../../shared/hooks/auth-service/client/rbacRoles/useDeleteRbacRole";

const DeleteRoleModalContent = ({ roleName }: { roleName: string }) => (
  <Stack rowGap="8px">
    <Typography variant="body2">
      Are you sure you want to delete this role?
    </Typography>
    <Typography variant="body2">
      Name: <b>{roleName}</b>
    </Typography>
  </Stack>
);

export const DeleteRoleModal: React.FC<{
  role: RbacRole;
  onClose: () => void;
  refreshRoles: () => void;
}> = ({ role, onClose, refreshRoles }) => {
  const { mutateAsync: deleteRole, isLoading: deleteLoading } =
    useDeleteRbacRole();

  const handleOnClose = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        const result = await deleteRole({ id: role.id });
        dispatchEvent(AnalyticEvents.Settings.DeleteRole, {
          roleName: result.name,
        });

        refreshRoles();
      }

      onClose();
    },
    [onClose, refreshRoles, deleteRole, role.id]
  );

  return (
    <ConfirmationDialog
      title="Delete Role"
      content={<DeleteRoleModalContent roleName={role.name} />}
      onClose={handleOnClose}
      okText={deleteLoading ? "Deleting..." : "Delete"}
      okDisabled={deleteLoading}
      type="error"
      width="fit-content"
    />
  );
};
