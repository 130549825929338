import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { EmptyState } from "@komodorio/design-system/komodor-ui";
import Button from "@mui/material/Button";

import { WorkspaceManagementControls } from "./WorkspaceManagementControls";
import { ScopedWorkspacesList } from "./ScopedWorkspacesList";

import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { CenteredLoader } from "@/shared/components/CenteredLoader";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useGetWorkspaces } from "@/shared/hooks/workspaces-api/workspaces/useGetWorkspaces";
import { WorkspaceKind } from "@/generated/workspacesApi";
import { TypedScopeWorkspace } from "@/shared/hooks/workspaces-api/types";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { WORKSPACE_MANAGEMENT_SEARCH_NAME_KEY } from "@/shared/config/urlSearchParamsKeys";

export const WorkspaceManagementPage = () => {
  const [searchTerm, setSearchTerm] = useStateInSearchParams(
    WORKSPACE_MANAGEMENT_SEARCH_NAME_KEY
  );
  const { canManageWorkspaces } = useHasPermissions();
  const { isFetching, data } = useGetWorkspaces({
    enabled: canManageWorkspaces,
  });

  const filteredWs = useMemo(() => {
    return data
      ?.filter((item) => item.kind === "scoped")
      .map(
        (existing) =>
          ({
            ...existing,
            kind: WorkspaceKind.Scoped,
            readableKind: "scoped",
          } as TypedScopeWorkspace)
      );
  }, [data]);

  const pageContents = useMemo(() => {
    const hasItems = filteredWs?.length !== 0;

    if (isFetching) {
      return <CenteredLoader />;
    }

    return (
      <Stack rowGap="16px">
        <WorkspaceManagementControls
          searchTerm={searchTerm ?? ""}
          setSearchTerm={setSearchTerm}
          controlsDisabled={!hasItems}
          createdByValues={[]}
        />

        {hasItems ? (
          <ScopedWorkspacesList
            isLoading={isFetching}
            data={filteredWs ?? []}
            searchTerm={searchTerm ?? ""}
          />
        ) : (
          <EmptyState
            title={
              <Stack rowGap="16px">
                <Typography variant="h4">No workspaces created</Typography>
                <Button variant="text">Add new workspace</Button>
              </Stack>
            }
          />
        )}
      </Stack>
    );
  }, [isFetching, filteredWs, searchTerm, setSearchTerm]);

  return (
    <SettingsViewVerticalLayout title="Workspace Management">
      {canManageWorkspaces ? (
        pageContents
      ) : (
        <Typography>Access denied due to lack of permissions.</Typography>
      )}
    </SettingsViewVerticalLayout>
  );
};
