import React, { useEffect, useMemo, useRef } from "react";

import InspectionViewComponent, {
  useInspectionParams,
} from "../InspectionViews/common";
import { KubernetesSecretsResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesSecretsResource";
import { useInspectionData } from "../utils/useGetInspectionData";
import { Panel } from "../styles";
import FetchDataLoading from "../../common/loaders/LoadingState";
import {
  AgentInfo,
  agentInfoToProperties,
} from "../../../shared/hooks/useAgentInfo/useAgentInfo";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { getViewOptionsByResource } from "@/components/Inspection/InspectionViews/datadogReporting/inspectionViewsReportingUtils";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";

const SecretsPageContent: React.FC = () => {
  const {
    cluster,
    onClusterChange,
    agentInfo: agentType,
    agentId,
  } = useInspectionParams(KubernetesSecretsResource);
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  const agentInfo = useMemo(() => {
    if (!agentType) return null;
    return agentInfoToProperties(agentType as AgentInfo);
  }, [agentType]);

  const { namespacesResult, resourceList, refresh, isLiveDataSupported } =
    useInspectionData({
      agentId: agentId ?? "",
      agentInfo,
      cluster: cluster ?? "",
      kubernetesResource: KubernetesSecretsResource,
      fieldSelector: "type!=helm.sh/release.v1", // exclude helm secrets
    });

  const hasReportedLoadingStateDone = useRef(false);
  useEffect(() => {
    // this is to make sure we will report DD timing only once, even for polling requests
    if (hasReportedLoadingStateDone.current) {
      return;
    }

    reportLoadingState("resource", resourceList.fetching);
    if (!resourceList.fetching && !resourceList.isPaginating) {
      hasReportedLoadingStateDone.current = true;
    }
  }, [
    reportLoadingState,
    resourceList.fetching,
    resourceList.isPaginating,
    resourceList.rows,
  ]);

  if (!cluster || !agentId) {
    return (
      <Panel>
        <FetchDataLoading
          dataName={
            KubernetesSecretsResource.PresentName ??
            KubernetesSecretsResource.NameInK8S
          }
        />
      </Panel>
    );
  }

  return (
    <InspectionViewComponent
      cluster={cluster}
      onClusterChange={onClusterChange}
      resourceList={resourceList}
      kubernetesResource={KubernetesSecretsResource}
      refresh={refresh}
      namespaces={namespacesResult}
      shouldUseLiveData={isLiveDataSupported}
    />
  );
};

export const SecretsPage: React.FC = () => {
  return (
    <DatadogReportLoadingTimeContextProvider
      viewOptions={{
        ...getViewOptionsByResource(KubernetesSecretsResource),
      }}
    >
      <SecretsPageContent />
    </DatadogReportLoadingTimeContextProvider>
  );
};
