import { useMemo } from "react";

import { useAuthorization } from "../roles/useAuthorization";
import { useAppViewsStore } from "../../store/appViewsStore/appViewsStore";
import {
  currentAppViewSelector,
  selectedAppViewIdSelector,
} from "../../store/appViewsStore/appViewStoreSelectors";

import { APP_VIEW_ID_HEADER, WORKSPACE_ID_HEADER } from "./constants";

export type RequestHeadersParams = {
  skipAppViewInHeaders?: boolean;
  skipWorkspaceHeader?: boolean;
  accept?: string;
};

export const useRequestHeaders = (params?: RequestHeadersParams) => {
  const { skipAppViewInHeaders, skipWorkspaceHeader, accept } = params ?? {};
  const authorization = useAuthorization() ?? undefined;
  const appViewId = useAppViewsStore(selectedAppViewIdSelector);
  const currentAppView = useAppViewsStore(currentAppViewSelector);

  return useMemo(() => {
    let wsHeaders = {};

    const isScopedWs = currentAppView?.kind === "scoped";

    // If the current app view is a workspace,
    // of type "scoped", we need to add the workspace id to the headers
    // See: https://app.clickup.com/t/86c1r0c84
    if (isScopedWs && !skipWorkspaceHeader) {
      wsHeaders = { [WORKSPACE_ID_HEADER]: currentAppView.id };
    }

    return {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: authorization,
      ...(appViewId &&
        !skipAppViewInHeaders &&
        !isScopedWs && {
          [APP_VIEW_ID_HEADER]: appViewId,
        }),
      ...wsHeaders,
      ...(accept && { Accept: accept }),
    };
  }, [
    currentAppView?.kind,
    currentAppView?.id,
    skipAppViewInHeaders,
    skipWorkspaceHeader,
    authorization,
    appViewId,
    accept,
  ]);
};
