import { Box, List } from "@mui/material";
import {
  ChatMessage,
  ChatMessageType,
} from "components/komodor-ui/Chat/ChatMessage";
import { TypingIndicator } from "components/komodor-ui/Chat/TypingIndicator";
import { useEffect, useMemo, useRef } from "react";

export interface ChatMessagesListProps {
  messages: ChatMessageType[];
  isLoading?: boolean;
  isFailed?: boolean;
  failedMessageContent?: ChatMessageType["content"];
  autoScroll?: boolean;
}

export const ChatMessagesList: React.FC<ChatMessagesListProps> = ({
  messages,
  isLoading,
  isFailed,
  failedMessageContent,
  autoScroll = true,
}) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // Auto-scroll to the bottom when new messages are added
  useEffect(() => {
    if (autoScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const chatMessages = useMemo(
    () => messages.map((msg) => <ChatMessage key={msg.id} message={msg} />),
    [messages]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      overflow="auto"
    >
      <List>
        {chatMessages}
        {isFailed ? (
          <ChatMessage
            message={{
              id: "failed",
              content: failedMessageContent,
              isFailed: true,
              isUser: false,
            }}
          />
        ) : isLoading ? (
          <ChatMessage
            message={{
              id: "typing",
              content: <TypingIndicator />,
              isUser: false,
            }}
          />
        ) : null}
        {/* Reference for auto-scrolling */}
        <div ref={messagesEndRef} />
      </List>
    </Box>
  );
};
