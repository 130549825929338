import {
  IResourceDrawerByData,
  IResourceDrawerByServiceId,
} from "@/components/ResourceView/types";
import {
  IReliabilityByScope,
  ViolationDrawerProps,
} from "@/components/reliability";
import { ReliabilityPolicyDrawerProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerTypes";
import { IEventDrawerById } from "@/components/common/ProcessList/types";
import { LiveStateDrawerProps } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerTypes";
import { RealtimeIssuesDrawerProps } from "@/components/reliability/components/RealtimeIssuesDrawer/types";
import { KlaudiaChatProps } from "@/components/KlaudiaChat/KlaudiaChatDrawer";
import { HelmDrawerProps } from "@/components/Inspection/Helm/drawers/types";

export interface DrawerStateBase {
  index: number;
}

export type UrlStates = { [key: string]: unknown };
export interface DrawerUrlState {
  urlStates?: UrlStates;
}

export enum DrawerType {
  ResourceDrawerByData = "ResourceDrawerByData",
  ResourceDrawerByServiceId = "ResourceDrawerByServiceId",
  ViolationsDrawerByScope = "ViolationsDrawerByScope",
  ViolationDrawer = "ViolationDrawer",
  RealtimeIssuesDrawer = "RealtimeIssuesDrawer",
  ReliabilityPolicyDrawer = "ReliabilityPolicyDrawer",
  EventDrawer = "EventDrawer",
  EventDrawerById = "EventDrawerById",
  K8sAddonLiveStateDrawer = "K8sAddonLiveStateDrawer",
  KlaudiaChatDrawer = "KlaudiaChatDrawer",
  HelmReleaseDrawer = "HelmReleaseDrawer",
}

export interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

export type DrawerState = DrawerUrlState & { drawerType: DrawerType };

type DrawerStateByDrawerType<T extends DrawerType, U> = DrawerUrlState & {
  drawerType: T;
} & U;

export type ResourceDrawerByDataType = DrawerStateByDrawerType<
  DrawerType.ResourceDrawerByData,
  IResourceDrawerByData
>;

export type ResourceDrawerByServiceIdType = DrawerStateByDrawerType<
  DrawerType.ResourceDrawerByServiceId,
  IResourceDrawerByServiceId
>;

export type ViolationsDrawerType = DrawerStateByDrawerType<
  DrawerType.ViolationDrawer,
  ViolationDrawerProps
>;

export type ViolationsDrawerByScopeType = DrawerStateByDrawerType<
  DrawerType.ViolationsDrawerByScope,
  IReliabilityByScope
>;

export type ReliabilityPolicyDrawerType = DrawerStateByDrawerType<
  DrawerType.ReliabilityPolicyDrawer,
  ReliabilityPolicyDrawerProps
>;

export type EventDrawerByIdType = DrawerStateByDrawerType<
  DrawerType.EventDrawerById,
  IEventDrawerById
>;

export type K8sAddonLiveStateDrawerType = DrawerStateByDrawerType<
  DrawerType.K8sAddonLiveStateDrawer,
  LiveStateDrawerProps
>;

export type RealtimeIssuesDrawerType = DrawerStateByDrawerType<
  DrawerType.RealtimeIssuesDrawer,
  RealtimeIssuesDrawerProps
>;

export type KlaudiaChatDrawerType = DrawerStateByDrawerType<
  DrawerType.KlaudiaChatDrawer,
  KlaudiaChatProps
>;

export type HelmReleaseDrawerType = DrawerStateByDrawerType<
  DrawerType.HelmReleaseDrawer,
  HelmDrawerProps
>;

export type DrawerStateType =
  | (DrawerStateBase & ResourceDrawerByDataType)
  | (DrawerStateBase & ResourceDrawerByServiceIdType)
  | (DrawerStateBase & ViolationsDrawerType)
  | (DrawerStateBase & ViolationsDrawerByScopeType)
  | (DrawerStateBase & ReliabilityPolicyDrawerType)
  | (DrawerStateBase & EventDrawerByIdType)
  | (DrawerStateBase & K8sAddonLiveStateDrawerType)
  | (DrawerStateBase & RealtimeIssuesDrawerType)
  | (DrawerStateBase & KlaudiaChatDrawerType)
  | (DrawerStateBase & HelmReleaseDrawerType);

export type DrawerStatePush =
  | ResourceDrawerByDataType
  | ResourceDrawerByServiceIdType
  | ViolationsDrawerType
  | ViolationsDrawerByScopeType
  | ReliabilityPolicyDrawerType
  | EventDrawerByIdType
  | K8sAddonLiveStateDrawerType
  | RealtimeIssuesDrawerType
  | KlaudiaChatDrawerType
  | HelmReleaseDrawerType;

export type DrawerStateSet = UrlStates;
