import {
  ContentType,
  LabeledContent as LabeledContentData,
} from "@/components/common/LabeledData/LabeledContent.types";
import { ChipsFlexContainer } from "@/components/k8sAddons/LiveStateDrawer/components/ChipsFlexContainer";
import { ListOfLinks } from "@/components/common/LabeledData/ListOfLinks";

type LabeledContentProps = {
  content: LabeledContentData;
};

export const LabeledContent: React.FC<LabeledContentProps> = ({ content }) => {
  switch (content.type) {
    case ContentType.String:
      return content.value;
    case ContentType.Chips:
      return <ChipsFlexContainer values={content.value} />;
    case ContentType.ButtonsList:
      return <ListOfLinks links={content.value} />;
  }
};
