import { useCallback } from "react";

import { useInspectionPreferencesInLocalStorage } from "@/components/Inspection/InspectionViews/localStorageHooks";
import { useInspectionStore } from "@/shared/store/inspection/inspectionStore";
import {
  selectedNamespacesSelector,
  setNamespacesSelector,
} from "@/shared/store/inspection/inspectionSelectors";
import { InspectionViewPreferences } from "@/components/Inspection/InspectionViews/localStorage";

type UseNamespacesFilterOutput = [string[], (namespaces: string[]) => void];

/**
 * @param resourceKeyInLS if the param is not provided, the namespaces will be managed in the store
 * @returns namespaces getter and setter
 */
export const useNamespacesFilter = (
  resourceKeyInLS?: string
): UseNamespacesFilterOutput => {
  const [inspectionViewPreferencesInLs, setInspectionViewPreferencesInLs] =
    useInspectionPreferencesInLocalStorage();

  const selectedNamespaces = useInspectionStore(selectedNamespacesSelector);
  const setNamespaces = useInspectionStore(setNamespacesSelector);

  const setNamespacesInLs = useCallback(
    (namespaces: string[]) => {
      if (!resourceKeyInLS) return;
      const newPreferences: InspectionViewPreferences = {
        ...inspectionViewPreferencesInLs,
        selectedNamespaces: {
          ...inspectionViewPreferencesInLs.selectedNamespaces,
          [resourceKeyInLS]: namespaces,
        },
      };
      setInspectionViewPreferencesInLs(JSON.stringify(newPreferences));
    },
    [
      inspectionViewPreferencesInLs,
      resourceKeyInLS,
      setInspectionViewPreferencesInLs,
    ]
  );

  if (!resourceKeyInLS) {
    return [selectedNamespaces, setNamespaces];
  }

  const namespaces =
    inspectionViewPreferencesInLs.selectedNamespaces[resourceKeyInLS] ??
    selectedNamespaces; // fallback to store if not in LS

  return [namespaces, setNamespacesInLs];
};
