import {
  EndpointsRequestMap as MetricsEndpointsRequestMap,
  EndpointsResponseMap as MetricsEndpointsResponseMap,
} from "../metrics-api/requestResponseMaps";
import {
  EndpointsResponseMap as MonitorsEndpointsResponseMap,
  EndpointsRequestMap as MonitorsEndpointsRequestMap,
} from "../monitors-api/requestResponseMaps";
import {
  EndpointsResponseMap as ResourcesEndpointsResponseMap,
  EndpointsRequestMap as ResourceEndpointsRequestMap,
} from "../resources-api/requestResponseMaps";
import {
  EndpointsResponseMap as KomodorCostEndpointsResponseMap,
  EndpointsRequestMap as KomodorCostEndpointsRequestMap,
} from "../komodor-cost-api/requestResponseMaps";

export const MAX_RETRIES = 3;
export const CLIENT_ERROR_PREFIX = "Client error:";
export const SERVER_ERROR_PREFIX = "Server error:";
export const CLIENT_CACHE_EXPIRY_IN_SECONDS = 5;

export type EndpointRequestTypeNames =
  | keyof MetricsEndpointsResponseMap
  | keyof ResourcesEndpointsResponseMap
  | keyof MonitorsEndpointsResponseMap
  | keyof KomodorCostEndpointsResponseMap;

export type EndpointsRequestType =
  | MetricsEndpointsRequestMap[keyof MetricsEndpointsResponseMap]
  | ResourceEndpointsRequestMap[keyof ResourcesEndpointsResponseMap]
  | MonitorsEndpointsRequestMap[keyof MonitorsEndpointsResponseMap]
  | KomodorCostEndpointsRequestMap[keyof KomodorCostEndpointsResponseMap];

export type EndpointsResponseType =
  | MetricsEndpointsResponseMap[keyof MetricsEndpointsResponseMap]
  | ResourcesEndpointsResponseMap[keyof ResourcesEndpointsResponseMap]
  | MonitorsEndpointsResponseMap[keyof MonitorsEndpointsResponseMap]
  | KomodorCostEndpointsResponseMap[keyof KomodorCostEndpointsResponseMap];
