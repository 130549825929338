export enum Permissions {
  canManageUsers = "canManageUsers",
  canManageAccountAccess = "canManageAccountAccess",
  canManageUsage = "canManageUsage",
  canViewAudit = "canViewAudit",
  canManageAgents = "canManageAgents",
  canManageReliabilityPolicies = "canManageReliabilityPolicies",
  canManageReliability = "canManageReliability",
  canManageMonitors = "canManageMonitors",
  canManageIntegrations = "canManageIntegrations",
  canManageFeatures = "canManageFeatures",
  canManageWorkspaces = "canManageWorkspaces",
  canManageTrackedKeys = "canManageTrackedkeys",
}
