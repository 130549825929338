import { useMemo } from "react";

import { useDriftComparisonContext } from "../../context/useDriftComparisonContext";
import { SeeAllModalParams } from "../../common/DiffModal";
import { SHOW_ONLY_DRIFT_SERVICES_KEY } from "../constants";

import { DiffModal, AttributesTableData, ContainerTableData } from "./types";
import {
  getBaselineTableData,
  getComparedTableData,
  getTableAttributes,
} from "./AttributesTableUtils";
import {
  useContainerTableAttributes,
  getComparedServicesContainers,
  getBaselineContainer,
} from "./ContainersTableUtils";

import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import {
  ServiceComparisonResponse,
  ServiceComparisonResponseContainer,
  ServiceComparisonResponseTargetServices,
} from "@/generated/workspacesApi";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

export const useAttributesTableData = ({
  showOnlyDrift,
  data,
  setDiffModal,
}: {
  showOnlyDrift: boolean;
  data: ServiceComparisonResponse | undefined;
  setDiffModal: (diffModal: DiffModal | null) => void;
}): AttributesTableData => {
  const { baselineSelection, comparedSelections } = useDriftComparisonContext();
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return useMemo(() => {
    const baselineParsedKomdorUid = baselineSelection
      ? parseKomodorUid(baselineSelection.value)
      : undefined;
    const baselineData = getBaselineTableData({
      baselineSelection,
      data,
      baselineParsedKomdorUid,
    });

    return {
      attributes: getTableAttributes({
        baselineData,
        data,
        showOnlyDrift,
        pushDrawer,
        setDiffModal,
      }),
      compared: getComparedTableData({
        data,
        comparedSelections,
        showOnlyDrift,
        baselineParsedKomdorUid,
      }),
      baseline: baselineData,
    };
  }, [
    baselineSelection,
    data,
    showOnlyDrift,
    pushDrawer,
    setDiffModal,
    comparedSelections,
  ]);
};

export const useContainersTableData = ({
  targetContainer,
  targetServices,
  isLoading,
  setDiffModal,
  setSeeAllModal,
}: {
  targetContainer: ServiceComparisonResponseContainer;
  targetServices: ServiceComparisonResponseTargetServices[] | undefined;
  isLoading: boolean;
  setDiffModal: (diffModal: DiffModal | null) => void;
  setSeeAllModal: (seeAllModal: SeeAllModalParams) => void;
}): ContainerTableData => {
  const { baselineSelection, comparedSelections } = useDriftComparisonContext();
  const [showOnlyDrift] = useStringifiedStateInSearchParams<boolean>(
    SHOW_ONLY_DRIFT_SERVICES_KEY
  );
  const baselineContainer = getBaselineContainer(
    baselineSelection,
    targetContainer
  );
  const attributes = useContainerTableAttributes({
    targetServices,
    baselineContainer,
    targetContainer,
    setDiffModal,
    setSeeAllModal,
  });
  return useMemo(
    () => ({
      attributes,
      compared: getComparedServicesContainers({
        targetContainer,
        targetServices,
        comparedSelections,
        isLoading,
        showOnlyDrift: showOnlyDrift ?? false,
      }),
      baseline: baselineContainer,
    }),
    [
      attributes,
      baselineContainer,
      comparedSelections,
      isLoading,
      showOnlyDrift,
      targetContainer,
      targetServices,
    ]
  );
};
