import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useRef } from "react";

import { RichSessionData } from "@/components/AiInvestigation/useEnrichedSession";
import { WhatHappened } from "@/components/AiInvestigation/AiInvestigationContent/WhatHappened";
import { RelatedEvidence } from "@/components/AiInvestigation/AiInvestigationContent/RelatedEvidence";
import { SuggestedRemediation } from "@/components/AiInvestigation/AiInvestigationContent/SuggestedRemediation";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useAiInvestigationChat } from "@/components/AiInvestigation/AiInvestigationChat/useAiInvestigationChat";
import { ChatBody } from "@/components/AiInvestigation/AiInvestigationChat/ChatBody";
import { ChatFooter } from "@/components/AiInvestigation/AiInvestigationChat/ChatFooter";
import { Feedback } from "@/components/AiInvestigation/AiInvestigationContent/Feedback";

interface AiInvestigationResultsProps {
  richSession: RichSessionData;
  cluster: string;
}

export const AiInvestigationResults: React.FC<AiInvestigationResultsProps> = ({
  richSession,
  cluster,
}) => {
  const resultsRef = useRef<HTMLDivElement>(null);
  const { klaudiaChatRca } = useOverridableFlags();
  const {
    messages,
    handleSendMessage,
    waitingForResponse,
    isResponseFailed,
    chatSessionId,
    input,
    setInput,
    shouldDisableInput,
    shouldAutoScroll,
  } = useAiInvestigationChat({ cluster, richSession });

  return (
    <Stack>
      <Box
        maxHeight={resultsRef.current?.clientHeight}
        minHeight="200px"
        overflow="auto"
      >
        <Box
          display="grid"
          gridTemplateColumns="max-content auto"
          gap="10px 32px"
          padding="16px 16px 8px 16px"
          ref={resultsRef}
        >
          <WhatHappened session={richSession} />
          <RelatedEvidence session={richSession} />
          <SuggestedRemediation session={richSession} />
          <Feedback />
        </Box>
        {!!klaudiaChatRca && (
          <ChatBody
            messages={messages}
            waitingForResponse={waitingForResponse}
            isResponseFailed={isResponseFailed}
            chatSessionId={chatSessionId}
            shouldAutoScroll={shouldAutoScroll}
          />
        )}
      </Box>
      {!!klaudiaChatRca && (
        <ChatFooter
          input={input}
          setInput={setInput}
          shouldDisableInput={shouldDisableInput}
          handleSendMessage={handleSendMessage}
        />
      )}
    </Stack>
  );
};
